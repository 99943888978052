import {
  EXCHANGE_POINT_TO_HEART,
  FETCH_DATA_FOOT_PRINTS,
  FETCH_DATA_FOOT_PRINTS_FAIL,
  FETCH_DATA_FOOT_PRINTS_SUCCESS,
  FETCH_DATA_FOOT_UPDATE,
  FETCH_DATA_FOOT_UPDATE_FAIL,
  FETCH_DATA_FOOT_UPDATE_SUCCESS,
  FETCH_DATA_SKIPPED,
  FETCH_DATA_SKIPPED_FAIL,
  FETCH_DATA_SKIPPED_SUCCESS,
  FETCH_BOX_NOTIFICATION,
  FETCH_BOX_NOTIFICATION_FAILED,
  FETCH_BOX_NOTIFICATION_SUCCESS,
  CHANGE_NOTI_MESSAGE_REQUEST,
  CHANGE_NOTI_MESSAGE_SUCCESS,
  CHANGE_NOTI_MESSAGE_FAILED,
  API_NOTI_MESSAGE_REQUEST,
  API_NOTI_MESSAGE_SUCCESS,
  API_NOTI_MESSAGE_FAILED,
  FETCH_DETAIL_NOTIFICATION,
  FETCH_DETAIL_NOTIFICATION_FAILED,
  FETCH_DETAIL_NOTIFICATION_SUCCESS,
  UPDATE_READ_NOTIFICATION,
  UPDATE_READ_NOTIFICATION_FAILED,
  UPDATE_READ_NOTIFICATION_SUCCESS,
  FETCH_DATA_MUTED,
  FETCH_DATA_MUTED_FAIL,
  FETCH_DATA_MUTED_SUCCESS,
  UNMUTE_USER,
  SEND_QUESTION_REQUEST,
  DELETE_ACCOUNT_REQUEST,
  ADD_EMAIL,
  SEND_WITHDRAWAL_REQUEST,
  UPDATE_BANK_REQUEST,
  GET_WITHDRAWAL_HISTORY,
  ON_DATA_REPORT,
  ON_DATA_MATCHING_READ,
  HIDE_USER,
  GET_BANNER_SUCCESS,
  GET_BANNER_REQUESTED,
  GET_BANNER_FAILED,
  SEND_VERIFY_EMAIL,
  NOTIFICATION_SETTING_REQUESTED,
  GET_NOTI_SETTING_REQUESTED
} from "../constants/actions/other"

export const profileAction = (data = {}, callbacks) => {
  return {
    type: EXCHANGE_POINT_TO_HEART,
    payload: {
      data,
      callbacks
    }
  }
}

export const fetchDataFootPrints = (data = {}, callbacks) => {
  return {
    type: FETCH_DATA_FOOT_PRINTS,
    payload: {
      data,
      callbacks
    }
  }
}
export const fetchDataFootPrintsSuccess = (data) => {
  return {
    type: FETCH_DATA_FOOT_PRINTS_SUCCESS,
    payload: {
      data
    }
  }
}

export const fetchDataFootPrintsFail = () => {
  return {
    type: FETCH_DATA_FOOT_PRINTS_FAIL
  }
}

export const fetchDataListSkipped = (data = {}) => {
  return {
    type: FETCH_DATA_SKIPPED,
    payload: {
      data
    }
  }
}
export const fetchDataListSkippedSuccess = (data) => {
  return {
    type: FETCH_DATA_SKIPPED_SUCCESS,
    payload: {
      data
    }
  }
}

export const fetchDataListSkippedFail = () => {
  return {
    type: FETCH_DATA_SKIPPED_FAIL
  }
}

export const fetchDataFootUpdate = (data = {}, callbacks) => {
  return {
    type: FETCH_DATA_FOOT_UPDATE,
    payload: {
      data
    },
    callbacks
  }
}

export const fetchDataFootUpdateSuccess = (data) => {
  return {
    type: FETCH_DATA_FOOT_UPDATE_SUCCESS,
    payload: {
      data
    }
  }
}

export const fetchDataFootUpdateFail = () => {
  return {
    type: FETCH_DATA_FOOT_UPDATE_FAIL
  }
}

export const fetchDataBoxNotification = (dataDetail) => {
  return {
    type: FETCH_BOX_NOTIFICATION,
    payload: {
      dataDetail: dataDetail
    }
  }
}
export const fetchDataBoxNotificationSuccess = (dataDetail) => {
  return {
    type: FETCH_BOX_NOTIFICATION_SUCCESS,
    payload: {
      dataDetail: dataDetail
    }
  }
}

export const fetchDataBoxNotificationFailed = () => {
  return {
    type: FETCH_BOX_NOTIFICATION_FAILED
  }
}

export const fetchDetailNotification = (dataDetail) => {
  return {
    type: FETCH_DETAIL_NOTIFICATION,
    dataDetail: dataDetail
  }
}

export const fetchNotiMessageRequested = (data = {}, callbacks) => {
  return {
    type: CHANGE_NOTI_MESSAGE_REQUEST,
    payload: {
      data: data
    },
    callbacks
  }
}

export const fetchNotiMessageSuccess = (data) => {
  return {
    type: CHANGE_NOTI_MESSAGE_SUCCESS,
    data
  }
}

export const fetchNotiMessageFailed = () => {
  return {
    type: CHANGE_NOTI_MESSAGE_FAILED
  }
}

export const fetchApiNotiMessageRequested = (data = {}, callbacks) => {
  return {
    type: API_NOTI_MESSAGE_REQUEST,
    payload: {
      data: data
    },
    callbacks
  }
}

export const fetchApiNotiMessageSuccess = (data) => {
  return {
    type: API_NOTI_MESSAGE_SUCCESS,
    data
  }
}

export const fetchApiNotiMessageFailed = () => {
  return {
    type: API_NOTI_MESSAGE_FAILED
  }
}

export const fetchDetailNotificationSuccess = (dataDetail) => {
  return {
    type: FETCH_DETAIL_NOTIFICATION_SUCCESS,
    dataDetail
  }
}

export const fetchDetailNotificationFailed = () => {
  return {
    type: FETCH_DETAIL_NOTIFICATION_FAILED
  }
}

export const fetchUpdateReadNotification = (dataDetail) => {
  return {
    type: UPDATE_READ_NOTIFICATION,
    dataDetail: dataDetail
  }
}

export const fetchUpdateReadNotificationSuccess = (dataDetail) => {
  return {
    type: UPDATE_READ_NOTIFICATION_SUCCESS,
    dataDetail
  }
}

export const fetchUpdateReadNotificationFailed = () => {
  return {
    type: UPDATE_READ_NOTIFICATION_FAILED
  }
}

export const fetchDataListMuted = (data = {}, callbacks) => {
  return {
    type: FETCH_DATA_MUTED,
    payload: {
      data,
      callbacks
    }
  }
}
export const fetchDataMutedSuccess = (data) => {
  return {
    type: FETCH_DATA_MUTED_SUCCESS,
    payload: {
      data
    }
  }
}

export const fetchDataMutedFail = () => {
  return {
    type: FETCH_DATA_MUTED_FAIL
  }
}

export const unMuteUser = (data = {}, callbacks) => {
  return {
    type: UNMUTE_USER,
    payload: { userId: data, callbacks }
  }
}

export const sendQuestionRequest = (data = {}, callbacks) => {
  return {
    type: SEND_QUESTION_REQUEST,
    payload: data,
    callbacks
  }
}

export const onDeleteAccountRequest = (data = {}, callbacks) => {
  return {
    type: DELETE_ACCOUNT_REQUEST,
    payload: data,
    callbacks
  }
}

export const addEmail = (data, callbacks) => {
  return {
    type: ADD_EMAIL,
    payload: data,
    callbacks
  }
}

export const withdrawalRequest = (data = {}, callbacks) => {
  return {
    type: SEND_WITHDRAWAL_REQUEST,
    payload: data,
    callbacks
  }
}

export const updateBankRequest = (data = {}, callbacks) => {
  return {
    type: UPDATE_BANK_REQUEST,
    payload: data,
    callbacks
  }
}

export const getWithdrawalHistory = (data = {}, callbacks) => {
  return {
    type: GET_WITHDRAWAL_HISTORY,
    payload: data,
    callbacks
  }
}

export const onDataReport = (data = {}, callbacks) => {
  return {
    type: ON_DATA_REPORT,
    payload: data,
    callbacks
  }
}

export const onDataIsMatchRead = (data = {}, callbacks) => {
  return {
    type: ON_DATA_MATCHING_READ,
    payload: data,
    callbacks
  }
}

export const onHideUser = (data = {}, callbacks) => {
  return {
    type: HIDE_USER,
    payload: data,
    callbacks
  }
}

export const getBannerRequested = () => {
  return {
    type: GET_BANNER_REQUESTED
  }
}

export const getBannerSuccess = (data) => {
  return {
    type: GET_BANNER_SUCCESS,
    payload: data
  }
}

export const getBannerFailed = (error) => {
  return {
    type: GET_BANNER_FAILED,
    error: error
  }
}

export const verifyEmail = (data, callbacks) => {
  return {
    type: SEND_VERIFY_EMAIL,
    payload: data,
    callbacks
  }
}

export const notificationSetting = (data, callbacks) => {
  return {
    type: NOTIFICATION_SETTING_REQUESTED,
    payload: data,
    callbacks
  }
}

export const getNotificationSetting = (callbacks) => {
  return {
    type: GET_NOTI_SETTING_REQUESTED,
    callbacks
  }
}
