import HttpClient from "../utils/client"

export function* getUserDetail({ id, isFromPrivateChat = false}, action) {
  return yield HttpClient("GET", { url: `/users/${id}?isFromPrivateChat=${isFromPrivateChat}` }, action)
}

export function* updateBonus(action) {
  const url = `/users/regular-bonus/update`
  return yield HttpClient("POST", { url }, action)
}
