export const GET_RESIDENCE_FAILED = "GET_RESIDENCE_FAILED"
export const GET_RESIDENCE_SUCCESS = "GET_RESIDENCE_SUCCESS"
export const GET_RESIDENCE_REQUEST = "GET_RESIDENCE_REQUEST"

export const GET_FILTER_OPTIONS_FAILED = "GET_FILTER_OPTIONS_FAILED"
export const GET_FILTER_OPTIONS_SUCCESS = "GET_FILTER_OPTIONS_SUCCESS"
export const GET_FILTER_OPTIONS_REQUEST = "GET_FILTER_OPTIONS_REQUEST"

export const FILTER_USERS_FAILED = "FILTER_USERS_FAILED"
export const FILTER_USERS_SUCCESS = "FILTER_USERS_SUCCESS"
export const FILTER_USERS_REQUEST = "FILTER_USERS_REQUEST"

export const FETCH_RELATED_USERS_REQUESTED = "FETCH_RELATED_USERS_REQUESTED"
export const FETCH_RELATED_USERS_SUCCESS = "FETCH_RELATED_USERS_SUCCESS"
export const FETCH_RELATED_USERS_FAILED = "FETCH_RELATED_USERS_FAILED"

export const SELECT_USER = "SELECT_USER"
