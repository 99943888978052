import React from "react"
import { useHistory } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCamera } from "@fortawesome/free-solid-svg-icons"
import { getAvatarUrl } from "../../../utils"
import defaultAvatar from "../images/avatar.jpg"
import IconPlus from "../images/icon-plus.png"
import routes from "../../../constants/routes"

export const TopOfPage = (props) => {
  const { me } = props
  const history = useHistory()
  const avatarUrl = getAvatarUrl(me.images)

  return (
    <div className="container top-info">
      <div className="information">
        <div className="avatar" id="avatar">
          {avatarUrl ? (
            <img src={avatarUrl} alt={me.biography} />
          ) : (
            <img src={defaultAvatar} alt="default avatar" />
          )}
          <div className="icon-camera" onClick={() => history.push("/media")}>
            <FontAwesomeIcon icon={faCamera} />
          </div>
        </div>
        <div className="other-information">
          <h1 className="fullname">
            <span className="nickname">{me.nickname}</span>
          </h1>
          <p
            className="div-age"
            onClick={() => {
              history.push(routes.PROFILE_REVIEW)
            }}
          >
            <span className="age">プロフィール確認 &gt;</span>
          </p>
        </div>
      </div>

      <div className="list-button">
        <div
          className="btn-edit-profile"
          style={{
            backgroundColor:
              me.contract?.plan_type === 4
                ? "#00205D"
                : me.contract?.plan_type === 3
                ? "#5E08B4"
                : me.contract?.plan_type === 2
                ? "#35ADD9"
                : "#EEEEEE",
            color:
              me.contract?.plan_type === 4 ||
              me.contract?.plan_type === 3 ||
              me.contract?.plan_type === 2
                ? "white"
                : "#333333"
          }}
          onClick={() => {
            if (
              me.contract?.plan_type === 4 ||
              me.contract?.plan_type === 3 ||
              me.contract?.plan_type === 2 ||
              me.contract?.plan_type === 1
            ) {
              me.sex === 1 && history.push(routes.MYPLAN)
            } else {
              me.sex === 1 && history.push(routes.PLAN)
            }
          }}
        >
          <p>
            {me.contract?.plan_type === 4
              ? "モノリス"
              : me.contract?.plan_type === 3
              ? "ダイヤモンド"
              : me.contract?.plan_type === 2
              ? "プラチナ"
              : me.contract?.plan_type === 1 && me.sex === 1
              ? "通常プラン"
              : "無料アカウント"}
          </p>
        </div>
        {/* <div className="btn-edit-profile">
          <div className="wrap-btn">
            <FontAwesomeIcon
              color="#E7141A"
              className="icon-like"
              icon={faThumbsUp}
            />
            <p>保持数</p>
            <span className="icon-plus">
              <img src={IconPlus} width={16} alt="Icon plus" />
            </span>
          </div>
        </div> */}
        {(me.identity_verification_status === 0 ||
          me.identity_verification_status === 2) && (
          <div
            className="btn-edit-profile"
            style={{ backgroundColor: "#EEEEEE", color: "#333333" }}
          >
            <div
              className="wrap-btn"
              onClick={() => {
                history.push("/verify")
              }}
            >
              <p>身分証なし</p>
              <span className="icon-plus">
                <img src={IconPlus} width={16} alt="Icon plus" />
              </span>
            </div>
          </div>
        )}

        {me.identity_verification_status === 1 && (
          <div
            className="btn-edit-profile"
            style={{ backgroundColor: "#EEEEEE", color: "#333333" }}
          >
            <div
              className="wrap-btn"
              onClick={() => {
                history.push("/verify")
              }}
            >
              <p>本人確認中</p>
              <span className="icon-plus">
                <img src={IconPlus} width={16} alt="Icon plus" />
              </span>
            </div>
          </div>
        )}

        {me.identity_verification_status === 3 && (
          <div
            className="btn-edit-profile"
            style={{ backgroundColor: "#EEEEEE", color: "#333333" }}
          >
            <div
              className="wrap-btn"
              onClick={() => {
                history.push("/verify")
              }}
            >
              <p>身分証確認済み</p>
            </div>
          </div>
        )}
      </div>

      {me.contract?.plan_type !== 4 &&
        me.contract?.plan_type !== 3 &&
        me.contract?.plan_type !== 2 &&
        me.contract?.plan_type !== 1 && (
          <div className="payment-wrap text-center">
            <div
              className="btn btn-danger btn-payment"
              onClick={() => history.push("/plans")}
            >
              有料会員プランに加入
            </div>
          </div>
        )}
    </div>
  )
}
