import _ from "lodash"
import Modal from "react-bootstrap/Modal"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useState, useContext, useEffect } from "react"

import AppContext from "../../../../Context"
import AppBar from "../../../../components/AppBar"
import Layout from "../../../../components/Layout"
import { updateBankRequest } from "../../../../actions/other"
import { LoadingIndicator } from "../../../../components/LoadingIndicator"

const BankRegister = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const profileUserData = useSelector((state) => state.auth.profile)
  const [notificationContent, setContent] = useState("")
  const [checkRequiredOne, setcheckRequiredOne] = useState(true)
  const [checkRequiredTwo, setcheckRequiredTwo] = useState(true)
  const [checkRequiredThree, setcheckRequiredThree] = useState(true)
  const [checkRequiredThreeNumber, setcheckRequiredThreeNumber] = useState(true)
  const [checkRequiredFour, setcheckRequiredFour] = useState(true)

  const [bankName, setBankName] = useState("")
  const [bankBranch, setBankBranch] = useState("")
  const [bankType, setBankType] = useState(0)
  const [bankAccountNumber, setBankAccountNumber] = useState("")
  const [bankAccountName, setBankAccountName] = useState("")

  useEffect(() => {
    if (!_.isEmpty(profileUserData)) {
      setBankName(profileUserData.bank_name)
      setBankBranch(profileUserData.branch_name)
      setBankAccountName(profileUserData.account_name)
      setBankAccountNumber(profileUserData.account_number)
      setBankType(profileUserData.account_type)
    }
  }, [profileUserData])

  const [show, setShow] = useState(false)
  const { setContext } = useContext(AppContext)

  useEffect(() => {
    setcheckRequiredOne(true)
  }, [bankName])

  useEffect(() => {
    setcheckRequiredTwo(true)
  }, [bankBranch])

  useEffect(() => {
    setcheckRequiredThree(true)
    setcheckRequiredThreeNumber(true)
  }, [bankAccountNumber])

  useEffect(() => {
    setcheckRequiredFour(true)
  }, [bankAccountName])

  const handleRequest = () => {
    let check = true
    if (bankName == null || bankName == "") {
      setcheckRequiredOne(false)
      check = false
    }

    if (bankBranch == null || bankBranch == "") {
      setcheckRequiredTwo(false)
      check = false
    }

    if (bankAccountNumber == null || bankAccountNumber == "") {
      setcheckRequiredThree(false)
      check = false
    } else {
      let regex = /^[0-9]*$/
      if (!regex.test(bankAccountNumber)) {
        setcheckRequiredThreeNumber(false)
        check = false
      }
    }

    if (bankAccountName == null || bankAccountName == "") {
      setcheckRequiredFour(false)
      check = false
    }

    if (check == true) {
      setContext("overlay", <LoadingIndicator />)
      const data = {
        bank_name: bankName,
        branch_name: bankBranch,
        account_number: bankAccountNumber,
        account_name: bankAccountName,
        account_type: bankType ? parseInt(bankType) : 0
      }

      dispatch(
        updateBankRequest(data, {
          onSuccess: (response) => {
            if (response.status == 422) {
              setContext("overlay", null)
              setContent("入力情報はもう一度確認してください。")
              setShow(true)
            } else if (response.status == 200) {
              setContext("overlay", null)
              profileUserData.bank_name = bankName
              profileUserData.branch_name = bankBranch
              profileUserData.account_name = bankAccountName
              profileUserData.account_number = bankAccountNumber
              profileUserData.account_type = parseInt(bankType)
              history.push("/withdrawal")
            } else {
              setContext("overlay", null)
              setContent("サーバーに接続できません。")
              setShow(true)
            }
          },
          onFailed: (error) => {
            setContent("システムエラー")
            setShow(true)
          }
        })
      )
    }
  }

  const handleClose = () => {
    setShow(false)
  }

  return (
    <Layout>
      <AppBar title="銀行口座登録" withBack />
      <div className="bg-white d-flex flex-column full-height-with-navbar pt-2">
        {!_.isEmpty(profileUserData) ? (
          <div className="p-3 d-flex flex-column">
            <div className="p-2 d-flex flex-column border-bottom">
              <label className="yu-gothic-bold">金融機関名</label>
              <input
                type="text"
                className="border-0 mt-2"
                placeholder="入力してください"
                value={bankName}
                onChange={(e) => setBankName(e.target.value)}
              />
            </div>

            {checkRequiredOne == false ? (
              <div className="text-danger text-small">
                金融機関名 は必ず入力してください
              </div>
            ) : null}

            <div className="p-2 d-flex flex-column border-bottom">
              <label className="yu-gothic-bold">支店名</label>
              <input
                type="text"
                className="border-0 mt-2"
                placeholder="入力してください"
                value={bankBranch}
                onChange={(e) => setBankBranch(e.target.value)}
              />
            </div>

            {checkRequiredTwo == false ? (
              <div className="text-danger text-small">
                支店名 は必ず入力してください
              </div>
            ) : null}

            <div className="p-2 d-flex flex-column border-bottom">
              <label className="yu-gothic-bold">口座種別</label>
              <select
                className="border-0 mt-1 pt-2 pb-2"
                value={bankType}
                onChange={(e) => setBankType(e.target.value)}
              >
                <option value="0" defaultValue>
                  普通口座
                </option>
                <option value="1">当座預金</option>
              </select>
            </div>

            <div className="p-2 d-flex flex-column border-bottom">
              <label className="yu-gothic-bold">口座番号</label>
              <input
                type="text"
                inputMode="numeric"
                className="border-0 mt-2"
                placeholder="入力してください"
                value={bankAccountNumber}
                onChange={(e) => setBankAccountNumber(e.target.value)}
                maxLength="7"
              />
            </div>

            {checkRequiredThree == false ? (
              <div className="text-danger text-small">
                口座番号 は必ず入力してください
              </div>
            ) : null}
            {checkRequiredThreeNumber == false ? (
              <div className="text-danger text-small">
                口座番号 は数値入力してください
              </div>
            ) : null}

            <div className="p-2 d-flex flex-column border-bottom">
              <label className="yu-gothic-bold">口座名義</label>
              <input
                type="text"
                className="border-0 mt-2"
                placeholder="入力してください"
                value={bankAccountName}
                onChange={(e) => setBankAccountName(e.target.value)}
              />
            </div>

            {checkRequiredFour == false ? (
              <div className="text-danger text-small">
                口座名義 は必ず入力してください
              </div>
            ) : null}

            <button
              className="btn btn-danger mt-4 sumbit-btn w-100 pt-3 pb-3"
              onClick={handleRequest}
            >
              この内容で登録する
            </button>
            <Modal show={show} onHide={handleClose} centered size="sm">
              <Modal.Body>
                <h6 className="text-center pt-3 pb-3">{notificationContent}</h6>

                <button
                  className="btn text-danger w-100 m-0 rounded-0 confirm-button"
                  onClick={handleClose}
                >
                  OK
                </button>
              </Modal.Body>
            </Modal>
          </div>
        ) : (
          <LoadingIndicator />
        )}
      </div>
    </Layout>
  )
}

export default BankRegister
