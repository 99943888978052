import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import AppBar from "../../../components/AppBar"
import Login from "../../../components/Form/Login"
import Layout from "../../../components/Layout"

import { loginWithEmailRequest } from "../../../actions/auth"
import routes from "../../../constants/routes"
import messages from "../../../constants/messages"

const Account = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const handleLogin = (values) => {
    dispatch(
      loginWithEmailRequest(values, {
        onSuccess: () => {
          history.push(routes.TAKEOVER_UPDATE_PHONE)
        },
        onFailed: () => {
          alert(messages.emailOrPasswordIncorrect)
        },
      })
    )
  }

  return (
    <Layout>
      <AppBar withBack />
      <div className="container">
        <Login onSubmit={handleLogin} />
      </div>
    </Layout>
  )
}

export default Account
