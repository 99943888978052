import "./styles.scss"
import moment from "moment"
import { useHistory } from "react-router-dom"
import { Button, Row, Col } from "react-bootstrap"
import routes from "../../../../constants/routes"

export const MySchedule = ({ user, children }) => {
  const history = useHistory()
  const handleEditSchedule = () => {
    history.push({
      pathname: routes.CREATE_SCHEDULE,
      state: { user }
    })
  }

  const typeOfHouse = () => {
    switch (user.type_of_hour) {
      case 1:
        return "午前中"
      case 2:
        return "昼"
      case 3:
        return "午後"
      case 4:
        return "夕方"
      case 5:
        return "夜"
      case 6:
        return "深夜"
      default:
        break
    }
  }

  return (
    <div className="yu-gothic w-100">
      <div className="p-3">
        <Row>
          <div className="d-flex justify-content-between">
            <div className="overflow d-flex align-items-center">
              <span className="overflow status-label fs-8">
                {user?.purpose || ""}
              </span>
            </div>
            <div className="d-flex align-items-center float-right text-black-bold">
              <span className="fs-8">
                {moment(user?.date).format("MM月DD日")} {typeOfHouse()}
              </span>
            </div>
          </div>
        </Row>
        <Row className="mt-3">
          <Col xs={8}>
            <Row>
              <div className="overflow d-flex align-items-center">
                <span className="overflow position-label fs-8">
                  {user?.title || ""}
                </span>
              </div>
            </Row>
            <Row>
              <div className="overflow d-flex align-items-center">
                <span className="overflow fs-8">{user?.content}</span>
              </div>
            </Row>
          </Col>
          <Col xs={4} className="d-flex justify-content-end">
            <Button
              onClick={handleEditSchedule}
              variant="danger"
              size="lg"
              className="btn btn-edit-schedule fs-6"
            >
              募集中
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  )
}
