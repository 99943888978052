import { Modal } from "react-bootstrap"

export const ErrorModal = ({ show, header, content, onClose }) => {
  return (
    <Modal size="sm" show={show} centered>
      <Modal.Header closeButton onHide={onClose}>
        {header}
      </Modal.Header>
      <Modal.Body> {content} </Modal.Body>
    </Modal>
  )
}
