import Loader from "react-loader-spinner"

export const LoadingIndicator = ({ sm }) => {
  return (
    <div className="vh-100 d-flex justify-content-center align-items-center">
      <Loader
        type="Oval"
        color="grey"
        width={sm ? "2rem" : "4rem"}
        height={sm ? "2rem" : "4rem"}
      />
    </div>
  )
}
