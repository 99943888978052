import { Modal } from "react-bootstrap"
import { Link, useHistory } from "react-router-dom"

export const ReportModal = ({ show, onClose }) => {
  return (
    <Modal size="sm" show={show} centered>
      <Modal.Body className="px-0">
      <p className="text-start mb-2 px-3 pt-2 text-black-bold">通報しました。</p>
        <p className="text-start mb-3 px-3 text-black-bold">通報内容を詳しく送る場合は、
        <Link
          to="/contact"
          className="text-center mt-4 fs-6 text-black-bold"
        >
          お問い合わせフォーム
        </Link>
        からご連絡ください。</p>
        <button
          className="btn text-danger w-100 m-0 rounded-0 confirm-button"
          onClick={onClose}
        >
          OK
        </button>
      </Modal.Body>
    </Modal>
  )
}
