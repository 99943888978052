import { useState, useEffect } from "react"
import { TabPanel } from "react-headless-tabs"
import { useDispatch, useSelector } from "react-redux"

import "./styles.scss"
import AppBar from "../../components/AppBar"
import Layout from "../../components/Layout"
import { ScheduleList } from "./components"
import {
  scheduleListRequested,
  myScheduleRequested
} from "../../actions/schedule"
import { LoadingIndicator } from "../../components"
import { useLocation } from "react-router-dom"
import { filterAccountPopups } from "../../components/FilterAccountPopups";
import CustomPopups from "../../components/CustomPopups";

const Schedules = () => {
  const dispatch = useDispatch()
  const [bannerHeight, setBannerHeight] = useState(0)
  const location = useLocation()

  const profileUserData = useSelector((state) => state.auth.profile);
  const data = useSelector((state) => state.popups.data);
  const [filteredData, setFilteredData] = useState([]);
  const [openPopups, setOpenPopups] = useState(false);
  const [statePopUps, setOpenStatePopUps] = useState(false);

  const filterAndSetData = () => {
    const result = filterAccountPopups(data, profileUserData);
    const validFilteredData = result.filter(item => item !== []);

    if (validFilteredData.length > 0 && result.length > 0
      && validFilteredData[0]?.screen_active === location.pathname.replace(/^\//, '')) {
      setFilteredData(validFilteredData);
      if (!statePopUps) {
        setOpenPopups(true);
      }
    }
  };

  useEffect(() => {
    filterAndSetData();
  }, [data, profileUserData, openPopups]);

  const handleClosePopups = () => {
    setOpenStatePopUps(true);
    setOpenPopups(false);
  };

  const myPlan = useSelector((state) => {
    return state?.schedule?.mySchedule
  })
  const users = useSelector((state) => {
    return state?.schedule?.schedule
  })

  const loading = useSelector((state) => state.schedule.isLoading)

  useEffect(() => {
    dispatch(myScheduleRequested())
    dispatch(scheduleListRequested())
  }, [dispatch])

  return (
    <Layout nav>
      <AppBar
        title="今からどう？"
        colorTitle="#666666"
        search
        placeholder=" "
        banner
        setBannerHeight={setBannerHeight}
      />
      {loading ? (
        <LoadingIndicator />
      ) : (
        <div
          className="bg-white d-flex flex-column border-top wrap-list-schedule"
          style={{ top: bannerHeight + 85 }}
        >
          <div className="h-100">
            <div className="h-100">
              <TabPanel className="h-100">
                <ScheduleList myPlan={myPlan} users={users} />
              </TabPanel>
            </div>
          </div>
        </div>
      )}
      {(openPopups && filteredData?.length > 0) && <CustomPopups openPopups={openPopups} onClose={handleClosePopups} filteredData={filteredData[0]} />}
    </Layout>
  )
}

export default Schedules
