import {
  SET_CALLER,
  SET_CALLEE,
  SET_AGORA_DATA,
  FETCH_GIFTS_REQUEST,
  FETCH_GIFTS_SUCCESS,
  FETCH_GIFTS_FAILED,
  TIPPING_GIFT_REQUEST,
  TIPPING_GIFT_SUCCESS,
  TIPPING_GIFT_FAILED,
  RETRIEVE_AGORA_TOKEN_REQUESTED,
  RETRIEVE_AGORA_TOKEN_SUCCESS,
  RETRIEVE_AGORA_TOKEN_FAILED,
  GET_RTM_TOKEN_REQUESTED,
  GET_RTM_TOKEN_FAILED,
  GET_RTM_TOKEN_SUCCESS,
  FETCH_GIFT_LIST_REQUESTED,
  FETCH_GIFT_LIST_SUCCESS,
  FETCH_GIFT_LIST_FAILED,
  PURCHASE_GIFT_REQUESTED,
  PURCHASE_GIFT_SUCCESS,
  PURCHASE_GIFT_FAILED,
  SEND_GIFT_REQUESTED,
  SEND_GIFT_SUCCESS,
  SEND_GIFT_FAILED,
  UPDATE_VIDEO_CALL_REQUESTED,
  UPDATE_VIDEO_CALL_SUCCESS,
  UPDATE_VIDEO_CALL_FAILED,
  UPDATE_VIDEO_CALL_MSG_REQUESTED,
  UPDATE_VIDEO_CALL_MSG_FAILED,
  SET_CALLEE_UID,
  EXTEND_VIDEO_CALL_REQUESTED,
  EXTEND_VIDEO_CALL_SUCCESS,
  EXTEND_VIDEO_CALL_FAILED,
  START_VIDEO_CALL_REQUESTED,
  START_VIDEO_CALL_SUCCESS,
  START_VIDEO_CALL_FAILED,
  SET_IS_CALLING,
  SET_CALLER_UID,
  SET_LIKE_ID
} from "../constants/actions"

export const setCaller = (caller, isCaller) => {
  return {
    type: SET_CALLER,
    payload: { caller, isCaller }
  }
}

export const setCallee = (callee, isCaller) => {
  return {
    type: SET_CALLEE,
    payload: { callee, isCaller }
  }
}

export const setAgoraData = (data) => {
  return {
    type: SET_AGORA_DATA,
    payload: {
      token: data.token,
      channel: data.channel_id
    }
  }
}

export const fetchGiftsRequest = (callbacks) => {
  return {
    type: FETCH_GIFTS_REQUEST,
    payload: {
      data: {}
    },
    callbacks
  }
}
export const fetchGiftsSuccess = (data) => {
  return {
    type: FETCH_GIFTS_SUCCESS,
    data
  }
}

export const fetchGiftsFailed = (data) => {
  return {
    type: FETCH_GIFTS_FAILED,
    data
  }
}

export const tippingGiftRequest = (data, callbacks) => {
  return {
    type: TIPPING_GIFT_REQUEST,
    data,
    callbacks
  }
}
export const tippingGiftSuccess = (data) => {
  return {
    type: TIPPING_GIFT_SUCCESS,
    data
  }
}

export const tippingGiftFailed = (data) => {
  return {
    type: TIPPING_GIFT_FAILED,
    data
  }
}

export const retrieveAgoraTokenRequested = (toUserId, callbacks) => {
  return {
    type: RETRIEVE_AGORA_TOKEN_REQUESTED,
    payload: { toUserId },
    callbacks
  }
}

export const retrieveAgoraTokenSuccess = () => {
  return {
    type: RETRIEVE_AGORA_TOKEN_SUCCESS
  }
}

export const retrieveAgoraTokenFailed = (error) => {
  return { type: RETRIEVE_AGORA_TOKEN_FAILED, payload: { error } }
}

export const getRtmTokenRequested = (deviceToken, callbacks) => {
  return {
    type: GET_RTM_TOKEN_REQUESTED,
    payload: { deviceToken },
    callbacks
  }
}

export const getRtmTokenSuccess = () => {
  return { type: GET_RTM_TOKEN_SUCCESS }
}

export const getRtmTokenFailed = (error) => {
  return {
    type: GET_RTM_TOKEN_FAILED,
    payload: { error }
  }
}

export const fetchGiftListRequested = () => {
  return {
    type: FETCH_GIFT_LIST_REQUESTED
  }
}

export const fetchGiftListSuccess = () => {
  return {
    type: FETCH_GIFT_LIST_SUCCESS
  }
}

export const fetchGiftListFailed = (error) => {
  return {
    type: FETCH_GIFT_LIST_FAILED,
    payload: {
      error
    }
  }
}

export const purchaseGiftRequested = (
  channelId,
  callerId,
  calleeId,
  giftId,
  amount
) => {
  return {
    type: PURCHASE_GIFT_REQUESTED,
    payload: { channelId, callerId, calleeId, giftId, amount }
  }
}

export const purchaseGiftSuccess = () => {
  return {
    type: PURCHASE_GIFT_SUCCESS
  }
}

export const purchaseGiftFailed = (error) => {
  return {
    type: PURCHASE_GIFT_FAILED,
    payload: {
      error
    }
  }
}

export const sendGiftRequested = (channelId, calleeId, userGiftId) => {
  return {
    type: SEND_GIFT_REQUESTED,
    payload: { channelId, calleeId, userGiftId }
  }
}

export const sendGiftSuccess = () => {
  return {
    type: SEND_GIFT_SUCCESS
  }
}

export const sendGiftFailed = (error) => {
  return {
    type: SEND_GIFT_FAILED,
    payload: { error }
  }
}

export const updateVideoCallRequested = (videoCallId, status, callbacks) => {
  return {
    type: UPDATE_VIDEO_CALL_REQUESTED,
    payload: { videoCallId, status },
    callbacks
  }
}

export const updateVideoCallSuccess = () => {
  return { type: UPDATE_VIDEO_CALL_SUCCESS }
}

export const updateVideoCallFailed = (error) => {
  return { type: UPDATE_VIDEO_CALL_FAILED, payload: error }
}

export const updateVideoMessageRequested = (likeId, messageId, message) => {
  return {
    type: UPDATE_VIDEO_CALL_MSG_REQUESTED,
    payload: { likeId, messageId, message }
  }
}

export const updateVideoMessageSuccess = () => {
  return { type: UPDATE_VIDEO_CALL_SUCCESS }
}

export const updateVideoMessageFailed = (error) => {
  return { type: UPDATE_VIDEO_CALL_MSG_FAILED, payload: { error } }
}

export const setCalleeUid = (uid) => {
  return {
    type: SET_CALLEE_UID,
    payload: { uid }
  }
}

export const setCallerUid = (uid) => ({
  type: SET_CALLER_UID,
  payload: { uid }
})

export const extendVideoCallRequested = (channelId, toUserId, callbacks) => ({
  type: EXTEND_VIDEO_CALL_REQUESTED,
  payload: { channelId, toUserId },
  callbacks
})

export const extendVideoCallSuccess = (data) => ({
  type: EXTEND_VIDEO_CALL_SUCCESS,
  payload: data
})

export const extendVideoCallFailed = (error) => ({
  type: EXTEND_VIDEO_CALL_FAILED,
  payload: { error }
})

export const startVideoCallRequested = (channelId, toUserId, callbacks) => ({
  type: START_VIDEO_CALL_REQUESTED,
  payload: { channelId, toUserId },
  callbacks
})

export const startVideoCallSuccess = (data) => ({
  type: START_VIDEO_CALL_SUCCESS,
  payload: data
})

export const startVideoCallFailed = (error) => ({
  type: START_VIDEO_CALL_FAILED,
  payload: { error }
})

export const setIsCalling = (isCalling, reason) => ({
  type: SET_IS_CALLING,
  payload: { isCalling, reason }
})

export const setLikeId = (likeId) => ({
  type: SET_LIKE_ID,
  payload: { likeId }
})
