export const SCHEDULE_LIST_REQUESTED = "SCHEDULE_LIST_REQUESTED"
export const SCHEDULE_LIST_SUCCESS = "SCHEDULE_LIST_SUCCESS"
export const SCHEDULE_LIST_FAILED = "SCHEDULE_LIST_FAILED"

export const CREATE_SCHEDULE_REQUESTED = "CREATE_SCHEDULE_REQUESTED"
export const CREATE_SCHEDULE_SUCCESS = "CREATE_SCHEDULE_SUCCESS"
export const CREATE_SCHEDULE_FAILED = "CREATE_SCHEDULE_FAILED"

export const SCHEDULE_DETAIL_REQUESTED = "SCHEDULE_DETAIL_REQUESTED"
export const SCHEDULE_DETAIL_SUCCESS = "SCHEDULE_DETAIL_SUCCESS"
export const SCHEDULE_DETAIL_FAILED = "SCHEDULE_DETAIL_FAILED"

export const MY_SCHEDULE_REQUESTED = "MY_SCHEDULE_REQUESTED"
export const MY_SCHEDULE_SUCCESS = "MY_SCHEDULE_SUCCESS"
export const MY_SCHEDULE_FAILED = "MY_SCHEDULE_FAILED"

export const EDIT_SCHEDULE_REQUESTED = "EDIT_SCHEDULE_REQUESTED"
export const EDIT_SCHEDULE_SUCCESS = "EDIT_SCHEDULE_SUCCESS"
export const EDIT_SCHEDULE_FAILED = "EDIT_SCHEDULE_FAILED"

export const DELETE_SCHEDULE_REQUESTED = "DELETE_SCHEDULE_REQUESTED"
export const DELETE_SCHEDULE_SUCCESS = "DELETE_SCHEDULE_SUCCESS"
export const DELETE_SCHEDULE_FAILED = "DELETE_SCHEDULE_FAILED"