import AgoraRTM from "agora-rtm-sdk"
import EventEmitter from "events"

const APP_ID = "415ee921337f43b99246431bfadea9da"

class RTMClient {
  constructor() {
    if (!RTMClient.instance) {
      this._client = AgoraRTM.createInstance(APP_ID)
      this._uid = ""
      this._token = undefined
      this._invitation = null
      this._remoteInvitation = null
      this.eventBus = new EventEmitter()

      this.status = "offline"

      this.peerInvitation()

      this._invitations = {}

      RTMClient.instance = this
    }
    return RTMClient.instance
  }

  async login(uid, token) {
    this._uid = uid
    this._token = token
    try {
      await this._client.login({ token: this._token, uid })
      this.status = "online"
    } catch (error) {
      console.log("Error log in RTM: ", error)
    }
  }

  async logout() {
    try {
      await this._client.logout()
      console.log("Logout RTM succeeded")
      this.status = "offline"
    } catch (err) {
      console.log("Logout RTM failed: ", err)
    }
  }

  inquire(peerIds) {
    return this._client.queryPeersOnlineStatus(peerIds)
  }

  sendLocalInvitations(calleeIds, channel, content, onComplete) {
    for (let uid in this._invitations) {
      this._invitations[uid].removeAllListeners()
    }
    this._invitations = {}

    for (let callee of calleeIds) {
      this._invitations[callee] = this._client.createLocalInvitation(callee)

      this._invitations[callee].on("LocalInvitationReceivedByPeer", () => {
        console.log("agora. local invitation received: ", callee)
        this.status = "calling"
        this.eventBus.emit("LocalInvitationReceivedByPeer")
      })

      this._invitations[callee].on("LocalInvitationCanceled", () => {
        console.log("agora. local invitation cancelled: ", callee)
        this.eventBus.emit("LocalInvitationCanceled")
      })

      this._invitations[callee].on("LocalInvitationAccepted", (response) => {
        console.log("agora. local invitation accepted: ", callee, response)
        for (let uid in this._invitations) {
          if (uid != callee) {
            this._invitations[uid].cancel()
          }
        }
        this.status = "meeting"
        this.eventBus.emit("LocalInvitationAccepted", callee)
      })

      this._invitations[callee].on("LocalInvitationRefused", (response) => {
        console.log("agora. local invitation refused: ", callee, response)
        for (let uid in this._invitations) {
          if (uid != callee) {
            this._invitations[uid].cancel()
          }
        }
        this.status = "online"
        this.eventBus.emit("LocalInvitationRefused")
      })

      this._invitations[callee].on("LocalInvitationFailure", (err) => {
        console.log("agora. local invitation failed: ", callee, err)
        this.status = "online"
        this.eventBus.emit("LocalInvitationFailure", err)
      })

      this._invitations[callee].content = content

      this._invitations[callee].send()
    }
    onComplete && onComplete()
  }

  localInvitation(calleeId, channel, content) {
    if (this._invitation !== null) {
      this._invitation.removeAllListeners()
      this._invitation = null
    }

    this._invitation = this._client.createLocalInvitation(calleeId)

    this._invitation.on("LocalInvitationReceivedByPeer", () => {
      this.status = "calling"
      this.eventBus.emit("LocalInvitationReceivedByPeer")
    })

    this._invitation.on("LocalInvitationCanceled", () => {
      this.eventBus.emit("LocalInvitationCanceled")
    })

    this._invitation.on("LocalInvitationAccepted", () => {
      this.status = "meeting"
      this.eventBus.emit("LocalInvitationAccepted")
    })

    this._invitation.on("LocalInvitationRefused", () => {
      this.status = "online"
      this.eventBus.emit("LocalInvitationRefused")
    })

    this._invitation.on("LocalInvitationFailure", (err) => {
      this.status = "online"
      this.eventBus.emit("LocalInvitationFailure", err)
    })

    this._invitation.content = content

    this._invitation.send()
  }

  peerInvitation() {
    this._client.on("RemoteInvitationReceived", (remoteInvitation) => {
      if (this.status !== "online") {
        setTimeout(() => remoteInvitation.refuse(), 1000)
        return
      }
      if (this._remoteInvitation !== null) {
        this._remoteInvitation.removeAllListeners()
        this._remoteInvitation = null
      }

      this.status = "calling"
      this._remoteInvitation = remoteInvitation
      this.peerEvents()
      this.eventBus.emit("RemoteInvitationReceived", remoteInvitation)
    })
  }

  peerEvents() {
    // the caller cancelled the invitation
    this._remoteInvitation.on("RemoteInvitationCanceled", () => {
      console.log("agora")
      this.status = "online"
      this.eventBus.emit("RemoteInvitationCanceled")
    })

    // I accepted the call invitation
    this._remoteInvitation.on("RemoteInvitationAccepted", () => {
      console.log("I received this call")
      this.eventBus.emit("RemoteInvitationAccepted")
    })

    this._remoteInvitation.on("RemoteInvitationRefused", () => {
      console.log("I declined the call")
      this.eventBus.emit("RemoteInvitationRefused")
    })

    this._remoteInvitation.on("RemoteInvitationFailure", () => {
      console.log("Remote invitation failure")
      this.status = "online"
      this.eventBus.emit("RemoteInvitationFailure")
    })
  }

  cancelCall() {
    try {
      for (let uid in this._invitations) {
        this._invitations[uid] && this._invitations[uid].cancel()
      }
      this._invitations = {}
      this.status = "online"
    } catch (err) {
      console.log("error cancelling local invitations: ", err)
    }
  }

  acceptCall() {
    this._remoteInvitation && this._remoteInvitation.accept()
    this.status = "meeting"
  }

  refuseCall() {
    this._remoteInvitation && this._remoteInvitation.refuse()
    this.status = "online"
  }

  on(evt, callback) {
    const customEvents = [
      "LocalInvitationReceivedByPeer",
      "LocalInvitationCanceled",
      "LocalInvitationAccepted",
      "LocalInvitationRefused",
      "LocalInvitationFailure",
      "RemoteInvitationReceived",
      "RemoteInvitationCanceled",
      "RemoteInvitationAccepted",
      "RemoteInvitationRefused",
      "RemoteInvitationFailure"
    ]
    if (customEvents.indexOf(evt) !== -1) {
      this.eventBus.on(evt, callback)
      return
    }
    this._client.on(evt, callback)
  }

  off(evt, callback) {
    const customEvents = [
      "LocalInvitationReceivedByPeer",
      "LocalInvitationCanceled",
      "LocalInvitationAccepted",
      "LocalInvitationRefused",
      "LocalInvitationFailure",
      "RemoteInvitationReceived",
      "RemoteInvitationCanceled",
      "RemoteInvitationAccepted",
      "RemoteInvitationRefused",
      "RemoteInvitationFailure"
    ]
    if (customEvents.indexOf(evt) !== -1) {
      this.eventBus.off(evt, callback)
      return
    }
    this._client.off(evt, callback)
  }

  sendAgoraToken(message) {
    this._client.sendMessageToPeer()
  }

  setStatus(status) {
    this.status = status
  }

  sendMessageToPeer(uid, message, onSuccess, onFailed) {
    console.log("Send to peer: ", uid)
    this._client
      .sendMessageToPeer(
        {
          messageType: "TEXT",
          text: JSON.stringify(message)
        },
        uid.toString()
      )
      .then((res) => onSuccess && onSuccess(res))
      .catch((err) => onFailed && onFailed(err))
  }
}

const rtmClient = new RTMClient()
export default rtmClient
