import {
  SET_CALLER,
  SET_CALLEE,
  FETCH_GIFTS_SUCCESS,
  RETRIEVE_AGORA_TOKEN_REQUESTED,
  RETRIEVE_AGORA_TOKEN_SUCCESS,
  RETRIEVE_AGORA_TOKEN_FAILED,
  SET_AGORA_DATA,
  SET_CALLEE_UID,
  SET_CALLER_UID,
  SET_IS_CALLING,
  SET_LIKE_ID,
  START_VIDEO_CALL_SUCCESS,
  EXTEND_VIDEO_CALL_SUCCESS,
  START_VIDEO_CALL_FAILED,
  EXTEND_VIDEO_CALL_FAILED
} from "../constants/actions"

const initialState = {
  isLoading: false,
  isCalling: false,
  reason: null,
  agoraToken: null,
  error: null,
  caller: null,
  callee: null,
  calleeUid: null,
  callerUid: null,
  isCaller: false,
  token: null,
  channel: null,
  likeId: null,
  gifts: [],
  videoCallData: null
}

const videoCallReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CALLER:
      return {
        ...state,
        caller: action.payload.caller,
        isCaller: action.payload.isCaller
      }
    case SET_CALLEE:
      return {
        ...state,
        callee: action.payload.callee,
        isCaller: action.payload.isCaller
      }
    case SET_AGORA_DATA:
      return {
        ...state,
        token: action.payload.token,
        channel: action.payload.channel
      }
    case RETRIEVE_AGORA_TOKEN_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case RETRIEVE_AGORA_TOKEN_SUCCESS:
      return { ...state, isLoading: false }
    case RETRIEVE_AGORA_TOKEN_FAILED:
      return { ...state, isLoading: false, error: action.payload.error }

    case FETCH_GIFTS_SUCCESS:
      return {
        ...state,
        gifts: action.data.data
      }
    case SET_CALLEE_UID:
      return {
        ...state,
        calleeUid: action.payload.uid
      }
    case SET_CALLER_UID:
      return {
        ...state,
        callerUid: action.payload.uid
      }
    case SET_IS_CALLING:
      return {
        ...state,
        isCalling: action.payload.isCalling,
        reason:
          action.payload.reason !== undefined ? action.payload.reason : null
      }
    case SET_LIKE_ID:
      return { ...state, likeId: action.payload.likeId }
    case START_VIDEO_CALL_SUCCESS:
      return { ...state, videoCallData: action.payload }
    case START_VIDEO_CALL_FAILED:
      return { ...state, videoCallData: action.payload }
    case EXTEND_VIDEO_CALL_SUCCESS:
      return { ...state, videoCallData: action.payload }
    case EXTEND_VIDEO_CALL_FAILED:
      return { ...state, videoCallData: action.payload }
    default:
      return state
  }
}

export default videoCallReducer
