import { useHistory } from "react-router-dom"

import icon1 from "../images/icon1.png"
import icon2 from "../images/icon2.png"
import icon3 from "../images/icon3.png"
import icon4 from "../images/icon4.png"

export const PanelMenu = (props) => {
  const history = useHistory()

  const itemsForMen = [
    {
      icon: icon1,
      title: "プロフ編集",
      isN: false,
      url: "/profile/edit"
    },
    {
      icon: icon2,
      title: "設定",
      isN: false,
      url: "/settings"
    },
    {
      icon: icon3,
      title: "写真・動画",
      isN: false,
      url: "/media"
    },
    {
      icon: icon4,
      title: "定型分",
      isN: true,
      url: "/message-template"
    }
  ]

  return (
    <div className="control-panel">
      <ul className="menu-list">
        {itemsForMen.map((item, index) => (
          <li
            key={index}
            onClick={() => history.push(item.url)}
            className="menu-item"
          >
            <img className="menu-icon" src={item.icon} alt="" />
            <p className="menu-title">{item.title}</p>
          </li>
        ))}
      </ul>
    </div>
  )
}
