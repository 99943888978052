import { useState, useRef } from "react"
import { Container } from "react-bootstrap"

import { PhotoTaker } from "./takePhoto"
import { MESSAGE_TYPES } from "../../../../constants/env"

import "../styles.scss"

export const TakePhoto = ({ onSend }) => {
  const [isTakingPhoto, setIsTakingPhoto] = useState(false)

  const inputRef = useRef(null)

  return (
    <>
      <Container className="d-flex flex-column align-items-center" fluid>
        <button
          onClick={() => setIsTakingPhoto(true)}
          className="btn btn btn-danger verify-btn mt-4"
        >
          カメラで撮影する
        </button>
        <button
          className="btn btn-outline-danger verify-btn mt-3"
          onClick={() => {
            inputRef && inputRef.current.click()
          }}
        >
          ライブラリから選択
        </button>
        <input
          className="d-none"
          ref={inputRef}
          type="file"
          accept=".png,.jpg,.jpeg"
          onChange={(event) => {
            if (event.target.files.length > 0) {
              console.log("Selected file: ", event.target.files[0])
              const newMsg = {
                type: MESSAGE_TYPES.IMAGE,
                message: "",
                videoCallId: null,
                imageUrl: null,
                imageFile: event.target.files[0]
              }
              onSend(newMsg)
            }
          }}
        />
      </Container>
      {isTakingPhoto && (
        <PhotoTaker
          onClose={() => setIsTakingPhoto(false)}
          onTakePhoto={(file) => {
            const newMsg = {
              type: MESSAGE_TYPES.IMAGE,
              message: "",
              videoCallId: null,
              imageUrl: null,
              imageFile: file
            }
            setIsTakingPhoto(false)
            onSend(newMsg)
          }}
        />
      )}
    </>
  )
}
