import _ from "lodash"
import moment from "moment"
import { useState, useContext } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import AppBar from "../../components/AppBar"
import Layout from "../../components/Layout"
import { renewMyPlanRequest, changeInfoPlanRequest } from "../../actions"
import NotiItem from "../NotiSetting/components/NotiItem"
import { LoadingIndicator } from "../../components/LoadingIndicator"
import AppContext from "../../Context"

const Myplan = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { setContext } = useContext(AppContext)

  const profileUserData = useSelector((state) => state.auth.profile)

  const [renew, setRenew] = useState(
    profileUserData?.contract?.renew_flag === 1 ? true : false
  )

  const handleRenewFlag = (type, status) => {
    dispatch(
      renewMyPlanRequest(
        { setting: status ? 1 : 0 },
        {
          onSuccess: () => {
            setRenew(status)
          },
          onFailed: () => {
            alert("何か問題でも")
          }
        }
      )
    )
  }

  const handleChangeCard = (plan_id, typeChange) => {
    setContext("overlay", <LoadingIndicator />)
    dispatch(
      changeInfoPlanRequest(
        {
          type_change: typeChange,
          plan_id: plan_id
        },
        {
          onSuccess: (response) => {
            window.open(response.url, "_self")
            setContext("overlay", null)
          },
          onFailed: () => {
            setContext("overlay", null)
            alert("プランを申し込むすることを失敗しました。")
          }
        }
      )
    )
  }

  return (
    <Layout>
      <AppBar title="会員プラン" withBack noBorderBottom />
      <div className="bg-white d-flex flex-column my-3 setting-body">
        {!_.isEmpty(profileUserData) ? (
          <>
            <div
              className="text-left button-with-arrow d-flex justify-content-between pe-5"
              onClick={() => history.push("/plans")}
            >
              <div>現在のプラン</div>
              <div>
                {profileUserData?.contract?.plan_type === 1
                  ? "通常プラン"
                  : profileUserData?.contract?.plan_type === 2
                  ? "プラチナ"
                  : profileUserData?.contract?.plan_type === 3
                  ? "ダイアモンド"
                  : profileUserData?.contract?.plan_type === 4 && "モノリス"}
              </div>
            </div>

            {profileUserData?.sex !== 2 && (
              <div className="text-left d-flex justify-content-between pe-4">
                <div>有効期限</div>
                <div>
                  {moment(profileUserData?.contract?.end_datetime).format(
                    "YYYY/MM/DD"
                  )}
                </div>
              </div>
            )}

            <NotiItem
              title="自動更新"
              checked={renew}
              setChecked={handleRenewFlag}
            />
            <div
              className="text-left d-flex justify-content-between pe-4"
              onClick={() => {
                const planId = profileUserData.contract.plan_id
                handleChangeCard(planId, 1)
              }}
            >
              クレジットカード情報変更
            </div>
            <div className="mt-3 px-3">
              <p>
                ※自動継続について
                <br />
                ・自動継続課金は、所持しているすべてのプランの期限が切れる24時間以内に自動的に課金される仕組みです。
                <br />
                ・最後に自動継続に対応したお支払い方法で購入されたプランに自動継続されます。
                <br />
                ・「会員プラン有効期限日」より前に自動継続を解除しなければ、購読プランは自動的に更新されます。
                <br />
                ・所持プランと有効期限は「マイページ &gt;
                会員プラン」でご確認いただけます。
                <br />
                ・更新日前に退会のお手続きをされた場合、残りの期間についての月割り・日割りでの返金はいたしません。
                <br />
                ・「マイページ &gt;
                会員プラン」のページにございます「自動更新」をオフにすると、自動継続を停止することが可能です。
              </p>
            </div>
          </>
        ) : (
          <LoadingIndicator />
        )}
      </div>
    </Layout>
  )
}

export default Myplan
