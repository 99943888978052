import { useState } from "react"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

import "./style.scss"
import routes from "../../constants/routes"
import { AppBar, Layout } from "../../components"
import { createAndUpdateMessageTemplate } from "../../actions"

const MessageTemplateEdit = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [value, setValue] = useState(
    history.location.state ? history.location.state.message : ""
  )

  const handleChange = (event) => {
    setValue(event.target.value)
  }

  const handleSubmit = () => {
    const data = history.location.state?.user_id
      ? { message: value, messageId: history.location.state.id }
      : { message: value }
    dispatch(
      createAndUpdateMessageTemplate(data, {
        onSuccess: () => {
          history.push(routes.MESSAGE_TEMPLATE)
        },
        onFailed: () => {}
      })
    )
  }

  return (
    <Layout>
      <AppBar title="定型文" withBack />
      <div className="message-edit">
        <div className="message-template-heading">
          <h3 className="message-template-heading-text">定型文編集</h3>
        </div>

        <div className="message-edit-content">
          <textarea
            value={value}
            onChange={handleChange}
            className="message-edit-input"
            rows={13}
            onBlur={() => {
              window.scrollTo(0, 0)
            }}
          ></textarea>
          <button
            disabled={value === "" ? true : false}
            className="btn btn-danger message-edit-button"
            onClick={handleSubmit}
          >
            保存する
          </button>
        </div>
      </div>
    </Layout>
  )
}

export default MessageTemplateEdit
