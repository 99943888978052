import HttpClient from "../utils/client"

function* updateUserProfile(data, token, userId, action) {
  return yield HttpClient(
    "POST",
    { url: `/users/${userId}`, data, token },
    action,
    data instanceof FormData ? "multipart/form-data" : "application/json",
    "updateUserProfileAPI",
    true
  )
}

export { updateUserProfile }
