import * as actionTypes from "../constants/actions"

export function upsertAuthProfile(data, callbacks) {
  return {
    type: actionTypes.UPSERT_AUTH_PROFILE,
    payload: { data, callbacks }
  }
}

export function loginWithEmailRequest(data = {}, callbacks) {
  const { email_for_takeover, password_for_takeover } = data

  return {
    type: actionTypes.AUTH_LOGIN_WITH_EMAIL_REQUEST,
    payload: {
      email_for_takeover,
      password_for_takeover,
      callbacks
    }
  }
}

export function loginWithEmailSuccess(data) {
  return {
    data,
    type: actionTypes.AUTH_LOGIN_WITH_EMAIL_SUCCESS
  }
}

export function loginWithEmailFailed(data) {
  return {
    data,
    type: actionTypes.AUTH_LOGIN_WITH_EMAIL_FAILED
  }
}

export function loginWithPhoneRequest(phone, callbacks) {
  return {
    type: actionTypes.AUTH_LOGIN_WITH_PHONE_REQUEST,
    payload: {
      phone,
      callbacks
    }
  }
}

export function loginWithPhoneSuccess(data) {
  return {
    data,
    type: actionTypes.AUTH_LOGIN_WITH_EMAIL_SUCCESS
  }
}

export function loginWithPhoneFailed(data) {
  return {
    data,
    type: actionTypes.AUTH_LOGIN_WITH_EMAIL_FAILED
  }
}

export const authThrowError = (data) => ({
  type: actionTypes.AUTH_THROW_ERROR,
  data
})

export const updateUserProfileRequest = (data = {}, callbacks) => {
  return {
    type: actionTypes.UPDATE_USER_PROFILE_REQUEST,
    payload: { data, callbacks, userId: data.userId }
  }
}

export const updateUserProfileSuccess = (data = {}) => {
  return {
    type: actionTypes.UPDATE_USER_PROFILE_SUCCESS,
    data
  }
}

export const updateUserProfileFailed = (message) => {
  return {
    type: actionTypes.UPDATE_USER_PROFILE_FAILED,
    message
  }
}

export const showPopupAfterLogin = (data) => {
  return {
    type: actionTypes.SHOW_POPUP_AFTER_LOGIN,
    data
  }
}

export function fetchAuthProfileRequest(data = {}, callbacks) {
  return {
    type: actionTypes.FETCH_AUTH_PROFILE_REQUEST,
    payload: {
      data,
      callbacks
    }
  }
}

export function fetchAuthProfileSuccess(data) {
  return {
    data,
    type: actionTypes.FETCH_AUTH_PROFILE_SUCCESS
  }
}

export function fetchAuthProfileFailed(data) {
  return {
    data,
    type: actionTypes.FETCH_AUTH_PROFILE_FAILED
  }
}

export function addDeviceTokenRequested() {
  return {
    type: actionTypes.ADD_DEVICE_TOKEN_REQUESTED
  }
}

export function addDeviceTokenSuccess(deviceToken) {
  return {
    type: actionTypes.ADD_DEVICE_TOKEN_SUCCESS,
    payload: { deviceToken }
  }
}

export function addDeviceTokenFailed(error) {
  return {
    type: actionTypes.ADD_DEVICE_TOKEN_FAILED,
    payload: { error }
  }
}

export function addDeviceIdFirebaseRequested(userId, deviceId) {
  return {
    type: actionTypes.ADD_DEVICE_ID_FIREBASE_REQUESTED,
    payload: {
      userId,
      deviceId
    }
  }
}

export function addDeviceIdFirebaseSuccess() {
  return {
    type: actionTypes.ADD_DEVICE_ID_FIREBASE_SUCCESS
  }
}

export function addDeviceIdFirebaseFailed(error) {
  return {
    type: actionTypes.ADD_DEVICE_ID_FIREBASE_FAILED,
    payload: { error }
  }
}
