import moment from "moment"
import { toast } from "react-toastify"
import { useDispatch } from "react-redux"
import FullCalendar from "@fullcalendar/react"
import listPlugin from "@fullcalendar/list"
import "@fullcalendar/list/main.min.css"
import "@fullcalendar/daygrid/main.min.css"
import "@fullcalendar/timegrid/main.min.css"
import { useHistory } from "react-router-dom"
import { Form, Button } from "react-bootstrap"
import JapaneseHolidays from "japanese-holidays"
import dayGridPlugin from "@fullcalendar/daygrid"
import timeGridPlugin from "@fullcalendar/timegrid"
import timelinePlugin from "@fullcalendar/timeline"
import interactionPlugin from "@fullcalendar/interaction"
import { useEffect, useState, useRef, useContext } from "react"

import "./styles.scss"
import AppContext from "../../../../Context"
import routes from "../../../../constants/routes"
import AppBar from "../../../../components/AppBar"
import {
  createScheduleRequested,
  updateScheduleRequested,
  deleteScheduleRequested
} from "../../../../actions/schedule"
import { LoadingIndicator } from "../../../../components"

const CreateSchedule = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const calendarRef = useRef(null)
  const { setContext } = useContext(AppContext)

  const { state } = history.location
  const defaultEdit = state?.user

  const [date, setDate] = useState(moment().toDate())
  const [value, setValue] = useState(defaultEdit?.type_of_hour?.toString())

  const typeOfHideMatch = () => {
    switch (defaultEdit?.is_hide_matched) {
      case false:
        return 0
      case true:
        return 1
      default:
        break
    }
  }

  const typeOfPurpose = () => {
    switch (defaultEdit?.purpose) {
      case "まずはメッセージから":
        return "1"
      case "今から会いたい":
        return "2"
      case "この日どうでしょう？":
        return "3"
      case "お茶したい":
        return "4"
      case "ランチ行きたい":
        return "5"
      case "ディナー行きたい":
        return "6"
        case "合コンしたい":
        return "7"
        case "通話したい":
        return "8"
      default:
        break
    }
  }
  const [valueMatch, setValueMatch] = useState({
    mainpic: defaultEdit ? typeOfHideMatch() : 0
  })
  const [category, setCategory] = useState(defaultEdit ? typeOfPurpose() : 1)
  const [dateClick, setDateClick] = useState(
    defaultEdit
      ? moment(defaultEdit.date).format("YYYY-MM-DD")
      : moment().format("YYYY-MM-DD")
  )
  const [content, setContent] = useState(defaultEdit ? defaultEdit.content : "")
  const [title, setTitle] = useState(defaultEdit ? defaultEdit.title : "")

  useEffect(() => {
    setValue(value)
    setValueMatch(valueMatch)
    setDateClick(dateClick)
  }, [])

  const handleChangeTime = (event) => {
    setValue(event.target.value)
  }

  const handleChangeMatch = (name, event) => {
    const {
      target: { value, checked }
    } = event
    if (typeof checked === "boolean") {
      setValueMatch({
        ...valueMatch,
        [name]: value === "on" ? (checked ? 1 : 0) : value
      })
      return
    }
  }

  const handleDatePrev = () => {
    const calendarEl = calendarRef.current
    if (calendarEl) {
      const calendarApi = calendarEl.getApi()

      calendarApi.prev()
      setDate(calendarApi.getDate())
    }
  }

  const handleDateNext = () => {
    const calendarEl = calendarRef.current

    if (calendarEl) {
      const calendarApi = calendarEl.getApi()

      calendarApi.next()
      setDate(calendarApi.getDate())
    }
  }

  const handleDateClick = (info) => {
    if (moment(info.date).valueOf() < moment(new Date()).subtract(1, 'days').valueOf()) {
      return false;
    }
    if (moment(info.date).valueOf() > moment(new Date()).add(1, 'years').valueOf()) {
      return false;
    }
    setDateClick(moment(info.dateStr).format("YYYY-MM-DD"))
    let hasEvent = false
    if (!hasEvent) {
      return
    }
  }

  const handleCreateSchedule = () => {
    setContext("overlay", <LoadingIndicator />)

    dispatch(
      createScheduleRequested(
        {
          date: dateClick,
          type_of_hour: Number(value),
          purpose_id: Number(category),
          title: title?.trim(),
          content: content?.trim(),
          is_hide_matched: valueMatch.mainpic
        },
        {
          onSuccess: () => {
            toast("投稿しました")
            setContext("overlay", null)
            history.push(routes.SCHEDULE)
          },
          onFailed: () => {
            setContext("overlay", null)
            toast("作成できるプランは 1 つだけです")
          }
        }
      )
    )
  }

  const handleDeleteSchedule = () => {
    setContext("overlay", <LoadingIndicator />)
    dispatch(
      deleteScheduleRequested(
        {
          id: defaultEdit.id
        },
        {
          onSuccess: () => {
            toast("削除しました")
            setContext("overlay", null)
            history.push(routes.SCHEDULE)
          },
          onFailed: () => {
            setContext("overlay", null)
          }
        }
      )
    )
  }

  const handleUpdateSchedule = () => {
    setContext("overlay", <LoadingIndicator />)
    dispatch(
      updateScheduleRequested(
        {
          id: defaultEdit.id,
          date: dateClick,
          type_of_hour: Number(value),
          purpose_id: Number(category),
          title: title?.trim(),
          content: content?.trim(),
          is_hide_matched: valueMatch.mainpic
        },
        {
          onSuccess: () => {
            setContext("overlay", null)
            history.push(routes.SCHEDULE)
            toast("更新しました")
          },
          onFailed: () => {
            setContext("overlay", null)
          }
        }
      )
    )
  }

  const events = [
    {
      start: moment(dateClick).format("YYYY-MM-DD"),
      end: moment(dateClick).add(1, "days").format("YYYY-MM-DD"),
      display: "background",
      backgroundColor: "rgb(82 75 75)"
    }
  ]

  return (
    <div>
      <AppBar withBack title="募集条件設定" />
      <div className="px-3 wrap-child-setting">
        <div className="border-top-grey"></div>
        <div className="calendar">
          <p className="icon-prev fs-4 text-grey-bold" onClick={handleDatePrev}>
            ◀︎
          </p>
          <p className="icon-next fs-4 text-grey-bold" onClick={handleDateNext}>
            ▶︎
          </p>
          <p className="mt-3 mb-0 fs-4 text-grey-bold">
            {moment(date).format("YYYY年MM月")}
          </p>
          <FullCalendar
            dayCellDidMount={(e) => {
              // If date is holiday add class fc-hol
              if (JapaneseHolidays.isHoliday(e.date)) {
                const el = document.querySelector(
                  'td[data-date="' +
                    moment(e.date).format('YYYY-MM-DD') +
                    '"]'
                );
                el?.classList.add('fc-hol');
              }
            }}
            allDayMaintainDuration
            initialDate={date}
            initialView="dayGridMonth"
            droppable
            dateClick={handleDateClick}
            events={events}
            selectable={true}
            height={400}
            ref={calendarRef}
            rerenderDelay={10}
            weekends
            // locales={[jaLocale]}
            locale="en"
            firstDay={0}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin,
              timelinePlugin
            ]}
            dayCellContent={(e) => {
              e.dayNumberText = e.dayNumberText.replace("日", "")
            }}
          />
        </div>
        <div className="border-top-grey mx-2 mt-5"></div>
        <div className="py-3" style={{ marginLeft: "25%" }}>
          <Form className="form-group">
            <Form.Check
              onChange={handleChangeTime}
              name="mainpic"
              size="lg"
              type="checkbox"
              label="午前中（9時〜12時）"
              value={1}
              checked={value === "1"}
              className="checked"
            />
            <Form.Check
              onChange={handleChangeTime}
              name="mainpic"
              size="lg"
              type="checkbox"
              label="昼（12時〜13時）"
              value={2}
              checked={value === "2"}
              className="checked"
            />
            <Form.Check
              onChange={handleChangeTime}
              name="mainpic"
              size="lg"
              type="checkbox"
              label="午後（13時〜16時）"
              value={3}
              checked={value === "3"}
              className="checked"
            />
            <Form.Check
              onChange={handleChangeTime}
              name="mainpic"
              size="lg"
              type="checkbox"
              label="夕方（16時〜19時）"
              value={4}
              checked={value === "4"}
              className="checked"
            />
            <Form.Check
              onChange={handleChangeTime}
              name="mainpic"
              size="lg"
              type="checkbox"
              label="夜（19時〜22時）"
              value={5}
              checked={value === "5"}
              className="checked"
            />
            <Form.Check
              onChange={handleChangeTime}
              name="mainpic"
              size="lg"
              type="checkbox"
              label="深夜（22時〜）"
              value={6}
              checked={value === "6"}
              className="checked"
            />
          </Form>
        </div>
        <div className="border-top-grey mx-2 mt-5">
          <div className="my-5 mx-3 d-flex align-items-center">
            <p className="fs-5 mb-0 text-grey col-2 col-md-6">目的</p>
            <Form className="form-group col-10 col-md-6">
              <Form.Select
                className="border-0 text-center"
                defaultValue={defaultEdit ? typeOfPurpose() : category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value={1}>まずはメッセージから</option>
                <option value={2}>今から会いたい</option>
                <option value={3}>この日どうでしょう？</option>
                <option value={4}>お茶したい</option>
                <option value={5}>ランチ行きたい</option>
                <option value={6}>ディナー行きたい</option>
                <option value={7}>合コンしたい</option>
                <option value={8}>通話したい</option>
              </Form.Select>
            </Form>
          </div>
          <div className="my-5 mx-3 d-flex align-items-center">
            <p className="fs-5 mb-0 text-grey col-10">マッチ済には非公開</p>
            <Form className="form-group col-2">
              <Form.Check
                onChange={(event) => handleChangeMatch("mainpic", event)}
                name="mainpic"
                size="lg"
                type="switch"
                checked={valueMatch.mainpic === 1}
                className="checked-switch text-end"
              />
            </Form>
          </div>
          <div className="mx-3">
            <Form className="form-group-input col-12 mb-5">
              <Form.Control
                name="title"
                placeholder="タイトル（20文字以内）"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                // isInvalid={!!errors.title}
                maxLength="20"
              />
            </Form>
            <textarea
              maxLength="500"
              className="w-100 col-12 textarea px-3 py-2"
              rows="5"
              placeholder="投稿内容（500文字以内）"
              name="content"
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
          </div>
          {defaultEdit ? (
            <div className="mt-3 mb-5 text-center d-flex justify-content-around">
              <Button
                onClick={handleDeleteSchedule}
                size="lg"
                className="btn-delete-schedule fs-6"
                style={{ width: 150 }}
                disabled={!value || !title || !content}
              >
                募集を削除
              </Button>
              <Button
                onClick={handleUpdateSchedule}
                variant="danger"
                size="lg"
                className="btn fs-6 btn-update-schedule"
                style={{ width: 150 }}
                disabled={!value || !title || !content}
              >
                編集決定
              </Button>
            </div>
          ) : (
            <div className="mt-3 mb-5 text-center">
              <Button
                onClick={handleCreateSchedule}
                variant="danger"
                size="lg"
                className="btn fs-6 btn-create-schedule"
                style={{ width: 150 }}
                disabled={!value || !title || !content}
              >
                募集する
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CreateSchedule
