import _ from "lodash"
import InfiniteScroll from "react-infinite-scroller"
import { useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory, useLocation } from "react-router-dom"

import AppContext from "../../Context"
import AppBar from "../../components/AppBar"
import Layout from "../../components/Layout"
import Filter from "../../components/Filter"
import UserLogin from "../../components/UserLogin/List"
import { LoadingIndicator } from "../../components/LoadingIndicator"
import { filterUsersRequest, selectUser } from "../../actions/filter"
import { filterAccountPopups } from "../../components/FilterAccountPopups";
import CustomPopups from "../../components/CustomPopups";

import "./styles.scss"
import routes from "../../constants/routes"

const perpage = 12

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const Users = () => {
  const sort = localStorage.getItem("sort")
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation()
  const [filter, setFilter] = useState({ sort: sort ? sort : "login" })
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(true)

  const [hasNextPage, setHasNextPage] = useState(true)

  const profileUserData = useSelector((state) => state.auth.profile);
  const data = useSelector((state) => state.popups.data);
  const [filteredData, setFilteredData] = useState([]);
  const [openPopups, setOpenPopups] = useState(false);
  const [statePopUps, setOpenStatePopUps] = useState(false);

  const filterAndSetData = () => {
    const result = filterAccountPopups(data, profileUserData);
    const validFilteredData = result.filter(item => item !== []);

    if (validFilteredData.length > 0 && result.length > 0
      && validFilteredData[0]?.screen_active === location.pathname.replace(/^\//, '')) {
      setFilteredData(validFilteredData);
      if (!statePopUps) {
        setOpenPopups(true);
      }
    }
  };

  useEffect(() => {
    filterAndSetData();
  }, [data, profileUserData, openPopups]);

  const handleClosePopups = () => {
    setOpenStatePopUps(true);
    setOpenPopups(false);
  };

  const { setContext } = useContext(AppContext)
  const users = useSelector((state) => {
    return state.filter.users
  })
  const query = useQuery()

  const handleFilterChange = (name, value) => {
    document.body.scrollTop = 0
    document.documentElement.scrollTop = 0
    if (name == "sort") {
      localStorage.setItem("sort", value)
    }
    setFilter({ ...filter, [name]: value })
  }

  const handleFilter = (options = {}) => {
    setHasNextPage(false)
    let { page: requestPage, ...params } = options
    if (requestPage === undefined) {
      requestPage = page
    }

    for (const [key, value] of query) {
      params[key] = params[key] || value
    }

    dispatch(
      filterUsersRequest(
        {
          page: requestPage,
          sort: filter.sort,
          perpage,
          ...params
        },
        {
          onSuccess: (response) => {
            setContext("overlay", null)
            setHasNextPage(!!_.size(response.data.users))
            setLoading(true)
          },
          onFailed: () => {
            setContext("overlay", null)
            setLoading(false)
          }
        }
      )
    )

    setPage(requestPage + 1)
  }

  const handleToggleFilter = () => {
    history.push(routes.FILTER)
  }

  const handleClick = (id) => {
    dispatch(selectUser(id))
    let ids = []
    users.forEach(function (u) {
      ids.push(u.user_id)
    })
    localStorage.setItem("list_user", JSON.stringify(ids))
    history.push(`${routes.USERS}/${id}`, {
      swipeable: true
    })
  }

  useEffect(() => {
    localStorage.removeItem("fromdetail")
    handleFilter()
  }, [])

  useEffect(() => {
    handleFilter({ page: 1 })
  }, [filter])

  return (
    <Layout nav>
      <AppBar title="一覧" colorTitle="black" search />
      <div className="bg-white d-flex flex-column full-height-with-navbar wrap-user">
        {!loading ? (
          <LoadingIndicator />
        ) : (
          <div className="container pt-2 px-0">
            <Filter
              {...filter}
              sort={filter.sort}
              onChange={handleFilterChange}
              toggleFilter={handleToggleFilter}
            />
            {users && !_.isEmpty(users) ? (
              <div id="scroller">
                <InfiniteScroll
                  pageStart={0}
                  loadMore={handleFilter}
                  hasMore={hasNextPage}
                  loader={
                    <div className="loader text-center" key={0}>
                      Loading...
                    </div>
                  }
                  useWindow={false}
                >
                  <UserLogin onClick={handleClick} items={users} />
                </InfiniteScroll>
              </div>
            ) : (
              <div className="empty-search">
                <svg
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="search"
                  className="svg-inline--fa fa-search fa-w-16"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 512 512"
                >
                  <path
                    fill="currentColor"
                    d="M505 442.7L405.3 343c-4.5-4.5-10.6-7-17-7H372c27.6-35.3 44-79.7 44-128C416 93.1 322.9 0 208 0S0 93.1 0 208s93.1 208 208 208c48.3 0 92.7-16.4 128-44v16.3c0 6.4 2.5 12.5 7 17l99.7 99.7c9.4 9.4 24.6 9.4 33.9 0l28.3-28.3c9.4-9.4 9.4-24.6.1-34zM208 336c-70.7 0-128-57.2-128-128 0-70.7 57.2-128 128-128 70.7 0 128 57.2 128 128 0 70.7-57.2 128-128 128z"
                  ></path>
                </svg>
                <h2>
                  設定された検索条件に、
                  <br />
                  あてはまるユーザーがいません。
                </h2>
                <h3>
                  絞り込み検索条件をご確認の上、再度条件を設定
                  <br />
                  してください
                </h3>
              </div>
            )}
          </div>
        )}
      </div>
      {(openPopups && filteredData?.length > 0) && <CustomPopups openPopups={openPopups} onClose={handleClosePopups} filteredData={filteredData[0]} />}
    </Layout>
  )
}

export default Users
