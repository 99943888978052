import { takeLatest, put, call } from "redux-saga/effects"
import {
  EXCHANGE_POINT_TO_HEART,
  FETCH_DATA_FOOT_PRINTS,
  FETCH_DATA_FOOT_UPDATE,
  SEND_QUESTION_REQUEST,
  DELETE_ACCOUNT_REQUEST,
  ADD_EMAIL,
  SEND_WITHDRAWAL_REQUEST,
  UPDATE_BANK_REQUEST,
  GET_WITHDRAWAL_HISTORY,
  ON_DATA_REPORT,
  ON_DATA_MATCHING_READ,
  HIDE_USER,
  GET_BANNER_REQUESTED,
  SEND_VERIFY_EMAIL,
  NOTIFICATION_SETTING_REQUESTED,
  GET_NOTI_SETTING_REQUESTED
} from "../constants/actions/other"
import {
  exChangePointApi,
  sendQuestionApi,
  deleteAccountApi,
  takeOverApi,
  sendWithdrawalRequest,
  updateBankRequest,
  listDataWithdrawal,
  userReportData,
  getBanner,
  verifyEmail,
  notificationSetting,
  getNotificationSetting
} from "../services/other"
import { footPrintsApi, footUpdate } from "../services/footprint"
import {
  fetchDataFootPrintsSuccess,
  fetchDataFootPrintsFail,
  fetchDataFootUpdateSuccess,
  fetchDataFootUpdateFail,
  getBannerSuccess,
  getBannerFailed
} from "../actions/other"
import { updatedIsMatchRead, updatedIsHideUser } from "../utils/firebase"

function* handleExchangePointSaga(action) {
  const { data, callbacks } = action.payload
  const { onSuccess, onFailed } = callbacks
  try {
    const response = yield exChangePointApi(data, action)
    if (response.data && response.message === "Successfully") {
      yield call(onSuccess, response.data)
    } else {
      yield call(onFailed)
    }
  } catch (error) {
    yield call(onFailed)
  }
}

function* handleFetchDataFootPrints(action) {
  try {
    const { onSuccess, onFailed } = action?.payload.callbacks || {}
    const response = yield footPrintsApi(action.payload, action)
    if (response.status === 200) {
      yield put(fetchDataFootPrintsSuccess(response.data))
      if (typeof onSuccess === "function") {
        yield call(onSuccess, response)
      }
    } else {
      yield put(fetchDataFootPrintsFail())
      if (typeof onFailed === "function") {
        yield call(onFailed)
      }
    }
  } catch (error) {}
}

function* handleFetchDataFootUpdate(action) {
  try {
    const response = yield footUpdate(action.payload, action)
    if (response) {
      yield put(fetchDataFootUpdateSuccess(response.data))
    } else {
      yield put(fetchDataFootUpdateFail())
    }
  } catch (error) {}
}

function* handleFetchDataSendQuestion(action) {
  try {
    const response = yield sendQuestionApi(action.payload, action)
    const { onSuccess, onFailed } = action.callbacks
    if (response) {
      yield call(onSuccess, response)
    } else {
      yield call(onFailed)
    }
  } catch (error) {}
}

function* handleDeleteAccount(action) {
  try {
    const response = yield deleteAccountApi(action.payload, action)
    const { onSuccess, onFailed } = action.callbacks
    if (response) {
      yield call(onSuccess, response)
    } else {
      yield call(onFailed)
    }
  } catch (error) {}
}

function* handleAddEmail(action) {
  const { onSuccess, onFailed } = action.callbacks

  try {
    const response = yield takeOverApi(action.payload)
    if (response.status === 200) {
      yield call(onSuccess, response)
    } else {
      yield call(onFailed)
    }
  } catch (error) {
    yield call(onFailed)
  }
}

function* handleVerifyEmail(action) {
  const { onSuccess, onFailed } = action.callbacks

  try {
    const response = yield verifyEmail(action.payload)

    if (response.status === 200) {
      yield call(onSuccess, response)
    } else {
      yield call(onFailed, response)
    }
  } catch (error) {
    yield call(onFailed)
  }
}

function* handleWithdrawalRequest(action) {
  try {
    const response = yield sendWithdrawalRequest(action.payload, action)
    const { onSuccess, onFailed } = action.callbacks
    if (response) {
      yield call(onSuccess, response)
    } else {
      yield call(onFailed)
    }
  } catch (error) {}
}

function* handleUpdateBankRequest(action) {
  try {
    const response = yield updateBankRequest(action.payload, action)
    const { onSuccess, onFailed } = action.callbacks
    if (response) {
      yield call(onSuccess, response)
    } else {
      yield call(onFailed)
    }
  } catch (error) {}
}

function* handleFetchDataWithdrawal(action) {
  try {
    const response = yield listDataWithdrawal(action.payload, action)
    const { onSuccess, onFailed } = action.callbacks
    if (response) {
      yield call(onSuccess, response)
    } else {
      yield call(onFailed)
    }
  } catch (error) {}
}

function* handleDataReportApi(action) {
  try {
    const response = yield userReportData(action.payload, action)
    const { onSuccess, onFailed } = action.callbacks
    if (response) {
      yield call(onSuccess, response)
    } else {
      yield call(onFailed)
    }
  } catch (error) {}
}

function* handleDataMatchingReadApi(action) {
  const data = action.payload
  const { onSuccess, onFailed } = action.callbacks
  try {
    const isReadDoc = yield call(updatedIsMatchRead, data)
    yield call(onSuccess, isReadDoc)
  } catch (err) {
    yield call(onFailed, err.toString())
  }
}

function* handleHideUser(action) {
  const data = action.payload
  const { onSuccess, onFailed } = action.callbacks
  try {
    const isHideDoc = yield call(updatedIsHideUser, data)
    yield call(onSuccess, isHideDoc)
  } catch (err) {
    yield call(onFailed, err.toString())
  }
}

function* handleGetBanner() {
  try {
    const response = yield getBanner()
    if (response.status === 200) {
      yield put(getBannerSuccess(response.data))
    } else {
      yield put(getBannerFailed(response.error))
    }
  } catch (error) {
    yield put(getBannerFailed(error))
  }
}

function* handleGetNotiSetting(action) {
  const { onSuccess, onFailed } = action.callbacks

  try {
    const response = yield getNotificationSetting()

    if (response.status === 200) {
      onSuccess(response.data)
    } else {
      onFailed(response.error)
    }
  } catch (error) {
    onFailed(error)
  }
}

function* handleNotificationSetting(action) {
  const { onSuccess, onFailed } = action.callbacks
  try {
    const response = yield notificationSetting(action.payload)

    if (response.status === 200) {
      onSuccess({ status: response.data.status, type: action.payload.type })
    } else {
      onFailed(response.error)
    }
  } catch (error) {
    onFailed(error)
  }
}

export default function* watchOther() {
  yield takeLatest(FETCH_DATA_FOOT_PRINTS, handleFetchDataFootPrints)
  yield takeLatest(EXCHANGE_POINT_TO_HEART, handleExchangePointSaga)
  yield takeLatest(FETCH_DATA_FOOT_UPDATE, handleFetchDataFootUpdate)
  yield takeLatest(SEND_QUESTION_REQUEST, handleFetchDataSendQuestion)
  yield takeLatest(DELETE_ACCOUNT_REQUEST, handleDeleteAccount)
  yield takeLatest(ADD_EMAIL, handleAddEmail)
  yield takeLatest(SEND_WITHDRAWAL_REQUEST, handleWithdrawalRequest)
  yield takeLatest(UPDATE_BANK_REQUEST, handleUpdateBankRequest)
  yield takeLatest(GET_WITHDRAWAL_HISTORY, handleFetchDataWithdrawal)
  yield takeLatest(ON_DATA_REPORT, handleDataReportApi)
  yield takeLatest(ON_DATA_MATCHING_READ, handleDataMatchingReadApi)
  yield takeLatest(HIDE_USER, handleHideUser)
  yield takeLatest(GET_BANNER_REQUESTED, handleGetBanner)
  yield takeLatest(SEND_VERIFY_EMAIL, handleVerifyEmail)
  yield takeLatest(GET_NOTI_SETTING_REQUESTED, handleGetNotiSetting)
  yield takeLatest(NOTIFICATION_SETTING_REQUESTED, handleNotificationSetting)
}
