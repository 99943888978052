import * as actionTypes from "../constants/actions"

const initialState = {
  data: null,
}

const skippedReducer = (state = initialState, action) => {
  let data = null
  switch (action.type) {
    case actionTypes.FETCH_DATA_SKIPPED:
      return {
        data,
      }
    case actionTypes.FETCH_DATA_SKIPPED_SUCCESS:
      data = action.payload.data
      return {
        data,
      }
    case actionTypes.FETCH_DATA_SKIPPED_FAIL:
      return {
        data,
      }

    default:
      return state
  }
}

export default skippedReducer
