import { takeEvery, put, call } from "redux-saga/effects"
import {
  scheduleListSuccess,
  scheduleListFailed,
  createScheduleSuccess,
  createScheduleFailed,
  scheduleDetailSuccess,
  scheduleDetailFailed,
  myScheduleSuccess,
  myScheduleFailed,
  updateScheduleSuccess,
  updateScheduleFailed,
  deleteScheduleSuccess,
  deleteScheduleFailed
} from "../actions/schedule"
import {
  SCHEDULE_LIST_REQUESTED,
  CREATE_SCHEDULE_REQUESTED,
  SCHEDULE_DETAIL_REQUESTED,
  MY_SCHEDULE_REQUESTED,
  EDIT_SCHEDULE_REQUESTED,
  DELETE_SCHEDULE_REQUESTED
} from "../constants/actions/schedule"
import {
  scheduleList,
  createSchedule,
  getScheduleDetail,
  getMySchedule,
  updateSchedule,
  deleteSchedule
} from "../services/schedule"

function* handleScheduleList(action) {
  const { onSuccess, onFailed } = action?.callbacks || {}

  try {
    const response = yield scheduleList(action)
    if (response && response.status === 200) {
      if (typeof onSuccess === "function") {
        yield call(onSuccess, response.data)
      }
      yield put(scheduleListSuccess(response.data))
    } else {
      if (typeof onFailed === "function") {
        yield call(onFailed)
      }

      yield put(scheduleListFailed())
    }
  } catch (error) {
    if (typeof onFailed === "function") {
      yield call(onFailed)
    }
    yield put(scheduleListFailed())
  }
}

function* handleMySchedule(action) {
  const { onSuccess, onFailed } = action?.callbacks || {}

  try {
    const response = yield getMySchedule(action)
    if (response && response.status === 200) {
      if (typeof onSuccess === "function") {
        yield call(onSuccess, response.data)
      }
      yield put(myScheduleSuccess(response.data))
    } else {
      if (typeof onFailed === "function") {
        yield call(onFailed)
      }

      yield put(myScheduleFailed())
    }
  } catch (error) {
    if (typeof onFailed === "function") {
      yield call(onFailed)
    }
    yield put(myScheduleFailed())
  }
}

function* handleScheduleDetail(action) {
  const { onSuccess, onFailed } = action?.payload.callbacks || {}

  try {
    const response = yield getScheduleDetail(action)
    if (response && response.status === 200) {
      if (typeof onSuccess === "function") {
        yield call(onSuccess, response.data)
      }
      yield put(scheduleDetailSuccess(response.data))
    } else {
      if (typeof onFailed === "function") {
        yield call(onFailed)
      }

      yield put(scheduleDetailFailed())
    }
  } catch (error) {
    if (typeof onFailed === "function") {
      yield call(onFailed)
    }
    yield put(scheduleDetailFailed())
  }
}

function* handlePostSchedule(action) {
  const { onSuccess, onFailed } = action?.payload?.callbacks || {}
  try {
    const response = yield createSchedule(action)
    if (response && response.status === 200) {
      if (typeof onSuccess === "function") {
        yield call(onSuccess, response.data)
      }
      yield put(createScheduleSuccess(response.data))
    } else {
      if (typeof onFailed === "function") {
        yield call(onFailed)
      }

      yield put(createScheduleFailed())
    }
  } catch (error) {
    if (typeof onFailed === "function") {
      yield call(onFailed)
    }
    yield put(createScheduleFailed())
  }
}

function* handleUpdateSchedule(action) {
  const { onSuccess, onFailed } = action?.payload?.callbacks || {}
  try {
    const response = yield updateSchedule(action)
    if (response && response.status === 200) {
      if (typeof onSuccess === "function") {
        yield call(onSuccess, response.data)
      }
      yield put(updateScheduleSuccess(response.data))
    } else {
      if (typeof onFailed === "function") {
        yield call(onFailed)
      }

      yield put(updateScheduleFailed())
    }
  } catch (error) {
    if (typeof onFailed === "function") {
      yield call(onFailed)
    }
    yield put(updateScheduleFailed())
  }
}

function* handleDeleteSchedule(action) {
  const { onSuccess, onFailed } = action?.payload?.callbacks || {}
  try {
    const response = yield deleteSchedule(action)
    if (response && response.status === 200) {
      if (typeof onSuccess === "function") {
        yield call(onSuccess, response.data)
      }
      yield put(deleteScheduleSuccess(response.data))
    } else {
      if (typeof onFailed === "function") {
        yield call(onFailed)
      }

      yield put(deleteScheduleFailed())
    }
  } catch (error) {
    if (typeof onFailed === "function") {
      yield call(onFailed)
    }
    yield put(deleteScheduleFailed())
  }
}

export default function* watchScheduleList() {
  yield takeEvery(SCHEDULE_LIST_REQUESTED, handleScheduleList)
  yield takeEvery(CREATE_SCHEDULE_REQUESTED, handlePostSchedule)
  yield takeEvery(SCHEDULE_DETAIL_REQUESTED, handleScheduleDetail)
  yield takeEvery(MY_SCHEDULE_REQUESTED, handleMySchedule)
  yield takeEvery(EDIT_SCHEDULE_REQUESTED, handleUpdateSchedule)
  yield takeEvery(DELETE_SCHEDULE_REQUESTED, handleDeleteSchedule)
}
