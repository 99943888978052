import { useSelector } from "react-redux"

import "./styles.scss"
import AppBar from "../../../components/AppBar"
import Layout from "../../../components/Layout"
import ArrowUp from "../../../images/arrow-up.png"
import ArrowDown from "../../../images/arrow-down.png"

const QA = () => {
  const profile = useSelector((state) => {
    return state.auth.profile
  })

  const qaDataFemale = [
    {
      id: 1,
      title: "年齢を間違えて登録してしまった",
      answer:
        "原則、年齢の変更をできません。\n年齢を変更するにはアカウントを作り直す必要がございます。 "
    },
    {
      id: 2,
      title: "年齢確認書類を提出したが審査が完了しない。",
      answer:
        "年齢確認書類は担当スタッフにて一件ずつ目視にて確認しております。時間帯によって24時間程度お時間をいただくことがございます \n ご登録されている年齢と年齢確認提出書類に相違がある場合は否認になります 提出から24時間が経過しても審査が完了しない場合には、Tutu総合受付までご連絡ください。 "
    },
    {
      id: 3,
      title: "年齢確認書類はどんなものが対象になるのか。",
      answer:
        "年齢確認書類は下記の書類が対象になります。\n 各種健康保険（全国健康保険協会、健康保険組合、共済組合、国民健康保険、後期高齢者医療、自衛官診療証） \n パスポート \n 運転免許証 \n マイナンバーカード \n その他公的機関が発行する身分証 "
    },
    {
      id: 4,
      title: "女性会員は本当に無料でしょうか？",
      answer: "はい。完全無料でご利用頂けます"
    },
    {
      id: 5,
      title: "会員登録にお金はかかりますか？",
      answer: "男性会員の方で有料会員に登録されますと料金が発生致します"
    },
    {
      id: 6,
      title: "男性会員の無料登録でどこまで利用できますか？",
      answer:
        "女性会員のプロフ閲覧などは可能です \n メッセージのやりとりをする際に有料会員ご登録が必要になります"
    },
    {
      id: 7,
      title: "サクラや業者は本当にいないのですか？",
      answer:
        "24時間監視システムでの管理体制でしております。業者など見かけましたら通報をお願い致します。当アプリが用意したサクラなどは一切おりません"
    },
    {
      id: 8,
      title: "一度退会した後再登録は可能ですか？",
      answer:
        "はい。可能ですが前回のデータは全て消えてしまいますのでご注意下さい"
    },
    {
      id: 9,
      title: "メールアドレスを変更したのでログイン出来ないのですが？",
      answer: "Tutu総合受付までお問い合わせ下さい"
    },
    {
      id: 10,
      title: "連絡先の交換に制限は設けていますか？",
      answer:
        "当アプリ以外での連絡はご控えください。（他社SNS、携帯番号、メールアドレス等）"
    },
    {
      id: 11,
      title: "退会はどうするのですか？",
      answer: "退会フォームよりご申請下さい"
    },
    {
      id: 12,
      title: "携帯の機種を変更したのですが、どのような手続きが必要ですか？",
      answer:
        "メールアドレス、電話番号での引き継ぎが可能です。 \n 機種変更される前にご登録をお願い致します。 \n 再度新しい機種でメールアドレス、電話番号でログインして頂きましたら引継ぎ可能です。"
    },
    {
      id: 13,
      title: "個人情報の漏洩が心配なのですが？",
      answer:
        "当アプリ運営会社に所属している個人情報保護士による厳重な管理体制の下管理しておりますのでご安心下さいませ"
    },
    {
      id: 14,
      title: "ログイン出来ないのですが？",
      answer:
        "電波状況をご確認頂き改善しない場合はTutu総合受付までお問い合わせ下さい"
    },
    {
      id: 15,
      title: "パスワードを忘れてしまいました。",
      answer: "Tutu総合受付までお問い合わせ下さい"
    }
  ]

  const qaDataMale = [
    {
      id: 1,
      title: "決済が完了しない場合",
      answer:
        "各種有料プランへのご登録・ポイントのご購入など、決済を伴うお申込みをされたにも関わらず、完了しない場合は下記をご確認ください。\n決済の完了を再確認 \n クレジット決済 Credixからメールで領収書が届きます。\n Google決済 Googleからメールで領収書が届きます。 \n メールが届いていない場合、googleにご登録されているクレジットカードの問題の可能性がございます \n googleのお支払い情報をご確認下さい \n 解決しない場合は \n Tutu総合受付まで問い合わせください。"
    },

    {
      id: 2,
      title: "年齢を間違えて登録してしまった",
      answer:
        "原則、年齢の変更をできません。\n年齢を変更するにはアカウントを作り直す必要がございます。 "
    },
    {
      id: 3,
      title: "年齢確認書類を提出したが審査が完了しない。",
      answer:
        "年齢確認書類は担当スタッフにて一件ずつ目視にて確認しております。時間帯によって24時間程度お時間をいただくことがございます \n ご登録されている年齢と年齢確認提出書類に相違がある場合は否認になります 提出から24時間が経過しても審査が完了しない場合には、Tutu総合受付までご連絡ください。 "
    },
    {
      id: 4,
      title: "年齢確認書類はどんなものが対象になるのか。",
      answer:
        "年齢確認書類は下記の書類が対象になります。\n 各種健康保険（全国健康保険協会、健康保険組合、共済組合、国民健康保険、後期高齢者医療、自衛官診療証） \n パスポート \n 運転免許証 \n マイナンバーカード \n その他公的機関が発行する身分証 "
    },
    {
      id: 5,
      title: "会員登録にお金はかかりますか？",
      answer: "男性会員の方で有料会員に登録されますと料金が発生致します"
    },
    {
      id: 6,
      title: "男性会員の無料登録でどこまで利用できますか？",
      answer:
        "女性会員のプロフ閲覧などは可能です \n メッセージのやりとりをする際に有料会員ご登録が必要になります"
    },
    {
      id: 7,
      title: "有料会員の登録の仕方を教えてください",
      answer:
        "会員ページより無料会員をタップ頂ければ有料会員登録の画面に進むことが可能です"
    },
    {
      id: 8,
      title: "サクラや業者は本当にいないのですか？",
      answer:
        "24時間監視システムでの管理体制でしております。業者など見かけましたら通報をお願い致します。当アプリが用意したサクラなどは一切おりません"
    },
    {
      id: 9,
      title: "一度退会した後再登録は可能ですか？",
      answer:
        "はい。可能ですが前回のデータは全て消えてしまいますのでご注意下さい"
    },
    {
      id: 10,
      title: "メールアドレスを変更したのでログイン出来ないのですが？",
      answer: "Tutu総合受付までお問い合わせ下さい"
    },
    {
      id: 11,
      title: "連絡先の交換に制限は設けていますか？",
      answer:
        "当アプリ以外での連絡はご控えください。（他社SNS、携帯番号、メールアドレス等）"
    },
    {
      id: 12,
      title: "退会はどうするのですか？",
      answer: "退会フォームよりご申請下さい"
    },
    {
      id: 13,
      title: "携帯の機種を変更したのですが、どのような手続きが必要ですか？",
      answer:
        "メールアドレス、電話番号での引き継ぎが可能です。 \n 機種変更される前にご登録をお願い致します。 \n 再度新しい機種でメールアドレス、電話番号でログインして頂きましたら引継ぎ可能です。"
    },
    {
      id: 14,
      title: "個人情報の漏洩が心配なのですが？",
      answer:
        "当アプリ運営会社に所属している個人情報保護士による厳重な管理体制の下管理しておりますのでご安心下さいませ"
    },
    {
      id: 15,
      title: "会員の料金の支払いは、どのような方法がありますか？",
      answer:
        "クレジット決済をご利用ください。他決済種別も今後追加予定になります。"
    },
    {
      id: 16,
      title: "ログイン出来ないのですが？",
      answer:
        "電波状況をご確認頂き改善しない場合はTutu総合受付までお問い合わせ下さい"
    },
    {
      id: 17,
      title: "パスワードを忘れてしまいました。",
      answer: "Tutu総合受付までお問い合わせ下さい"
    }
  ]

  const handleShowAnswer = (answerId) => {
    const el = document.getElementById("answer" + answerId)
    const qs = document.getElementById("question" + answerId)
    if (el.style.display === "" || el.style.display === "none") {
      el.style.display = "flex"
      qs.style.background = `url(${ArrowUp}) no-repeat top 50% right 0/ 1.5rem`
    } else {
      el.style.display = "none"
      qs.style.background = `url(${ArrowDown}) no-repeat top 50% right 0/ 1.5rem`
    }
  }
  const qaData = profile.sex === 1 ? qaDataMale : qaDataFemale

  return (
    <Layout>
      <AppBar title="よくある質問" withBack />
      <div className="bg-white d-flex flex-column pb-5 mb-5 container-wrap">
        {qaData.map((qa, index) => (
          <div key={index}>
            <div
              className="d-flex border-bottom mx-1 align-items-center question"
              onClick={() => handleShowAnswer(qa.id)}
              id={`question${qa.id}`}
            >
              <div className="q-icon">Q</div>
              <div className="question-content">{qa.title}</div>
            </div>
            <div
              className="m-0 px-4 pt-3 pb-3 align-items-center answer"
              id={`answer${qa.id}`}
            >
              <div className="a-icon me-2">A</div>
              <div className="m-2 answer-content">{qa.answer}</div>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export default QA
