import HttpClient from "../utils/client"

export function* retrieveAgoraToken(toUserId) {
  const url = `/users/${toUserId}/video-calls`
  return yield HttpClient("POST", { url })
}

export function* getRTMToken(deviceToken) {
  const url = `/agora/login`
  return yield HttpClient("POST", { url, data: { device_token: deviceToken } })
}

export function* fetchGiftList() {
  const url = `/gifts`
  return yield HttpClient("GET", { url })
}

export function* purchaseGift(action) {
  const url = `/users/${action.data.caller_id}/gifts`
  return yield HttpClient("POST", {
    url,
    data: {
      gift_id: action.data.gift_id,
      amount: 1
    }
  })
}

export function* sendGift(channelId, calleeId, userGiftId) {
  const url = `/users/gifts/${userGiftId}/tipping`
  return yield HttpClient("PUT", {
    url,
    data: {
      channel_id: channelId,
      to_user_id: calleeId
    }
  })
}

export function* getListGifts(action) {
  const url = `/gifts`
  return yield HttpClient("GET", { url }, action)
}

export function* putTippingGift(action) {
  const payload = {
    url: `/users/gifts/${action.data.user_gift_id}/tipping`,
    data: {
      channel_id: action.data.channel_id,
      to_user_id: action.data.to_user_id
    }
  }
  return yield HttpClient("PUT", payload, action)
}

export function* startVideoCall(channelId, toUserId) {
  const url = `/users/${toUserId}/video-calls/start`
  return yield HttpClient("PUT", { url, data: { channel_id: channelId } })
}

export function* extendVideoCall(channelId, toUserId) {
  const url = `/users/${toUserId}/video-calls/extend`
  return yield HttpClient("PUT", { url, data: { channel_id: channelId } })
}
