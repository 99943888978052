import classNames from "classnames"
import { useRef, useState, useContext } from "react"
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Camera from "react-html5-camera-photo"
import "react-html5-camera-photo/build/css/index.css"
import imageCompression from "browser-image-compression"

import CropImages from "./Crop"
import AppContext from "../../../Context"
import Select from "../../Elements/Select"
import messages from "../../../constants/messages"
import ChooseAvatar from "../../../components/Avatar/Choose"

const Avatars = ({ limit, onSubmit }) => {
  const { setContext } = useContext(AppContext)
  const files = useRef([])
  const [activeIndex, setActiveIndex] = useState(null)
  const [avatars, setAvatars] = useState({})

  const hasGetUserMedia = () => {
    return !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia)
  }

  const handleClick = (i) => {
    if (files.current[i]) {
      files.current[i].click();
      setActiveIndex(i);
    }
  };

  const setCameraDisplay = (flag) => {
    if (flag) {
      if (hasGetUserMedia()) {
        setContext("overlay", <Camera onTakePhoto={handleCaptureImage} />)
      } else {
        alert("カメラはお使いのブラウザではサポートされていません")
      }
    } else {
      setContext("overlay", null)
    }
  }

  const handleChooseOption = (name, value) => {
    switch (value) {
      case 0:
        handleSelectFile(activeIndex, value)
        break

      case 1:
        handleSelectFile(activeIndex, value)
        break

      case 2:
      default:
        setActiveIndex(null)
        break
    }
  }

  const handleSelectFile = (idx, device) => {
    if (device === 0) {
      files.current[idx].setAttribute("capture", "camera")
    } else {
      files.current[idx].removeAttribute("capture")
    }
    files.current[idx].click()
  }

  const handleCaptureImage = (capture) => {
    setCameraDisplay(false)
    setAvatars({ ...avatars, [activeIndex]: capture })
    setActiveIndex(null)
  }

  const handleChange = async (idx) => {
    const file = files.current[idx].files[0]
    if (file.type === "image/gif") {
      alert("GIF画像を選択できません。")
      return
    }

    if (file.size > 5000000) {
      alert(messages.imageTooLarge)
      return false
    }

    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1200,
      useWebWorker: true
    }

    const compressedFile = await imageCompression(file, options)

    setAvatars({
      ...avatars,
      [idx]: {
        file: compressedFile
      }
    })
    setActiveIndex(null)
    const reader = new FileReader()

    reader.readAsDataURL(compressedFile)
    reader.onloadend = async (e) => {
      var src = await resizeImage(reader.result)
      setAvatars({
        ...avatars,
        [idx]: {
          file: compressedFile,
          url: src
        }
      })
      if (idx === 0) {
        setContext(
          "overlay",
          <CropImages
            src={src}
            onCancel={cancelCrop}
            onComplete={completeCrop}
          />
        )
      }
      setActiveIndex(null)
    }
  }

  const cancelCrop = () => {
    setContext("overlay", null)
  }

  const completeCrop = async (src) => {
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: 1200,
      useWebWorker: true
    }

    const compressedFile = await imageCompression(
      files.current[0].files[0],
      options
    )

    setAvatars({
      ...avatars,
      [0]: {
        file: compressedFile,
        url: src
      }
    })
    setContext("overlay", null)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit(avatars)
  }

  function resizeImage(base64Str, maxWidth = 600, maxHeight = 600) {
    return new Promise((resolve) => {
      let img = new Image()
      img.src = base64Str
      img.onload = () => {
        let canvas = document.createElement("canvas")
        const MAX_WIDTH = maxWidth
        const MAX_HEIGHT = maxHeight
        let width = img.width
        let height = img.height

        if (width > height) {
          if (width > MAX_WIDTH) {
            height *= MAX_WIDTH / width
            width = MAX_WIDTH
          }
        } else {
          if (height > MAX_HEIGHT) {
            width *= MAX_HEIGHT / height
            height = MAX_HEIGHT
          }
        }
        canvas.width = width
        canvas.height = height
        let ctx = canvas.getContext("2d")
        ctx.drawImage(img, 0, 0, width, height)
        resolve(canvas.toDataURL())
      }
    })
  }

  return (
    <Form
      onSubmit={handleSubmit}
      className="d-flex flex-column full-height-with-navbar ox-hidden mt-5 pt-4 pb-5 mb-5"
    >
      <h1 className="register-picker-title">プロフィール写真の登録</h1>
      <p className="space-5 register-picker-subtitle">
        あなたのプロフィール写真・動画を登録してください
      </p>

      <Row className="pb-5">
        {[...Array(limit)].map((_, i) => (
          <Col
            key={i}
            xs={i === 0 ? 12 : 4}
            className={classNames({ "mb-3": i === 0 })}
          >
            <ChooseAvatar
              index={i}
              avatar={avatars[i]}
              onClick={() => {
                handleClick(i)
              }}
              backgroundSize="cover"
            />
            <Form.Control
              className="d-none"
              type="file"
              name="file"
              id={`file${i}`}
              accept="image/*"
              ref={(el) => (files.current[i] = el)}
              onChange={() => handleChange(i)}
              isInvalid={false}
            />
          </Col>
        ))}
      </Row>
      <br/>
      <br/>
      <br/>
      {activeIndex !== null && (
        <Select
          onChange={handleChooseOption}
          className="select-image"
          options={["写真・動画を登録", "ライブラリから選択", "キャンセル"]}
        />
      )}
      <div className="position-fixed bottom-0 start-0 bg-white w-100 p-2 text-center">
        <Button
          className="align-self-center w-100 btn btn-avatar-register"
          variant="danger"
          type="submit"
        >
          早速、はじめる
        </Button>
      </div>
    </Form>
  )
}

export default Avatars
