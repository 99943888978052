import { useSpring, animated } from "@react-spring/web"

export const AnimatedGift = ({ giftSrc }) => {
  const fadeStyle = useSpring({
    config: { duration: 5000 },
    from: { opacity: 1 },
    to: {
      opacity: 0
    }
  })

  return (
    <animated.img
      className="gift"
      style={fadeStyle}
      height={200}
      src={giftSrc}
    />
  )
}
