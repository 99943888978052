import { useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"

import { Item } from "./Item"
import AppBar from "../../../../components/AppBar"
import { LoadingIndicator } from "../../../../components"
import { scheduleDetailRequested } from "../../../../actions/schedule"

export const ScheduleDetail = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { state } = history.location
  const [users, setUsers] = useState({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    dispatch(
      scheduleDetailRequested(
        { id: state?.user?.id },
        {
          onSuccess: (response) => {
            setLoading(false)
            setUsers(response)
          },
          onFailed: () => {
            setLoading(false)
          }
        }
      )
    )
  }, [state?.user?.id])

  return (
    <div>
      <AppBar withBack title="募集詳細" />
      {loading ? (
        <LoadingIndicator />
      ) : (
        <div
          style={{
            paddingBottom: "74px",
            overflow: "scroll",
            height: "100%",
            maxHeight: "1000px",
            marginBottom: "200px",
            paddingTop: 63
          }}
        >
          <div className="border-top-grey"></div>
          <Item
            user={users}
            onAvatarClick={() => {
              history.push(`/users/${users?.user_id}`)
            }}
            onRightClick={() => {
              // history.push(`/private-chat/${user.id}`)
            }}
          />
        </div>
      )}
    </div>
  )
}
