import HttpClient from "../utils/client"

export function* loginWithEmail(email_for_takeover, password_for_takeover) {
  const url = "/users/1/login"
  const params = { email_for_takeover, password_for_takeover }

  return yield HttpClient("POST", { url, params }, null, "loginWithEmail", true)
}

export function* loginWithPhone(phone) {
  return yield HttpClient("POST", {
    url: "/users/1/login",
    params: {
      phone
    }
  })
}

export function* fetchAuthProfile(data, action) {
  return yield HttpClient("GET", { url: "/me" }, action)
}

export function* addDeviceToken(formData, token) {
  const url = `/device-token`
  return yield HttpClient(
    "POST",
    { url, data: formData },
    null,
    null,
    null,
    false,
    token
  )
}
