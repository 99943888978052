import { useContext, useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import useWindowSize from "../../hooks/use-window-size"
import useOnClickOutside from "../../hooks/use-onclick-outside"
import Carousel from "react-bootstrap/Carousel"
import { LoadingIndicator } from "../LoadingIndicator"
import AppContext from "../../Context"
import GiftsImg from "./images"

import { fetchGiftsRequest, tippingGiftRequest } from "../../actions/videoCall"
import { fetchAuthProfileRequest } from "../../actions/auth"

import icon from "./images/money.png"
import "./style.scss"

const Gifts = (props) => {
  const dispatch = useDispatch()
  const windowSize = useWindowSize()
  const ref = useRef()
  const {
    gifts,
    channelId,
    toUserId,
    callerId,
    onClickOutside,
    onBuyPoint,
    onTipping,
    onTipped
  } = props
  const { setContext } = useContext(AppContext)
  const [dataInPage, setDataInPage] = useState([])
  const [heightSlider, setHeightSlider] = useState(0)
  const [heightImg, setHeightImg] = useState(0)
  const [tipData, setTipData] = useState({})
  const [showConfirmTipping, setShowConfirmTipping] = useState(false)
  const [showError, setShowError] = useState(false)
  const perpage = 8

  useOnClickOutside(ref, (event) => {
    if (onClickOutside) {
      onClickOutside(event)
    }
  })

  const profile = useSelector((state) => {
    return state.auth.profile
  })

  useEffect(() => {
    if (gifts) {
      calcDataInPage(gifts)
    } else {
      dispatch(
        fetchGiftsRequest({
          onSuccess: (response) => {
            setContext("overlay", null)
            calcDataInPage(response.data)
          },
          onFailed: () => {
            setContext("overlay", null)
          }
        })
      )
    }
  }, [])

  useEffect(() => {
    CalHeight()
  }, [windowSize, dataInPage])

  const calcDataInPage = (data) => {
    var result = []
    var page = []
    var idx = 0
    for (var i = 0; i < data.length; i++) {
      page.push(data[i])
      idx++
      if (idx == perpage || i == data.length - 1) {
        result.push(page)
        page = []
      }
    }
    setDataInPage(result)
    CalHeight()
  }

  const CalHeight = () => {
    let item = document.getElementById("carousel-item0")
    if (item) {
      setHeightSlider(item.clientHeight)
    }
    let imgItem = document.getElementById("img0")
    if (imgItem) {
      setHeightImg(imgItem.clientWidth - 20)
    }
  }

  const toStringNumber = (num) => {
    if (num == null || num == '') {
      return '0'
    }
    return (Math.round(num) + "").replace(/(\d)(?=(\d{3})+$)/g, "$1,")
  }

  const confirmTippingGift = (gift_id, points_spent, image, name) => {
    if (Math.round(points_spent) > Math.round(profile.points_balance)) {
      //setShowError(true)
      alert("ポイントは不足しています。")
    } else {
      setShowConfirmTipping(true)
      setTipData({
        gift_id: gift_id,
        points_spent: points_spent,
        image: image,
        name: name
      })
    }
  }

  const handleBuyPoint = () => {
    setShowError(false)
    setShowConfirmTipping(false)
    if (onBuyPoint) {
      onBuyPoint()
    }
  }

  const handleTipping = () => {
    setShowError(false)
    setShowConfirmTipping(false)
    if (onTipping) {
      onTipping(tipData)
    } else {
      setContext("overlay", <LoadingIndicator />)
      dispatch(
        tippingGiftRequest(
          {
            gift_id: tipData.gift_id,
            channel_id: channelId,
            to_user_id: toUserId,
            caller_id: callerId,
            amount: Math.round(tipData.points_spent)
          },
          {
            onSuccess: (response) => {
              setContext("overlay", null)
              if (response.status !== 200) {
                if (response.data.error && response.data.error.message && response.data.error.message == "NotEnoughPoint" ) {
                  alert("ポイントは不足しています。")
                }
                else {
                  alert("ギフトを贈りすることは失敗しました")
                }
              } else {
                dispatch(
                  fetchAuthProfileRequest(
                    {},
                    {
                      onSuccess: (response) => {},
                      onFailed: () => {}
                    }
                  )
                )
                if (onTipped) {
                  console.log("Tip data: ", tipData)
                  onTipped(response.data, tipData)
                }
              }
            },
            onFailed: () => {
              setContext("overlay", null)
              alert("ギフトを贈りすることは失敗しました")
            }
          }
        )
      )
    }
  }

  return (
    <div className="popup-list-gifts" ref={ref}>
      <h2 className="title">ギフトを贈ろう！</h2>
      <div className="list-gifts">
        <Carousel
          interval={null}
          onSelect={CalHeight}
          style={{ minHeight: heightSlider }}
        >
          {dataInPage.map((page, idx) => {
            return (
              <Carousel.Item key={idx} id={`carousel-item${idx}`}>
                {page.map((gift, i) => {
                  return (
                    <div
                      key={i}
                      className="gift-item"
                      onClick={() => {
                        confirmTippingGift(
                          gift.gift_id,
                          gift.points_spent,
                          GiftsImg[i + idx * perpage],
                          gift.name
                        )
                      }}
                    >
                      <div className="icon" id={`img${i + idx * perpage}`}>
                        <img
                          src={GiftsImg[i + idx * perpage]}
                          alt={gift.name}
                          style={{ height: heightImg, objectFit: "contain" }}
                        />
                      </div>
                      <div className="point">
                        <img src={icon} alt={gift.points_spent} />
                        <p>{toStringNumber(gift.points_spent)}</p>
                      </div>
                    </div>
                  )
                })}
              </Carousel.Item>
            )
          })}
        </Carousel>
      </div>
      <div className="current-point">
        <div className="point">
          <img src={icon} alt="point" />
          <p>{toStringNumber(profile.points_balance)}</p>
        </div>
        <div className="div-btn">
          <button type="button" onClick={handleBuyPoint}>
            チャージする
          </button>
        </div>
      </div>
      {showConfirmTipping && (
        <>
          <div className="popup-bg"></div>
          <div className="popup-content">
            <div className="popup-title">
              このギフトを贈りますが、よろしいで すか？
            </div>
            <div className="popup-gift">
              <div className="icon">
                <img src={tipData.image} alt="gift" />
              </div>
              <div className="point">
                <img src={icon} alt="point" />
                <p>{toStringNumber(tipData.points_spent)}</p>
              </div>
            </div>
            <div className="popup-btn ok">
              <button type="button" onClick={handleTipping}>
                OK
              </button>
            </div>
            <div className="popup-btn">
              <button
                type="button"
                onClick={() => {
                  setShowConfirmTipping(false)
                }}
              >
                キャンセル
              </button>
            </div>
          </div>
        </>
      )}
      {showError && (
        <>
          <div className="popup-bg"></div>
          <div className="popup-content">
            <div className="popup-title">ポイントが不足しています。</div>
            <div className="popup-detail">
              ポイントが不足しています。ポイント購入は下記 よりご購入ください
            </div>
            <div className="popup-btn ok">
              <button type="button" onClick={handleBuyPoint}>
                OK
              </button>
            </div>
            <div className="popup-btn">
              <button
                type="button"
                onClick={() => {
                  setShowError(false)
                }}
              >
                キャンセル
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Gifts
