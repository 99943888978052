import AppBar from "../../../../components/AppBar"
import Layout from "../../../../components/Layout"

const AboutCompany = () => {
  return (
    <Layout>
      <AppBar title="会社概要" withBack />
      <div
        className="bg-white d-flex flex-column px-3 wrap-child-setting"
      >
        <h4>会社概要</h4>
        <br />
        <p>
          事業者名：株式会社DaDa
          <br />
          <br />
          代表責任者：右田圭吾
          <br />
          所在地：東京都新宿区大久保一丁目2-1
          <br />
          ・TEL：03-4400-3551
          <br />
          ※お問い合わせはメールにて承っております
          <br />
          お問い合わせ：
          <br />
          ・MAIL：support@tu-tu.jp
          <br />
          商品等の販売価格：
          <br />
          ・通常プラン 1ヶ月5,000円/月(5,000円)、3ヶ月4,000/月(12,000円)、12ヶ月3,233/月(38,800円)
          <br />
          ・プラチナ 1ヶ月14,800円/月(14,800円)、3ヶ月11,600/月(34,800円)、12ヶ月9,900/月(118,800円)
          <br />
          ・ダイヤモンド 1ヶ月20,000円/月(20,000円)、3ヶ月16,000/月(48,000円)、12ヶ月13,200/月(158,400円)
          <br />
          ・モノリス 1ヶ月100,000円/月(100,000円)、3ヶ月90,000/月(270,000円)、12ヶ月83,333/月(1,000,000円)
          <br />
          <br />
          送料などの商品代金以外の付帯費用：なし
          <br />
          代金の支払方法：
          <br />
          ・クレジットカード
          <br />
          商品等の引き渡し時期：契約成立日当日
          <br />
          返品の可否と条件：サービスの性質上、返品はお受けしておりません
        </p>
      </div>
    </Layout>
  )
}

export default AboutCompany
