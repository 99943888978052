import _ from "lodash"
import { toast } from "react-toastify"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import InfiniteScroll from "react-infinite-scroller"
import { useSelector, useDispatch } from "react-redux"

import {
  getLikersRequested,
  thankUserRequested,
  fetchUserDetailSuccess,
  markReadUserRequested,
  muteUserRequested,
  labelUserRequested
} from "../../../../actions"
import { Item } from "./Item"
import { EmptyFirstTab } from "./EmptyFirstTab"
import routes from "../../../../constants/routes"
import { ErrorModal } from "../../../../components/ErrorModal"
import { getShowMatchConfirmation } from "../../../../utils/persist"
import { LoadingIndicator } from "../../../../components/LoadingIndicator"

const LIMIT = 10

export const List = ({ onViewUsers }) => {
  const [displayedUsers, setDisplayedUsers] = useState([])
  const [errorModal, setErrorModal] = useState(false)

  const dispatch = useDispatch()
  const history = useHistory()
  const currentUserId = useSelector((state) => {
    return state.auth.profile.user_id
  })
  const allUsers = useSelector((state) => state.likes.likers)
  const isLoading = useSelector((state) => state.likes.isLoading)
  const error = useSelector((state) => state.likes.error)

  useEffect(() => {
    dispatch(getLikersRequested(currentUserId, dispatch))
  }, [currentUserId])

  useEffect(() => {
    setDisplayedUsers(allUsers.slice(0, LIMIT))
  }, [allUsers])

  const onLoadMore = (page) => {
    setTimeout(() => {
      setDisplayedUsers(allUsers.slice(0, page * LIMIT))
    }, 1000)
  }

  const handleMarkUser = (likeId) => {
    dispatch(
      markReadUserRequested(likeId, 1, {
        onSuccess: () => {
          toast("既読しました")
        },
        onFailed: () => {
          setErrorModal(true)
        }
      })
    )
  }

  const handleBlockUser = (userId) => {
    dispatch(
      muteUserRequested(
        { id: userId },
        {
          onSuccess: (response) => {
            toast("ブロックしました")
          },
          onFailed: () => {}
        }
      )
    )
  }

  const handleThankUser = (user) => {
    dispatch(
      thankUserRequested(currentUserId, user.id, user.likeId, {
        onSuccess: () => {
          toast("気にしました")
          dispatch(fetchUserDetailSuccess(user))
          const persist = getShowMatchConfirmation()
          const persistUid = _.get(persist, "uid", false)
          const persistFlag = _.get(persist, "flag", false)
          if (persistUid === currentUserId && persistFlag) {
            const dataAfterDelete = displayedUsers.filter(
              (elm) => elm.id !== user.user_id
            )
            setDisplayedUsers(dataAfterDelete)
          } else {
            history.push(routes.MATCH)
          }
        },
        onFailed: () => {
          setErrorModal(true)
        }
      })
    )
  }

  const handleLabelUser = (toUserId, isLabel) => {
    const data = {
      toUserId: toUserId,
      isLabel: isLabel
    }

    dispatch(
      labelUserRequested(data, {
        onSuccess: () => {
          toast("更新されました")
        },
        onFailed: () => {
          setErrorModal(true)
        }
      })
    )
  }

  const handleClickItem = (user) => {
    let ids = []
    allUsers.forEach(function (u) {
      ids.push(parseInt(u.id))
    })

    localStorage.setItem("list_user_like", JSON.stringify(ids))
    history.push(`${routes.USERS}/${user.id}?from=like`, {
      swipeable: true
    })

    if (user.isLikeRead === 0) {
      handleMarkUser(user.likeId)
    }
  }

  return (
    <>
      {isLoading || !currentUserId ? (
        <LoadingIndicator />
      ) : !_.isEmpty(displayedUsers) ? (
        <InfiniteScroll
          pageStart={0}
          loadMore={onLoadMore}
          hasMore={allUsers.length > displayedUsers.length}
          loader={<LoadingIndicator key={0} sm />}
          useWindow={false}
        >
          <div className="user-list">
            {displayedUsers.map((user, index) => (
              <Item
                key={index}
                user={user}
                onBlock={handleBlockUser}
                onThank={handleThankUser}
                onMark={handleMarkUser}
                onLabel={handleLabelUser}
                onAvatarClick={() => {
                  handleClickItem(user)
                }}
                onRightClick={() => {
                  handleClickItem(user)
                }}
              />
            ))}
          </div>
        </InfiniteScroll>
      ) : (
        <EmptyFirstTab onClick={onViewUsers} />
      )}
      <ErrorModal
        show={errorModal}
        content={error}
        onClose={() => setErrorModal(false)}
      />
    </>
  )
}
