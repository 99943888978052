import HttpClient from "../utils/client"

export function* skipUser(fromUserId) {
  const url = `/users/${fromUserId}/skip`
  return yield HttpClient("PUT", { url })
}

export function* thankUser(userId) {
  const url = `/users/${userId}/thanks`
  return yield HttpClient("PUT", { url })
}

export function* markReadUser(userId) {
  const url = `/footprint-read/${userId}`
  return yield HttpClient("POST", { url })
}

export function* likeUser(id, likeType) {
  const url = `/users/${id}/like`
  if (likeType === 1) {
    return yield HttpClient("POST", { url })
  } else {
    const data = new FormData()
    data.append("like_type", likeType)
    return yield HttpClient("POST", { url, data })
  }
}

export function* labelUser(toUserId, isLabel) {
  const url = `/users/${toUserId}/label`

  if (isLabel) {
    return yield HttpClient("DELETE", { url })
  } else {
    return yield HttpClient("POST", { url })
  }
}
