import _ from "lodash"
import { useEffect, useState } from "react"
import { NavLink } from "react-router-dom"
import HomeActive from "./images/icon-active/home.png"
import NowActive from "./images/icon-active/now.png"
import ChatActive from "./images/icon-active/chat.png"
import HeartActive from "./images/icon-active/heart.png"
import ProfileActive from "./images/icon-active/profile.png"
import Home from "./images/icon-non-active/home.png"
import Now from "./images/icon-non-active/now.png"
import Chat from "./images/icon-non-active/chat.png"
import Heart from "./images/icon-non-active/heart.png"
import Profile from "./images/icon-non-active/profile.png"
import { useSelector, useDispatch } from "react-redux"
import {
  getLikedUsersRequested,
  getLikersRequested,
  fetchDataFootPrints
} from "../../actions"
import { removeAccessToken } from "../../utils/auth"

const Item = ({ to, icon, label, number }) => {
  const pathName = window.location.pathname
  const dispatch = useDispatch()
  const currentUserId = useSelector((state) => {
    return state.auth.profile.user_id
  })
  const unreadMessageNumber = useSelector((state) => state.message.unreadMessageNumber)

  const userTab1 = useSelector((state) => state.likes.likers)
  const userTab2 = useSelector((state) => state.likes.likedUsers)
  const userTab3 = useSelector((state) => state.footPrint?.data)
  const [displayedUsersTab3, setDisplayedUsersTab3] = useState([])

  useEffect(() => {
    dispatch(getLikedUsersRequested(currentUserId, dispatch))
    dispatch(getLikersRequested(currentUserId, dispatch))
    dispatch(
      fetchDataFootPrints(
        { page: 1, perpage: 100 },
        // {
        //   onSuccess: (response) => {},
        //   onFailed: (response) => {
        //     if (response.status === 401) {
        //       removeAccessToken()
        //       window.location.reload()
        //     }
        //   }
        // }
      )
    )
  }, [currentUserId])

  useEffect(() => {
    let data = []
    if (userTab3) {
      data = displayedUsersTab3.concat(userTab3.users)
      setDisplayedUsersTab3(_.reverse(_.uniqBy(_.reverse([...data]), "user_id")))
    }
  }, [userTab3?.users])
  const userTab3Unread = displayedUsersTab3.filter(({ is_read }) => is_read === 0);
  const userTab2Unread = userTab2.filter(({ isLikeRead }) => isLikeRead === 0);
  const userTab1Unread = userTab1.filter(({ isLikeRead }) => isLikeRead === 0);

  return (
    <div className="nav-item">
      <NavLink to={to} activeClassName="active">
        {icon === 1 && (
          <img
            className="nav-item-icon"
            src={pathName === "/users" ? HomeActive : Home}
            alt="Home icon"
          />
        )}

        {icon === 2 && (
          <img
            className="nav-item-icon"
            src={pathName === "/schedules" ? NowActive : Now}
            alt="Now icon"
          />
        )}

        {icon === 3 && (
          <div>
            <img
              className="nav-item-icon"
              src={pathName === "/chats" ? ChatActive : Chat}
              alt="Chat icon"
            />
            <span class="position-absolute translate-middle badge rounded-pill bg-danger">
              {unreadMessageNumber === 0 ? '' : unreadMessageNumber}
            </span>
          </div>
        )}

        {icon === 4 && (
          <div>
            <img
              className="nav-item-icon"
              src={pathName === "/likes" ? HeartActive : Heart}
              alt="Heart icon"
            />
            <span class="position-absolute translate-middle badge rounded-pill bg-danger">
              {(userTab1Unread?.length + userTab2Unread?.length + userTab3Unread?.length === 0)
              ? '' : (userTab1Unread?.length + userTab2Unread?.length + userTab3Unread?.length)}
            </span>
          </div>
        )}

        {icon === 5 && (
          <img
            className="nav-item-icon"
            src={pathName === "/profile" ? ProfileActive : Profile}
            alt="Profile icon"
          />
        )}
        <span className="nav-item-label">{label}</span>
        {number > 0 && pathName !== "/likes" && pathName === "/messages" && (
          <label className="unread-number">{number}</label>
        )}
      </NavLink>
    </div>
  )
}

export default Item
