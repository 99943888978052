import {
  FETCH_ME_REQUEST,
  FETCH_ME_SUCCESS,
  FETCH_ME_FAILED,
  UPDATE_EDIT_PROFILE,
  FETCH_POINT_HISTORIES_REQUEST,
  FETCH_POINT_HISTORIES_SUCCESS,
  FETCH_POINT_HISTORIES_FAILED
} from "../constants/actions/mypage"
  
  const initialState = {
    me: {},
    error: {},
    editProfile: {},
    pointHistories: {},
    isLoading: false
  }
  
  const mypageReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_ME_REQUEST:
        return {
          ...state,
          isLoading: true,
        }

      case FETCH_ME_FAILED:
        return {
          ...state,
          error: action.data,
          isLoading: false
        }
  
      case FETCH_ME_SUCCESS:
        return {
          ...state,
          me: action.data,
          isLoading: false
        }

      case UPDATE_EDIT_PROFILE:
        return {
          ...state,
          editProfile: action.data
        }

      case FETCH_POINT_HISTORIES_REQUEST:
        return {
          ...state,
          isLoading: true,
        }

      case FETCH_POINT_HISTORIES_FAILED:
        return {
          ...state,
          error: action.data,
          isLoading: false
        }
  
      case FETCH_POINT_HISTORIES_SUCCESS:
        return {
          ...state,
          pointHistories: action.data,
          isLoading: false
        }
  
      default:
        return state
    }
  }

export default mypageReducer
