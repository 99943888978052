export const getAccessToken = () => {
  return localStorage.getItem("@Token")
}

export const setAccessToken = (token) => {
  localStorage.setItem("@Token", token)
}

export const removeAccessToken = () => {
  localStorage.removeItem("@Token")
}
