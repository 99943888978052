import { takeEvery, put, call } from "redux-saga/effects"
import { fetchMeSuccess, fetchMeFailed, fetchPointHistoriesSuccess, fetchPointHistoriesFailed } from "../actions/mypage"
import { FETCH_ME_REQUEST, FETCH_POINT_HISTORIES_REQUEST } from "../constants/actions/mypage"
import { getMe, getPointHistories } from "../services/mypage"

function* handleGetMe(action) {
  const { onSuccess, onFailed } = action.callbacks

  try {
    const response = yield getMe(action)
    if (response) {
      if (typeof onSuccess === "function") {
        yield call(onSuccess, response)
      }
      yield put(fetchMeSuccess(response))
    } else {
      if (typeof onFailed === "function") {
        yield call(onFailed, response)
      }
      yield put(fetchMeFailed())
    }
  } catch (error) {
    if (typeof onFailed === "function") {
      yield call(onFailed)
    }
    yield put(fetchMeFailed())
  }
}

function* handleGetPointHistories(action) {
  const { onSuccess, onFailed } = action.callbacks

  try {
    const response = yield getPointHistories(action)
    if (response) {
      if (typeof onSuccess === "function") {
        yield call(onSuccess, response)
      }
      yield put(fetchPointHistoriesSuccess(response.data))
    } else {
      if (typeof onFailed === "function") {
        yield call(onFailed, response)
      }
      yield put(fetchPointHistoriesFailed())
    }
  } catch (error) {
    console.log(error)
    if (typeof onFailed === "function") {
      yield call(onFailed)
    }
    yield put(fetchPointHistoriesFailed())
  }
}

export default function* watchMyPage() {
  yield takeEvery(FETCH_ME_REQUEST, handleGetMe)
  yield takeEvery(FETCH_POINT_HISTORIES_REQUEST, handleGetPointHistories)
}
