import HttpClient from "../utils/client"

function* checkPhoneNumber(action) {
  const payload = {
    url: "/check-phone",
    params: {
      phone: action?.data?.phone,
    },
  }

  return yield HttpClient("POST", payload, action)
}

function* registertWithPhone(action) {
  const url = "/users/1/register"
  const params = {
    phone: action?.data,
  }

  return yield HttpClient("POST", { url, params })
}

export { checkPhoneNumber, registertWithPhone }
