import HttpClient from "../utils/client"

export function* scheduleList(action) {
  let option = {
    url: `/user-plans`
  }
  if (action?.payload?.data?.params) {
    option.params = action.payload.data.params
  }
  return yield HttpClient("GET", option, action)
}

export function* createSchedule(action) {
  return yield HttpClient("POST", { url: `/user-plans`, data: action.payload.data }, action)
}

export function* getScheduleDetail(action) {
  return yield HttpClient("GET", { url: `/user-plans/${action.payload.data.id}` }, action)
}

export function* getMySchedule(action) {
  return yield HttpClient("GET", { url: `/my-plan` }, action)
}

export function* updateSchedule(action) {
  const { id, ...rest } = action.payload.data
  return yield HttpClient("PUT", { url: `/user-plans/${id}`, data: rest }, action)
}

export function* deleteSchedule(action) {
  return yield HttpClient("DELETE", { url: `/user-plans/${action.payload.data.id}` }, action)
}
