import HttpClient from "../utils/client"

export function* likeUser({ id, likeType }, action) {
  if (likeType === 1) {
    return yield HttpClient("POST", { url: `/users/${id}/like` }, action)
  } else {
    const data = new FormData()
    data.append("like_type", likeType)
    return yield HttpClient("POST", { url: `/users/${id}/like`, data }, action)
  }
}

export function* skipUser({ id }, action) {
  return yield HttpClient("PUT", { url: `/users/${id}/skip` }, action)
}

export function* thankUser({ id }, action) {
  return yield HttpClient("PUT", { url: `/users/${id}/thanks` }, action)
}
