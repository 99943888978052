import _ from "lodash"
import PropTypes from "prop-types"
import { useContext, useEffect, useState, Fragment } from "react"
import Container from "react-bootstrap/Container"
import Form from "react-bootstrap/Form"
import AppBar from "../../../components/AppBar"
import Layout from "../../../components/Layout"
import AppContext from "../../../Context"

import "./styles.scss"
import removeIcon from "../../../icons/remove.svg"
import toggleIcon from "../../../icons/arr-down.svg"

const Range = ({
  title,
  names,
  options,
  labels,
  values,
  onChange,
  compare,
  children,
  required,
  placeholders,
  emptyText,
  open: focus,
  onFocus,
  optionSuffix,
}) => {
  const [open, setOpen] = useState(false)
  const [min, setMin] = useState("")
  const [max, setMax] = useState("")
  const { setContext } = useContext(AppContext)
  const fromOptions =
    _.isEmpty(options) ||
    values[0] === undefined ||
    options[values[0]] === undefined
      ? options
      : _.filter(options, (label, value) =>
          compare(values[0], label, value, true)
        )
  const toOptions =
    _.isEmpty(options) ||
    values[1] === undefined ||
    options[values[1]] === undefined
      ? options
      : _.filter(options, (label, value) =>
          compare(values[1], label, value, false)
        )

  const toggleOpen = () => {
    onFocus ? onFocus() : setOpen(!open)
  }

  const handleRemove = (e) => {
    e.preventDefault()
    e.stopPropagation()
    onChange && onChange([null, null])
    return false
  }

  const handleChange = (idx, { target: { value } }) => {
    values[idx] = value == null ? "" : value
    if (idx === 0) {
      setMin(value)
    } else {
      setMax(value)
    }
    onChange && onChange(values)
  }
  useEffect(() => {
    if (focus !== undefined ? focus : open) {
      document.querySelector('.modal-search-user').removeAttribute('tabindex');
      if (values[0] == null) {
        values[0] = ""
        setMin("")
      }
      if (values[1] == null) {
        values[1] = ""
        setMax("")
      }
      setContext(
        "overlayfilter",
        <Layout>
          <AppBar title={title} onGoBack={toggleOpen} />
          <Form className="form-filter bg-white d-flex flex-column h-100">
            <div className="element element-range d-flex justify-content-center">
              <div className="range-options align-self-center w-100 bg-white py-2">
                <Container>
                  <Form.Group className="form-group">
                    {labels[0] && <Form.Label>{labels[0]}</Form.Label>}
                    <Form.Select
                      name={names[0] || ""}
                      onChange={(e) => handleChange(0, e)}
                      value={
                        _.get(options, values[0], undefined) === undefined
                          ? ""
                          : values[0]
                      }
                    >
                      {!required && placeholders[0] && (
                        <option value="">{placeholders[0]}</option>
                      )}
                      {_.map(
                        options,
                        (label, value) =>
                          (max === "" || value < max) && (
                            <option key={value} value={value}>
                              {label}
                            </option>
                          )
                      )}
                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="form-group">
                    {labels[1] && <Form.Label>{labels[1]}</Form.Label>}
                    <Form.Select
                      name={names[1] || ""}
                      onChange={(e) => handleChange(1, e)}
                      value={
                        _.get(options, values[1], undefined) === undefined
                          ? ""
                          : values[1]
                      }
                    >
                      {!required && placeholders[1] && (
                        <option value="">{placeholders[1]}</option>
                      )}
                      {_.map(
                        options,
                        (label, value) =>
                          (min === "" || value > min) && (
                            <option key={value} value={value}>
                              {label}
                            </option>
                          )
                      )}
                    </Form.Select>
                  </Form.Group>
                </Container>
              </div>
            </div>
            <div className="mt-auto py-3 btn-fixed">
              <div className="d-grid">{children}</div>
            </div>
          </Form>
        </Layout>
      )
    } else {
      setContext("overlayfilter", null)
    }

    return () => {
      setContext("overlayfilter", null)
    }
  }, [open, focus, values[0], values[1]])

  return (
    <div className="range-wrapper" onClick={toggleOpen}>
      <div className="range-input">
        {values[0] === "" && values[1] === "" ? (
          "こだわらない ~ こだわらない"
        ) : options[values[0]] === undefined &&
          options[values[1]] === undefined ? (
          emptyText
        ) : (
          <Fragment>
            <div className="chip">
              {options[values[0]] !== undefined && (
                <span className="value">{options[values[0]]}</span>
              )}
            </div>
            ~
            <div className="chip">
              {options[values[1]] !== undefined && (
                <span className="value">{options[values[1]]}</span>
              )}
            </div>
            {title == "年齢" ? "歳" : "cm"}
          </Fragment>
        )}
        <div className="reparate"></div>
      </div>
      <div className="range-actions">
        <div className="range-action action-remove" onClick={handleRemove}>
          {(options[values[0]] !== undefined ||
            options[values[1]] !== undefined) && (
            <img src={removeIcon} alt="" />
          )}
        </div>
        <div className="range-action action-toggle" onClick={toggleOpen}>
          <img src={toggleIcon} alt="" />
        </div>
      </div>
    </div>
  )
}

Range.defaultProps = {
  compare: (check, label, value, flag) => {
    return flag ? check >= value : check < value
  },
  values: [null, null],
  required: false,
  placeholders: [],
}

Range.propTypes = {
  compare: PropTypes.func,
  onToggle: PropTypes.func,
  names: PropTypes.array.isRequired,
  options: PropTypes.array.isRequired,
  required: PropTypes.bool,
}

export default Range
