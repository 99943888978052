import _ from "lodash"
import moment from "moment"
import Modal from "react-bootstrap/Modal"
import Button from "react-bootstrap/Button"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Fragment, useState, useContext, useEffect } from "react"
import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons"
import { faCamera } from "@fortawesome/free-solid-svg-icons"

import "./styles.scss"
import AppContext from "../../Context"
import { getAvatarUrl } from "../../utils"
import defaultAvatar from "./images/avatar.jpg"
import { LoadingIndicator, Layout, AppBar } from "../../components"
import { fetchAuthProfileRequest, getFilterOptionsRequest } from "../../actions"

const Edit = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { setContext } = useContext(AppContext)

  const isLoading = useSelector((state) => {
    return state.auth.loadingOptions
  })

  useEffect(() => {
    if (localStorage.getItem("curent_scroll")) {
      const el = document.querySelector(".user-detail")
      el.scrollTop = localStorage.getItem("curent_scroll")
      localStorage.removeItem("curent_scroll")
    }
    if (
      localStorage.getItem("profileEdit") == null ||
      localStorage.getItem("profileEditOption") == null
    ) {
      dispatch(
        fetchAuthProfileRequest(
          {},
          {
            onSuccess: (response) => {
              localStorage.setItem("profileEdit", JSON.stringify(response))
              setContext("overlay", null)
            },
            onFailed: () => {
              setContext("overlay", null)
            }
          }
        )
      )
      dispatch(
        getFilterOptionsRequest(
          {},
          {
            onSuccess: (response) => {
              localStorage.setItem(
                "profileEditOption",
                JSON.stringify(response)
              )
              setContext("overlay", null)
            },
            onFailed: () => {
              setContext("overlay", null)
            }
          }
        )
      )
    }
  }, [])

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)

  let user = JSON.parse(localStorage.getItem("profileEdit"))
  let userOption = JSON.parse(localStorage.getItem("profileEditOption"))

  const [message, setMessage] = useState(0)
  const handleSwitchModel = () => {
    if (message >= 2) {
      handleClose()
      setMessage(0)
    } else {
      setMessage(message + 1)
    }
  }

  const editItem = (field) => {
    const el = document.querySelector(".user-detail")
    localStorage.setItem("curent_scroll", el.scrollTop)
    history.push(`/profile/edit-item/${field}`)
  }

  const avatarUrl = getAvatarUrl(user?.images)

  let birthplace
  let baby_aspiration
  let alcohol
  let occupation
  let annual_income
  let residence
  let cigarette
  if (user && userOption) {
    birthplace = userOption.birthplaces.find(
      (element) => element.birthplace_id == user.birthplace_id
    )
    baby_aspiration = userOption.baby_aspirations.find(
      (element) => element.baby_aspiration_id == user.baby_aspiration_id
    )
    alcohol = userOption.alcohols.find(
      (element) => element.alcohol_id == user.alcohol_id
    )
    occupation = userOption.occupations.find(
      (element) => element.occupation_id == user.occupation_id
    )
    annual_income = userOption.annual_incomes.find(
      (element) => element.annual_income_id == user.annual_income_id
    )
    residence = userOption.residences.find(
      (element) => element.residence_id == user.residence_id
    )
    cigarette = userOption.cigarettes.find(
      (element) => element.cigarette_id == user.cigarette_id
    )
  }

  return (
    <Layout>
      <AppBar
        title="プロフィール編集"
        onGoBack={() => {
          history.push("/profile")
          localStorage.removeItem("profileEdit")
        }}
      />
      {isLoading ? (
        <LoadingIndicator classname="m-auto" />
      ) : (
        <div className="bg-white d-flex flex-column full-height-with-navbar user-review-fix pb-5">
          {user && (
            <div className="user-detail">
              <div className="user-fields">
                <div className="field-group field-others clearfix pb-50">
                  <div className="field-group-label text-center">
                    プロフィール
                  </div>

                  <div className="wrap-avatar-profile">
                    <div className="avatar" id="avatar">
                      <img
                        src={avatarUrl ? avatarUrl : defaultAvatar}
                        alt="Avatar"
                      />
                      <div
                        className="icon-camera"
                        onClick={() => history.push("/media")}
                      >
                        <FontAwesomeIcon icon={faCamera} />
                      </div>
                    </div>
                  </div>

                  <div className="field-group-label text-center">
                    プロフィール
                  </div>

                  <div className="fields-wrapper">
                    {/* Date of birth */}
                    <div className="field field-email arrow-right-after">
                      <div className="field-label">生年月日</div>
                      <div className="field-value">
                        {moment(user.date_of_birth).format("YYYY年MM月DD日")}
                      </div>
                    </div>

                    {/* Nickname */}
                    <div className="field field-residence arrow-right-after">
                      <div className="field-label">ニックネーム</div>
                      <div className="field-value">{user.nickname}</div>
                    </div>

                    {/* Residence */}
                    <div
                      onClick={() => editItem("residence")}
                      className="field field-date arrow-right-after"
                    >
                      <div className="field-label">居住地</div>
                      <div className="field-value">
                        {_.get(residence, "name", null)}
                      </div>
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="chevron-right"
                        className="svg-inline--fa fa-chevron-right fa-w-10"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                      >
                        <path
                          fill="#E7141A"
                          d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                        ></path>
                      </svg>
                    </div>

                    {/* Self introduction */}
                    <div
                      onClick={() => editItem("biography")}
                      className="field field-occupation arrow-right-after"
                    >
                      <div className="field-label">自己紹介</div>
                      <div className="field-value">{user.biography}</div>
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="chevron-right"
                        className="svg-inline--fa fa-chevron-right fa-w-10"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                      >
                        <path
                          fill="#E7141A"
                          d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                        ></path>
                      </svg>
                    </div>

                    {/* Desired area */}
                    <div
                      onClick={() => editItem("desired_area")}
                      className="field field-height arrow-right-after"
                    >
                      <div className="field-label">希望エリア</div>
                      <div className="field-value">
                        {user.match_area_1 !== null &&
                        typeof user.match_area_1 !== "number"
                          ? JSON.parse(user.match_area_1).map(
                              (item) => item.name + " , "
                            )
                          : ""}
                      </div>
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="chevron-right"
                        className="svg-inline--fa fa-chevron-right fa-w-10"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                      >
                        <path
                          fill="#E7141A"
                          d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                        ></path>
                      </svg>
                    </div>

                    {/* Condition to meet */}
                    <div
                      onClick={() => editItem("condition")}
                      className="field field-birthplace arrow-right-after"
                    >
                      <div className="field-label">会う条件</div>
                      <div className="field-value">{user.condition}</div>
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="chevron-right"
                        className="svg-inline--fa fa-chevron-right fa-w-10"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                      >
                        <path
                          fill="#E7141A"
                          d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                        ></path>
                      </svg>
                    </div>

                    {/* Height */}
                    <div
                      onClick={() => editItem("height")}
                      className="field field-educational_background arrow-right-after"
                    >
                      <div className="field-label">身長</div>
                      <div className="field-value">
                        {user.height != null ? `${user.height}cm` : ""}
                      </div>
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="chevron-right"
                        className="svg-inline--fa fa-chevron-right fa-w-10"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                      >
                        <path
                          fill="#E7141A"
                          d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                        ></path>
                      </svg>
                    </div>

                    {/* Body */}
                    <div
                      onClick={() => editItem("body")}
                      className="field field-baby_aspiration arrow-right-after"
                    >
                      <div className="field-label">体型</div>
                      <div className="field-value">{user.body}</div>
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="chevron-right"
                        className="svg-inline--fa fa-chevron-right fa-w-10"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                      >
                        <path
                          fill="#E7141A"
                          d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                        ></path>
                      </svg>
                    </div>

                    {/* Birthplace */}
                    <div
                      onClick={() => editItem("birthplace")}
                      className="field field-date arrow-right-after"
                    >
                      <div className="field-label">出身地</div>
                      <div className="field-value">
                        {_.get(birthplace, "name", null)}
                      </div>
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="chevron-right"
                        className="svg-inline--fa fa-chevron-right fa-w-10"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                      >
                        <path
                          fill="#E7141A"
                          d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                        ></path>
                      </svg>
                    </div>

                    {/* Profession */}
                    <div
                      onClick={() => editItem("occupation")}
                      className="field field-cigarette arrow-right-after"
                    >
                      <div className="field-label">職業</div>
                      <div className="field-value">
                        {_.get(occupation, "name", null)}
                      </div>
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="chevron-right"
                        className="svg-inline--fa fa-chevron-right fa-w-10"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                      >
                        <path
                          fill="#E7141A"
                          d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                        ></path>
                      </svg>
                    </div>

                    {/* Annual income */}
                    {user.sex === 1 && (
                      <div
                        onClick={() => editItem("annual_income")}
                        className="field field-alcohol arrow-right-after"
                      >
                        <div className="field-label">年収</div>
                        <div className="field-value">
                          {_.get(annual_income, "name", null)}
                        </div>
                        <svg
                          aria-hidden="true"
                          focusable="false"
                          data-prefix="fas"
                          data-icon="chevron-right"
                          className="svg-inline--fa fa-chevron-right fa-w-10"
                          role="img"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 320 512"
                        >
                          <path
                            fill="#E7141A"
                            d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                          ></path>
                        </svg>
                      </div>
                    )}

                    {/* Baby aspiration */}
                    <div
                      onClick={() => editItem("baby_aspiration")}
                      className="field field-alcohol arrow-right-after"
                    >
                      <div className="field-label">子供の有無</div>
                      <div className="field-value">
                        {_.get(baby_aspiration, "name", null)}
                      </div>
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="chevron-right"
                        className="svg-inline--fa fa-chevron-right fa-w-10"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                      >
                        <path
                          fill="#E7141A"
                          d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                        ></path>
                      </svg>
                    </div>

                    {/* My personality */}
                    <div
                      onClick={() => editItem("personality")}
                      className="field field-alcohol arrow-right-after"
                    >
                      <div className="field-label">自分の性格</div>
                      <div className="field-value">
                        {user.personality !== null
                          ? JSON.parse(user.personality).map(
                              (item) => item.name + " / "
                            )
                          : ""}
                      </div>
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="chevron-right"
                        className="svg-inline--fa fa-chevron-right fa-w-10"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                      >
                        <path
                          fill="#E7141A"
                          d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                        ></path>
                      </svg>
                    </div>

                    {/* Cigarettes */}
                    <div
                      className="field field-alcohol arrow-right-after"
                      onClick={() => editItem("cigarette")}
                    >
                      <div className="field-label">タバコ</div>
                      <div className="field-value">
                        {_.get(cigarette, "name", null)}
                      </div>
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="chevron-right"
                        className="svg-inline--fa fa-chevron-right fa-w-10"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                      >
                        <path
                          fill="#E7141A"
                          d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                        ></path>
                      </svg>
                    </div>

                    {/* Sake */}
                    <div
                      onClick={() => editItem("alcohol")}
                      className="field field-alcohol arrow-right-after"
                    >
                      <div className="field-label">お酒</div>
                      <div className="field-value">
                        {_.get(alcohol, "name", null)}
                      </div>
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="chevron-right"
                        className="svg-inline--fa fa-chevron-right fa-w-10"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                      >
                        <path
                          fill="#E7141A"
                          d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                        ></path>
                      </svg>
                    </div>

                    {/* Holiday */}
                    <div
                      onClick={() => editItem("holiday")}
                      className="field field-alcohol arrow-right-after"
                    >
                      <div className="field-label">休日</div>
                      <div className="field-value">{user.holiday}</div>
                      <svg
                        aria-hidden="true"
                        focusable="false"
                        data-prefix="fas"
                        data-icon="chevron-right"
                        className="svg-inline--fa fa-chevron-right fa-w-10"
                        role="img"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 320 512"
                      >
                        <path
                          fill="#E7141A"
                          d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                        ></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>

              <Modal show={show} onHide={handleClose} centered>
                <Modal.Body>
                  {message === 0 && (
                    <Fragment>
                      <h3>スクリーンショットを検知しました。</h3>
                      <p>
                        お相手の内容など、他者に関する情報はインター
                        ネットなどで公開しないようお願いします
                      </p>
                    </Fragment>
                  )}
                  {message === 1 && (
                    <Fragment>
                      <h3>いいね不足しています。</h3>
                      <p>
                        いいねが不足しています。ポイント購入は下記
                        よりご購入ください
                      </p>
                    </Fragment>
                  )}
                  {message === 2 && (
                    <div className="text-center">
                      <h3>いいねを押せる回数が増えました。</h3>
                      <p>※30日に1回の間隔で、100いいねが追加されます。</p>
                      <p className="mt-5">残いいね</p>
                      <p>
                        <FontAwesomeIcon
                          className="text-danger"
                          icon={faHeartSolid}
                          size="lg"
                        />{" "}
                        30
                      </p>
                    </div>
                  )}
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                  <Button
                    className="text-danger border-0 py-0 disable-focus"
                    variant="light"
                    onClick={handleSwitchModel}
                  >
                    {message === 1 ? "ポイント購入はこちら" : "OK"}
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          )}
        </div>
      )}
    </Layout>
  )
}

export default Edit
