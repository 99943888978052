import _ from "lodash"
import { toast } from "react-toastify"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import InfiniteScroll from "react-infinite-scroller"
import { useSelector, useDispatch } from "react-redux"

import { Item } from "./Item"
import { EmptySecondTab } from "./EmptySecondTab"
import routes from "../../../../constants/routes"
import { LoadingIndicator } from "../../../../components/LoadingIndicator"
import {
  getLikedUsersRequested,
  labelUserRequested
} from "../../../../actions/likes"
import { ErrorModal } from "../../../../components/ErrorModal"
import { muteUserRequested } from "../../../../actions"

const LIMIT = 10

export const List = ({ onViewUsers }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const currentUserId = useSelector((state) => {
    return state.auth.profile.user_id
  })

  const isLoading = useSelector((state) => state.likes.isLoading)
  const [displayedUsers, setDisplayedUsers] = useState([])
  const [errorModal, setErrorModal] = useState(false)

  const allUsers = useSelector((state) => state.likes.likedUsers)
  const error = useSelector((state) => state.likes.error)

  useEffect(() => {
    dispatch(getLikedUsersRequested(currentUserId, dispatch))
  }, [currentUserId])

  useEffect(() => {
    setDisplayedUsers(allUsers.slice(0, LIMIT))
  }, [allUsers])

  const onLoadMore = (page) => {
    setTimeout(() => {
      setDisplayedUsers(allUsers.slice(0, page * LIMIT))
    }, 1000)
  }

  const handleBlockUser = (userId) => {
    dispatch(
      muteUserRequested(
        { id: userId },
        {
          onSuccess: (response) => {
            toast("ブロックしました")
          },
          onFailed: () => {}
        }
      )
    )
  }

  const handleLabelUser = (toUserId, isLabel) => {
    const data = {
      toUserId: toUserId,
      isLabel: isLabel
    }

    dispatch(
      labelUserRequested(data, {
        onSuccess: () => {
          toast("更新されました")
        },
        onFailed: () => {
          setErrorModal(true)
        }
      })
    )
  }

  const handleClickItem = (user) => {
    let ids = []
    allUsers.forEach(function (u) {
      ids.push(parseInt(u.id))
    })
    localStorage.setItem("list_user_like", JSON.stringify(ids))
    history.push(`${routes.USERS}/${user.id}?from=like`, {
      swipeable: true
    })
  }

  return (
    <>
      {isLoading || !currentUserId ? (
        <LoadingIndicator />
      ) : !_.isEmpty(displayedUsers) ? (
        <div className="user-list">
          <InfiniteScroll
            pageStart={0}
            loadMore={onLoadMore}
            hasMore={allUsers.length > displayedUsers.length}
            loader={<LoadingIndicator key={0} sm />}
            useWindow={false}
          >
            {displayedUsers.map((user, index) => (
              <Item
                key={index}
                user={user}
                onBlock={handleBlockUser}
                onLabel={handleLabelUser}
                onAvatarClick={() => {
                  handleClickItem(user)
                }}
                onRightClick={() => {
                  handleClickItem(user)
                }}
              />
            ))}
          </InfiniteScroll>
        </div>
      ) : (
        <EmptySecondTab onClick={onViewUsers} />
      )}
      <ErrorModal
        show={errorModal}
        content={error}
        onClose={() => setErrorModal(false)}
      />
    </>
  )
}
