export const SET_CHATTING_USER = "SET_CHATTING_USER"

export const GET_MESSAGE_LIST_REQUESTED = "GET_MESSAGE_LIST_REQUESTED"
export const GET_MESSAGE_LIST_SUCCESS = "GET_MESSAGE_LIST_SUCCESS"
export const GET_MESSAGE_LIST_FAILED = "GET_MESSAGE_LIST_FAILED"

export const SEND_MESSAGE_REQUESTED = "SEND_MESSAGE_REQUESTED"
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS"
export const SEND_MESSAGE_FAILED = "SEND_MESSAGE_FAILED"

export const NOTIFY_MESSAGE = "NOTIFY_MESSAGE"