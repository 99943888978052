import HttpClient from "../utils/client"

export function* getPointPackages(action) {
  return yield HttpClient("GET", { url: `/points-packages?app_type=Web` }, action)
}

export function* postPurchase(action) {
  const payload = {
    url: "/users/points/purchase",
    data: action.data,
  }
  return yield HttpClient("POST", payload, action)
}
