import { useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import { useState, useEffect, useCallback, useRef } from "react"

import { ErrorModal } from "./ErrorModal"
import { getAvatarUrl } from "../../utils"
import rtmClient from "../../agora/agoraRTMClient"
import { getUserDeviceIds } from "../../utils/firebase"
import { CallStarting } from "../../components/CallIndicator"
import {
  retrieveAgoraTokenRequested,
  updateVideoCallRequested,
  updateVideoMessageRequested,
  setCalleeUid,
  setIsCalling,
  fetchAuthProfileRequest,
  startVideoCallRequested
} from "../../actions"
import { MESSAGE_TYPES, VIDEO_CALL_STATUS } from "../../constants/env"

const OutgoingCall = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const callee = useSelector((state) => state.videoCall.callee)
  const currentUser = useSelector((state) => state.auth.profile)

  const [callId, _setCallId] = useState(null)
  const callIdRef = useRef(callId)
  const setCallId = (data) => {
    callIdRef.current = data
    _setCallId(data)
  }
  const { channel } = useSelector((state) => state.videoCall)
  const channelRef = useRef(channel)
  const [cancellable, setCancellable] = useState(false)
  const [errorModal, setErrorModal] = useState({
    visible: false,
    content: null
  })

  const onGoBack = () => {
    rtmClient.cancelCall()
    cancelCall()
    window.removeEventListener("popstate", onGoBack)
  }

  const onLocalInvitationAccepted = (calleeUid) => {
    console.log("Local invitation accepted: ", calleeUid)
    dispatch(setIsCalling(true))
    dispatch(setCalleeUid(calleeUid))
    dispatch(
      startVideoCallRequested(channelRef.current, callee.user_id, {
        onSuccess: (res) => {
          history.replace(`/video-call/${callee.user_id}`)
        },
        onFailed: (error) => {
          cancelCall()
          setErrorModal({ visible: true, content: error })
        }
      })
    )
  }

  const onLocalInvitationRefused = async (params) => {
    cancelCall()
    setErrorModal({
      visible: true,
      content:
        "お相手は現在通話できないようでした。\n メッセージを送るか、時間をあけて再度お試しください。"
    })
  }

  const onLocalInvitationFailed = (params) => {
    cancelCall()
    history.replace(`/private-chat/${callee.user_id}`)
  }

  const onLocalInvitationReceived = (params) => {}

  const setupListeners = useCallback(() => {
    rtmClient.on("LocalInvitationReceivedByPeer", onLocalInvitationReceived)
    rtmClient.on("LocalInvitationAccepted", onLocalInvitationAccepted)
    rtmClient.on("LocalInvitationRefused", onLocalInvitationRefused)
    rtmClient.on("LocalInvitationFailure", onLocalInvitationFailed)
  }, [])

  const detachListeners = useCallback(() => {
    rtmClient.off("LocalInvitationReceivedByPeer", onLocalInvitationReceived)
    rtmClient.off("LocalInvitationAccepted", onLocalInvitationAccepted)
    rtmClient.off("LocalInvitationRefused", onLocalInvitationRefused)
    rtmClient.off("LocalInvitationFailure", onLocalInvitationFailed)
  }, [])

  const checkUserStatus = async (agoraData) => {
    try {
      const deviceIds = await getUserDeviceIds(callee.user_id)
      const peerIds = []
      for (let device of deviceIds) {
        const peerStatus = await rtmClient.inquire(new Array(device.toString()))
        if (peerStatus && peerStatus[device.toString()]) {
          peerIds.push(device.toString())
        }
      }
      if (peerIds.length > 0) {
        setupListeners()
        const data = {
          appId: agoraData.agora_id,
          channelName: agoraData.channel_id,
          token: agoraData.token,
          userName: currentUser.nickname,
          image_url: getAvatarUrl(currentUser.images),
          likeId: callee.likeId,
          caller_id: currentUser.user_id
        }
        rtmClient.sendLocalInvitations(
          peerIds,
          agoraData.channel_id,
          JSON.stringify(data),
          () => setCancellable(true)
        )
      } else {
        setErrorModal({
          visible: true,
          content:
            "お相手は現在通話できないようでした。メッセージを送るか、時間をあけて再度お試しください。"
        })
        cancelCall()
      }
    } catch (err) {
      console.log("get user device id error: ", err)
    }
  }

  useEffect(() => {
    window.addEventListener("popstate", onGoBack)
    dispatch(
      retrieveAgoraTokenRequested(callee.user_id, {
        onSuccess: (data) => {
          dispatch(fetchAuthProfileRequest({}))
          setCallId(data.channel_id)
          checkUserStatus(data)
        },
        onFailed: (err) => {
          setErrorModal({ visible: true, content: err })
        }
      })
    )

    return () => {
      detachListeners()
    }
  }, [])

  useEffect(() => {
    channelRef.current = channel
  }, [channel])

  const cancelCall = () => {
    dispatch(
      updateVideoCallRequested(callIdRef.current, VIDEO_CALL_STATUS.MISSED, {
        onSuccess: () => {
          const message = composeMessage()
          dispatch(updateVideoMessageRequested(callee.likeId, null, message))
        },
        onFailed: (err) => {
          console.log("update video call when invitation expired error: ", err)
        }
      })
    )
  }

  const composeMessage = () => {
    return {
      userId: currentUser.user_id,
      type: MESSAGE_TYPES.VIDEO_CALL,
      // message: "",
      isRead: 0,
      imageUrl: null,
      videoCallId: callIdRef.current,
      videoCallStatus: VIDEO_CALL_STATUS.MISSED,
      videoCallStartDatetime: null,
      videoCallEndDatetime: null
    }
  }

  const handleUnload = (e) => {
    e.preventDefault()
    e.stopPropagation()
    rtmClient.cancelCall()
    cancelCall()
    history.replace(`/private-chat/${callee.user_id}`)
    return (e.returnValue = "")
  }

  useEffect(() => {
    window.addEventListener("beforeunload", handleUnload)

    return () => window.removeEventListener("beforeunload", handleUnload)
  }, [handleUnload])

  return (
    <>
      <CallStarting
        user={callee}
        out
        cancellable={cancellable}
        onCancel={() => {
          rtmClient.cancelCall()
          cancelCall()
          history.replace(`/private-chat/${callee.user_id}`)
        }}
      />
      <ErrorModal
        show={errorModal.visible}
        content={errorModal.content}
        onClose={() => {
          setErrorModal({ visible: false })
          history.replace(`/private-chat/${callee.user_id}`)
        }}
      />
    </>
  )
}

export default OutgoingCall
