import * as actionTypes from "../constants/actions"

const initialState = {
  fetchDataDetailNotification: false,
  fetchDataUpdateReadNotification: false,
  fetchDataBoxNotification: false,
  fetchPostNotification: false,
  fetchNotificationChat: false,
  data: null,
  isLoading: false,
}

const NotificationReducer = (state = initialState, action) => {
  let data = null
  switch (action.type) {
    case actionTypes.FETCH_BOX_NOTIFICATION:
      return {
        fetchDataBoxNotification: true,
        isLoading: true,
      }
    case actionTypes.FETCH_BOX_NOTIFICATION_SUCCESS:
      data = action.payload.dataDetail
      return {
        fetchDataBoxNotification: true,
        data,
        isLoading: false,
      }
    case actionTypes.FETCH_BOX_NOTIFICATION_FAILED:
      return {
        fetchDataBoxNotification: false,
        isLoading: false,
      }
    case actionTypes.FETCH_DETAIL_NOTIFICATION:
      return {
        fetchDataDetailNotification: true,
      }
    case actionTypes.FETCH_DETAIL_NOTIFICATION_SUCCESS:
      data = action.dataDetail
      return {
        fetchDataDetailNotification: true,
        data,
      }
    case actionTypes.FETCH_DETAIL_NOTIFICATION_FAILED:
      return {
        fetchDataDetailNotification: false,
      }
    case actionTypes.CHANGE_NOTI_MESSAGE_REQUEST:
      return {
        fetchPostNotification: true,
      }
    case actionTypes.CHANGE_NOTI_MESSAGE_SUCCESS:
      data = action.dataDetail
      return {
        fetchPostNotification: true,
        ...state,
        data,
      }
    case actionTypes.CHANGE_NOTI_MESSAGE_FAILED:
      return {
        fetchPostNotification: false,
      }
    case actionTypes.API_NOTI_MESSAGE_REQUEST:
      return {
        fetchNotificationChat: true,
      }
    case actionTypes.API_NOTI_MESSAGE_SUCCESS:
      data = action.dataDetail
      return {
        fetchNotificationChat: true,
        ...state,
        data,
      }
    case actionTypes.API_NOTI_MESSAGE_FAILED:
      return {
        fetchNotificationChat: false,
      }
    case actionTypes.UPDATE_READ_NOTIFICATION:
      return {
        fetchDataUpdateReadNotification: true,
      }
    case actionTypes.UPDATE_READ_NOTIFICATION_SUCCESS:
      data = action.dataUpdate
      return {
        fetchDataUpdateReadNotification: true,
        data,
      }
    case actionTypes.UPDATE_READ_NOTIFICATION_FAILED:
      return {
        fetchDataUpdateReadNotification: false,
      }
    default:
      return state
  }
}

export default NotificationReducer
