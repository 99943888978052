import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import { useState } from "react"
import "./styles.scss"


const Match = ({ onSubmit, props }) => {
  const [valueAge, setValueAge] = useState(props.valueAge)
  const [valueDay, setValueDay] = useState(props.valueDay)
  const [valueTime, setValueTime] = useState(props.valueTime)

  const handleReset = () => {
    setValueAge(0)
    setValueDay(0)
    setValueTime(0)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit({"age_type": valueAge, "date_type": valueTime, "created_at_type": valueDay})
  }

  return (
    <Form
      onSubmit={handleSubmit}
      className="bg-white d-flex flex-column form-filter"
    >
      <div className="container form-containers">
        <div className="d-flex align-items-center">
          <p className="fs-5 mb-0 text-grey col-6">年齢</p>
          <Form className="col-6">
            <Form.Select
              className="border-0 text-center"
              value={valueAge}
              onChange={(e) => setValueAge(e.target.value)}
            >
              <option value={0}>選択してください</option>
              <option value={1}>20歳以下</option>
              <option value={2}>30歳以下</option>
              <option value={3}>40歳以下</option>
              <option value={4}>50歳以下</option>
              <option value={5}>60歳以下</option>
              <option value={6}>70歳以下</option>
              <option value={7}>80歳以下</option>
            </Form.Select>
          </Form>
        </div>
        <div className="d-flex align-items-center">
          <p className="fs-5 mb-0 text-grey col-6">日</p>
          <Form className="col-6">
            <Form.Select
              className="border-0 text-center"
              value={valueDay}
              onChange={(e) => setValueDay(e.target.value)}
            >
              <option value={0}>選択してください</option>
              <option value={1}>本日</option>
              <option value={2}>3日間</option>
              <option value={3}>1週間</option>
              <option value={4}>2週間</option>
              <option value={5}>3週間</option>
              <option value={6}>1ヶ月</option>
            </Form.Select>
          </Form>
        </div>
        <div className="d-flex align-items-center">
          <p className="fs-5 mb-0 text-grey col-6">時間</p>
          <Form className="col-6">
            <Form.Select
              className="border-0 text-center"
              value={valueTime}
              onChange={(e) => setValueTime(e.target.value)}
            >
              <option value={0}>選択してください</option>
              <option value={1}>午前中（9時〜12時）</option>
              <option value={2}>昼（12時〜13時）</option>
              <option value={3}>午後（13時〜16時）</option>
              <option value={4}>夕方（16時〜19時）</option>
              <option value={5}>夜（19時〜22時）</option>
              <option value={6}>深夜（22時〜）</option>
            </Form.Select>
          </Form>
        </div>
        <div className="mt-auto" style={{ padding: "13px 0px" }}>
          <div className="d-grid container d-flex justify-content-between">
            <Button
              className="border-0 btn-w-45"
              variant="dark"
              type="button"
              onClick={handleReset}
            >
              条件リセット
            </Button>
            <Button
              className="align-self-center btn-w-45"
              variant="danger"
              type="submit"
            >
              検索する
            </Button>
          </div>
        </div>
      </div>
    </Form>
  )
}

Match.defaultProps = {
  sex: 1
}

export default Match
