import _ from "lodash"
import { useContext, useEffect, useMemo, useState } from "react"
import { TabPanel, useTabs } from "react-headless-tabs"
import { useHistory } from "react-router-dom"
import "./styles.scss"
import routes from "../../constants/routes"
import AppBar from "../../components/AppBar"
import Layout from "../../components/Layout"
import { FirstTabList, SecondTabList, ThirdTabList } from "./components"
import AppContext from "../../Context"
import { useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { filterAccountPopups } from "../../components/FilterAccountPopups";
import CustomPopups from "../../components/CustomPopups";

const TabSelector = ({ isActive, children, onClick }) => (
  <button
    className={`tab-button-like ${
      isActive ? "active-like" : "inactive-like"
    } yu-gothic-bold`}
    onClick={onClick}
  >
    {children}
  </button>
)

const Likes = () => {
  const history = useHistory()
  const { setContext } = useContext(AppContext)
  const userTab1 = useSelector((state) => state.likes.likers)
  const userTab2 = useSelector((state) => state.likes.likedUsers)
  const userTab3 = useSelector((state) => state?.footPrint?.data)
  
  const [displayedUsersTab3, setDisplayedUsersTab3] = useState([])

  const location = useLocation()

  const profileUserData = useSelector((state) => state.auth.profile);
  const data = useSelector((state) => state.popups.data);
  const [filteredData, setFilteredData] = useState([]);
  const [openPopups, setOpenPopups] = useState(false);
  const [statePopUps, setOpenStatePopUps] = useState(false);

  const filterAndSetData = () => {
    const result = filterAccountPopups(data, profileUserData);
    const validFilteredData = result.filter(item => item !== []);

    if (validFilteredData.length > 0 && result.length > 0
      && validFilteredData[0]?.screen_active === location.pathname.replace(/^\//, '')) {
      setFilteredData(validFilteredData);
      if (!statePopUps) {
        setOpenPopups(true);
      }
    }
  };

  useEffect(() => {
    filterAndSetData();
  }, [data, profileUserData, openPopups]);

  const handleClosePopups = () => {
    setOpenStatePopUps(true);
    setOpenPopups(false);
  };

  useEffect(() => {
    let data = []
    if (userTab3) {
      data = displayedUsersTab3.concat(userTab3.users)
      setDisplayedUsersTab3(_.reverse(_.uniqBy(_.reverse([...data]), "user_id")))
    }
  }, [userTab3?.users])
  const userTab3Unread = displayedUsersTab3.filter(({ is_read }) => is_read === 0);
  const userTab2Unread = userTab2.filter(({ isLikeRead }) => isLikeRead === 0);
  const userTab1Unread = userTab1.filter(({ isLikeRead }) => isLikeRead === 0);

  const [selectedTab, setSelectedTab] = useTabs([
    "account",
    "company",
    "footPrint"
  ])

  const handleSelectTab1 = () => {
    setSelectedTab("account")
    history.push({
      pathname: history.location.pathname,
      search: "tab=1"
    })
  }

  const handleSelectTab2 = () => {
    setSelectedTab("company")
    history.push({
      pathname: history.location.pathname,
      search: "tab=2"
    })
  }

  const handleSelectTab3 = () => {
    setSelectedTab("footPrint")
    history.push({
      pathname: history.location.pathname,
      search: "tab=3"
    })
  }

  useEffect(() => {
    setContext("overlay", null)
  }, [])

  useMemo(() => {
    if (history.location.search === "?tab=3") {
      setSelectedTab("footPrint")
    } else if (history.location.search === "?tab=2") {
      setSelectedTab("company")
    } else {
      setSelectedTab("account")
    }
  }, [history.location.search])

  return (
    <Layout nav>
      <AppBar title="気になる" titleLeft noBorderBottom/>
      <div
        className="bg-white d-flex flex-column full-height-with-navbar wrap-like"
      >
        <nav className="nav">
          <TabSelector
            isActive={selectedTab === "account"}
            onClick={handleSelectTab1}
          >
            相手から
            <span className="position-absolute translate-middle badge rounded-pill bg-danger">
              {!userTab1Unread?.length ? '' : userTab1Unread?.length}
            </span>
          </TabSelector>
          <TabSelector
            isActive={selectedTab === "company"}
            onClick={handleSelectTab2}
          >
            自分から
            <span className="position-absolute translate-middle badge rounded-pill bg-danger">
              {!userTab2Unread?.length ? '' : userTab2Unread?.length}
            </span>
          </TabSelector>
          <TabSelector
            isActive={selectedTab === "footPrint"}
            onClick={handleSelectTab3}
          >
            足あと
            <span className="position-absolute translate-middle badge rounded-pill bg-danger">
              {!userTab3Unread?.length ? '' : userTab3Unread?.length}
            </span>
          </TabSelector>
        </nav>
        <div className="h-100-like w-100 mt-3">
          <div className="h-100">
            <TabPanel hidden={selectedTab !== "account"} className="h-100">
              <FirstTabList onViewUsers={() => history.push(routes.USERS)} />
            </TabPanel>
            <TabPanel hidden={selectedTab !== "company"} className="h-100">
              <SecondTabList onViewUsers={() => history.push(routes.USERS)} />
            </TabPanel>
            <TabPanel hidden={selectedTab !== "footPrint"} className="h-100">
              <ThirdTabList onViewUsers={() => history.push(routes.USERS)} />
            </TabPanel>
          </div>
        </div>
      </div>
      {(openPopups && filteredData?.length > 0) && <CustomPopups openPopups={openPopups} onClose={handleClosePopups} filteredData={filteredData[0]} />}
    </Layout>
  )
}

export default Likes
