import { useState, useContext, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import "./style.scss"
import AppContext from "../../Context"
import AppBar from "../../components/AppBar"
import Layout from "../../components/Layout"
import { TopOfPage } from "./components/top"
import { PanelMenu } from "./components/panel-menu"
import { fetchAuthProfileRequest } from "../../actions/auth"
import { getFilterOptionsRequest } from "../../actions/filter"
import { LoadingIndicator } from "../../components/LoadingIndicator"
import { useLocation } from "react-router-dom"
import { filterAccountPopups } from "../../components/FilterAccountPopups";
import CustomPopups from "../../components/CustomPopups";

const MyPage = () => {
  const dispatch = useDispatch()
  const { setContext } = useContext(AppContext)
  const [bannerHeight, setBannerHeight] = useState(0)

  const isLoading = useSelector((state) => {
    return state.auth.loadingProfile
  })
  const options = useSelector((state) => {
    return state.filter.options
  })
  const location = useLocation()

  const profileUserData = useSelector((state) => state.auth.profile);
  const data = useSelector((state) => state.popups.data);
  const [filteredData, setFilteredData] = useState([]);
  const [openPopups, setOpenPopups] = useState(false);
  const [statePopUps, setOpenStatePopUps] = useState(false);
  
  const filterAndSetData = () => {
    const result = filterAccountPopups(data, profileUserData);
    const validFilteredData = result.filter(item => item !== []);

    if (validFilteredData.length > 0 && result.length > 0
      && validFilteredData[0]?.screen_active === location.pathname.replace(/^\//, '')) {
      setFilteredData(validFilteredData);
      if (!statePopUps) {
        setOpenPopups(true);
      }
    }
  };

  useEffect(() => {
    filterAndSetData();
  }, [data, profileUserData, openPopups]);

  const handleClosePopups = () => {
    setOpenStatePopUps(true);
    setOpenPopups(false);
  };

  useEffect(() => {
    dispatch(
      fetchAuthProfileRequest(
        {},
        {
          onSuccess: (response) => {
            localStorage.setItem("profileEdit", JSON.stringify(response))
            setContext("overlay", null)
          },
          onFailed: () => {
            setContext("overlay", null)
          }
        }
      )
    )
    dispatch(
      getFilterOptionsRequest(
        {},
        {
          onSuccess: (response) => {
            localStorage.setItem("profileEditOption", JSON.stringify(response))
            setContext("overlay", null)
          },
          onFailed: () => {
            setContext("overlay", null)
          }
        }
      )
    )
    localStorage.removeItem("curent_scroll")
  }, [])

  return (
    <Layout nav>
      <AppBar title="マイページ" banner titleLeft setBannerHeight={setBannerHeight} />
      <div
        className="bg-white d-flex flex-column wrap-profile max-w-screen-xl"
        style={{ 
          top: `${bannerHeight + 90}px`, 
          height: `calc(100vh - (80px + ${bannerHeight}px))` 
        }}
      >
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <div className="my-page">
            <TopOfPage me={profileUserData} options={options} />
            <PanelMenu me={profileUserData} options={options} />
          </div>
        )}
      </div>
      {(openPopups && filteredData?.length > 0) && <CustomPopups openPopups={openPopups} onClose={handleClosePopups} filteredData={filteredData[0]} />}
    </Layout>
  )
}

export default MyPage
