import { useState, useRef } from "react"
import TextArea from "react-textarea-autosize"
import imageCompression from "browser-image-compression"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faImage } from "@fortawesome/free-regular-svg-icons"
import { faBookMedical } from "@fortawesome/free-solid-svg-icons"
import { Container, Row, Col, Image, Button, Modal } from "react-bootstrap"

import "./styles.scss"
import IconSend from "../../../icons/ic_send.png"
import { MESSAGE_TYPES } from "../../../constants/env"

export const InputField = ({ onSend, messagesTemplate, userName }) => {
  const [message, setMessage] = useState("")
  const [modal, setModal] = useState(false)
  const [modalTemplate, setModalTemplate] = useState(false)

  const inputRef = useRef(null)
  const cameraRef = useRef(null)

  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: 1200,
    useWebWorker: true
  }

  const handleKeyUp = (event) => {
    if( (event.ctrlKey && (event.keyCode == 13)) ) {
      const newMsg = {
        type: MESSAGE_TYPES.TEXT,
        message,
        videoCallId: null,
        imageUrl: null,
        isRead: 0
      }
      setMessage("")
      onSend(newMsg)
    }
  }

  const renderImageClick = () => {
    return (
      <Container
        className="border border-1 position-fixed bottom-0 bg-white d-flex flex-column p-3"
        style={{
          borderTopLeftRadius: "2rem",
          borderTopRightRadius: "2rem"
        }}
        fluid
      >
        <Button
          className="bg-white border-0 p-3 yu-gothic-bold"
          style={{ color: "#010101", borderRadius: 0 }}
          onClick={() => {
            cameraRef.current.setAttribute("capture", "camera")
            cameraRef.current.click()
          }}
        >
          カメラで撮影する
        </Button>
        <Button
          className="bg-white border-0 border-top border-1 p-3 yu-gothic-bold"
          style={{ color: "#010101", borderRadius: 0 }}
          onClick={() => {
            inputRef && inputRef.current.click()
          }}
        >
          ライブラリから選択
        </Button>
        <input
          className="d-none"
          ref={cameraRef}
          type="file"
          accept=".png,.jpg,.jpeg"
          onChange={async (event) => {
            if (event.target.files.length > 0) {
              const compressedFile = await imageCompression(
                event.target.files[0],
                options
              )

              const newMsg = {
                type: MESSAGE_TYPES.IMAGE,
                videoCallId: null,
                imageUrl: null,
                isRead: 0,
                imageFile: compressedFile
              }
              setModal(false)
              onSend(newMsg)
            }
          }}
        />
        <input
          className="d-none"
          ref={inputRef}
          type="file"
          accept=".png,.jpg,.jpeg"
          onChange={async (event) => {
            if (event.target.files.length > 0) {
              const compressedFile = await imageCompression(
                event.target.files[0],
                options
              )
              const newMsg = {
                type: MESSAGE_TYPES.IMAGE,
                videoCallId: null,
                imageUrl: null,
                isRead: 0,
                imageFile: compressedFile
              }
              setModal(false)
              onSend(newMsg)
            }
          }}
        />
        <Button
          className="bg-white border-0 border-top border-1 p-3 yu-gothic-bold"
          style={{ color: "#9F9F9F", borderRadius: 0 }}
          onClick={() => setModal(false)}
        >
          キャンセル
        </Button>
      </Container>
    )
  }

  return (
    <>
      {modal ? (
        renderImageClick()
      ) : (
        <Container
          className="position-fixed bottom-0 border-top border-1 p-2 max-w-screen-xl"
          fluid
          style={{ backgroundColor: "#D9D9D9" }}
        >
          <Row>
            <Col
              xs={2}
              sm={2}
              className="d-flex justify-content-center align-items-center"
            >
              {/* <Image src={IconCamera} onClick={() => setModal(true)}></Image> */}
              <FontAwesomeIcon
                // className="text-danger"
                icon={faImage}
                size="lg"
                onClick={() => setModal(true)}
              />
            </Col>
            <Col className="p-0 py-2 d-flex justify-content-center align-items-center">
              <TextArea
                className="w-100 message-input"
                placeholder="メッセージを入力してください"
                maxRows={3}
                minRows={1}
                value={message}
                onChange={(event) => setMessage(event.target.value)}
                onBlur={() => window.scrollTo(0, 0)}
                onKeyUp={handleKeyUp}
              />
            </Col>
            <Col
              xs={2}
              sm={2}
              className="py-2 d-flex justify-content-center align-items-center"
              onClick={(e) => {
                const newMsg = {
                  type: MESSAGE_TYPES.TEXT,
                  message,
                  videoCallId: null,
                  imageUrl: null,
                  isRead: 0
                }
                setMessage("")
                onSend(newMsg)
              }}
            >
              {message !== "" ? (
                <Image
                  className="icon-send-message"
                  src={IconSend}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faBookMedical}
                  size="lg"
                  onClick={() => {
                    setModalTemplate(true)
                  }}
                />
              )}
            </Col>
          </Row>
        </Container>
      )}

      <Modal size="sm" show={modalTemplate} centered>
        <Modal.Header
          closeButton
          onHide={() => {
            setModalTemplate(false)
          }}
          className="mess-template-heading"
        >
          定型文
        </Modal.Header>
        <Modal.Body className="mess-template-body">
          {messagesTemplate?.map((message) => (
            <div
              key={message.id}
              className="mess-template-item"
              onClick={() => {
                setMessage(message.message?.replace("〇〇", userName))
                setModalTemplate(false)
              }}
            >
              {message.message?.replace("〇〇", userName)}
            </div>
          ))}
        </Modal.Body>
      </Modal>
    </>
  )
}
