import * as actionTypes from "../constants/actions"

const initialState = {
  profile: {},
  banners: []
}

const otherReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EXCHANGE_POINT_TO_HEART:
      return {
        ...state,
        detail: action.data
      }

    case actionTypes.SEND_QUESTION_REQUEST:
      return {
        ...state,
        detail: action.data
      }

    case actionTypes.DELETE_ACCOUNT_REQUEST:
      return {
        ...state,
        detail: action.data
      }

    case actionTypes.ADD_EMAIL:
      return {
        ...state,
        detail: action.data
      }

    case actionTypes.SEND_WITHDRAWAL_REQUEST:
      return {
        ...state,
        detail: action.data
      }

    case actionTypes.UPDATE_BANK_REQUEST:
      return {
        ...state,
        detail: action.data
      }

    case actionTypes.GET_WITHDRAWAL_HISTORY:
      return {
        ...state,
        detail: action.data
      }

    case actionTypes.ON_DATA_REPORT:
      return {
        ...state,
        detail: action.data
      }
    case actionTypes.ON_DATA_MATCHING_READ:
      return {
        ...state,
        detail: action.data
      }
    case actionTypes.HIDE_USER:
      return {
        ...state,
        detail: action.data
      }
    case actionTypes.GET_BANNER_REQUESTED:
      return {
        ...state
      }
    case actionTypes.GET_BANNER_SUCCESS:
      return {
        ...state,
        banners: action.payload
      }
    case actionTypes.GET_BANNER_FAILED:
      return {
        ...state,
        error: action.error
      }
    default:
      return state
  }
}

export default otherReducer
