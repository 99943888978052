import PropTypes from "prop-types"
import classNames from "classnames"

import "./styles.scss"
import ImagePicker from "../../../images/picker-image.svg"
import ImagePickerSub from "../../../images/image-picker-sub.svg"

const ChooseAvatar = ({
  className,
  avatar,
  index,
  onClick,
  backgroundSize
}) => {
  const preview = avatar
    ? { backgroundImage: `url(${avatar.url})`, backgroundSize: backgroundSize }
    : { backgroundImage: `url(${index === 0 ? ImagePicker : ImagePickerSub})` }

  return (
    <div
      className={classNames("text-light rounded-3 square avatar", className)}
      onClick={onClick}
    >
      <div
        className="square-inner d-flex justify-content-center preview"
        style={preview}
      ></div>
    </div>
  )
}

ChooseAvatar.propTypes = {
  onClick: PropTypes.func
}

export default ChooseAvatar
