import { useContext, useEffect, useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import Loader from "react-loader-spinner"
import useOnClickOutside from "../../hooks/use-onclick-outside"
import { LoadingIndicator } from "..//LoadingIndicator"
import AppContext from "../../Context"

import {
  fetchPointPackageRequest,
  purchasePointRequest
} from "../../actions/buy-point"
import { fetchAuthProfileRequest } from "../../actions/auth"

import icon from "./images/money.png"
import "./style.scss"

const BuyPointGift = (props) => {
  const dispatch = useDispatch()
  const ref = useRef()
  const { pointPackages, onClickOutside, onPayment } = props
  const { setContext } = useContext(AppContext)
  const [data, setData] = useState([])
  const [showPaymentPopup, setShowPaymentPopup] = useState(false)
  const [paymentUrl, setPaymentUrl] = useState("")
  const [showClose, setShowClose] = useState(true)
  const [showLoadingFrame, setShowLoadingFrame] = useState(false)

  useOnClickOutside(ref, (event) => {
    if (onClickOutside) {
      onClickOutside(event)
    }
  })

  const profile = useSelector((state) => {
    return state.auth.profile
  })

  useEffect(() => {
    if (pointPackages) {
      setData(pointPackages)
    } else {
      dispatch(
        fetchPointPackageRequest({
          onSuccess: (response) => {
            setContext("overlay", null)
            setData(response.data)
          },
          onFailed: () => {
            setContext("overlay", null)
          }
        })
      )
    }
    window.addEventListener("message", (e) => {
      try {
        if (e.data == "payment-complete") {
          reload()
        }
        if (e.data == "hidden-close-button") {
          setShowClose(false)
        }
      } catch (e) {}
    })
  }, [])

  const reload = () => {
    dispatch(
      fetchAuthProfileRequest(
        {},
        {
          onSuccess: (response) => {
            setContext("overlay", null)
          },
          onFailed: () => {
            setContext("overlay", null)
          }
        }
      )
    )
    setPaymentUrl("")
    setShowPaymentPopup(false)
    if (onPayment) {
      onPayment()
    }
  }

  const onPurchase = (points_package_id) => {
    setContext("overlay", <LoadingIndicator />)
    dispatch(
      purchasePointRequest(
        {
          app_type: "Web",
          points_package_id: points_package_id,
          redirect_url: window.location.origin + "/payment-complete"
        },
        {
          onSuccess: (response) => {
            setContext("overlay", null)
            if (response.url) {
              setPaymentUrl(response.url)
              setShowPaymentPopup(true)
              setShowClose(true)
              setShowLoadingFrame(true)
              setTimeout(() => {
                setShowLoadingFrame(false)
              }, 400)
            } else if (response.data.error && response.data.error.message) {
              alert(response.data.error.message)
            } else {
              alert("支払いに失敗しました。")
            }
          },
          onFailed: (response) => {
            setContext("overlay", null)
            alert("支払いに失敗しました。")
          }
        }
      )
    )
  }

  const toStringNumber = (num) => {
    if (num == null || num == '') {
      return '0'
    }
    return (Math.round(num) + "").replace(/(\d)(?=(\d{3})+$)/g, "$1,")
  }

  return (
    <div className="popup-list-points" ref={ref}>
      <div className="current-point">
        <div className="div-text">ポイント残高</div>
        <div className="point">
          <img src={icon} alt="point" />
          <p>{toStringNumber(profile.points_balance)}</p>
        </div>
      </div>
      <div className="list-points">
        {data.map((point, idx) => {
          return (
            <div key={idx} className="point-item">
              <div className="icon">
                <img src={icon} alt="point" />
              </div>
              <div className="point">
                <span>{toStringNumber(point.points)}</span>
              </div>
              <div className="div-btn-buy">
                <button
                  type="button"
                  className="btn-buy"
                  onClick={() => {
                    onPurchase(point.points_package_id)
                  }}
                >
                  <span
                    className={`origin-price ${
                      point.raw_price == null || point.price == point.raw_price
                        ? "no-line"
                        : ""
                    }`}
                  >
                    {point.raw_price == null || point.price == point.raw_price
                      ? "通常価格"
                      : `¥${toStringNumber(point.raw_price)}`}
                  </span>
                  <span className="price">¥{toStringNumber(point.price)}</span>
                </button>
              </div>
            </div>
          )
        })}
      </div>
      {showPaymentPopup && paymentUrl !== "" && (
        <>
          <div className="popup-payment">
            {showClose && (
              <button
                type="button"
                className="btn-cancel-payment"
                onClick={reload}
              >
                x
              </button>
            )}
            <iframe
              src={paymentUrl}
              title="Credix株式会社"
              frameBorder="0"
            ></iframe>
          </div>
          {showLoadingFrame && (
            <div className="loading-frame">
              <Loader type="Oval" color="grey" width="4rem" height="4rem" />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default BuyPointGift
