import _ from "lodash"
import { takeEvery, put, call } from "redux-saga/effects"
import {
  updateFollowUserSuccess,
  updateFollowUserFailed
} from "../actions/follow"
import { UPDATE_FOLLOW_USER_REQUEST } from "../constants/actions/follow"
import { likeUser, skipUser, thankUser } from "../services/follow"
import { statuses } from "../constants/follow"

function* handleUpdateFollowUser(action) {
  const { onSuccess, onFailed } = action?.payload?.callbacks || {}
  try {
    let response = null
    const payload = {}
    switch (_.get(action, "payload.data.followState")) {
      case statuses.NEW:
        response = yield likeUser(action.payload.data, action)
        payload.is_liked = 1
        payload.matching_status = response.data.matching_status
        break

      case statuses.FOLLOW_ME:
        if (_.get(action, "payload.data.state", null) === false) {
          response = yield skipUser(action.payload.data, action)
          payload.matching_status = response.data.matching_status
        } else {
          response = yield thankUser(action.payload.data, action)
          payload.matching_status = response.data.matching_status
        }
        break

      case statuses.FOLLOW_SKIP:
        response = yield thankUser(action.payload.data, action)
        payload.matching_status = response.data.matching_status
        break

      case statuses.FOLLOW_UP:
        response = yield likeUser(action.payload.data, action)
        payload.is_liked = 1
        payload.matching_status = response.data.matching_status
        break

      default:
        return
    }
    if (response && response.status === 200) {
      if (typeof onSuccess === "function") {
        yield call(onSuccess, response.data)
      }
      yield put(updateFollowUserSuccess(payload))
    } else {
      if (typeof onFailed === "function") {
        yield call(onFailed)
      }

      yield put(updateFollowUserFailed())
    }
  } catch (error) {
    if (typeof onFailed === "function") {
      yield call(onFailed)
    }
    yield put(updateFollowUserFailed())
  }
}

export default function* watchFollowUser() {
  yield takeEvery(UPDATE_FOLLOW_USER_REQUEST, handleUpdateFollowUser)
}
