import _ from "lodash"
import { useHistory } from "react-router-dom"
import { useContext, useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

import AppContext from "../../../Context"
import routes from "../../../constants/routes"
import messages from "../../../constants/messages"
import { upsertAuthProfile } from "../../../actions/auth"
import { updatePhoneNumber } from "../../../actions/signup"
import { sendVerifyCode, signOut } from "../../../utils/firebase"
import { AppBar, Layout, LoadingIndicator, OTPInput } from "../../../components"
import { runVerifyTrackingScript } from "./verifyTrackingScript"

const VerifyPhone = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { setContext } = useContext(AppContext)
  const [code, setCode] = useState("")
  const [confirm, setConfirm] = useState(null)
  const [reSendCode, setReSendCode] = useState(false)

  const phone = useSelector((state) => {
    return state.auth.phone
  })

  async function confirmCode(code) {
    if (_.isEmpty(code)) {
      setContext("overlay", null)
      alert(messages.emptyCode)
      return
    }
    try {
      await confirm.confirm(code)
      dispatch(upsertAuthProfile({ phoneVerified: true }))
      dispatch(updatePhoneNumber(""))
      localStorage.setItem("registing", 1)
      setTimeout(() => {
        runVerifyTrackingScript()
        setContext("overlay", null)
        history.push(routes.REGISTER_PROFILE)
      }, 100)
    } catch (error) {
      setContext("overlay", null)
      alert(messages.invalidCode)
    }
  }

  const handleResendCode = () => {
    setContext("overlay", <LoadingIndicator />)
    setTimeout(() => {
      setReSendCode(false)
    }, 15000)
    setReSendCode(true)
    sendVerifyCode(
      phone,
      handleSendVerifySuccess(true),
      handleSendVerifyFailure(true)
    )
  }

  const handleConfirmCode = (e) => {
    e.preventDefault()
    setContext("overlay", <LoadingIndicator />)
    if (_.isEmpty(code)) {
      alert(messages.emptyCode)
      setContext("overlay", null)
      return
    }

    confirmCode(code)
  }

  const handleChange = ({ target: { value } }) => {
    setCode(value)
  }

  const handleSendVerifySuccess = (isResend) => (confirmation) => {
    setContext("overlay", null)
    setConfirm(confirmation)
    if (isResend) alert(messages.resendCodeSuccesss)
  }

  const handleSendVerifyFailure = (isResend) => () => {
    setContext("overlay", null)
    if (isResend) alert(messages.codeSendFail)
  }

  useEffect(() => {
    if (_.isEmpty(phone)) {
      history.push(routes.TITLE)
    } else {
      setContext("overlay", <LoadingIndicator />)
      signOut()
      sendVerifyCode(
        phone,
        handleSendVerifySuccess(false),
        handleSendVerifyFailure(false)
      )
    }
  }, [])

  return (
    <Layout height={false}>
      <AppBar withBack title="新規登録" colorTitle="red" />

      <div className="container" style={{ paddingTop: 63 }}>
        <OTPInput
          onChange={handleChange}
          onRefresh={handleResendCode}
          onSubmit={handleConfirmCode}
          reSendCode={reSendCode}
        />
      </div>
    </Layout>
  )
}

export default VerifyPhone
