import { useState } from "react"
import { Image, Row, Col, Modal } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons"

import "./styles.scss"
import { StatusDot } from "../StatusDot"
import IconTag from "../../icons/tag.png"
import HeartLike from "../../images/heart-like.png"
import { USER_STATUS } from "../../constants/env"
import DefaultAvatar from "../../images/avatar-app.png"
import { calculateAge, getUserStatus, getLikeTime } from "../../utils"

export const UserItem = ({
  user,
  children,
  onAvatarClick,
  onRightClick,
  onMark,
  onBlock,
  onLabel
}) => {
  const status = user.last_login
    ? getUserStatus(user.last_login)
    : USER_STATUS.OFFLINE

  const dateTimeStr = user.likedDatetime
    ? user.likedDatetime
    : user.matchingDatetime
    ? user.matchingDatetime
    : user.lastSentDatetime
    ? user.lastSentDatetime
    : user.footprint_at

  let date = null
  let time = null
  if (dateTimeStr) {
    const t = getLikeTime(dateTimeStr)
    if(status == 3) {
      time = t.time
    } else if(status == 1) {
      time = '３日以内'
    } else {
      time = 'オフライン'
    }
    date = t.date
  }
  const [show, setShow] = useState(false)
  const handleClose = () => {
    setShow(false)
  }

  return (
    <div
      style={{
        backgroundColor: user.isLikeRead || user.is_read ? "white" : "#FFE3E0"
      }}
    >
      {user.likeType === 2 && window.location.search === "?tab=1" && (
        <div className="super-like">
          <img className="super-like-image" src={HeartLike} alt="Heart like" />
          <span className="super-like-text">
            {user?.nickname}さんから【スーパー気になる】が届いています！
          </span>
        </div>
      )}
      <div className="d-flex p-2 user-item">
        <Col
          xs={3}
          md={2}
          className="d-flex justify-content-start align-items-center ps-2"
        >
          <div style={{ position: "relative" }}>
            <StatusDot
              color={
                status === 3 ? "#38C638" : status === 1 ? "#FF7018" : "#939393"
              }
              left={-15}
              top={-11}
            />
            <Image
              className={`img ${
                user?.paymentType === 4 || user?.payment_type === 4
                  ? "vip_4"
                  : user?.paymentType === 3 || user?.payment_type === 3
                  ? "vip_3"
                  : user?.paymentType === 2 || user?.payment_type === 2
                  ? "vip_2"
                  : ""
              }`}
              style={{ cursor: "pointer" }}
              height={60}
              width={60}
              src={user.imageUrl || user.image_url || DefaultAvatar}
              onClick={() => onAvatarClick && onAvatarClick()}
            />
            {(user?.paymentType === 4 ||
              user?.payment_type === 4 ||
              user?.paymentType === 3 ||
              user?.payment_type === 3 ||
              user?.paymentType === 2 ||
              user?.payment_type === 2) && (
              <p
                className="vip"
                style={{
                  backgroundColor:
                    user?.paymentType === 4 || user?.payment_type === 4
                      ? "#00205D"
                      : user?.paymentType === 3 || user?.payment_type === 3
                      ? "#5E08B4"
                      : user?.paymentType === 2 || user?.payment_type === 2
                      ? "#35ADD9"
                      : "black",
                  left: 12
                }}
              >
                VIP
              </p>
            )}
          </div>
        </Col>
        <Col xs={9} md={10} className="d-flex flex-row justify-content-between">
          <Row
            style={{ maxWidth: "76%" }}
            onClick={() => {
              onRightClick && onRightClick()
            }}
          >
            <Col>
              <Row>
                <Col className="d-flex justify-content-between">
                  <div className="overflow d-flex align-items-center">
                    <span
                      className="overflow yu-gothic-bold"
                      style={{ fontSize: 14, marginRight: 2 }}
                    >
                      {user?.nickname || ""}
                    </span>
                    {user.is_label && (
                      <img src={IconTag} width={18} alt="Icon tag" />
                    )}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col
                  xs={date && date.length > 0 ? 8 : 12}
                  sm={date && date.length > 0 ? 8 : 12}
                  md={date && date.length > 0 ? 8 : 12}
                  className="small-text m-0 w-100 yu-gothic-regular"
                >{`${
                  user.date_of_birth
                    ? `${calculateAge(user.date_of_birth)}歳 | `
                    : `${user?.age}歳 | `
                } ${user.residence || ""}`}</Col>
                <p className="text-small overflow m-0">{user.biography}</p>
                <div className="text-small d-flex align-items-center yu-gothic-regular">
                  {time}
                </div>
              </Row>
            </Col>
          </Row>
          <div>
            <Col>
              <div className="three-dot" onClick={() => setShow(true)}>
                <FontAwesomeIcon icon={faEllipsisV} color="#333333" />
              </div>
              <div>
                <Col>{children}</Col>
              </div>
            </Col>
          </div>
        </Col>
        <Modal
          show={show}
          // onHide={handleClose}
          centered
          dialogClassName="modal-custom mx-auto"
        >
          <Modal.Body className="modal-custom-body">
            <h6 className="text-center yu-gothic-bold h4 modal-custom-title">
              {`${user.nickname} (${
                user.date_of_birth ? calculateAge(user.date_of_birth) : user.age
              }歳)`}
            </h6>
            <button
              className="btn font-simple w-100 m-0 rounded-0 modal-custom-button"
              onClick={() => {
                onMark(user.likeId || user.user_id)
                handleClose()
              }}
              disabled={user.isLikeRead || user.is_read ? true : false}
            >
              既読にする
            </button>
            <button
              className="btn font-simple w-100 m-0 rounded-0 modal-custom-button"
              onClick={() => {
                onLabel(user.id || user.user_id, user.is_label)
                handleClose()
              }}
            >
              {user.is_label ? "ラベルを削除する" : "ラベル"}
            </button>
            <button
              className="btn font-simple w-100 m-0 rounded-0 modal-custom-button"
              onClick={() => {
                onBlock(user.id || user.user_id)
                handleClose()
              }}
            >
              ブロック
            </button>
            <button
              className="btn font-simple w-100 m-0 rounded-0 modal-custom-button"
              onClick={handleClose}
            >
              キャンセル
            </button>
          </Modal.Body>
        </Modal>
      </div>
    </div>
  )
}
