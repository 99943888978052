export const EXCHANGE_POINT_TO_HEART = "EXCHANGE_POINT_TO_HEART"

export const FETCH_DATA_FOOT_PRINTS = "FETCH_DATA_FOOT_PRINTS"
export const FETCH_DATA_FOOT_PRINTS_SUCCESS = "FETCH_DATA_FOOT_PRINTS_SUCCESS"
export const FETCH_DATA_FOOT_PRINTS_FAIL = "FETCH_DATA_FOOT_PRINTS_FAIL"

export const FETCH_DATA_FOOT_UPDATE = "FETCH_DATA_FOOT_UPDATE"
export const FETCH_DATA_FOOT_UPDATE_SUCCESS = "FETCH_DATA_FOOT_UPDATE_SUCCESS"
export const FETCH_DATA_FOOT_UPDATE_FAIL = "FETCH_DATA_FOOT_UPDATE_FAIL"

export const FETCH_DATA_SKIPPED = "FETCH_DATA_SKIPPED"
export const FETCH_DATA_SKIPPED_SUCCESS = "FETCH_DATA_SKIPPED_SUCCESS"
export const FETCH_DATA_SKIPPED_FAIL = "FETCH_DATA_SKIPPED_FAIL"

export const FETCH_DATA_MUTED = "FETCH_DATA_MUTED"
export const FETCH_DATA_MUTED_SUCCESS = "FETCH_DATA_MUTED_SUCCESS"
export const FETCH_DATA_MUTED_FAIL = "FETCH_DATA_MUTED_FAIL"

export const FETCH_BOX_NOTIFICATION = "FETCH_BOX_NOTIFICATION"
export const FETCH_BOX_NOTIFICATION_SUCCESS = "FETCH_BOX_NOTIFICATION_SUCCESS"
export const FETCH_BOX_NOTIFICATION_FAILED = "FETCH_BOX_NOTIFICATION_FAILED"

export const CHANGE_NOTI_MESSAGE_REQUEST = "CHANGE_NOTI_MESSAGE_REQUEST"
export const CHANGE_NOTI_MESSAGE_SUCCESS = "CHANGE_NOTI_MESSAGE_SUCCESS"
export const CHANGE_NOTI_MESSAGE_FAILED = "CHANGE_NOTI_MESSAGE_FAILED"

export const API_NOTI_MESSAGE_REQUEST = "API_NOTI_MESSAGE_REQUEST"
export const API_NOTI_MESSAGE_SUCCESS = "API_NOTI_MESSAGE_SUCCESS"
export const API_NOTI_MESSAGE_FAILED = "API_NOTI_MESSAGE_FAILED"

export const FETCH_DETAIL_NOTIFICATION = "FETCH_DETAIL_NOTIFICATION"
export const FETCH_DETAIL_NOTIFICATION_SUCCESS =
  "FETCH_DETAIL_NOTIFICATION_SUCCESS"
export const FETCH_DETAIL_NOTIFICATION_FAILED =
  "FETCH_DETAIL_NOTIFICATION_FAILED"

export const UPDATE_READ_NOTIFICATION = "UPDATE_READ_NOTIFICATION"
export const UPDATE_READ_NOTIFICATION_SUCCESS =
  "UPDATE_READ_NOTIFICATION_SUCCESS"
export const UPDATE_READ_NOTIFICATION_FAILED = "UPDATE_READ_NOTIFICATION_FAILED"

export const UNMUTE_USER = "UNMUTE_USER"

export const SEND_QUESTION_REQUEST = "SEND_QUESTION_REQUEST"

export const DELETE_ACCOUNT_REQUEST = "DELETE_ACCOUNT_REQUEST"

export const ADD_EMAIL = "ADD_EMAIL"

export const SEND_WITHDRAWAL_REQUEST = "SEND_WITHDRAWAL_REQUEST"

export const UPDATE_BANK_REQUEST = "UPDATE_BANK_REQUEST"

export const GET_WITHDRAWAL_HISTORY = "GET_WITHDRAWAL_HISTORY"

export const ON_DATA_REPORT = "ON_DATA_REPORT"
export const ON_DATA_MATCHING_READ = "ON_DATA_MATCHING_READ"

export const HIDE_USER = "HIDE_USER"

export const GET_BANNER_REQUESTED = "GET_BANNER_REQUESTED"
export const GET_BANNER_SUCCESS = "GET_BANNER_SUCCESS"
export const GET_BANNER_FAILED = "GET_BANNER_FAILED"

export const SEND_VERIFY_EMAIL = "SEND_VERIFY_EMAIL"

export const NOTIFICATION_SETTING_REQUESTED = "NOTIFICATION_SETTING_REQUESTED"

export const GET_NOTI_SETTING_REQUESTED = "GET_NOTI_SETTING_REQUESTED"
