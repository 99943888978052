import { useContext, useEffect, useState, Fragment } from "react"
import Container from "react-bootstrap/Container"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import AppBar from "../../../components/AppBar"
import Layout from "../../../components/Layout"
import AppContext from "../../../Context"

import "./styles.scss"
import removeIcon from "../../../icons/remove.svg"
import toggleIcon from "../../../icons/arr-down.svg"

const MultiSelect = (props) => {
  const {
    title,
    emptyText,
    options,
    values,
    propId,
    children,
    name,
    open: focus,
  } = props
  const { onFocus, onChange } = props
  const [open, setOpen] = useState(false)
  const [countChange, setCountChange] = useState(0)
  const [innerValues, setInnerValues] = useState(
    values == undefined ? [] : values
  )
  const { setContext } = useContext(AppContext)

  const toggleOpen = () => {
    onFocus ? onFocus() : setOpen(!open)
  }

  const handleRemove = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setInnerValues([])
    onChange && onChange([])
    return false
  }

  const handleChange = (value) => {
    let index = innerValues.indexOf(value)
    if (index > -1) {
      innerValues.splice(index, 1)
      onChange && onChange(innerValues)
    } else {
      if (value == "こだわらない") {
        setInnerValues(["こだわらない"])
        onChange && onChange(["こだわらない"])
      } else {
        index = innerValues.indexOf("こだわらない")
        if (index > -1) {
          innerValues.splice(index, 1)
        }
        innerValues.push(value)
        onChange && onChange(innerValues)
      }
    }
    setCountChange(countChange + 1)
  }

  const handleRemoveOne = (e, value) => {
    e.preventDefault()
    e.stopPropagation()
    const index = innerValues.indexOf(value)
    if (index > -1) {
      innerValues.splice(index, 1)
      onChange && onChange(innerValues)
    }
    return false
  }

  const getItem = (id) => {
    if (id == "こだわらない") {
      let item = {}
      item[propId] = "こだわらない"
      item.name = "こだわらない"
      return item
    }
    var items = options.filter((item) => {
      return item[propId] == id
    })
    if (items && items.length > 0) {
      return items[0]
    }
    return null
  }

  useEffect(() => {
    if (focus !== undefined ? focus : open) {
      setContext(
        "overlayfilter",
        <Layout>
          <AppBar title={title} onGoBack={toggleOpen} />
          <Form className="form-filter bg-white d-flex flex-column h-100">
            <div className="element element-range d-flex justify-content-center select-options form-wrap overflow-hidden">
              <Container className="container-multi">
                <p>※複数選択可能</p>
                <Form.Group className="form-group">
                  <Form.Check
                    inline
                    label="こだわらない"
                    value="こだわらない"
                    name={name}
                    type="checkbox"
                    checked={innerValues.indexOf("こだわらない") >= 0}
                    onChange={(e) => handleChange("こだわらない")}
                  />
                </Form.Group>
                {options.map((item, idx) => (
                  <Form.Group key={item[propId]} className="form-group">
                    <Form.Check
                      inline
                      label={item.name}
                      value={item[propId]}
                      name={name}
                      type="checkbox"
                      checked={innerValues.indexOf(item[propId]) >= 0}
                      onChange={(e) => handleChange(item[propId], item.name)}
                    />
                  </Form.Group>
                ))}
              </Container>
            </div>
            <div className="mt-auto py-3 fix-bottom">
              <div className="d-grid">
                <div className="buttons-separate border-top pt-3 pb-2">
                  <div className="container d-flex justify-content-between">
                    <Button
                      className="btn-w-45 w-100"
                      variant="danger"
                      type="button"
                      onClick={toggleOpen}
                    >
                      決定
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Layout>
      )
    } else {
      setContext("overlayfilter", null)
    }

    return () => {
      setContext("overlayfilter", null)
    }
  }, [open, focus, countChange])

  return (
    <div className="range-wrapper" onClick={toggleOpen}>
      <div className="range-input">
        {values == null || values == undefined || values.length == 0 ? (
          emptyText
        ) : (
          <Fragment>
            {values.map((value, idx) => {
              const item = getItem(value)
              return (
                item && (
                  <div className="chip ml-2" key={item[propId]}>
                    <span className="value">{item.name}</span>
                    <div className="delete-option">
                      <svg
                        height="14"
                        width="14"
                        viewBox="0 0 20 20"
                        aria-hidden="true"
                        focusable="false"
                        className="css-tj5bde-Svg"
                        onClick={(e) => handleRemoveOne(e, item[propId])}
                      >
                        <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                      </svg>
                    </div>
                  </div>
                )
              )
            })}
          </Fragment>
        )}
      </div>
      <div className="range-actions">
        <div className="range-action action-remove" onClick={handleRemove}>
          {!(values == null || values == undefined || values.length == 0) && (
            <img src={removeIcon} alt="" />
          )}
        </div>
        <div className="range-action action-toggle" onClick={toggleOpen}>
          <img src={toggleIcon} alt="" />
        </div>
      </div>
    </div>
  )
}

export default MultiSelect
