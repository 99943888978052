const routes = {
  SPLASH: "/",
  TITLE: "/title",
  REGISTER_VERIFY_PHONE: "/register/verify-phone",
  REGISTER_PROFILE: "/register/profile",
  REGISTER_AVATARS: "/register/avatars",
  LOGIN: "/login",
  LOGIN_VERIFY_PHONE: "/login/verify-phone",
  USERS: "/users",
  FILTER: "/filters",
  GUIDES: "/guides",
  SIGNUP: "/signup",
  TAKEOVER: "/takeover",
  TAKEOVER_UPDATE_PHONE: "/takeover/update-phone",
  TAKEOVER_VERIFY_PHONE: "/takeover/verify-phone",
  MATCH: "/match",
  PROFILE: "/profile",
  PROFILE_MEDIA: "/media",
  PROFILE_REVIEW: "/profile/preview",
  PROFILE_EDIT_ITEM: "/profile/edit-item",
  PROFILE_CROP_IMAGES: "/profile/crop-images",
  PROFILE_EDIT: "/profile/edit",
  SETTING: "/settings",
  PLAN: "/plans",
  BUY_POINT: "/buy-point",
  POINT_HISTORIES: "/point-histories",
  PAYMENT_COMPLETE: "/payment-complete",
  CREATE_SCHEDULE: "/create-schedule",
  SCHEDULE: "/schedules",
  CHAT: "/chats",
  TERM: "/terms",
  LIKE: "/likes",
  NOTI_SETTING: "/noti-setting",
  PRIVATE_CHAT: "/private-chat",
  OUTGOING_CALL: "/outgoing-call",
  VIDEO_CALL: "/video-call",
  EXCHANGE: "/exchange",
  PAYMENT_TYPE: "/payment-type",
  MESSAGE_TEMPLATE: "/message-template",
  MESSAGE_TEMPLATE_EDIT: "/message-template-edit",
  VERIFY_EMAIL: "/verify-email",
  MYPLAN: "/myplan"
}

export default routes
