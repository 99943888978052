import {
  FETCH_PLAN_SUCCESS,
  FETCH_PLAN_FAILED
} from "../constants/actions/plan"

const initialState = {
  plans: [],
  error: {}
}

const planReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_PLAN_FAILED:
      return {
        ...state,
        error: action.data
      }

    case FETCH_PLAN_SUCCESS:
      return {
        ...state,
        plans: action.data.data
      }

    default:
      return state
  }
}

export default planReducer
