import HttpClient from "../utils/client"

function* detailNotificationApi(dataDetail, action) {
  const url = "/box-notifications/" + dataDetail
  return yield HttpClient("GET", { url }, action)
}

function* updateReadNotificationApi(dataDetail, action) {
  const url = "/notifications/" + dataDetail
  return yield HttpClient("PUT", { url }, action)
}

function* boxNotificationApi(dataDetail, action) {
  const url = "/notifications"
  return yield HttpClient("GET", { url, params: dataDetail }, action)
}

function* changeNotiStatusRequest(data, action) {
  const url = `/change-notification-settings`
  return yield HttpClient("GET", { url, params: data }, action)
}

function* changeNotiMessageRequest(user_id, action) {
  const url = `/users/${user_id}/send-mail-message`
  return yield HttpClient("GET", { url }, action)
}

export {
  boxNotificationApi,
  detailNotificationApi,
  changeNotiStatusRequest,
  updateReadNotificationApi,
  changeNotiMessageRequest
}
