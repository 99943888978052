import { takeLatest, call, put } from "redux-saga/effects"
import { checkPhoneNumber, registertWithPhone } from "../services/signup"
import { CHECK_PHONE_NUMBER } from "../constants/actions/signup"
import { REGISTER_WITH_PHONE_REQUEST } from "../constants/actions/register"
import {
  registerWithPhoneSuccess,
  registerWithPhoneFailed,
} from "../actions/register"
import { HTTP_CODE } from "../constants/env"
import { setAccessToken } from "../utils/auth"

function* handleCheckPhoneNumber(action) {
  try {
    const { onSuccess, onFailed } = action.callbacks || {}
    const response = yield checkPhoneNumber(action)
    if (response.status == HTTP_CODE.OK) {
      yield call(onSuccess, response.data)
    } else {
      yield call(onFailed)
    }
  } catch (error) {
    console.log(error)
  }
}

function* handleRegisterWithPhoneNumber(action) {
  try {
    const {
      callbacks: { onSuccess, onFailed },
      data,
    } = action.payload || {}
    const response = yield registertWithPhone({ data })
    if (response.status == HTTP_CODE.OK) {
      if (typeof onSuccess === "function") {
        yield call(onSuccess, response.data)
      }
    } else {
      if (typeof onFailed === "function") yield call(onFailed)
    }

    const accessToken = response?.token
    if (accessToken) {
      setAccessToken(accessToken)
      if (typeof onSuccess === "function") yield call(onSuccess, accessToken)
      yield put(registerWithPhoneSuccess(accessToken))
    } else {
      if (typeof onFailed === "function") yield call(onFailed, null)
      yield put(registerWithPhoneFailed())
    }
  } catch (error) {
    console.log(error)
  }
}

export default function* watchSignUp() {
  yield takeLatest(CHECK_PHONE_NUMBER, handleCheckPhoneNumber)
  yield takeLatest(REGISTER_WITH_PHONE_REQUEST, handleRegisterWithPhoneNumber)
}
