import _ from "lodash"
import { toast } from "react-toastify"
import { useEffect, useState } from "react"
import InfiniteScroll from "react-infinite-scroller"
import { useDispatch, useSelector } from "react-redux"

import "./styles.scss"
import Item from "./Components/Item"
import AppBar from "../../../components/AppBar"
import Layout from "../../../components/Layout"
import { ErrorModal } from "../../../components/ErrorModal"
import { fetchDataListMuted, unMuteUser } from "../../../actions/other"
import { LoadingIndicator } from "../../../components/LoadingIndicator"

const HideUsers = () => {
  const dispatch = useDispatch()

  const [errorModal, setErrorModal] = useState(false)
  const [listUser, setListUser] = useState([])
  const [countPage, setCountPage] = useState(1)
  const [hasNextPage, setHasNextPage] = useState(true)
  const [loading, setLoading] = useState(true)
  const perPage = 10

  const error = useSelector((state) => state.likes.error)

  const mutedUsersData = useSelector((state) => state.muted?.data)

  const getUnique = (arr, index) => {

    const unique = arr
         .map(e => e[index])
         .map((e, i, final) => final.indexOf(e) === i && i)
        .filter(e => arr[e]).map(e => arr[e]);      
  
     return unique;
  }

  useEffect(() => {
    let data = []
    if (mutedUsersData) {
      data = listUser.concat(mutedUsersData.users)
      setListUser(getUnique(data, 'user_id'))
      setLoading(false)
    }
  }, [mutedUsersData])

  useEffect(() => {
    dispatch(fetchDataListMuted({ page: countPage, perpage: perPage }))
    setListUser([])
  }, [])

  const loadData = () => {
    if (mutedUsersData) {
      if (mutedUsersData?.users.length < perPage) {
        setHasNextPage(false)
      } else {
        setCountPage(countPage + 1)
        dispatch(fetchDataListMuted({ page: countPage + 1, perpage: perPage }))
      }
    }
    return false
  }

  const handleUnmute = (fromUserId) => {
    dispatch(
      unMuteUser(fromUserId, {
        onSuccess: () => {
          toast("ブロックを解除しました")
          dispatch(fetchDataListMuted({ page: countPage, perpage: perPage }))
          const dataAfterDelete = listUser.filter(
            (elm) => elm.user_id !== fromUserId
          )
          setListUser(dataAfterDelete)
        },
        onFailed: () => {
          setErrorModal(true)
        }
      })
    )
  }

  return (
    <Layout>
      <AppBar title="ブロックリスト" withBack />
      <div className="bg-white d-flex flex-column px-3 wrap-child-setting">
        <div className="border-bottom">
          <div className="ms-3">あなたがブロックしたユーザーです。</div>
        </div>
        {loading ? (
          <LoadingIndicator />
        ) : !_.isEmpty(listUser) ? (
          <div className="users">
            <InfiniteScroll
              pageStart={0}
              loadMore={loadData}
              hasMore={hasNextPage}
              loader={<LoadingIndicator key={0} sm />}
              useWindow={false}
            >
              {listUser?.map((user, index) => (
                <Item user={user} thankButton={handleUnmute} key={index} />
              ))}
            </InfiniteScroll>
          </div>
        ) : (
          <div className="h-100 w-75 d-flex justify-content-center mx-auto align-items-center text-center">
            あなたがブロックしたユーザーはいません。
          </div>
        )}
        <ErrorModal
          show={errorModal}
          content={error}
          onClose={() => setErrorModal(false)}
        />
      </div>
    </Layout>
  )
}

export default HideUsers
