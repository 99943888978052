import { getLikeTime } from "../../../../utils"
import DefaultAvatar from "../../../../images/avatar-app.png"
import moment from "moment"

import "../styles.scss"

const Item = ({ user, thankButton }) => {
  const dateTimeStr = user.muted_at
  let time = null
  let date = null
  if (dateTimeStr) {
    const t = getLikeTime(dateTimeStr)
    time = t.time
    date = t.date
  }

  return (
    <div className="block-item d-flex border-bottom">
      <div className="field field-avatar in-list">
          <img
            className={`avatar ${
              user.payment_type === 4
                ? "vip_4"
                : user.payment_type === 3
                ? "vip_3"
                : user.payment_type === 2
                ? "vip_2"
                : ""
            }`}
            src={user.image_url || DefaultAvatar}
            alt={user.nickname}
          />

          {(user.payment_type === 4 ||
            user.payment_type === 3 ||
            user.payment_type === 2) && (
            <p
              className="vip"
              style={{
                backgroundColor:
                  user.payment_type === 4
                    ? "#00205D"
                    : user.payment_type === 3
                    ? "#5E08B4"
                    : user.payment_type === 2
                    ? "#35ADD9"
                    : "black"
              }}
            >
              VIP
            </p>
          )}
        </div>

      <div className="block-item-info">
        <h3 className="block-item-nickname">{user.nickname}</h3>
        <p className="block-item-age">
          {`${user.age + "歳" || ""} ${user.occupation_name || ""}`}
        </p>
        <p className="block-item-time">{moment(user.muted_at).format("YYYY/MM/DD HH:mm")}</p>
      </div>

      <div className="block-item-action d-flex align-items-end">
        <div
          className="btn btn-outline-danger block-item-button"
          onClick={() => thankButton(user.user_id)}
        >
          解除する
        </div>
      </div>
    </div>
  )
}

export default Item
