import _ from "lodash"
import { createStore, applyMiddleware, compose } from "redux"
import createSagaMiddleware from "redux-saga"

import reducer from "./reducers"
import rooberSaga from "./sagas"

// create the saga middleware
const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware]
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// mount it on the Store
const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(...middlewares))
)

// then run the saga
sagaMiddleware.run(rooberSaga)

// render the application

export default store
