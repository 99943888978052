import Modal from "react-bootstrap/Modal"
import { useState, useContext, useLayoutEffect } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import routes from "../../../constants/routes"
import AppContext from "../../../Context"
import AppBar from "../../../components/AppBar"
import Layout from "../../../components/Layout"
import { addEmail } from "../../../actions/other"
import { LoadingIndicator } from "../../../components/LoadingIndicator"

const Contact = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { setContext } = useContext(AppContext)

  const { profile } = useSelector((state) => state.auth)

  const [mail, setMail] = useState("")
  const [show, setShow] = useState(false)
  const [notice, setNotice] = useState("")

  const handleClose = () => {
    setShow(false)
  }

  const handleSend = () => {
    const checkMail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if (checkMail.test(mail.trim()) === false) {
      setNotice("メールアドレスは正しくありません。")
      setShow(true)
      return false
    }

    setContext("overlay", <LoadingIndicator />)
    const data = {
      email: mail
    }

    dispatch(
      addEmail(data, {
        onSuccess: (response) => {
          setContext("overlay", null)
          history.push({
            pathname: routes.VERIFY_EMAIL,
            state: {
              email: mail
            }
          })
        },
        onFailed: (error) => {
          setContext("overlay", null)
          setNotice("メールアドレスは更新できません。")
          setShow(true)
        }
      })
    )
  }

  useLayoutEffect(() => {
    if (profile.email_for_takeover !== null) {
      setMail(profile.email_for_takeover)
    }
  }, [])

  return (
    <Layout>
      <AppBar title="メールアドレス登録と変更" withBack />
      <div
        className="bg-white d-flex flex-column px-3 wrap-child-setting"
        style={{ marginTop: 63 }}
        id="formInput"
      >
        <p>メールアドレスを入力してください。</p>

        <div className="d-flex flex-column border-bottom p-2 mt-3">
          <label className="yu-gothic-bold">メールアドレス</label>

          <input
            type="text"
            placeholder="●●●＠●●●.com"
            className="border-0 mt-3"
            value={mail}
            onChange={(e) => setMail(e.target.value)}
          />
        </div>

        <button
          className="btn btn-danger p-3 m-2 mt-5"
          onClick={handleSend}
          id="confirmBtn"
        >
          決定
        </button>

        <Modal show={show} onHide={handleClose} centered size="sm">
          <Modal.Body>
            <h6 className="text-center p-3">{notice}</h6>
            <button
              className="btn text-danger w-100 m-0 rounded-0 confirm-button"
              onClick={handleClose}
            >
              OK
            </button>
          </Modal.Body>
        </Modal>
      </div>
    </Layout>
  )
}

export default Contact
