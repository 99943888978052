import { toast } from "react-toastify"
import _ from "lodash"
import { useSelector, useDispatch } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { useEffect, useState, useContext, useCallback } from "react"
import moment from "moment"
import Layout from "../../components/Layout"
import { InputField } from "./components/InputField"
import { MessageList } from "./components/MessageList"
import { ChatNavBar } from "./components/ChatNavBar"
import { ErrorSheet } from "./components/ErrorSheet"
import {
  CONTRACT_TYPE,
  SEX,
  IDENTITY_VERIFICATION_STATUS
} from "../../constants/env"
import {
  getMessageListRequested,
  sendMessageRequested,
  muteUserRequested,
  setCaller,
  setCallee,
  fetchUserDetailRequested,
  setChattingUser,
  setIsCalling,
  setLikeId,
  notifyMessage,
  getMessageTemplateMe,
  getMessageTemplate,
  fetchApiNotiMessageRequested,
  fetchDataListMuted,
  fetchAuthProfileRequest
} from "../../actions"
import { ErrorModal } from "../../components/ErrorModal"
import AppContext from "../../Context"
import { LoadingIndicator } from "../../components/LoadingIndicator"
import {
  getLikeStatus,
  clearUnreadMessages,
  clearUnreadMessageCount
} from "../../utils/firebase"
import { getAvatarUrl } from "../../utils"
// import { onDataReport } from "../../actions/other"
import routes from "../../constants/routes"

import "./styles.scss"

const PrivateChat = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const user = useSelector((state) => state.chat.user)
  const myProfile = useSelector((state) => state.auth.profile)
  const messageList = useSelector((state) => state.chat.messageList)
  const error = useSelector((state) => state.chat.error)
  const activeTab = useSelector((state) => state.message.activeTab)
  const messageInfo = useSelector((state) => state.message.chatList)
  const reason = useSelector((state) => state.videoCall.reason)
  const { messageTemplates, messageTemplatesMe } = useSelector(
    (state) => state.message
  )
  const { id } = useParams()
  const { setContext } = useContext(AppContext)
  var stateUser = messageInfo.filter((messageInfos) => {
    return Number(messageInfos?.id) === user?.user_id
  })

  useEffect(() => {
    const fetchProfile = () => {
      dispatch(
        fetchAuthProfileRequest(
          {},
          {
            onSuccess: () => {},
            onFailed: () => {}
          }
        )
      )
    }

    fetchProfile()
    const intervalId = setInterval(fetchProfile, 3000)

    return () => clearInterval(intervalId)
  }, [dispatch])

  useEffect(() => {
    if (myProfile?.status_cancel === 2) {
      history.push("/chats")
    }
  }, [myProfile, history])

  const [textSearch, setTextSearch] = useState({ value: "" })
  const [errorModal, setErrorModal] = useState({ visible: false })

  const fetchData = useCallback(async () => {
    setContext("overlay", <LoadingIndicator />)
    let likeId = null
    if (myProfile.user_id && (!user || user.user_id !== parseInt(id))) {
      dispatch(
        fetchUserDetailRequested(
          { 
            id,
            isFromPrivateChat: true
          },
          {
            onSuccess: async (data) => {
              likeId = await getLikeStatus(myProfile.user_id, parseInt(id))
              // if (!likeId) {
              //   history.push("/")
              //   return
              // }
              const avatarUrl = getAvatarUrl(data.images)
              dispatch(setChattingUser({ ...data, likeId, avatarUrl }))
              dispatch(getMessageListRequested(likeId, dispatch))
              setContext("overlay", null)
            },
            onFailed: (error) => {
              setContext("overlay", null)
              setContext(
                "overlay",
                <ErrorModal
                  show={true}
                  content="退会済みユーザーです"
                  onClose={() => {
                    setContext("overlay", null)
                    history.replace(`/chats?tab=${activeTab}`)
                  }}
                />
              )
              history.push("/chats")
            }
          }
        )
      )
    } else {
      setContext("overlay", null)
    }
  }, [user, myProfile.user_id, stateUser])

  useEffect(() => {
    return () => {
      dispatch(setChattingUser(null))
    }
  }, [])

  useEffect(() => {
    dispatch(getMessageTemplate())
    dispatch(getMessageTemplateMe({ onSuccess: () => {} }))
  }, [])

  // useEffect(() => {
  //   if (user && myProfile) {
  //     clearUnreadMessages(user.likeId, myProfile.user_id)
  //     clearUnreadMessageCount(user.likeId, myProfile.user_id)
  //   }
  // }, [messageList])

  useEffect(() => {
    fetchData()
  }, [user, myProfile.user_id])

  useEffect(() => {
    if (reason === 0) {
      setErrorModal({
        visible: true,
        content: "ポイントが不足しているためビデオ通話を終了しました。",
        onClose: () => {
          dispatch(setIsCalling(false, null))
        }
      })
    }
  }, [reason])

  const handleSendMessage = (message) => {
    if (myProfile.user_id && (!user || user.user_id === parseInt(id))) {
      dispatch(
        fetchUserDetailRequested(
          { 
            id,
            isFromPrivateChat: true
          },
          {
            onSuccess: async (data) => {
              if (stateUser?.length === 0) {
                history.push("/chats")
              }
              if (message?.message?.trim() === "") {
                return
              }
              message = { ...message, userId: myProfile.user_id }
              dispatch(
                sendMessageRequested(message, user.likeId, () => {
                  setErrorModal({ visible: true, content: error })
                })
              )
              dispatch(notifyMessage(user.user_id))
              if (
                stateUser[0]?.notificationSettings?.is_mail_message === 0 &&
                stateUser[0]?.notificationSettings?.push_notif_message === 1 &&
                !stateUser[0]?.isOffnotificationChat &&
                moment().valueOf() > stateUser[0]?.last_login + 86400
              ) {
                dispatch(fetchApiNotiMessageRequested(user.user_id))
              }
            },
            onFailed: (error) => {
              history.push("/chats");
            }
          }
        )
      )
    } else {
      setContext("overlay", null)
    }
  }

  const handleMuteUser = () => {
    dispatch(
      muteUserRequested(
        { id: user.user_id },
        {
          onSuccess: () => {
            toast("ブロックしました")
            console.log("Mute success: ", user.user_id)
            dispatch(fetchDataListMuted({ page: 1, perpage: 100 }))
            history.goBack()
          },
          onFailed: () => {
            console.log("Mute failed: ", user.user_id)
            setErrorModal({ visible: true, content: error })
          }
        }
      )
    )
  }

  // const handleReport = () => {
  //   dispatch(
  //     onDataReport(user.user_id, {
  //       onSuccess: () => {
  //         setReportModal(true)
  //       },
  //       onFailed: () => {
  //         setErrorModal({ visible: true, content: error })
  //       }
  //     })
  //   )
  // }

  const handleStartVideoCall = () => {
    dispatch(setCaller(myProfile, true))
    dispatch(setCallee(user, true))
    dispatch(setLikeId(user.likeId))
    history.push(`/outgoing-call/${user.user_id}`)
  }

  const handleSearchText = (text) => {
    setTextSearch({ ...textSearch, value: text })
  }

  const countPinned = _.without(
    _.map(messageInfo, function (user) {
      return user.is_pinned ? user : null
    }),
    null
  ).length

  const mutedUsersData = useSelector((state) => state.muted?.data)
  const muteUserChat = mutedUsersData?.users.filter(({ user_id }) => user_id === user?.user_id);
  useEffect(() => {
    dispatch(fetchDataListMuted({ page: 1, perpage: 100 }))
  }, [])

  useEffect(() => {
    if (muteUserChat?.length === 1) {
      return history.push(routes.CHAT)
    }
  }, [mutedUsersData, user])

  const showVideoCall =
    myProfile.sex === 1 &&
    myProfile.contract_type === CONTRACT_TYPE.PAID &&
    myProfile.identity_verification_status ===
      IDENTITY_VERIFICATION_STATUS.APPROVED

  return (
    <Layout>
      {user && (
        <>
          <ChatNavBar
            user={user}
            goBack={() => history.push("/chats")}
            showVideoCall={showVideoCall}
            onHide={handleMuteUser}
            // onReport={handleReport}
            onStartVideoCall={handleStartVideoCall}
            messageInfo={stateUser[0]}
            countPinned={countPinned}
            onSearch={handleSearchText}
          />
          <div className="bg-white MessageList1">
            <MessageList
              messages={messageList}
              avatarUrl={user.avatarUrl}
              currentUserId={myProfile.user_id}
              myProfile={myProfile}
              textSearch={textSearch}
            />
            <InputField
              onSend={handleSendMessage}
              messagesTemplate={messageTemplatesMe?.concat(messageTemplates)}
              userName={user?.nickname}
            />
            {myProfile.contract_type === CONTRACT_TYPE.FREE &&
            myProfile.sex === SEX.MALE ? (
              <ErrorSheet type={1} />
            ) : myProfile.identity_verification_status !==
              IDENTITY_VERIFICATION_STATUS.APPROVED ? (
              myProfile.identity_verification_status ===
                IDENTITY_VERIFICATION_STATUS.NOT_APPLIED_YET ||
              myProfile.identity_verification_status ===
                IDENTITY_VERIFICATION_STATUS.DENIED ? (
                <ErrorSheet type={3} />
              ) : (
                <ErrorSheet type={2} />
              )
            ) : null}
            {errorModal.visible && (
              <ErrorModal
                show={true}
                content={errorModal.content}
                onClose={() => {
                  setErrorModal(false)
                  errorModal.onClose && errorModal.onClose()
                }}
              />
            )}
          </div>
        </>
      )}
    </Layout>
  )
}

export default PrivateChat
