import _ from "lodash"
import { useDispatch, useSelector } from "react-redux"
import React, { useEffect, useState } from "react"

import "./styles.scss"
import Picker from "./images/picker-biger.png"
import PickerSmall from "./images/picker-small.png"
import { fetchAuthProfileRequest } from "../../actions"
import {
  AppBar,
  AvatarsFormGrid,
  Layout,
  LoadingIndicator
} from "../../components"

const Media = () => {
  const dispatch = useDispatch()
  const profile = useSelector((state) => state.auth.profile)
  const loading = useSelector((state) => state.auth.loadingProfile)

  const [browser, setBrowser] = useState("")

  useEffect(() => {
    dispatch(
      fetchAuthProfileRequest(
        {},
        {
          onSuccess: () => {},
          onFailed: () => {}
        }
      )
    )

    const userAgent = navigator.userAgent

    if (userAgent.includes("Chrome")) {
      setBrowser("Chrome")
    } else if (userAgent.includes("Firefox")) {
      setBrowser("Firefox")
    } else if (userAgent.includes("Safari")) {
      setBrowser("Safari")
    }
  }, [])

  return (
    <Layout>
      <AppBar title="写真・動画" backToLink={"/profile"} />
      {loading ? (
        <div className="vh-100">
          <LoadingIndicator />
        </div>
      ) : (
        <>
          {!_.isEmpty(profile) && (
            <AvatarsFormGrid
              user={profile}
              Picker={Picker}
              PickerSmall={PickerSmall}
              browser={browser}
            />
          )}
        </>
      )}
    </Layout>
  )
}

export default Media
