import { Modal } from "react-bootstrap"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import "./styles.scss"
import AppBar from "../../components/AppBar"
import Layout from "../../components/Layout"
import NotiItem from "./components/NotiItem"
import { LoadingIndicator } from "../../components"
import { getNotificationSetting, notificationSetting } from "../../actions"

const NotiSetting = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const profile = useSelector((state) => state.auth.profile)

  const [show, setShow] = useState(false)
  const [loading, setLoading] = useState(false)
  const [activeLike, setActiveLike] = useState(false)
  const [activeFoot, setActiveFoot] = useState(false)
  const [activeOther, setActiveOther] = useState(false)
  const [activeMessage, setActiveMessage] = useState(false)

  useEffect(() => {
    if (profile.email_for_takeover) {
      setLoading(true)
      dispatch(
        getNotificationSetting({
          onSuccess: (response) => {
            if (response.setting.push_notif_like === 1) {
              setActiveLike(true)
            }

            if (response.setting.push_notif_message === 1) {
              setActiveMessage(true)
            }

            if (response.setting.push_notif_others === 1) {
              setActiveOther(true)
            }

            if (response.setting.push_notif_footsprint === 1) {
              setActiveFoot(true)
            }
            setLoading(false)
          },
          onFailed: () => {
            setLoading(false)
          }
        })
      )
    }
  }, [])

  const handleNotiSetting = (type, status) => {
    if (profile.email_for_takeover === null) {
      setShow(true)
    } else {
      const data = {
        type: type,
        status: status
      }

      dispatch(
        notificationSetting(data, {
          onSuccess: (response) => {
            if (response.type === 1) {
              setActiveLike(response.status === 1 ? true : false)
            } else if (response.type === 2) {
              setActiveMessage(response.status === 1 ? true : false)
            } else if (response.type === 3) {
              setActiveOther(response.status === 1 ? true : false)
            } else if (response.type === 4) {
              setActiveFoot(response.status === 1 ? true : false)
            }
          },
          onFailed: () => {}
        })
      )
    }
  }

  return (
    <Layout>
      <AppBar title="通知設定" withBack />
      {loading ? (
        <LoadingIndicator />
      ) : (
        <div className="bg-white d-flex flex-column px-3 pb-3 wrap-child-setting">
          <div className="noti-setting">
            <h2 className="noti-setting-title">通知設定</h2>

            <div className="noti-setting-content">
              <h3 className="noti-setting-heading">▼メール通知</h3>

              <NotiItem
                title="足あとがついた時"
                checked={activeLike}
                setChecked={handleNotiSetting}
                type={1}
              />
              <NotiItem
                title="気になるをもらった時"
                checked={activeMessage}
                setChecked={handleNotiSetting}
                type={2}
              />
              <NotiItem
                title="メッセージを受信した時"
                checked={activeOther}
                setChecked={handleNotiSetting}
                type={3}
              />
              <NotiItem
                title="その他の重要なお知らせ"
                checked={activeFoot}
                setChecked={handleNotiSetting}
                type={4}
              />
            </div>
          </div>
        </div>
      )}

      <Modal show={show} centered size="sm">
        <Modal.Body>
          <h6 className="text-center p-3">メールアドレスを登録してください</h6>
          <button
            className="btn text-danger w-100 m-0 rounded-0 confirm-button"
            onClick={() => {
              setShow(false)
              history.push("/mail-setting")
            }}
          >
            メールアドレスを登録
          </button>
        </Modal.Body>
      </Modal>
    </Layout>
  )
}

export default NotiSetting
