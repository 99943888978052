import _ from "lodash"
import {
  FETCH_RELATED_USERS_SUCCESS,
  FILTER_USERS_SUCCESS,
  GET_FILTER_OPTIONS_SUCCESS,
  GET_RESIDENCE_SUCCESS,
  SELECT_USER
} from "../constants/actions/filter"

const initialState = {
  users: [],
  pages: {},
  options: {},
  currentUserId: null,
  relatedUsers: [],
  hasNext: false,
  hasPrevious: false,
  next: null,
  prev: null
}

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_RESIDENCE_SUCCESS: {
      return {
        ...state,
        options: { ...state.options, residences: action.data }
      }
    }

    case FILTER_USERS_SUCCESS: {
      const newData = {
        ...state,
        users:
          action.data.page == 1
            ? action.data.users
            : [...state.users, ...action.data.users],
        pages:
          action.data.page == 1
            ? { [action.data.page]: _.map(action.data.users) }
            : {
                ...state.pages,
                [action.data.page]: _.map(action.data.users)
              }
      }
      return newData
    }

    case GET_FILTER_OPTIONS_SUCCESS: {
      return {
        ...state,
        options: action.data
      }
    }

    case SELECT_USER:
      let hasNext = false
      let hasPrevious = false
      let prev = null
      let next = null
      for (let i = 0; i < state.users.length; i++) {
        if (state.users[i].user_id === action.payload.userId) {
          if (i > 0) {
            hasPrevious = true
            prev = state.users[i - 1].user_id
          }
          if (i < state.users.length - 1) {
            hasNext = true
            next = state.users[i + 1].user_id
          }
          break
        }
      }
      return {
        ...state,
        currentUserId: action.payload.userId,
        hasNext,
        hasPrevious,
        prev,
        next
      }

    case FETCH_RELATED_USERS_SUCCESS:
      return {
        ...state,
        relatedUsers: action.data.users
      }

    default:
      return state
  }
}

export default filterReducer
