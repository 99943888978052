import { useState } from "react"
import { Modal } from "react-bootstrap"
import Camera,{ FACING_MODES } from "react-html5-camera-photo"
import "react-html5-camera-photo/build/css/index.css"

import { b64toBlob } from "../../../../utils"

import "../styles.scss"

export const PhotoTaker = ({ onClose, onTakePhoto }) => {
  const [blob, setBlob] = useState(null)

  const handleTakePhoto = (dataUri) => {
    const block = dataUri.split(";")
    const contentType = block[0].split(":")[1]
    const realData = block[1].split(",")[1]
    const blob = b64toBlob(realData, contentType)
    setBlob(blob)
  }

  return (
    <Modal show={true} fullscreen={true} onHide={onClose}>
      <Modal.Body className="d-flex flex-column justify-content-center align-items-center">
        {blob ? (
          <img src={URL.createObjectURL(blob)} alt="Not found" />
        ) : (
          <>
            <Camera
              onTakePhoto={(dataUri) => {
                handleTakePhoto(dataUri)
              }}
              idealFacingMode = {FACING_MODES.ENVIRONMENT}
              isImageMirror={false}
            />
            <div className="camera-manual">
              <div className="m-2 yu-gothic-bold">
                枠線に合わせて撮影してください
              </div>
              <div className="rectangle-focus">
              </div>
            </div>
          </>
        )}
        <div
          className="d-flex justify-content-around w-100"
          style={{
            position: "fixed",
            bottom: "3rem",
          }}
        >
          <button className="btn btn-secondary" onClick={onClose}>
            キャンセル
          </button>
          {blob && (
            <button
              className="btn btn-secondary"
              onClick={() => onTakePhoto(blob)}
            >
              年齢確認
            </button>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}
