import { takeLatest, takeEvery, put, call } from "redux-saga/effects"
import {
  getResidencesFailed,
  getResidencesSuccess,
  filterUsersFailed,
  filterUsersSuccess,
  getFilterOptionsSuccess,
  getFilterOptionsFailed,
  fetchRelatedUsersSuccess,
  fetchRelatedUsersFailed
} from "../actions/filter"
import {
  GET_RESIDENCE_REQUEST,
  FILTER_USERS_REQUEST,
  GET_FILTER_OPTIONS_REQUEST,
  FETCH_RELATED_USERS_REQUESTED
} from "../constants/actions/filter"
import {
  getResidences,
  filterUsers,
  getFilterOptions
} from "../services/filter"

function* handleGetResidences(action) {
  const {
    callbacks: { onSuccess }
  } = action.payload || {}
  try {
    const response = yield getResidences(action)
    if (response.data?.status) {
      const { residences } = response.data.data || []
      if (typeof onSuccess === "function") {
        onSuccess(
          residences?.map((res) => {
            return { name: res.name, value: res.residence_id }
          })
        )
      }
      yield put(getResidencesSuccess(residences))
    } else {
      yield put(getResidencesFailed())
    }
  } catch (error) {
    console.log(error)
    yield put(getResidencesFailed())
  }
}

function* handleFilterUsers(action) {
  try {
    const { onSuccess, onFailed } = action?.payload?.callbacks || {}
    const response = yield filterUsers(action.payload, action)
    if (response.status == 200) {
      yield put(filterUsersSuccess(response.data))
      if (typeof onSuccess == "function") {
        yield call(onSuccess, response)
      }
    } else if (response.status == 500) {
      yield put(filterUsersFailed())
    } else {
      yield put(filterUsersFailed())
      if (typeof onFailed == "function") {
        yield call(onFailed, response)
      }
    }
  } catch (err) {}
}

function* handleGetFilterOptions(action) {
  const { onSuccess, onFailed } = action?.payload?.callbacks || {}
  try {
    const response = yield getFilterOptions(action.payload.data, action)
    response.marriages = [
      { marriage_id: 1, name: "指定しない", display_order: 1 },
      { marriage_id: 2, name: "既婚", display_order: 2 },
      { marriage_id: 3, name: "未婚", display_order: 3 },
      { marriage_id: 4, name: "バツあり", display_order: 4 }
    ]
    yield put(getFilterOptionsSuccess(response))
    if (typeof onSuccess == "function") {
      yield call(onSuccess, response)
    }
  } catch (err) {
    yield put(getFilterOptionsFailed())
    if (typeof onFailed == "function") {
      yield call(onFailed, err)
    }
  }
}

function* handleFetchRelatedUsers(action) {
  try {
    const { onSuccess, onFailed } = action?.payload?.callbacks || {}
    const response = yield filterUsers(action.payload, action)
    if (response.status == 200) {
      yield put(fetchRelatedUsersSuccess(response.data))
      if (typeof onSuccess == "function") {
        yield call(onSuccess, response)
      }
    } else if (response.status == 500) {
      yield put(fetchRelatedUsersFailed())
    } else {
      yield put(fetchRelatedUsersFailed())
      if (typeof onFailed == "function") {
        yield call(onFailed, response)
      }
    }
  } catch (err) {
    yield put(fetchRelatedUsersFailed())
  }
}

export default function* watchListDataFilter() {
  yield takeLatest(GET_RESIDENCE_REQUEST, handleGetResidences)
  yield takeLatest(GET_FILTER_OPTIONS_REQUEST, handleGetFilterOptions)
  yield takeEvery(FILTER_USERS_REQUEST, handleFilterUsers)
  yield takeEvery(FETCH_RELATED_USERS_REQUESTED, handleFetchRelatedUsers)
}
