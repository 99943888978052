import { takeLatest, put, call } from "redux-saga/effects"
import moment from "moment"
import {
  retrieveAgoraTokenFailed,
  retrieveAgoraTokenSuccess,
  setAgoraData,
  fetchGiftsSuccess,
  fetchGiftsFailed,
  tippingGiftSuccess,
  tippingGiftFailed,
  purchaseGiftFailed,
  purchaseGiftSuccess,
  sendGiftRequested,
  sendGiftSuccess,
  sendGiftFailed,
  updateVideoCallFailed,
  updateVideoMessageFailed,
  updateVideoMessageSuccess,
  extendVideoCallSuccess,
  extendVideoCallFailed,
  startVideoCallSuccess,
  startVideoCallFailed
} from "../actions"
import {
  RETRIEVE_AGORA_TOKEN_REQUESTED,
  GET_RTM_TOKEN_REQUESTED,
  TIPPING_GIFT_REQUEST,
  FETCH_GIFTS_REQUEST,
  FETCH_GIFT_LIST_REQUESTED,
  SEND_GIFT_REQUESTED,
  PURCHASE_GIFT_REQUESTED,
  UPDATE_VIDEO_CALL_REQUESTED,
  UPDATE_VIDEO_CALL_MSG_REQUESTED,
  EXTEND_VIDEO_CALL_REQUESTED,
  START_VIDEO_CALL_REQUESTED
} from "../constants/actions"
import { HTTP_CODE } from "../constants/env"
import {
  getRTMToken,
  retrieveAgoraToken,
  getListGifts,
  putTippingGift,
  fetchGiftList,
  purchaseGift,
  sendGift,
  extendVideoCall,
  startVideoCall
} from "../services/videoCall"
import { updateVideoCall, updateVideoMessage } from "../utils/firebase"

function* handleRetrieveAgoraToken(action) {
  const { onSuccess, onFailed } = action.callbacks
  try {
    const response = yield retrieveAgoraToken(action.payload.toUserId)
    if (response) {
      if (response.status === 200) {
        yield put(retrieveAgoraTokenSuccess(response.data))
        yield put(setAgoraData(response.data))
        yield call(onSuccess, response.data)
      } else if (response.status === 404) {
        if (
          response.data &&
          response.data.error &&
          response.data.error.code === 4041
        ) {
          yield put(retrieveAgoraTokenFailed(response.data.error.message))
          yield call(onFailed, response.data.error.message)
        } else {
          yield put(
            retrieveAgoraTokenFailed(
              "retrieve agora token failed for some reason"
            )
          )
          yield call(onFailed, "retrieve agora token failed for some reason")
        }
      }
    } else {
      yield put(
        retrieveAgoraTokenFailed("retrieve agora token failed for some reason")
      )
      yield call(onFailed, "retrieve agora token failed for some reason")
    }
  } catch (error) {
    yield put(retrieveAgoraTokenFailed(error.toString()))
    yield call(onFailed, error.toString())
  }
}

function* handleGetRtmToken(action) {
  const { onSuccess, onFailed } = action.callbacks
  const deviceToken = action.payload.deviceToken
  try {
    const response = yield getRTMToken(deviceToken)
    yield call(onSuccess, response)
  } catch (error) {
    console.log("get rtm token failed: ", error)
    yield call(onFailed, error.toString())
  }
}

function* handleGetGifts(action) {
  const { onSuccess, onFailed } = action.callbacks
  try {
    const response = yield getListGifts(action)
    if (response) {
      if (typeof onSuccess === "function") {
        yield call(onSuccess, response)
      }
      yield put(fetchGiftsSuccess(response))
    } else {
      if (typeof onFailed === "function") {
        yield call(onFailed, response)
      }
      yield put(fetchGiftsFailed())
    }
  } catch (error) {
    if (typeof onFailed === "function") {
      yield call(onFailed)
    }
    yield put(fetchGiftsFailed())
  }
}

function* handleTippingGift(action) {
  const { onSuccess, onFailed } = action.callbacks

  try {
    const resPurchase = yield purchaseGift(action)
    if (resPurchase) {
      if (resPurchase.message == "Successfully") {
        action.data.user_gift_id = resPurchase?.data?.user_gift_id
        const response = yield putTippingGift(action)
        if (response) {
          if (typeof onSuccess === "function") {
            yield call(onSuccess, response)
          }
          yield put(tippingGiftSuccess(response.data))
        } else {
          if (typeof onFailed === "function") {
            yield call(onFailed, response)
          }
          yield put(tippingGiftFailed())
        }
      } else {
        if (typeof onFailed === "function") {
          yield call(onFailed, resPurchase)
        }
        yield put(tippingGiftFailed())
      }
    }
  } catch (error) {
    if (typeof onFailed === "function") {
      yield call(onFailed)
    }
    yield put(tippingGiftFailed())
  }
}

function* handleFetchGiftList(action) {
  try {
    const response = yield fetchGiftList()
    console.log("List gift response: ", response)
  } catch (error) {
    console.log("List gift errr: ", error)
  }
}

function* handlePurchaseGift(action) {
  try {
    const { channelId, callerId, calleeId, giftId, amount } = action.payload
    const res = yield purchaseGift(callerId, giftId, amount)
    if (res && res.data) {
      yield put(purchaseGiftSuccess())
      yield put(sendGiftRequested(channelId, calleeId, res.data.user_gift_id))
    } else {
      yield put(purchaseGiftFailed("Something wrong happened"))
    }
  } catch (error) {
    yield put(purchaseGiftFailed(error.toString()))
  }
}

function* handleSendGift(action) {
  try {
    const { channelId, calleeId, userGiftId } = action.payload
    const res = yield sendGift(channelId, calleeId, userGiftId)
    if (res && res.data) {
      yield put(sendGiftSuccess())
    } else yield put(sendGiftFailed("Something wrong happened"))
  } catch (error) {
    yield put(sendGiftFailed(error.toString()))
  }
}

function* handleUpdateVideoCall(action) {
  const { videoCallId, status } = action.payload
  const { onSuccess, onFailed } = action.callbacks
  try {
    const videoCallDoc = yield call(updateVideoCall, videoCallId, status)
    yield call(onSuccess, videoCallDoc)
  } catch (err) {
    yield put(updateVideoCallFailed(err.toString()))
    yield call(onFailed, err.toString())
  }
}

function* handleUpdateVideoCallMessage(action) {
  const { likeId, messageId, message } = action.payload
  try {
    yield call(updateVideoMessage, likeId, messageId, message)
    yield put(updateVideoMessageSuccess())
  } catch (err) {
    yield put(updateVideoMessageFailed(err.toString()))
    console.log("update video message error: ", err)
  }
}

function* handleExtendVideoCall(action) {
  const { channelId, toUserId } = action.payload
  //   const { onSuccess, onFailed } = action.callbacks
  try {
    const res = yield call(extendVideoCall, channelId, toUserId)
    console.log(
      "anhanq. extend video call response: ",
      res.start_datetime,
      res.end_datetime
    )
    if (res.status === HTTP_CODE.OK) {
      //   onSuccess()
      yield put(extendVideoCallSuccess(res.data))
    } else {
      const err = res.data.error.message
      yield put(extendVideoCallFailed(res.data))
      //   onFailed(err)
    }
  } catch (err) {
    yield put(extendVideoCallFailed(err.toString()))
    // onFailed(err.toString())
  }
}

function* handleStartVideoCall(action) {
  console.log(action)
  const { channelId, toUserId } = action.payload
  const { onSuccess, onFailed } = action.callbacks
  try {
    const res = yield call(startVideoCall, channelId, toUserId)
    res.data.end_datetime = moment(res.data.start_datetime).add(2, 'minutes').valueOf()
    if (res.status === HTTP_CODE.OK) {
      yield put(startVideoCallSuccess(res.data))
      yield call(onSuccess, res.data)
    } else {
      yield put(startVideoCallFailed(res.data.error.message))
      yield call(onFailed, res.data.error.message)
    }
  } catch (err) {
    console.log("start video call error: ", err)
    yield put(startVideoCallFailed(err.toString()))
  }
}

export default function* videoCallSaga() {
  yield takeLatest(RETRIEVE_AGORA_TOKEN_REQUESTED, handleRetrieveAgoraToken)
  yield takeLatest(GET_RTM_TOKEN_REQUESTED, handleGetRtmToken)
  yield takeLatest(FETCH_GIFTS_REQUEST, handleGetGifts)
  yield takeLatest(TIPPING_GIFT_REQUEST, handleTippingGift)
  yield takeLatest(FETCH_GIFT_LIST_REQUESTED, handleFetchGiftList)
  yield takeLatest(PURCHASE_GIFT_REQUESTED, handlePurchaseGift)
  yield takeLatest(SEND_GIFT_REQUESTED, handleSendGift)
  yield takeLatest(UPDATE_VIDEO_CALL_REQUESTED, handleUpdateVideoCall)
  yield takeLatest(
    UPDATE_VIDEO_CALL_MSG_REQUESTED,
    handleUpdateVideoCallMessage
  )
  yield takeLatest(EXTEND_VIDEO_CALL_REQUESTED, handleExtendVideoCall)
  yield takeLatest(START_VIDEO_CALL_REQUESTED, handleStartVideoCall)
}
