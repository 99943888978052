import AppBar from "../../../../components/AppBar"
import Layout from "../../../../components/Layout"
import "./styles.scss"

const TermOfServices = () => {
  return (
    <Layout>
      <AppBar title="利用規約" withBack />
      <div className="bg-white d-flex flex-column px-3 wrap-child-setting">
        <p>
          本利用規約（以下「本規約」と言います）には、本サービスの提供条件及びDada株式会社（以下「当社」と言います）と登録ユーザーの皆様との間の権利義務関係が定められています。本サービスの利用に際しては、本規約の全文をお読みいただいたうえで、本規約に同意いただく必要があります。
          <br />
          登録ユーザーは、本規約に同意し、会員登録が完了した時点で、本規約を内容とする本サービス利用契約（以下「本契約」といいます）が当社との間で締結されます。
        </p>

        <h5>第1条（適用）</h5>
        <p>
          &emsp;1
          本規約は、本サービスの提供条件及び本サービスの利用に関する当社と登録ユーザーとの間の権利義務関係を定めることを目的とし、登録ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されます。
          <br />
          &emsp;2
          当社が当社アプリ上で掲載する諸規定（当社の登録ユーザーへの通知、料金プラン、ヘルプ、注意書き、ポイント制度、）は、本規約の一部を構成するものとします。
          <br />
          &emsp;3
          本規約の内容と、前項の諸規定その他の本規約外における本サービスの説明等とが異なる場合は、本規約の規定が優先して適用されるものとします。
          <br />
          &emsp;4
          本規約の各条項が、関連法令に反するとされた場合（法令の改正により反することになった場合を含みます）、当該条項は、その限りにおいて、当該登録ユーザーとの契約には適用されないものとします。但し、この場合でも、本規約の他の条項の効力には影響せず、継続して完全に効力を有するものとします。
          <br />
        </p>

        <h5>第2条（定義）</h5>
        <p>
          &emsp;本規約において使用する以下の用語は、各々如何に定める意味を有するものとします。
          <br />
          &emsp;（１）「本サービス」とは、日本在住の男女を対象とした、18歳以上（但し、18歳であっても高校生は除きます。以下同じ）の方のパートナー探しをサポートすることを目的とするサービスです。但し、本サービスは、パートナーを見つけることを保障するものではありません。
          <br />
          &emsp;（2）「知的財産権」とは、著作権、特許権、実用新案権、意匠権、商標権その他の知的財産権（それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます。）意味します。
          <br />
          &emsp;（3）「投稿データ」とは、登録ユーザーが本サービスを利用し投稿その他送信するコンテンツ（文章、画像、動画その他のデータを含みますがこれらに限りません）を意味します。
          <br />
          &emsp;（４）「登録ユーザー」とは、第3条に基づいて本サービスの利用者として登録された個人を意味します。
          <br />
          &emsp;（5）「ポイント」とは、本サービス内のみで使用でき、当社が有償で発行するポイントになります。
        </p>

        <h5>第3条（登録ユーザー）</h5>
        <p>
          &emsp;1
          本サービスの利用を希望する者は、本規約を遵守することに同意し、かつ当社の定める一定の情報（以下「登録事項」と言います）を当社の定める方法で当社に提供することにより、本サービスの利用の登録を行います。
          <br />
          &emsp;2
          前項に定める登録の完了時に、本契約が登録ユーザーと当社との間で成立し、登録ユーザーは本サービスを本規約に従い利用することができるようになります。
          <br />
          &emsp;3
          当社は、登録ユーザーが、以下の各号に該当することが判明した場合には、登録抹消や再登録の拒否をすることがあり、その理由について一切開示義務を負いません。また、この場合、登録ユーザーに発生した損害について一切の責任を負わないものとします。
          <br />
          &emsp;（1）当社に提供した登録事項の全部または一部につき虚偽、誤記または記載漏れがあった場合
          <br />
          &emsp;（2）<span style={{ color: "red" }}>未成年者</span>
          、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人、保佐人または補助人の同意等を得ていなかった場合
          <br />
          &emsp;（3）反社会的勢力等（暴力団、暴力団員、右翼団体、反社会的勢力、その他これに準ずる者を意味します。以下同じ）である、または資金提供その他を通じて反社会的勢力等の維持、運営もしくは経営に協力もしくは関与する反社会的勢力等との何らかの交流もしくは関与を行っていると当社が判断した場合
          <br />
          &emsp;（4）過去に当社との契約に違反した者またはその関係者である当社が判断した場合
          <br />
          &emsp;（5）その他、登録が適当でないと当社が判断した場合
          <br />
          &emsp;4
          登録ユーザーは、登録事項に変更があった場合、当社の定める方法により当該変更
          <br />
          &emsp;事項を遅滞なく当社に通知するものとします。
        </p>

        <h5>第4条（料金について）</h5>
        <p>
          &emsp;当サービスへの会員登録は<span style={{ color: "red" }}>無料</span>で行うことができます。有料サービスについて、利用に
          あたっての料金及び決済方法については、料金プランをご確認ください。
        </p>

        <h5>第5条（利用資格）</h5>
        <p>
          &emsp;1
          本サービスは、18歳以上のみが利用可能なサービスです。年齢は、当社が会員登録時に登録された生年月日や提供された運転免許証等のデータを元にしており、本サービスの全ての会員が18歳以上であることを当社が保証するものではありません。
          <br />
          &emsp;2
          本サービスの利用により、登録ユーザーは、本契約を締結し本規約と当社の定める条件の全てに従うための権利、権限および能力を有すること、発覚の有無にかかわらずいずれの性的犯罪を犯したことがないことを表明し、保証するものと見なされます。
          <br />
          &emsp;3
          登録後に利用資格の違反が発見された場合、当社の判断で会員登録を無効としますが、当社は、無効とする措置により登録ユーザーに発生した損害について一切の責任を負わないものとします。
          <br />
          &emsp;4
          当社は、登録ユーザーの犯罪経歴調査を行ったり、登録ユーザーの当社への申告の真偽を確認する義務を負いません（そのため、当社は、登録ユーザーの犯罪経歴調査は行わず、登録ユーザーの申告内容を保証するものではありません）が、法令もしくは本規約に定める場合または当社が必要と判断する場合には、いつでもこれらを行うことができるものとします。
        </p>

        <h5>第6条（パスワード及びユーザーIDの管理）</h5>
        <p>
          &emsp;1
          登録ユーザーは、自己の責任において、本サービスに関するパスワード及びユーザー
          <br />
          IDを適切に管理及び保管するものとし、これを第三者に利用させ（実質的に第三者に利用させていると当社が判断する場合を含みます）、または貸与、譲渡、名義変更、売買等をしてはならないものとします。
          <br />
          &emsp;2
          パスワード又はユーザーIDの管理不十分、使用上の過誤、第三者の使用等によって生じた損害に関する責任は登録ユーザーが負うものとし、当社は一切の責任を負いません。
        </p>

        <h5>第7条（サービスの内容の保証及び変更）</h5>
        <p>
          &emsp;1
          当社は、本サービスの内容について、瑕疵（安全性、信頼性、正確性、完全性、有効性、特定の目的への適合性、セキュリティなどに関する欠陥、エラーやバグ、権利侵害などを含みますが、これらに限りませんがないことは保証していません。
          <br />
          &emsp;2
          当社は、登録ユーザーにあらかじめ通知することなく、本サービスの内容や仕様を変更したり、提供を停止したり中止したりすることができるものとします。
          <br />
          &emsp;3
          当社は、本サービスが、全てのＰＣ・スマートフォン（新品・中古品を問いません。以下、同様とします）およびOSに対応し利用できることを保証していません。また、当社は、本サービスに対応するＰＣ・スマートフォンおよびOSを、いつでも、その裁量により、変更、停止、中止、終了することができるものとし、その提供を継続する義務を負わないものとします。
          <br />
          &emsp;4
          本条項により当社が行った措置や、登録ユーザーの環境により本サービスを利用できなかったことで、登録ユーザーに生じた損害について、当社は一切の責任を負いません。
        </p>

        <h5>第8条（禁止事項）</h5>
        <p>
          &emsp;1
          登録ユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為または該当すると当社が判断する行為をしてはなりません。
          <br />
          &emsp;（1）法令に違反する行為または犯罪行為に関連する行為
          <br />
          &emsp;（2）本サービスの他の登録ユーザーに対して性行為の強要
          <br />
          &emsp;（3）当社、本サービスの他の登録ユーザーまたはその他の第三者に対する詐欺、脅迫行為（本サービスを通して知り得た登録ユーザーの場合、本サービス外でのやりとりも対象とする）
          <br />
          &emsp;（4）公序良俗に反する行為
          <br />
          &emsp;（5）本規約に反する行為
          <br />
          &emsp;（6）登録ユーザーのアイコン画像、サブ写真を含む、本サービス上の画像を当社の承諾なくキャプチャーその他の方法により複製、利用又は公開する行為
          <br />
          &emsp;（7）本サービスの他の登録ユーザーまたはその他の第三者の権利を侵害し、または迷惑となるようなものを、投稿、掲載、開示、提供または送信する行為
          <br />
          &emsp;（8）当社、本サービスの他の登録ユーザーまたはその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利または利益を侵害する行為
          <br />
          &emsp;（9）登録ユーザー以外の第三者に自己のユーザIDおよびパスワードを譲渡して、本サービスを利用させる行為
          <br />
          &emsp;（10）第三者に自己のユーザIDおよびパスワードを閲覧可能な状態にしておく行為
          <br />
          &emsp;（11）本サービスに関連して、反社会的勢力等への利益供与
          <br />
          &emsp;（12）本サービスを、本来のサービス提供の目的とは異なる目的で利用する行為
          <br />
          &emsp;（13）第三者の個人情報を公開する行為
          <br />
          &emsp;（14）本サービスの他の登録ユーザーの情報の収集
          <br />
          &emsp;（15）18歳未満（高校生を含む）の登録および本サービスの利用
          <br />
          &emsp;（16）本サービスを通じ、以下に該当し、または該当すると当社が判断する情報を当社または本サービスの登録ユーザーに送信すること
          ・過度に暴力的または残虐な表現を含む情報
          <br />
          ・コンピューター・ウイルスその他の有害なコンピューター・プログラムを含む情報
          <br />
          ・過度にわいせつな表現を含む情報
          <br />
          ・差別を助長する表現を含む情報
          <br />
          ・自殺、自傷行為を助長する表現を含む情報
          <br />
          ・薬物の不適切な利用を助長する表現を含む情報
          <br />
          ・反社会的な表現を含む情報
          <br />
          ・チェーンメール等の第三者への情報の拡散を求める情報
          <br />
          ・違法行為を助長ないし示唆する情報
          <br />
          ・他人に不快感を与える表現を含む情報
          <br />
          &emsp;（17）本サービスの他の登録ユーザーや第三者に成りすます行為
          <br />
          &emsp;（18）虚偽の情報を投稿するなどして、閲覧者を欺く行為
          <br />
          &emsp;（19）選挙運動、またはこれらに類似する行為および公職選挙法に抵触する行為
          <br />
          &emsp;（20）当社が許可した場合を除く、本サービス上の文字、画像、登録ユーザーのニックネーム、アイコン画像、サブ写真、プロフィール情報、自己紹介文、その他の登録ユーザーの情報を無断で使用する行為（モザイク処理をしても、当社が許可をしていない場合は無断使用と見なします）
          <br />
          &emsp;（21）当社が許可した場合を除く、本サービス上において他の登録ユーザーに自己の連絡先や他サービスのID等を連絡・共有する行為
          <br />
          &emsp;（22）当社が提供するソフトウェアその他のシステムに対するリバースエンジニアリングその他の解析行為
          <br />
          &emsp;（23）本サービスの運営を妨害または妨害のおそれのある行為
          <br />
          &emsp;（24）本サービスのネットワークまたはシステム等に過度な負荷をかける行為
          <br />
          &emsp;（25）当社のネットワークまたはシステム等への不正アクセス
          <br />
          &emsp;（26）当社が事前に承諾しない商用目的の宣伝・広告行為、求人行為、引き抜き行為（本サービス以外の別のサービスへの誘導を目的として特定または不特定多数の登録ユーザーに送信ないし投稿する行為）
          <br />
          &emsp;（27）ネットワークビジネス、無限連鎖講、マルチ商法やそれに類するもの（当社がネットワークビジネス、無限連鎖講、マルチ商法やそれに類するものと判断するものを含みます）の営利的活動または販売行為
          <br />
          &emsp;（28）児童ポルノ、またはそれに類する内容、あるいは当社が児童ポルノに類すると断する内容を送信や配信・投稿等を行うこと
          &emsp;（30）本サービスを通じ、以下に該当し、または該当すると当社が判断する情報を配
          <br />
          ・許可を得ないで第三者のプロフィールや写真を使用した配信・投稿 <br />
          ・差別を助長する表現を含む配信・投稿
          <br />
          ・他人への嫌がらせ、または嫌がらせを支持する等
          <br />
          ・違法行為を助長ないし示唆する配信・投稿
          <br />
          ・反社会的な表現を含む配信・投稿
          <br />
          ・チェーンメール等の第三者への情報の拡散を求める配信・投稿
          <br />
          ・過度に暴力的または残虐的な表現を含む配信・投稿
          <br />
          ・虚偽や閲覧者の誤解を招くという表現を含む配信・投稿
          <br />
          ・過度にわいせつな表現を含む配信・投稿
          <br />
          ・他人の知的財産権によって保護された作品を違法または不正にコピーすることを
          <br />
          助長する配信・投稿
          <br />
          ・コンピューター・ウイルスその他の有害なコンピューター・プログラムを含む投稿
          <br />
          ・自殺、自傷行為を助長する表現を含む投稿
          <br />
          ・性的または暴力的な手法で18歳未満の人を不当に扱う投稿
          <br />
          ・本サービスの目的を損なう投稿
          <br />
          ・事前に当社の承諾のないコンテスト、宝くじ、物々交換、宣伝などの投稿
          <br />
          ・他人に不快感を与える表現を含む投稿
          <br />
          ・他人の権利を侵害する投稿
          <br />
          &emsp;（31）GoogleまたはCredix株式会社（以下、「Credix」といいます）の定めた利用規約（ガイドライン、ポリシー、ヘルプ、注意書きその他のGoogleまたはCredixが定める規定を含みます）に違反する行為
          <br />
          &emsp;（32）前各号の行為を直接または間接に惹起し、または容易にする行為
          <br />
          &emsp;（33）前各号の行為を試みること
          <br />
          &emsp;（34）その他当社が不適切であると判断する行為
          <br />
          &emsp;2
          登録ユーザーが前項の禁止行為を行った、又は行うおそれがあると当社が判断した場合、登録ユーザーに通知することなく、当社は以下の行為を行います。
          <br />
          &emsp;（1）前項の禁止事項に抵触ないし抵触のおそれがあると当社が判断したデータの削除
          <br />
          &emsp;（2）登録ユーザーに対して注意を促す通知
          <br />
          &emsp;（3）利用制限
          <br />
          &emsp;（4）強制退会
          <br />
          &emsp;3
          前項の定めに関わらず、当社は、当該データ削除する等を行う義務を負うものではなく、データの削除や利用制限等の処分について当社は説明の義務を負わないものとします。なお、当社は、本条に基づき当社が行った措置に基づき登録ユーザーに生じた損害について一切の責任を負いません。
          <br />
        </p>

        <h5>第9条（本サービスの停止等）</h5>
        <p>
          &emsp;1
          当社は、以下のいずれかに該当する場合には、登録ユーザーに事前に通知することなく、本サービスの全部または一部の提供を停止または中断することができるものとする。
          <br />
          &emsp;（1）本サービスに係るコンピューター・システムの点検または保守作業を緊急に行う場合
          <br />
          &emsp;（2）コンピューター、通信回線等の障害、誤操作、過度なアクセスの集中、不正アクセス、ハッキング等により本サービスの運営ができなくなった場合
          <br />
          &emsp;（3）地震、落雷、火災、風水害、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合
          <br />
          &emsp;（4）その他、当社が停止または中断を必要と判断した場合
          <br />
          &emsp;2
          当社は、本条に基づき当社が行った措置に基づき登録ユーザーに生じた損害について一切の責任を負いません。
          <br />
        </p>

        <h5>第10条（配信・投稿等の保証等）</h5>
        <p>
          &emsp;1
          登録ユーザーが本サービスで配信・投稿等した内容および本サービスを通して登録ユーザーが他の登録ユーザーに送信等をした内容については、登録ユーザーが単独で責任を負うものとし、当社は一切責任を負いません。
          <br />
          &emsp;2
          登録ユーザーが本サービスに関連して配信・投稿等した場合はいつでも、その内容が正確であり、本契約に違反せず、かつ当社、他の登録ユーザーやその他の第三者に対し損害を生じさせるものでないことを、登録ユーザーは表明し、保証したことになります。
          <br />
          &emsp;3
          本サービス上での送信や配信・投稿等のコミュニケーション手段は当社の管理するシステムを利用して提供されます。そのため、当社は、登録ユーザー同士の送信内容や投稿内容を当社が把握できる仕様となっており、当社が本サービスを適正に運営するために、登録ユーザーは、当社による本サービス上での送信や配信・投稿等に関する巡回・監視について了解したものとします。
          <br />
          ただし、当社は、巡回・監視する義務を負うものではありません。
        </p>

        <h5>第11条（登録抹消等）</h5>
        <p>
          &emsp;1
          本規約8条以外に、当社は、登録ユーザーが、以下の各号のいずれかに該当する場合には、事前に通知または催告することなく、配信・投稿データ等を削除もしくはブロックし、当該登録ユーザーについて本サービスの利用を一時的に停止し、または登録ユーザーとしての登録を抹消することができます。
          <br />
          &emsp;（1）本規約のいずれかの条項に違反した場合
          <br />
          &emsp;（2）登録事項に虚偽の事実があることが判明した場合
          <br />
          &emsp;（3）12ヶ月以上本サービスの利用がない場合
          <br />
          &emsp;（4）当社からの問い合わせその他の回答を求める連絡に対し30日間以上応答がない場合
          <br />
          &emsp;（5）第3条3項各号に該当する場合
          <br />
          &emsp;（6）その他、当社が本サービスの利用または登録ユーザーとしての登録の継続を適当でないと判断した場合
          <br />
          &emsp;2
          前項各号のいずれかの事由に該当した場合、登録ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
          <br />
          &emsp;3
          利用停止や登録抹消を行われた登録ユーザーについて、有料サービスの利用期限が残っていたり、ポイントが残っていたとしても、当社は返金等の精算は一切行いません。
        </p>

        <h5>第12条（ポイント）</h5>
        <p>
          &emsp;1
          登録ユーザーは、当社が定めるポイントを取得し、本サービスのために利用することができます。ポイント制度、ポイント取得方法及びポイントの利用方法については、ポイント制度をご確認ください。
          <br />
          &emsp;2
          前項に規定するポイントについては、登録中、有効期限は無期限となります。
          <br />
          &emsp;3
          ポイントは、ポイント制度を廃止する場合や法令上必要となる場合を除き、当社は払い戻しをしません。
          <br />
          &emsp;4
          他の登録ユーザーやその他第三者にポイントを使用させ、または貸与、譲渡、売買、質入等をすることはできないものとします。
        </p>

        <h5>第13条（配信について）</h5>
        <p>
          &emsp;1
          登録ユーザーは、本サービスの配信機能を利用して、配信者として配信を行うことができます。配信は、配信を行う登録ユーザーの責任により、本規約に従って行うものであり、当社は配信当事者ではありません。
          <br />
          &emsp;2
          登録ユーザーは、他の登録ユーザーの配信を閲覧して、ポイントを利用して、配信を行う登録ユーザーを応援・評価することができます。但し、応援・評価に利用したポイントの価格相当額が、配信を行う登録ユーザーに付与されるわけではありません。
          <br />
          &emsp;3
          当社は、登録ユーザーが行う配信について、その内容や閲覧者による評価その他一切の要素を考慮して、当社所定の方法による評価により、配信を行う登録ユーザーに対して本サービスのコンテンツ提供の分配金を支払うものとします。なお、分配金の支払に必要な振込手数料その他一切の費用は、配信を行う登録ユーザーが負担するものとします。
          <br />
          &emsp;4
          配信を行う登録ユーザーは、分配金を受け取ろうとするときは、あらかじめ当社に対して当社所定の方法により振込先口座（日本国内の金融機関に限ります）を届け出るものとします。なお、当社が必要と判断した場合には、配信を行う登録ユーザーに対して更に必要な書類・資料の提出を求めることができるものとします。
          <br />
          &emsp;5
          当社は、分配金を、前項により届け出られた振込先口座に振り込む方法により支払います。支払いは、分配金がある場合、当月分を翌々月15日（土日祝の場合は翌営業日）に行います。
          <br />
          &emsp;6
          次の各号に掲げる事由に該当する場合には、当社は、配信を行う登録ユーザーに対して分配金の支払を行わないものとし、当社が分配金を既に配信を行う登録ユーザーに対して支払済みである場合には、配信を行う登録ユーザーは、当社に対して直ちに支払いを受けた分配金を返還しなければならないものとします。
          <br />
          &emsp;（１）配信を行う登録ユーザーが本規約等に違反した場合
          <br />
          &emsp;（２）配信を行う登録ユーザーが配信により不正の利益を上げている場合
          <br />
          &emsp;（３）その他当社が分配金の支払が適切でないと判断した場合
          <br />
          &emsp;7
          配信を行う登録ユーザーが届け出た振込先口座情報が誤っているなどして、当社が分配金の支払いをできない場合には、分配金の支払いを当社は留保できますが、その支払留保について、当社は利息や遅延損害金等の支払義務を負いません。
        </p>

        <h5>第14条（利用制限）</h5>
        <p>
          本サービス自体や本サービスを利用する登録ユーザーを守るために、本サービス上で登録ユーザーが他の登録ユーザーに24時間以内に送ることができるメッセージの数を、当社の判断で適切な数に制限する権利および、本サービスに新たに登録をしようとする者に対して、当該登録を拒否する権利を当社は有します。
        </p>

        <h5>第15条（本サービスの終了）</h5>
        <p>
          &emsp;1
          当社は、当社の都合により、本サービスの提供を終了することができます。
          <br />
          &emsp;2
          当社が本サービスの提供を終了する場合、当社は登録ユーザーに事前に通知するものとします。
          <br />
          &emsp;3
          本条により本サービスの提供が終了した場合、有料サービスについて登録ユーザーが購入した利用期間が残っていたり、ポイントが残っていたとしても、当社は一切返金や返金に変わる代償措置は行わず、登録ユーザーは当社に対して何らの請求を行うことができません。
        </p>

        <h5>第16条（権利帰属）</h5>
        <p>
          &emsp;1
          当社ウェブサイト及び本サービスに関する知的財産権は全て当社または当社にライセンスを許諾している者に帰属しており、本規約に基づく本サービスの利用許諾は、当社のウェブサイトまたは本サービスに関する当社または当社にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。
          <br />
          登録ユーザーは無断で複製、譲渡、貸与、翻訳、改変、転載、公衆送信（送信可能化を含みます）、伝送、配布、出版、営業使用等をしてはならないものとします。
          <br />
          &emsp;2
          登録ユーザーは、配信・投稿データについて、自らが配信・投稿その他の送信することについての適法な権利を有していること、及び配信・投稿データが第三者の権利を侵害していないことについて、当社に対して表明し、保証するものとします。
          <br />
          当社は、登録ユーザーの投稿データにより、他の登録ユーザーやその他の第三者に生じた損害について一切の保証をせず、投稿を行った登録ユーザーが解決するものとします。
          <br />
          &emsp;3
          登録ユーザーによる配信・投稿データについては、登録ユーザーまたは当該配信・投稿データの著作権者に著作権が帰属します。投稿データについて、登録ユーザーは当社に対して、日本の国内外で無償かつ非独占的に利用（複製、上映、公衆送信、展示、頒布、譲渡、貸与、翻訳、翻案、出版を含みます）する権利を期限の定めなく許諾（サブライセンス権を含みます）したものとみなします。ただし、登録ユーザー間の送信内容についてはプライバシーを尊重し、本条の許諾対象には含まないものとします。
          <br />
          &emsp;4
          登録ユーザーは、当社及び当社から権利を承継しまたは許諾された者に対して著作者人格権を行使しないことに同意するものとします。
        </p>

        <h5>第17条（知的財産権の侵害の情報提供について）</h5>
        <p>
          &emsp;1
          登録ユーザーは、知的財産権等の権利者から事前の同意を得なければ、知的財産権等によって保護された情報を、配信・投稿等をすることは一切してはいけません。
          <br />
          &emsp;2
          他の登録ユーザーの配信・投稿等が他人の知的財産権等を侵害することを発見した場合は、カスタマーサポートセンターにメールで情報を提供してください。
        </p>

        <h5>第18条（データの取り扱い）</h5>
        <p>
          &emsp;当社の本サービスの保守や改良などの必要が生じた場合には、当社は登録ユーザーが当社の管理するサーバーに保存しているデータを、登録ユーザーの同意なく、本サービスの保守や改良などに必要な範囲で複製・改変等することができるものとします。
        </p>

        <h5>第19条（本契約の期間）</h5>
        <p>
          &emsp;登録ユーザーが、本利用規約に同意のうえ登録し、本サービスを利用し、または本サービスの登録ユーザーである限り、本契約は有効です。本サービスではクーリングオフは認められません。
        </p>

        <h5>第20条（退会）</h5>
        <p>
          &emsp;1
          登録ユーザーは、当社所定の手続きの完了により、本サービスから退会し、自己の登録ユーザーとしての登録を抹消することができます。
          <br />
          &emsp;2
          退会にあたり、当社に対して負っている債務がある場合は、登録ユーザーは、当社に対して負っている債務の一切について当然に期限の利益を失い、直ちに当社に対して全ての債務の支払を行わなければなりません。
          <br />
          &emsp;3
          退会後の登録ユーザーの情報の取扱いについては、第23条の規定に従うものとします。
          <br />
          &emsp;4
          登録ユーザーが本サービスを退会した場合、有料サービスも終了となるが、終了以前に登録ユーザーが購入した有料サービスの利用期限が残っていても、日割りでの返金はしないものとします。
          <br />
          &emsp;5
          登録ユーザーが本サービスを退会した場合、ポイントが残っていても、精算はしないものとします。
        </p>

        <h5>第21条（目的外使用）</h5>
        <p>
          &emsp;登録ユーザーが、本サービスやそれらを構成するデータを、本サービスの提供目的を超え、または本規約に違反して利用した場合、当社は、それらの行為を差し止める権利ならびに、当社が被った損害賠償請求以外に、それらの行為によって登録ユーザーが得た利益相当額を請求する権利を有します。
        </p>

        <h5>第22条（端末の紛失等による登録ユーザーからの利用停止）</h5>
        <p>
          &emsp;ご利用の携帯電話端末を紛失・盗難された場合その他必要がある場合、登録ユーザーは、当社に申し出ることにより、当社所定の方法による本人確認書類の提出を条件として、本サービスの利用を停止することができます。ただし、当社は、登録ユーザーの当該申出の有無にかかわらず、本サービスの利用停止がされないことおよび登録ユーザーのアカウントを第三者が利用したこと等により、登録ユーザーに損害が生じた場合であっても、これについて一切の責任を負いません。また、登録ユーザーの自身の携帯電話端末を第三者に譲渡、売却された場合も同様に当社は一切の責任を負いません。
        </p>

        <h5>第23条（秘密保持）</h5>
        <p>
          &emsp;1
          登録ユーザーは、本サービスに関連して当社が登録ユーザーに対して秘密に取り扱うことを求めて開示した非公知の情報について、当社の事前の書面による承諾がある場合を除き、秘密に取り扱うものとします。
          <br />
          &emsp;2
          前項に登録ユーザーが反した場合、当社が被った損害（弁護士費用を含みます）について、登録ユーザーは賠償するものとします。
        </p>

        <h5>第24条（利用者情報の取り扱い）</h5>
        <p>
          &emsp;1
          当社による登録ユーザーの利用者情報の取り扱いについては、別途当社プライバシーポリシーの定めによるものとし、登録ユーザーはこのプライバシーポリシーに従って当社が登録ユーザーの利用者情報を取り扱うことについて同意するものとします。
          <br />
          &emsp;2
          当社は、登録ユーザーが当社に提供した情報、データ等、個人を特定できない形での統計的情報として、当社の裁量で、利用及び公開することができるものとし、登録ユーザーはこれに異議を唱えないものとします。
        </p>

        <h5>第25条（当社への賠償）</h5>
        <p>
          &emsp;登録ユーザーの行為が原因で生じたクレームなどに関連して、当社に損害が発生した場合には、登録ユーザーは当社が被った損害（当社が信用・イメージ回復のために要した費用や当社が支払った弁護士費用を含みます）を賠償するものとします。
        </p>

        <h5>第26条（登録ユーザー等間の紛争）</h5>
        <p>
          &emsp;1
          登録ユーザーは、自己の責任に基づき本サービスを利用するものとし、本サービスの他の登録ユーザーとの交流に関しては、単独で責任を負うものとします。当社は、登録ユーザーと他の登録ユーザーとの間で起きた紛争について、情報収集や登録ユーザーに対して回答を求めたりすることはできますが、それらを行う義務はありません。
          <br />
          &emsp;2
          登録ユーザーは、当該登録ユーザーによる本サービスの利用に関して、当社が他の登録ユーザーを含む第三者からクレーム等を受けた場合には、自己の責任と費用においてこれを処理解決するものとします。
        </p>

        <h5>第27条（本規約の変更）</h5>
        <p>
          &emsp;当社は、当社が必要と認めた場合は、本規約を変更できるものとします。本規約を変更する場合、変更後の本規約の施行時期及び内容を当社ウェブサイト上での掲示その他の適切な方法により周知し、または登録ユーザーに通知します。但し、法令上、登録ユーザーの同意が必要となるような内容の変更の場合は、当社所定の方法で登録ユーザーの同意を得るものとします。
        </p>

        <h5>第28条（連絡・通知）</h5>
        <p>
          &emsp;1
          本サービスに関する問い合わせその他登録ユーザーから当社に対する連絡または通知、及び本規約の変更に関する通知その他当社から登録ユーザーに対する連絡または通知は、当社の定める方法で行うものとします。
          <br />
          &emsp;2
          当社が登録事項に含まれるメールアドレスその他の連絡先に連絡または通知を行った場合、登録ユーザーは当該連絡または通知を受領したものとみなします。
        </p>

        <h5>第29条（Google及びCredixの要件）</h5>
        <p>
          &emsp;1
          当社は、登録ユーザーがGoogleの利用規約に違反した結果、GoogleまたはCredixを通じて本サービスを利用できなくなってしまっても、責任を負いません。
          <br />
          &emsp;2
          当社は、登録ユーザーがいかなる理由であれGoogleまたはCredixのアカウントを利用できなくなった結果、本サービスを利用できなくなってしまっても、責任を負いません。
          <br />
          &emsp;3
          前項及び前々項の場合、登録ユーザーは、当社所定の手続きをするものとし、当該手続きを行わなかったことにより利用料金や損害が発生した場合でも、当社は一切の責任を負いません。また、有料サービスの利用期間が残っていたり、ポイントが残っていたとしても、当社は返金等の精算は一切行わず、登録ユーザーは当社に対して何らの請求をできません。
        </p>

        <h5>第30条（携帯電話等の端末の変更による引継ぎ）</h5>
        <p>
          &emsp;1
          登録時に利用した携帯電話端末または電話番号を変更する場合、当社所定の方法により、本サービスに係るデータの引継手続を行ってください。
          <br />
          &emsp;2
          当該手続を行わずに携帯電話端末または電話番号を変更した場合、変更前の本サービスを利用できない場合がありますのでご注意ください。登録ユーザーが携帯電話端末または電話番号を変更されたことで本サービスを利用できなくなってしまっても、当社は一切の責任を負いません。また、上記で定める方法によって本サービスに係るデータを引き継ぐことができなかった場合であっても、当社は一切の責任を負いません。
        </p>

        <h5>第31条（免責事項）</h5>
        <p>
          &emsp;1
          当社の債務不履行責任および不法行為責任は、当社の故意または重過失によらない場合には免責されるものとします。但し、本契約が消費者契約法に定める消費者契約に該当する場合、上記の免責は適用されないものとし、当社は、当社の故意または重過失に起因する場合を除き、通常生じうる損害の範囲内で、かつ、有料サービスにおいては代金額（継続的なサービスの場合は1か月相当額）を上限として損害賠償責任を負うものとします。
          <br />
          &emsp;2
          当社は、当サービスが登録ユーザーの特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、登録ユーザーによる本サービスの利用が登録ユーザーに適用のある法令に適合することおよび不具合が生じないことについて、何ら保証するものではありません。
          <br />
          &emsp;3
          当社、当社の提携企業や登録ユーザーが、本サービスを通じて投稿等する内容について、当社はその正確さや信頼性等を表明し保証するものではありません。これらの情報を信頼することについては、登録ユーザーの自己責任になることを登録ユーザーは予め同意するものとします。
          <br />
          &emsp;4
          本サービス内でも本サービス外でも、登録ユーザーの行為について当社は責任を負いません。本サービスを利用される際は、登録ユーザー自身で十分注意をしてください。
          <br />
          &emsp;5
          本サービスは、日本国内においてのみ使用されることを想定しており、当社は、日本国外において本サービスの利用ができることを保証しないものとし、当社は、日本国外における本サービスの利用について、一切のサポートを提供する義務を負わないものとします。
          <br />
          &emsp;6
          当社は、登録ユーザーが他の登録ユーザーに送信した内容や、登録ユーザーが投稿した内容について、いかなる責任も負わないものとします。
        </p>

        <h5>第32条（サービス利用契約上の地位の譲渡等）</h5>
        <p>
          &emsp;1
          登録ユーザーは、利用契約上の地位又は本規約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることができません。
          <br />
          &emsp;2
          当社は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い利用契約上の地位、本規約に基づく権利及び義務並びに登録ユーザーの登録事項その他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、登録ユーザーは、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。
        </p>

        <h5>第33条（本サービスの利用上の注意）</h5>
        <p>
          &emsp;本サービスは、真剣に交際相手を探している方が最適なパートナーと知り合う機会・可能性を提供するサービスですが、不特定多数の方がご利用になっているために場合によってはサービスを悪用されたり、あるいは適切なコミュニケーションがとれない方が利用されたりする可能性もあります。
          <br />
          &emsp;それらの可能性を十分ご認識のうえ、本サービスの利用に際して他人に対してどのような個人情報を開示するか、どのようなやりとりをするかについては、登録ユーザーご自身で常に慎重にお考えいただいたうえで本サービスを利用してください。
          <br />
          &emsp;本利用規約違反の疑いがある登録ユーザーを発見した場合は、すみやかにカスタマー
          <br />
          &emsp;サポートセンターにメールで情報を提供してください
        </p>

        <h5>第34条（刑事告訴等の法的措置）</h5>
        <p>
          当社は、本サービスが関連法令に適合するように活動し、登録ユーザーが本サービスの目的を損なう利用を行い、関連法令に反していることが判明した場合には、刑事告訴を含め法的措置をとり、本サービスの健全化に努めます。
        </p>

        <h5>第35条</h5>
        <p>
          &emsp;1 本規約の準拠法は日本法とします。
          <br />
          &emsp;2
          本規約または本サービスに起因し、または関連する一切の紛争については、神戸地方裁判所を第一審の専属的合意管轄裁判所とします。
        </p>
      </div>
    </Layout>
  )
}

export default TermOfServices
