export const FETCH_POPUPS_REQUEST = "FETCH_POPUPS_REQUEST"
export const FETCH_POPUPS_SUCCESS = "FETCH_POPUPS_SUCCESS"
export const FETCH_POPUPS_FAILURE = "FETCH_POPUPS_FAILURE"

export const fetchPopupsRequest = () => ({
  type: FETCH_POPUPS_REQUEST,
})

export const fetchPopupsSuccess = (data) => ({
  type: FETCH_POPUPS_SUCCESS,
  payload: data,
})

export const fetchPopupsFailure = (error) => ({
  type: FETCH_POPUPS_FAILURE,
  payload: error,
})