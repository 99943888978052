import { takeEvery, put, call } from "redux-saga/effects"
import { fetchPointPackageSuccess, fetchPointPackageFailed, purchasePointSuccess, purchasePointFailed } from "../actions/buy-point"
import { FETCH_POINT_PACKAGE_REQUEST, PURCHASE_POINT_REQUEST } from "../constants/actions/buy-point"
import { getPointPackages, postPurchase } from "../services/buy-point"

function* handleGetPointPackages(action) {
  const { onSuccess, onFailed } = action.callbacks
  try {
    const response = yield getPointPackages(action)
    if (response) {
      if (typeof onSuccess === "function") {
        yield call(onSuccess, response)
      }
      yield put(fetchPointPackageSuccess(response))
    } else {
      if (typeof onFailed === "function") {
        yield call(onFailed, response)
      }
      yield put(fetchPointPackageFailed())
    }
  } catch (error) {
    if (typeof onFailed === "function") {
      yield call(onFailed)
    }
    yield put(fetchPointPackageFailed())
  }
}

function* handlePostPurchase(action) {
  const { onSuccess, onFailed } = action.callbacks

  try {
    const response = yield postPurchase(action)
    if (response) {
      if (typeof onSuccess === "function") {
        yield call(onSuccess, response)
      }
      yield put(purchasePointSuccess(response.data))
    } else {
      if (typeof onFailed === "function") {
        yield call(onFailed, response)
      }
      yield put(purchasePointFailed())
    }
  } catch (error) {
    if (typeof onFailed === "function") {
      yield call(onFailed)
    }
    yield put(purchasePointFailed())
  }
}

export default function* watchMyPage() {
  yield takeEvery(FETCH_POINT_PACKAGE_REQUEST, handleGetPointPackages)
  yield takeEvery(PURCHASE_POINT_REQUEST, handlePostPurchase)
}
