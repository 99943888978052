import {
  GET_MATCH_LIST_REQUESTED,
  GET_MATCH_LIST_SUCCESS,
  GET_MATCH_LIST_FAILED,
  GET_CHAT_LIST_REQUESTED,
  GET_CHAT_LIST_SUCCESS,
  GET_CHAT_LIST_FAILED,
  GET_UNREAD_MATCH_COUNT_REQUESTED,
  GET_UNREAD_MATCH_COUNT_SUCCESS,
  GET_UNREAD_MATCH_COUNT_FAILED,
  GET_UNREAD_MESSAGE_COUNT_REQUESTED,
  GET_UNREAD_MESSAGE_COUNT_SUCCESS,
  GET_UNREAD_MESSAGE_COUNT_FAILED,
  CLEAR_UNREAD_MATCH_COUNT_REQUESTED,
  CLEAR_UNREAD_MATCH_COUNT_SUCCESS,
  CLEAR_UNREAD_MATCH_COUNT_FAILED,
  CLEAR_UNREAD_MESSAGE_COUNT_REQUESTED,
  CLEAR_UNREAD_MESSAGE_COUNT_SUCCESS,
  CLEAR_UNREAD_MESSAGE_COUNT_FAILED,
  SET_ACTIVE_TAB,
  GET_MESSAGE_TEMPLATE_REQUESTED,
  GET_MESSAGE_TEMPLATE_SUCCESS,
  GET_MESSAGE_TEMPLATE_FAILED,
  GET_MESSAGE_TEMPLATE_ME_REQUESTED,
  GET_MESSAGE_TEMPLATE_ME_FAILED,
  GET_MESSAGE_TEMPLATE_ME_SUCCESS,
  CREATE_AND_UPDATE_MESSAGE_TEMPLATE,
  CREATE_AND_UPDATE_MESSAGE_TEMPLATE_FAILED,
  CREATE_AND_UPDATE_MESSAGE_TEMPLATE_SUCCESS,
  DELETE_MESSAGE_TEMPLATE,
  DELETE_MESSAGE_TEMPLATE_FAILED,
  DELETE_MESSAGE_TEMPLATE_SUCCESS,
  GET_HIDE_LIST_REQUESTED,
  GET_HIDE_LIST_SUCCESS,
  GET_HIDE_LIST_FAILED,
} from "../constants/actions"

const initialState = {
  error: null,
  activeTab: 1,
  isLoading: false,
  matchList: [],
  chatList: [],
  hideList: [],
  messageTemplates: [],
  messageTemplatesMe: [],
  unreadMatchCount: 0,
  unreadMessageCount: 0
}

const messageReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_UNREAD_MESSAGE_NUMBER':
      return {
        ...state,
        unreadMessageNumber: action.payload.count
      }
    case GET_MATCH_LIST_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case GET_MATCH_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        matchList: action.payload.users,
        error: null
      }
    case GET_MATCH_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case GET_CHAT_LIST_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case GET_CHAT_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        chatList: action.payload.users,
        error: null
      }
    case GET_CHAT_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case GET_HIDE_LIST_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case GET_HIDE_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hideList: action.payload.users,
        error: null
      }
    case GET_HIDE_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case GET_UNREAD_MATCH_COUNT_REQUESTED:
      return {
        ...state,
        isLoading: true,
        unreadMatchCount: 0,
        error: null
      }
    case GET_UNREAD_MATCH_COUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        unreadMatchCount: action.payload.count,
        error: null
      }
    case GET_UNREAD_MATCH_COUNT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case GET_UNREAD_MESSAGE_COUNT_REQUESTED:
      return {
        ...state,
        isLoading: true,
        unreadMessageCount: 0,
        error: null
      }
    case GET_UNREAD_MESSAGE_COUNT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        unreadMessageCount: action.payload.count,
        error: null
      }
    case GET_UNREAD_MESSAGE_COUNT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case CLEAR_UNREAD_MATCH_COUNT_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case GET_MESSAGE_TEMPLATE_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case GET_MESSAGE_TEMPLATE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case GET_MESSAGE_TEMPLATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        messageTemplates: action.payload.messages,
        error: null
      }
    case GET_MESSAGE_TEMPLATE_ME_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case GET_MESSAGE_TEMPLATE_ME_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case GET_MESSAGE_TEMPLATE_ME_SUCCESS:
      return {
        ...state,
        isLoading: false,
        messageTemplatesMe: action.payload.messages,
        error: null
      }
    case CREATE_AND_UPDATE_MESSAGE_TEMPLATE:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case CREATE_AND_UPDATE_MESSAGE_TEMPLATE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case CREATE_AND_UPDATE_MESSAGE_TEMPLATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null
      }
    case DELETE_MESSAGE_TEMPLATE:
      return {
        ...state,
        isLoading: false,
        error: null
      }
    case DELETE_MESSAGE_TEMPLATE_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case DELETE_MESSAGE_TEMPLATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null
      }
    case CLEAR_UNREAD_MATCH_COUNT_SUCCESS:
      return { ...state, isLoading: false, error: null }
    case CLEAR_UNREAD_MATCH_COUNT_FAILED:
      return { ...state, isLoading: false, error: action.payload.error }
    case CLEAR_UNREAD_MESSAGE_COUNT_REQUESTED:
      return { ...state, isLoading: true, error: null }
    case CLEAR_UNREAD_MESSAGE_COUNT_SUCCESS:
      return { ...state, isLoading: false, error: null }
    case CLEAR_UNREAD_MESSAGE_COUNT_FAILED:
      return { ...state, isLoading: false, error: action.payload.error }
    case SET_ACTIVE_TAB:
      return { ...state, activeTab: action.payload.tabIndex }
    default:
      return state
  }
}

export default messageReducer
