import {
	FETCH_POINT_PACKAGE_REQUEST,
	FETCH_POINT_PACKAGE_SUCCESS,
	FETCH_POINT_PACKAGE_FAILED,
  PURCHASE_POINT_REQUEST,
  PURCHASE_POINT_SUCCESS,
  PURCHASE_POINT_FAILED
} from "../constants/actions/buy-point"
  
  export const fetchPointPackageRequest = (callbacks) => {
    return {
      type: FETCH_POINT_PACKAGE_REQUEST,
      payload: {
        data: {},
      },
      callbacks,
    }
  }
  export const fetchPointPackageSuccess = (data) => {
    return {
      type: FETCH_POINT_PACKAGE_SUCCESS,
      data
    }
  }
  
  export const fetchPointPackageFailed = (data) => {
    return {
      type: FETCH_POINT_PACKAGE_FAILED,
			data
    }
  }

  export const purchasePointRequest = (data, callbacks) => {
    return {
      type: PURCHASE_POINT_REQUEST,
      data,
      callbacks,
    }
  }
  export const purchasePointSuccess = (data) => {
    return {
      type: PURCHASE_POINT_SUCCESS,
      data
    }
  }
  
  export const purchasePointFailed = (data) => {
    return {
      type: PURCHASE_POINT_FAILED,
			data
    }
  }
  