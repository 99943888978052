import { Modal } from "react-bootstrap"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import "./style.scss"
import {
  deleteMessageTemplate,
  getMessageTemplate,
  getMessageTemplateMe
} from "../../actions"
import routes from "../../constants/routes"
import { AppBar, Layout, LoadingIndicator } from "../../components"

const MessageTemplate = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const { messageTemplates, isLoading } = useSelector((state) => state.message)

  const [open, setOpen] = useState(false)
  const [messageItem, setMessageItem] = useState(null)
  const [activeButton, setActiveButton] = useState(false)
  const [messageDisplay, setMessageDisplay] = useState([])

  useEffect(() => {
    dispatch(getMessageTemplate())
    dispatch(
      getMessageTemplateMe({
        onSuccess: (response) => {
          setMessageDisplay(response)
        }
      })
    )
  }, [dispatch])

  const handleClose = () => {
    setOpen(false)
  }

  const handleDeleteMessage = () => {
    dispatch(
      deleteMessageTemplate(
        { messageId: messageItem.id },
        {
          onSuccess: (response) => {
            const newMessages = messageDisplay.filter(
              (item) => item.id !== response
            )
            setMessageDisplay(newMessages)
          },
          onFailed: () => {}
        }
      )
    )
  }

  return (
    <Layout>
      <AppBar title="定型文" backToLink="/profile" />
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <div className="bg-white message-template">
          <div className="message-template-heading">
            <h3 className="message-template-heading-text">オリジナル定型文</h3>
          </div>

          {messageDisplay.map((message) => (
            <div key={message.id} className="message-template-item">
              <div className="d-flex justify-content-between align-items-center">
                <p className="message-template-text">{message.message}</p>
                <p
                  className="message-template-icon"
                  onClick={() => {
                    setOpen(true)
                    setActiveButton(false)
                    setMessageItem(message)
                  }}
                >
                  ・・・
                </p>
              </div>
            </div>
          ))}

          <div className="message-template-button">
            <h3
              className="message-template-button-text"
              onClick={() => history.push(routes.MESSAGE_TEMPLATE_EDIT)}
            >
              定型文を追加する
            </h3>
          </div>

          <div className="message-template-heading">
            <h3 className="message-template-heading-text">
              定型文テンプレート
            </h3>
          </div>

          {messageTemplates.map((message) => (
            <div key={message.id} className="message-template-item">
              <div className="d-flex justify-content-between align-items-center">
                <p className="message-template-text">{message.message}</p>
                <p
                  className="message-template-icon"
                  onClick={() => {
                    setOpen(true)
                    setActiveButton(true)
                    setMessageItem(message)
                  }}
                >
                  ・・・
                </p>
              </div>
            </div>
          ))}
        </div>
      )}

      <Modal show={open} centered dialogClassName="modal-custom mx-auto">
        <Modal.Body className="modal-custom-body">
          <button
            className="btn font-simple w-100 m-0 rounded-0 modal-custom-button"
            style={{ border: "none" }}
            onClick={() => {
              history.push({
                pathname: routes.MESSAGE_TEMPLATE_EDIT,
                state: {
                  ...messageItem
                }
              })
            }}
          >
            {activeButton ? "オリジナル定型文に追加・編集" : "定型文の編集"}
          </button>
          <button
            className="btn font-simple w-100 m-0 rounded-0 modal-custom-button"
            disabled={activeButton}
            onClick={() => {
              handleDeleteMessage()
              setOpen(false)
            }}
          >
            削除
          </button>
          <button
            className="btn font-simple w-100 m-0 rounded-0 modal-custom-button"
            onClick={handleClose}
          >
            キャンセル
          </button>
        </Modal.Body>
      </Modal>
    </Layout>
  )
}

export default MessageTemplate
