import React, { useEffect } from "react"

import iconMoney from "../images/money.png"

export const PointItem = (props) => {
  const { point, onPurchase } = props

  const toStringNumber = (num) => {
    if (num == null || num == "") {
      return "0"
    }
    return (Math.round(num) + "").replace(/(\d)(?=(\d{3})+$)/g, "$1,")
  }

  const isPopular = (num) => {
    const point = Math.round(num)
    return point == 300
  }

  const isSpecial = (num) => {
    const point = Math.round(num)
    return point == 1000
  }

  useEffect(() => {}, [])

  return (
    <div className="point-item">
      <div className="icon">
        <img src={iconMoney} alt="point" />
      </div>
      <div className="point">
        <span>{toStringNumber(point.points)}</span>
        {isPopular(point.points) && <div className="popular">一番人気！</div>}
        {isSpecial(point.points) && <div className="popular">一番お得！</div>}
      </div>
      <div className="div-btn-buy">
        <button
          type="button"
          className="btn-buy"
          onClick={() => {
            onPurchase(point.points_package_id)
          }}
        >
          <span
            className={`origin-price ${
              point.raw_price == null || point.price == point.raw_price
                ? "no-line"
                : ""
            }`}
          >
            {point.raw_price == null || point.price == point.raw_price
              ? "通常価格"
              : `¥${toStringNumber(point.raw_price)}`}
          </span>
          <span className="price">¥{toStringNumber(point.price)}</span>
        </button>
      </div>
    </div>
  )
}
