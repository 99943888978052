import HttpClient from "../utils/client"

function* mutedListApi(data, action) {
  const url = `/muted-users`
  return yield HttpClient("GET", { url, params: data.data }, action)
}

function* unmuteUser(userId, action) {
  const url = `/users/${userId}/unmute`
  return yield HttpClient("DELETE", { url }, action)
}

export { mutedListApi, unmuteUser }
