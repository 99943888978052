import _ from "lodash"
import * as yup from "yup"
import { Formik } from "formik"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import InputGroup from "react-bootstrap/InputGroup"

import matches from "../../constants/matches"
import messages from "../../constants/messages"

const schema = yup.object().shape({
  phone: yup.string().required().max(12).matches(matches.phone)
})

const Signup = ({ onSignup, title, phone }) => {
  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSignup}
      initialValues={{ phone: phone }}
    >
      {({ handleSubmit, handleChange, values, errors }) => (
        <Form
          noValidate
          onSubmit={handleSubmit}
          className="d-flex flex-column"
          style={{ paddingTop: 90 }}
        >
          <h3 className="fs-3 text-grey-bold">{title || "電話番号の入力"}</h3>
          <p className="fs-6 text-grey" style={{ marginBottom: "3.5rem" }}>
            まず、携帯電話番号を入力してください。
            <br />
            入力した電話番号に認証コードを通知します。
          </p>
          <Form.Group controlId="phone">
            <Form.Label className="fs-5 text-grey-bold">
              携帯電話番号
            </Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                className="fs-5 text-grey"
                type="tel"
                placeholder="ハイフンなし"
                name="phone"
                value={values.phone}
                onChange={handleChange}
                style={{ borderBottomColor: "#000" }}
                onBlur={() => window.scrollTo(0, 0)}
                isInvalid={!!errors.phone}
              />
              <Form.Control.Feedback type="invalid">
                {_.isEmpty(values.phone)
                  ? messages.emptyPhoneNumber
                  : messages.wrongPhoneNumber}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>

          <Button
            className="align-self-center w-100 mt-4"
            variant="danger"
            type="submit"
            style={{
              fontSize: 20,
              fontWeight: "bold",
              padding: 12,
              borderRadius: 6
            }}
          >
            次へ
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default Signup
