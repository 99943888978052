import { useContext, useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { Layout, AppBar, LoadingIndicator, OTPInput } from "../../../components"
import AppContext from "../../../Context"
import {
  upsertAuthProfile,
  updateUserProfileRequest
} from "../../../actions/auth"
import messages from "../../../constants/messages"
import routes from "../../../constants/routes"
import { sendVerifyCode, signOut } from "../../../utils/firebase"

const VerifyPhone = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { setContext } = useContext(AppContext)
  const [code, setCode] = useState("")
  const [confirm, setConfirm] = useState(null)

  const phone = useSelector((state) => {
    return state.auth.phone
  })

  async function confirmCode(code) {
    try {
      await confirm.confirm(code)
      const profile = new FormData()
      profile.append("phone", phone)
      dispatch(
        updateUserProfileRequest(profile, {
          onSuccess: () => {
            dispatch(
              upsertAuthProfile(
                { phoneVerified: true },
                {
                  onSuccess: () => {
                    setTimeout(() => {
                      setContext("overlay", null)
                      history.push(routes.USERS)
                    }, 100)
                  },
                  onFailed: () => {
                    alert(messages.somethingWrong)
                    setTimeout(() => {
                      setContext("overlay", null)
                    }, 100)
                  }
                }
              )
            )
          },
          onFailed: () => {
            alert(messages.somethingWrong)
            setTimeout(() => {
              setContext("overlay", null)
            }, 100)
          }
        })
      )
    } catch (error) {
      setContext("overlay", null)
      alert(messages.invalidCode)
    }
  }

  const handleConfirmCode = (e) => {
    e.preventDefault()
    setContext("overlay", <LoadingIndicator />)
    if (!code) {
      alert(messages.emptyCode)
      setContext("overlay", null)
      return
    }

    confirmCode(code)
  }

  const handleChange = ({ target: { value } }) => {
    setCode(value)
  }

  const handleSendVerifySuccess = (isResend) => (confirmation) => {
    setContext("overlay", null)
    setConfirm(confirmation)
    if (isResend) alert(messages.resendCodeSuccesss)
  }

  const handleSendVerifyFailure = (isResend) => () => {
    setContext("overlay", null)
    if (isResend) alert(messages.codeSendFail)
  }

  useEffect(() => {
    if (!phone) {
      history.push(routes.TITLE)
    } else {
      setContext("overlay", <LoadingIndicator />)
      signOut()
      sendVerifyCode(
        phone,
        handleSendVerifySuccess(false),
        handleSendVerifyFailure(false)
      )
    }
  }, [])

  return (
    <Layout>
      <AppBar withBack />
      <div className="container">
        <OTPInput
          onChange={handleChange}
          onRefresh={() => {
            setContext("overlay", <LoadingIndicator />)
            sendVerifyCode(
              phone,
              handleSendVerifySuccess(true),
              handleSendVerifyFailure(true)
            )
          }}
          onSubmit={handleConfirmCode}
        />
      </div>
    </Layout>
  )
}

export default VerifyPhone
