import { takeEvery, put, call } from "redux-saga/effects"
import { muteUserSuccess, muteUserFailed } from "../actions/mute"
import { MUTE_USER_REQUESTED } from "../constants/actions/mute"
import { muteUser } from "../services/mute"

function* handleMuteUser(action) {
  const { onSuccess, onFailed } = action.callbacks

  try {
    const response = yield muteUser(action.payload.data, action)
    if (response && response.status === 200) {
      if (typeof onSuccess === "function") {
        yield call(onSuccess, response.data)
      }
      yield put(muteUserSuccess(response.data))
    } else {
      if (typeof onFailed === "function") {
        yield call(onFailed)
      }

      yield put(muteUserFailed())
    }
  } catch (error) {
    if (typeof onFailed === "function") {
      yield call(onFailed)
    }
    yield put(muteUserFailed())
  }
}

export default function* watchMuteUser() {
  yield takeEvery(MUTE_USER_REQUESTED, handleMuteUser)
}
