import { Image } from "react-bootstrap"
import FootPrintIcon from "../../../../images/footprint.svg"

export const EmptyList = () => {
  return (
    <div className="h-100 d-flex flex-column justify-content-center align-items-center">
      <Image src={FootPrintIcon} width="100" className="m-5" />
      <h4 className="text-center yu-gothic-bold">
        あなたのプロフィールに訪問した
        <br />
        ユーザーはまだいません。
      </h4>
    </div>
  )
}
