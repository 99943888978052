import { takeLatest, put } from "redux-saga/effects"
import {
  skipUserSuccess,
  skipUserFailed,
  thankUserSuccess,
  thankUserFailed,
  getLikersSuccess,
  getLikersFailed,
  getLikedUsersSuccess,
  getLikedUsersFailed,
  markReadUserFailed,
  markReadUserSuccess,
  likeUserFootPrintFailed,
  likeUserFootPrintSuccess,
  labelUserSuccess,
  pinUserSuccess,
  pinUserFailed,
  labelUserFailed
} from "../actions/likes"
import {
  GET_LIKERS_REQUESTED,
  GET_LIKED_USERS_REQUESTED,
  SKIP_USER_REQUESTED,
  THANK_USER_REQUESTED,
  MARK_READ_USER_REQUESTED,
  LIKE_USER_FOOTPRINT_REQUESTED,
  LABEL_USER_REQUESTED,
  PIN_USER_REQUESTED
} from "../constants/actions/likes"
import {
  skipUser,
  thankUser,
  markReadUser,
  likeUser,
  labelUser
} from "../services/likes"
import {
  getLikes,
  updateLikeStatus,
  updateLikeReadStatus,
  updateLikeRead,
  pinUser
} from "../utils/firebase"
import { LIKE_STATUS } from "../constants/env"

function* handleGetLikers(action) {
  try {
    const userId = action.payload.userId
    const dispatch = action.dispatch

    yield getLikes(
      userId,
      async (users) => {
        // await updateLikeReadStatus(userId)
        dispatch(getLikersSuccess(users))
      },
      false
    )
  } catch (error) {
    yield put(getLikersFailed(error.toString()))
  }
}

function* handleGetLikedUsers(action) {
  try {
    const userId = action.payload.userId
    const dispatch = action.dispatch
    yield getLikes(
      userId,
      async (users) => {
        dispatch(getLikedUsersSuccess(users))
      },
      true
    )
  } catch (error) {
    yield put(getLikedUsersFailed(error.toString()))
  }
}

function* handleSkipUser(action) {
  const { onSuccess, onFailed } = action.callbacks
  try {
    const response = yield skipUser(action.payload.fromUserId)
    if (response && response.status === 200) {
      yield updateLikeStatus(action.payload.likeId, LIKE_STATUS.SKIPPED)
      yield updateLikeReadStatus(action.payload.toUserId)
      yield put(skipUserSuccess())
      onSuccess()
    } else {
      if (response && response.data && response.data.error) {
        yield put(skipUserFailed(response.data.error.message))
      } else yield put(skipUserFailed(response))
      onFailed()
    }
  } catch (error) {
    yield put(skipUserFailed(error.toString()))
    onFailed()
  }
}

function* handleMarkReadUser(action) {
  const { onSuccess, onFailed } = action.callbacks
  try {
    if (action.payload.tab === 1) {
      yield updateLikeRead(action.payload.likeId)
      yield put(markReadUserSuccess())
    } else {
      const response = yield markReadUser(action.payload.likeId)
      if (response.status === 200) {
        yield put(markReadUserSuccess())
        onSuccess()
      } else {
        if (response && response.data && response.data.error) {
          yield put(markReadUserFailed(response.data.error.message))
        } else {
          yield put(markReadUserFailed("unknown reason"))
        }
        onFailed()
      }
    }
  } catch (error) {
    yield put(markReadUserFailed(error.toString()))
    onFailed()
  }
}

function* handleThankUser(action) {
  const { onSuccess, onFailed } = action.callbacks
  try {
    const response = yield thankUser(action.payload.fromUserId)
    if (response.status === 200) {
      const likedId = response.data.like_id
      yield updateLikeStatus(likedId, LIKE_STATUS.MATCHED)
      yield updateLikeReadStatus(action.payload.toUserId)
      yield put(thankUserSuccess())
      onSuccess()
    } else {
      if (response && response.data && response.data.error) {
        yield put(thankUserFailed(response.data.error.message))
      } else {
        yield put(thankUserFailed("unknown reason"))
      }
      onFailed()
    }
  } catch (error) {
    yield put(thankUserFailed(error.toString()))
    onFailed()
  }
}

function* handleLikeUserFootprint(action) {
  const { onSuccess, onFailed } = action.callbacks

  try {
    if (action.payload.user.is_user_liked === 1) {
      const response = yield thankUser(action.payload.user.user_id)

      if (response.status === 200) {
        yield put(likeUserFootPrintSuccess())
        onSuccess(response)
      } else {
        yield put(likeUserFootPrintFailed(response.data.error.message))
        onFailed()
      }
    } else {
      const response = yield likeUser(
        action.payload.user.user_id,
        action.payload.likeType
      )

      if (response.status === 200) {
        yield put(likeUserFootPrintSuccess())
        onSuccess(response)
      } else {
        yield put(likeUserFootPrintFailed(response.data.error.message))
        onFailed()
      }
    }
  } catch (error) {
    yield put(likeUserFootPrintFailed(error.toString()))
    onFailed()
  }
}

function* handleLabelUser(action) {
  const { onSuccess, onFailed } = action.callbacks

  try {
    const response = yield labelUser(
      action.payload.toUserId,
      action.payload.isLabel
    )

    if (response.status === 200) {
      yield put(labelUserSuccess())
      onSuccess(response)
    } else {
      yield put(labelUserFailed(response.data.error.message))
      onFailed()
    }
  } catch (error) {
    yield put(labelUserFailed(error.toString()))
    onFailed()
  }
}

function* handlPinUser(action) {
  const { onSuccess, onFailed } = action.callbacks

  try {
    const response = yield pinUser(
      action.payload.likeID,
      action.payload.fromUserId,
      action.payload.isPinned
    )

    if (response.status === 200) {
      yield put(pinUserSuccess())
      onSuccess(response)
    } else {
      yield put(pinUserFailed(response.message))
      onFailed(response)
    }
  } catch (error) {
    yield put(pinUserFailed(error.toString()))
    onFailed()
  }
}

export default function* likesSaga() {
  yield takeLatest(GET_LIKERS_REQUESTED, handleGetLikers)
  yield takeLatest(GET_LIKED_USERS_REQUESTED, handleGetLikedUsers)
  yield takeLatest(SKIP_USER_REQUESTED, handleSkipUser)
  yield takeLatest(THANK_USER_REQUESTED, handleThankUser)
  yield takeLatest(MARK_READ_USER_REQUESTED, handleMarkReadUser)
  yield takeLatest(LIKE_USER_FOOTPRINT_REQUESTED, handleLikeUserFootprint)
  yield takeLatest(LABEL_USER_REQUESTED, handleLabelUser)
  yield takeLatest(PIN_USER_REQUESTED, handlPinUser)
}
