import classnames from "classnames"
import { UserItem } from "../../components/UserItem"
import "../styles.scss"

export const Item = ({ user, onAvatarClick, onRightClick }) => {
  // const isFromMe =
  //   currentUserId.toString() === user.lastMessage.userId.toString()

  const renderLastMessage = () => {
    // switch (user.lastMessage.type) {
    //   case "text":
    //     return <>{user.lastMessage.message}</>
    //   case "video_call":
    //     return (
    //       <>
    //         <img src={VideoIcon} alt="Not found" />
    //         <span style={{ marginLeft: "0.5rem" }}>
    //           ビデオ通話を終了しました
    //         </span>
    //       </>
    //     )
    //   default:
    //     return null
    // }
  }

  return (
    <UserItem
      user={user}
      onAvatarClick={onAvatarClick}
      onRightClick={onRightClick}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div className={classnames("one-line-text", "message", "w-100")}>
          {renderLastMessage()}
        </div>
        {/* {user.unreadMessageCount > 0 && (
          <div className="new-message-circle">{user.unreadMessageCount}</div>
        )} */}
      </div>
    </UserItem>
  )
}
