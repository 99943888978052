export const AUTH_LOGIN_WITH_EMAIL_REQUEST = "AUTH_LOGIN_WITH_EMAIL_REQUEST"
export const AUTH_LOGIN_WITH_EMAIL_SUCCESS = "AUTH_LOGIN_WITH_EMAIL_SUCCESS"
export const AUTH_LOGIN_WITH_EMAIL_FAILED = "AUTH_LOGIN_WITH_EMAIL_FAILED"

export const AUTH_LOGIN_WITH_PHONE_REQUEST = "AUTH_LOGIN_WITH_PHONE_REQUEST"
export const AUTH_LOGIN_WITH_PHONE_SUCCESS = "AUTH_LOGIN_WITH_PHONE_SUCCESS"
export const AUTH_LOGIN_WITH_PHONE_FAILED = "AUTH_LOGIN_WITH_PHONE_FAILED"

export const AUTH_THROW_ERROR = "AUTH_THROW_ERROR"
export const UPSERT_AUTH_PROFILE = "UPSERT_AUTH_PROFILE"

export const SHOW_POPUP_AFTER_LOGIN = "SHOW_POPUP_AFTER_LOGIN"

export const UPDATE_USER_PROFILE_REQUEST = "UPDATE_USER_PROFILE_REQUEST"
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS"
export const UPDATE_USER_PROFILE_FAILED = "UPDATE_USER_PROFILE_FAILED"

export const FETCH_AUTH_PROFILE_REQUEST = "FETCH_AUTH_PROFILE_REQUEST"
export const FETCH_AUTH_PROFILE_SUCCESS = "FETCH_AUTH_PROFILE_SUCCESS"
export const FETCH_AUTH_PROFILE_FAILED = "FETCH_AUTH_PROFILE_FAILED"

export const ADD_DEVICE_TOKEN_REQUESTED = "ADD_DEVICE_TOKEN_REQUESTED"
export const ADD_DEVICE_TOKEN_SUCCESS = "ADD_DEVICE_TOKEN_SUCCESS"
export const ADD_DEVICE_TOKEN_FAILED = "ADD_DEVICE_TOKEN_FAILED"

export const ADD_DEVICE_ID_FIREBASE_REQUESTED =
  "ADD_DEVICE_ID_FIREBASE_REQUESTED"
export const ADD_DEVICE_ID_FIREBASE_SUCCESS = "ADD_DEVICE_ID_FIREBASE_SUCCESS"
export const ADD_DEVICE_ID_FIREBASE_FAILED = "ADD_DEVICE_ID_FIREBASE_FAILED"
