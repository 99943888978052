import "./styles.scss"

export const StatusDot = ({ color, left, top, ...props }) => (
  <div
    {...props}
    style={{
      backgroundColor: color,
      left: left,
      top: top
    }}
    className="m-2 status"
  ></div>
)
