import { takeLatest, put } from "redux-saga/effects"
import {
  FETCH_BOX_NOTIFICATION,
  FETCH_DETAIL_NOTIFICATION,
  UPDATE_READ_NOTIFICATION,
  CHANGE_NOTI_MESSAGE_REQUEST,
  API_NOTI_MESSAGE_REQUEST
} from "../constants/actions/other"
import {
  boxNotificationApi,
  detailNotificationApi,
  updateReadNotificationApi,
  changeNotiMessageRequest
} from "../services/notifications"
import {
  fetchDetailNotificationFailed,
  fetchDetailNotificationSuccess,
  fetchDataFootPrintsSuccess,
  fetchUpdateReadNotificationFailed,
  fetchDataBoxNotificationSuccess,
  fetchDataBoxNotificationFailed,
  fetchNotiMessageSuccess,
  fetchNotiMessageFailed,
  fetchApiNotiMessageSuccess,
  fetchApiNotiMessageFailed
} from "../actions/other"
import { offNotifyUser } from "../utils/firebase"

function* handleFetchDataBoxNotification(action) {
  try {
    const response = yield boxNotificationApi(action.payload.dataDetail, action)
    if (response) {
      yield put(fetchDataBoxNotificationSuccess(response.data))
    } else {
      yield put(fetchDataBoxNotificationFailed())
    }
  } catch (error) {}
}

function* handleFetchDataDetailNotification(action) {
  try {
    const response = yield detailNotificationApi(action.dataDetail, action)
    if (response) {
      yield put(fetchDetailNotificationSuccess(response.data))
    } else {
      yield put(fetchDetailNotificationFailed())
    }
  } catch (error) {}
}

function* handleFetchDataUpdateReadNotification(action) {
  try {
    const response = yield updateReadNotificationApi(action.dataDetail, action)
    if (response) {
      yield put(fetchDataFootPrintsSuccess(response.data))
    } else {
      yield put(fetchUpdateReadNotificationFailed())
    }
  } catch (error) {}
}

function* handleApiNotifyUser(action) {
  try {
    const response = yield changeNotiMessageRequest(action.payload.data, action)
    if (response) {
      yield put(fetchApiNotiMessageSuccess(response.data))
    } else {
      yield put(fetchApiNotiMessageFailed())
    }
  } catch (error) {}
}

function* handleOffNotifyUser(action) {
  const { onSuccess, onFailed } = action.callbacks

  try {
    const response = yield offNotifyUser(
      action.payload.data.likeID,
      action.payload.data.fromUserId,
      action.payload.data.isOffnotificationChat
    )

    if (response.status === 200) {
      yield put(fetchNotiMessageSuccess())
      onSuccess(response)
    } else {
      yield put(fetchNotiMessageFailed(response.message))
      onFailed(response)
    }
  } catch (error) {
    yield put(fetchNotiMessageFailed(error.toString()))
    onFailed()
  }
}

export default function* watchNotification() {
  yield takeLatest(FETCH_BOX_NOTIFICATION, handleFetchDataBoxNotification)
  yield takeLatest(FETCH_DETAIL_NOTIFICATION, handleFetchDataDetailNotification)
  yield takeLatest(API_NOTI_MESSAGE_REQUEST, handleApiNotifyUser)
  yield takeLatest(CHANGE_NOTI_MESSAGE_REQUEST, handleOffNotifyUser)
  yield takeLatest(
    UPDATE_READ_NOTIFICATION,
    handleFetchDataUpdateReadNotification
  )
}
