export const filterAccountPopups = (data, profileUserData) => {
  return data.filter(account => {
    const { sex, label } = account;

    if (sex === 2 && profileUserData?.sex === 2) {
      if (label === 'woman_without_id' && profileUserData?.identity_verification_status !== 3) {
        return true;
      } else if (label === 'woman_verifying_id' && profileUserData?.identity_verification_status === 3) {
        return true;
      }
    } else if (sex === 1 && profileUserData?.sex === 1) {
      if (label === 'man_not_verify_and_not_plan' && profileUserData?.identity_verification_status !== 3 && profileUserData?.contract_type !== 2) {
        return true;
      } else if (label === 'man_not_verify_and_plan' && profileUserData?.identity_verification_status !== 3 && profileUserData?.contract_type === 2) {
        return true;
      } else if (label === 'man_verify_and_not_plan' && profileUserData?.identity_verification_status === 3 && profileUserData?.contract_type !== 2) {
        return true;
      } else if (label === 'man_verify_and_plan' && profileUserData?.identity_verification_status === 3 && profileUserData?.contract_type === 2) {
        return true;
      }
    }
    return false;
  });
};