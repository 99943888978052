import {
  CHECK_PHONE_NUMBER,
  CHECK_PHONE_NUMBER_FAILED,
  CHECK_PHONE_NUMBER_SUCCESS,
  UPDATE_PHONE_NUMBER,
} from "../constants/actions/signup"

export const checkPhoneNumberRequest = (data, callbacks) => {
  return {
    type: CHECK_PHONE_NUMBER,
    data,
    callbacks,
  }
}
export const checkPhoneNumberSuccess = (data) => {
  return {
    type: CHECK_PHONE_NUMBER_SUCCESS,
    data,
  }
}

export const checkPhoneNumberFailed = () => {
  return {
    type: CHECK_PHONE_NUMBER_FAILED,
  }
}

export const updatePhoneNumber = (data) => {
  return {
    type: UPDATE_PHONE_NUMBER,
    data,
  }
}
