import _ from "lodash"
import { Image } from "react-bootstrap"
import Form from "react-bootstrap/Form"
import { useSelector } from "react-redux"
import Button from "react-bootstrap/Button"
import { Fragment, useEffect, useState } from "react"
import "./styles.scss"
import Search from "../../../icons/search.svg"
import Range from "../../../components/Elements/Range"
import MultiSelect from "../../../components/Elements/MultiSelect"
import {
  persistFilterParams,
  getPersistFilterParams
} from "../../../utils/persist"
import {
  conditionMeets,
  bodyMale,
  bodyFeMale,
  matchArea,
  holiday
} from "../../../constants/user"

const ages = _.range(18, 100)
const heights = _.range(0, 12).map((height) => 145 + height * 5)

const defaultValues = (key, values, options) => {
  if (_.isEmpty(values)) {
    return []
  }

  return _.filter(options, (opt) => _.indexOf(values, opt[key]) !== -1).map(
    (opt) => ({ label: opt.name, value: opt[key] })
  )
}

const Match = ({ sex, options, onSubmit }) => {
  const [values, setValues] = useState({ mainpic: 0, subpic: 0 })
  const [focuses, setFocuses] = useState({})
  const [ranges, setRanges] = useState({
    heights: [null, null],
    ages: [null, null]
  })
  const [ready, setReady] = useState(false)
  const phone = useSelector((state) => {
    return state.auth.profile.phone
  })

  const pathName = window.location.pathname

  const handleRangeFocus = (name) => {
    setFocuses({ ...focuses, [name]: !focuses[name] })
  }

  const handleSelectFocus = (name) => {
    setFocuses({ ...focuses, [name]: !focuses[name] })
  }

  const handleRangeReset = (name) => {
    setRanges({ ...ranges, [name]: [null, null] })
  }

  const handleRangeChange = (name, values) => {
    setRanges({ ...ranges, [name]: values })
  }

  const handleSelectChange = (name, value) => {
    setValues({ ...values, [name]: value })
  }

  const handleChange = (name, e) => {
    if (Array.isArray(e)) {
      const value = []
      _.map(e, (item) => {
        value.push(item.value)
      })

      setValues({ ...values, [name]: value })
      return
    }

    const {
      target: { value, checked }
    } = e

    if (typeof checked == "boolean") {
      setValues({
        ...values,
        [name]: value === "on" ? (checked ? 1 : 0) : value
      })
      return
    }

    setValues({ ...values, [name]: value })
  }

  const handleChangeKeywork = (name, e) => {
    setValues({ [name]: e.target.value })
  }

  const handleReset = () => {
    setValues({ mainpic: 0, subpic: 0 })
    setRanges({})
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    console.log('values handleSubmit', values);
    console.log('e handleSubmit', e.target.value);
    if (values.conditions && values.conditions !== '') {
      console.log(123);
      let temp = []
      values.conditions.forEach((element) => {
        conditionMeets.forEach((el) => {
          if (el.id === element) {
            temp.push(el.name)
          }
        })
      })
      values["conditions[]"] = temp
    } else {
      console.log(456);
    }

    if (values.body) {
      let temp = []
      values.body.forEach((element) => {
        {
          sex === 2
            ? bodyMale.forEach((el) => {
                if (el.id === element) {
                  temp.push(el.name)
                }
              })
            : bodyFeMale.forEach((el) => {
                if (el.id === element) {
                  temp.push(el.name)
                }
              })
        }
      })
      values["body[]"] = temp
    }

    if (values.holiday) {
      let temp = []
      values.holiday.forEach((element) => {
        holiday.forEach((el) => {
          if (el.id === element) {
            temp.push(el.name)
          }
        })
      })
      values["holiday[]"] = temp
    }

    if (values.residence_ids) {
      let temp = []
      values.residence_ids.forEach((element) => {
        matchArea.forEach((el) => {
          if (el.id === element) {
            temp.push(el.id)
          }
        })
      })
      // values["residence_ids[]"] = temp
      // console.log(temp);

    }

    if (values.match_area_1) {
      let temp = []
      values.match_area_1.forEach((element) => {
        matchArea.forEach((el) => {
          if (el.id === element) {
            temp.push(el.name)
          }
        })
      })
      values["match_area_1[]"] = temp

    }
    
    if (values["conditions[]"] && !values["conditions[]"].length) {
      delete values["conditions[]"];
    }
    if (values["holiday[]"] && !values["holiday[]"].length) {
      delete values["holiday[]"];
    }
    if (values["body[]"] && !values["body[]"].length) {
      delete values["body[]"];
    }
    if (values["residence_ids[]"] && !values["residence_ids[]"].length) {
      delete values["residence_ids[]"];
    }
    if (values["match_area_1[]"] && !values["match_area_1[]"].length) {
      delete values["match_area_1[]"];
    }
    persistFilterParams(phone, { ...ranges, ...values })

    const formData = {}
    _.map(values, (value, name) => {
      formData[name] = Array.isArray(value) ? value.join(",") : value
    })

    _.map(ranges, (range, name) => {
      const fieldName = name == "ages" ? "age" : "height"
      const fieldValues = name == "ages" ? ages : heights

      if (!_.isEmpty(range[0])) {
        formData[`${fieldName}_lower`] = fieldValues[range[0]]
      }
      if (!_.isEmpty(range[1])) {
        formData[`${fieldName}_upper`] = fieldValues[range[1]]
      }
    })

    onSubmit(formData)
  }

  useEffect(() => {
    const prev = getPersistFilterParams(phone)
    if (!_.isEmpty(prev)) {
      const { ages, heights, ...others } = prev

      setRanges({
        ages: ages || [null, null],
        heights: heights || [null, null]
      })

      setValues({ ...values, ...others })
    }
  }, [])

  useEffect(() => {
    if (!_.isEmpty(options)) {
      setReady(true)
    }
  }, [options])

  return (
    <Form
      onSubmit={handleSubmit}
      className="bg-white d-flex flex-column full-height-with-navbar form-filter-home"
    >
      <div className="container form-container">
        <h5 className="mb-3 title p-2">写真・動画</h5>
        {ready && (
          <Fragment>
            <Form.Check
              onChange={(e) => handleChange("mainpic", e)}
              name="mainpic"
              size="lg"
              type="switch"
              label="写真登録済"
              checked={values.mainpic === 1}
              className="checked"
            />
            <Form.Check
              onChange={(e) => handleChange("subpic", e)}
              name="subpic"
              size="lg"
              type="switch"
              label="動画登録済"
              checked={values.subpic === 1}
              className="checked"
            />
            {pathName !== "/likes" && pathName !== "/users" && (
              <Form.Check
                onChange={(e) => handleChange("subpic", e)}
                name="subpic"
                size="lg"
                type="switch"
                label="マッチ済は除外"
                checked={values.subpic === 1}
                className="checked"
              />
            )}

            <h5 className="mt-4 mb-3 title p-2">基本プロフィール</h5>
            <Form.Group className="form-group d-flex align-items-center">
              <Form.Label className="label-w-140">会う条件</Form.Label>
              <MultiSelect
                open={focuses.conditions}
                title="会う条件"
                emptyText="選択してください"
                options={conditionMeets}
                values={values.conditions}
                propId="id"
                name="conditions"
                onChange={(values) => handleSelectChange("conditions", values)}
                onFocus={() => handleSelectFocus("conditions")}
              />
            </Form.Group>
            <Form.Group className="form-group d-flex align-items-center">
              <Form.Label className="label-w-140">希望エリア</Form.Label>
              <MultiSelect
                open={focuses.match_area_1}
                title="希望エリア"
                emptyText="選択してください"
                options={matchArea}
                values={values.match_area_1}
                propId="id"
                name="match_area_1"
                onChange={(values) =>
                  handleSelectChange("match_area_1", values)
                }
                onFocus={() => handleSelectFocus("match_area_1")}
              />
            </Form.Group>
            <Form.Group className="form-group d-flex align-items-center">
              <Form.Label className="label-w-140">居住地</Form.Label>
              <MultiSelect
                open={focuses.residence_ids}
                title="居住地"
                emptyText="選択してください"
                options={matchArea}
                values={values.residence_ids}
                propId="id"
                name="residence_ids"
                onChange={(values) =>
                  handleSelectChange("residence_ids", values)
                }
                onFocus={() => handleSelectFocus("residence_ids")}
              />
            </Form.Group>
            <Form.Group className="form-group d-flex align-items-center">
              <Form.Label className="label-w-140">年齢</Form.Label>
              <Range
                open={focuses.ages}
                title="年齢"
                names={["age_lower", "age_upper"]}
                emptyText="選択してください"
                options={ages}
                values={ranges.ages}
                placeholders={["こだわらない", "こだわらない"]}
                labels={["下限", "上限"]}
                onChange={(values) => handleRangeChange("ages", values)}
                onFocus={() => handleRangeFocus("ages")}
              >
                <div className="buttons-separate border-top pt-3 pb-2">
                  <div className="container d-flex justify-content-between">
                    <Button
                      className="btn-w-45 w-100"
                      variant="danger"
                      type="button"
                      onClick={() => handleRangeFocus("ages")}
                    >
                      決定
                    </Button>
                  </div>
                </div>
              </Range>
            </Form.Group>
            <Form.Group className="form-group d-flex align-items-center">
              <Form.Label className="label-w-140">身長</Form.Label>
              <Range
                open={focuses.heights}
                title="身長"
                names={["height_lower", "height_upper"]}
                options={heights}
                optionSuffix="cm"
                values={ranges.heights}
                labels={["下限", "上限"]}
                emptyText="選択してください"
                placeholders={["こだわらない", "こだわらない"]}
                onChange={(values) => handleRangeChange("heights", values)}
                onFocus={() => handleRangeFocus("heights")}
              >
                <div className="buttons-separate border-top pt-3 pb-2">
                  <div className="container d-flex justify-content-between">
                    {/* <Button
                      className="border-0 btn-w-45"
                      variant="secondary"
                      type="button"
                      onClick={() => handleRangeReset("heights")}
                    >
                      条件をリセット
                    </Button> */}
                    <Button
                      className="btn-w-45 w-100"
                      variant="danger"
                      type="button"
                      onClick={() => handleRangeFocus("heights")}
                    >
                      決定
                    </Button>
                  </div>
                </div>
              </Range>
            </Form.Group>
            <Form.Group className="form-group d-flex align-items-center">
              <Form.Label className="label-w-140">体型</Form.Label>
              <MultiSelect
                open={focuses.body}
                title="体型"
                emptyText="選択してください"
                options={sex === 2 ? bodyMale : bodyFeMale}
                values={values.body}
                propId="id"
                name="body"
                onChange={(values) => handleSelectChange("body", values)}
                onFocus={() => handleSelectFocus("body")}
              />
            </Form.Group>
            <Form.Group className="form-group d-flex align-items-center">
              <Form.Label className="label-w-140">職業</Form.Label>
              <MultiSelect
                open={focuses.occupation_ids}
                title="職業"
                emptyText="選択してください"
                options={options.occupations}
                values={values.occupation_ids}
                propId="occupation_id"
                name="occupation_ids"
                onChange={(values) =>
                  handleSelectChange("occupation_ids", values)
                }
                onFocus={() => handleSelectFocus("occupation_ids")}
              />
            </Form.Group>
            <Form.Group className="form-group d-flex align-items-center">
              <Form.Label className="label-w-140">出身地</Form.Label>
              <MultiSelect
                open={focuses.birthplace_ids}
                title="出身地"
                emptyText="選択してください"
                options={options.birthplaces}
                values={values.birthplace_ids}
                propId="birthplace_id"
                name="birthplace_ids"
                onChange={(values) =>
                  handleSelectChange("birthplace_ids", values)
                }
                onFocus={() => handleSelectFocus("birthplace_ids")}
              />
            </Form.Group>
            {sex !== 1 && (
              <Form.Group className="form-group d-flex align-items-center">
                <Form.Label className="label-w-140">タバコ</Form.Label>
                <MultiSelect
                  open={focuses.cigarette_ids}
                  title="タバコ"
                  emptyText="選択してください"
                  options={options.cigarettes}
                  values={values.cigarette_ids}
                  propId="cigarette_id"
                  name="cigarette_ids"
                  onChange={(values) =>
                    handleSelectChange("cigarette_ids", values)
                  }
                  onFocus={() => handleSelectFocus("cigarette_ids")}
                />
              </Form.Group>
            )}
            <Form.Group className="form-group d-flex align-items-center">
              <Form.Label className="label-w-140">休日</Form.Label>
              <MultiSelect
                open={focuses.holiday}
                title="休日"
                emptyText="選択してください"
                options={holiday}
                values={values.holiday}
                propId="id"
                name="holiday"
                onChange={(values) => handleSelectChange("holiday", values)}
                onFocus={() => handleSelectFocus("holiday")}
              />
            </Form.Group>

            <Form.Group className="form-group d-flex align-items-center">
              <Form.Label className="label-w-140">子供の有無</Form.Label>
              <MultiSelect
                open={focuses.baby_aspiration_ids}
                title="子供の有無"
                emptyText="選択してください"
                options={options.baby_aspirations}
                values={values.baby_aspiration_ids}
                propId="baby_aspiration_id"
                name="baby_aspiration_ids"
                onChange={(values) =>
                  handleSelectChange("baby_aspiration_ids", values)
                }
                onFocus={() => handleSelectFocus("baby_aspiration_ids")}
              />
            </Form.Group>
          </Fragment>
        )}
        <Form style={{ position: "relative" }}>
          <Form.Group controlId="exampleForm.ControlInput1">
            <Image className="icon-search" src={Search} width={20} />
            <Form.Control
              className="input-search"
              size="sm"
              placeholder={"フリーワードで検索"}
              onChange={(values) => handleChangeKeywork("keyword", values)}
              values={values.keyword}
              name="keyword"
              onBlur={() => window.scrollTo(0, 0)}
            />
          </Form.Group>
        </Form>
        <div
          className="mt-auto"
          style={{ padding: "40px 0px", marginBottom: "150px" }}
        >
          <div className="d-grid container d-flex justify-content-between wrap-btn-search">
            <Button
              className="border-0 btn-w-45"
              variant="dark"
              type="button"
              onClick={handleReset}
            >
              条件リセット
            </Button>
            <Button
              className="align-self-center btn-w-45"
              variant="danger"
              type="submit"
            >
              検索する
            </Button>
          </div>
        </div>
      </div>
    </Form>
  )
}

Match.defaultProps = {
  sex: 1
}

export default Match
