import { takeEvery, put, call } from "redux-saga/effects"
import {
  fetchPlanSuccess,
  fetchPlanFailed,
  subscribePlanSuccess,
  subscribePlanFailed,
  unSubscribePlanSuccess,
  unSubscribePlanFailed,
  changeInfoPlanSuccess,
  changeInfoPlanFailed
} from "../actions/plan"
import {
  FETCH_PLAN_REQUEST,
  RENEW_PLAN_REQUEST,
  SUBSCRIBE_PLAN_REQUEST,
  UNSUBSCRIBE_PLAN_REQUEST,
  CHANGE_INFO_CREDIT_REQUEST
} from "../constants/actions/plan"
import {
  getPlans,
  postSubscribe,
  postUnSubscribe,
  postChangeInfoPlan,
  renewPlan
} from "../services/plan"

function* handleGetPlans(action) {
  const { onSuccess, onFailed } = action.callbacks
  try {
    const response = yield getPlans(action)
    if (response) {
      if (typeof onSuccess === "function") {
        yield call(onSuccess, response)
      }
      yield put(fetchPlanSuccess(response))
    } else {
      if (typeof onFailed === "function") {
        yield call(onFailed, response)
      }
      yield put(fetchPlanFailed())
    }
  } catch (error) {
    if (typeof onFailed === "function") {
      yield call(onFailed)
    }
    yield put(fetchPlanFailed())
  }
}

function* handlePostSubscribe(action) {
  const { onSuccess, onFailed } = action.callbacks

  try {
    const response = yield postSubscribe(action)
    if (response) {
      if (typeof onSuccess === "function") {
        yield call(onSuccess, response)
      }
      yield put(subscribePlanSuccess(response.data))
    } else {
      if (typeof onFailed === "function") {
        yield call(onFailed, response)
      }
      yield put(subscribePlanFailed())
    }
  } catch (error) {
    if (typeof onFailed === "function") {
      yield call(onFailed)
    }
    yield put(subscribePlanFailed())
  }
}

function* handlePostUnSubscribe(action) {
  const { onSuccess, onFailed } = action.callbacks

  try {
    const response = yield postUnSubscribe(action)
    if (response) {
      if (typeof onSuccess === "function") {
        yield call(onSuccess, response)
      }
      yield put(unSubscribePlanSuccess(response.data))
    } else {
      if (typeof onFailed === "function") {
        yield call(onFailed, response)
      }
      yield put(unSubscribePlanFailed())
    }
  } catch (error) {
    if (typeof onFailed === "function") {
      yield call(onFailed)
    }
    yield put(unSubscribePlanFailed())
  }
}

function* handleChangeInfoCredit(action) {
  const { onSuccess, onFailed } = action.callbacks

  try {
    const response = yield postChangeInfoPlan(action)
    if (response) {
      if (typeof onSuccess === "function") {
        yield call(onSuccess, response)
      }
      yield put(changeInfoPlanSuccess(response.data))
    } else {
      if (typeof onFailed === "function") {
        yield call(onFailed, response)
      }
      yield put(changeInfoPlanFailed())
    }
  } catch (error) {
    if (typeof onFailed === "function") {
      yield call(onFailed)
    }
    yield put(changeInfoPlanFailed())
  }
}

function* handleRenewPlan(action) {
  const { onSuccess, onFailed } = action.callbacks

  try {
    const response = yield renewPlan(action.data.setting)

    if (response.status === 200) {
      onSuccess(response)
    } else {
      onFailed(response)
    }
  } catch (error) {
    onFailed(error)
  }
}

export default function* watchPlan() {
  yield takeEvery(FETCH_PLAN_REQUEST, handleGetPlans)
  yield takeEvery(SUBSCRIBE_PLAN_REQUEST, handlePostSubscribe)
  yield takeEvery(UNSUBSCRIBE_PLAN_REQUEST, handlePostUnSubscribe)
  yield takeEvery(CHANGE_INFO_CREDIT_REQUEST, handleChangeInfoCredit)
  yield takeEvery(RENEW_PLAN_REQUEST, handleRenewPlan)
}
