import { Image } from "react-bootstrap"
import Img from "../../../../images/img_conversation.png"

export const EmptySecondTab = () => {
  return (
    <div className="h-100 p-3 d-flex flex-column justify-content-center align-items-center">
      <Image
        src={Img}
        className="mb-4"
        style={{ width: "30%", maxWidth: 256 }}
      />
      <div
        className="text-center mb-3 yu-gothic-bold"
        style={{ fontSize: "1.3rem" }}
      >
        現在やりとり中のお相手はいません。
      </div>
      <div
        className="text-center yu-gothic-regular"
        style={{ fontSize: "1rem" }}
      >
        気になるお相手には自分から「気になる」を送ってみましょう！
      </div>
    </div>
  )
}
