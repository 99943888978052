import _ from "lodash"
import { useDispatch } from "react-redux"
import { useState, useContext, useEffect } from "react"

import "../styles.scss"
import AppContext from "../../../../Context"
import AppBar from "../../../../components/AppBar"
import Layout from "../../../../components/Layout"
import messages from "../../../../constants/messages"
import { getWithdrawalHistory } from "../../../../actions/other"
import { LoadingIndicator } from "../../../../components/LoadingIndicator"

const WithdrawalHistory = () => {
  const dispatch = useDispatch()
  const { setContext } = useContext(AppContext)
  const [listData, setlistData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const numberWithCommas = (x) => {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  useEffect(() => {
    dispatch(
      getWithdrawalHistory(
        {},
        {
          onSuccess: (response) => {
            setContext("overlay", null)
            setlistData(response.data)
            setIsLoading(false)
          },
          onFailed: (error) => {
            alert(_.get(error, "data.error.message", messages.somethingWrong))
          }
        }
      )
    )
  }, [])

  return (
    <Layout>
      <AppBar title="出金履歴" withBack />
      <div className="bg-white d-flex flex-column full-height-with-navbar">
        {isLoading ? (
          <LoadingIndicator />
        ) : !_.isEmpty(listData) ? (
          <div>
            <h6 className="border-bottom p-2 pt-3 pb-3 position-fixed w-100 bg-white margin-back">
              出金履歴
            </h6>
            <br />
            <br />
            {listData?.map((withdrawal, index) => (
              <div key={index} className="d-flex p-2 border-bottom ms-2">
                <div className="w-75">
                  <div>出金</div>
                  <div className="d-flex align-items-end">
                    {withdrawal.status == 0 ? (
                      <div className="withdrawal-status-check">確認中</div>
                    ) : (
                      <div className="withdrawal-status-done">承認</div>
                    )}
                    <div className="text-small ms-3">
                      {withdrawal.updated_at}
                    </div>
                  </div>
                </div>
                <div className="float-right content-with-coin-small w-25">
                  {numberWithCommas(Number(withdrawal.withdrawal_amount))}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="h-100 d-flex justify-content-center align-items-center">
            出金履歴はありません。
          </div>
        )}
      </div>
    </Layout>
  )
}

export default WithdrawalHistory
