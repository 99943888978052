import { UserItem } from "../../../../components/UserItem"

export const Item = ({
  user,
  onAvatarClick,
  onRightClick,
  onBlock,
  onLabel
}) => {
  return (
    <UserItem
      user={user}
      onAvatarClick={onAvatarClick}
      onRightClick={onRightClick}
      onBlock={onBlock}
      onLabel={onLabel}
    />
  )
}
