import { useHistory } from "react-router-dom"
import { Item } from "./Item"
import Button from "react-bootstrap/Button"
import routes from "../../../../constants/routes"
import { MySchedule } from "../../components/MySchedule"

export const ScheduleList = ({ myPlan, users }) => {
  const history = useHistory()
  const handleClick = (route) => {
    history.push(route)
  }

  return (
    <>
      <div className="bg-white d-flex flex-column align-items-center">
        {(!myPlan || myPlan?.length === 0) ?
          <Button
            onClick={() => handleClick(routes.CREATE_SCHEDULE)}
            variant="danger"
            size="lg"
            className="btn my-3 btn-add-schedule"
          >
            募集する
          </Button> : <MySchedule user={myPlan} />
        }
      </div>
      {users?.length > 0 && (
        <div className="pt-2 wrap-schedule-list">
          {users.map((user, index) => (
            <Item
              key={index}
              user={user}
              onAvatarClick={() => {
                // history.push(`/users/${user.id}`)
              }}
              onRightClick={() => {
                // history.push(`/private-chat/${user.id}`)
              }}
            />
          ))}
        </div>
      )}
    </>
  )
}
