import _ from "lodash"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Item from "./Item"

import "./styles.scss"

const Nav = ({ items }) => {
  return (
    <div className="nav-wrapper">
      <Container>
        <Row className="justify-content-center">
          {_.map(items, (item, idx) => (
            <Col key={idx} xs={_.round(items.length / 12)}>
              <Item {...item} />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  )
}

export default Nav
