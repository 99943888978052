import {
  FETCH_USER_DETAIL_REQUEST,
  FETCH_USER_DETAIL_SUCCESS,
} from "../constants/actions/user"

import { UPDATE_FOLLOW_USER_SUCCESS } from "../constants/actions/follow"

const initialState = {
  detail: {},
}

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USER_DETAIL_REQUEST:
      return {
        ...state,
        detail: {},
      }

    case FETCH_USER_DETAIL_SUCCESS:
      return {
        ...state,
        detail: action.data,
      }

    case UPDATE_FOLLOW_USER_SUCCESS:
      return {
        ...state,
        detail: { ...state.detail, ...action.data },
      }

    default:
      return state
  }
}

export default userReducer
