import { v4 as uuidv4 } from "uuid"
import { takeLatest, put, call } from "redux-saga/effects"
import {
  loginWithEmailSuccess,
  loginWithEmailFailed,
  loginWithPhoneSuccess,
  loginWithPhoneFailed,
  fetchAuthProfileFailed,
  fetchAuthProfileSuccess,
  addDeviceTokenSuccess,
  addDeviceIdFirebaseFailed,
  addDeviceIdFirebaseSuccess
} from "../actions/auth"
import * as actionTypes from "../constants/actions"
import { HTTP_CODE } from "../constants/env"
import {
  loginWithEmail,
  loginWithPhone,
  fetchAuthProfile,
  addDeviceToken
} from "../services/auth"
import { getAccessToken, setAccessToken } from "../utils/auth"
import { addDeviceId } from "../utils/firebase"

function* handleAuthLoginWithEmail(action) {
  const { onSuccess, onFailed } = action.payload.callbacks || {}

  try {
    const { email_for_takeover, password_for_takeover } = action.payload
    const response = yield loginWithEmail(
      email_for_takeover,
      password_for_takeover,
      action
    )
    const accessToken = response?.token
    if (accessToken) {
      if (typeof onSuccess === "function") {
        yield call(onSuccess)
      }
      setAccessToken(accessToken)
      yield put(loginWithEmailSuccess(accessToken))
    } else {
      if (typeof onFailed === "function") {
        yield call(onFailed)
      }
      yield put(loginWithEmailFailed())
    }
  } catch (error) {
    onFailed && onFailed()
    yield put(loginWithEmailFailed())
  }
}

function* handleLoginWithPhone(action) {
  const {
    callbacks: { onSuccess, onFailed },
    phone
  } = action.payload || {}

  try {
    const response = yield loginWithPhone(phone)
    const accessToken = response?.token

    if (accessToken) {
      // FirebaseService.signInAnonymously()
      if (typeof onSuccess === "function") {
        yield call(onSuccess)
      }
      setAccessToken(accessToken)

      // yield call(getUserInfoSaga, accessToken);
      yield put(loginWithPhoneSuccess(accessToken))
    } else {
      if (typeof onFailed === "function") yield call(onFailed, null)
      yield put(loginWithPhoneFailed())
    }
  } catch (error) {
    if (typeof onFailed === "function") yield call(onFailed, error)
  }
}

function* handleFetchAuthProfile(action) {
  const { onSuccess, onFailed } = action?.payload?.callbacks || {}
  try {
    const response = yield fetchAuthProfile(action.payload, action)
    if (response) {
      if (response.status == HTTP_CODE.FORBIDDEN) {
        // App logic: logout <-- Not sure because forbidden, not UNAUTHORIZED
        yield onFailed && call(onFailed, response)
        yield put(fetchAuthProfileFailed(response))
      } else if (response.status == HTTP_CODE.EXPIRED_TOKEN_ERR) {
        yield onFailed && call(onFailed, response)
        yield put(fetchAuthProfileFailed(response))
      } else {
        yield put(fetchAuthProfileSuccess(response))
        yield onSuccess && call(onSuccess, response)
      }
    } else {
      yield put(fetchAuthProfileFailed())
    }
  } catch (error) {
    yield put(fetchAuthProfileFailed(error))
  }
}

function* handleAddDeviceToken(action) {
  const deviceToken = uuidv4()
  const formData = new FormData()
  formData.append("token", deviceToken)
  formData.append("platform", 3)
  const token = getAccessToken()
  try {
    const response = yield addDeviceToken(formData, token)
    yield put(addDeviceTokenSuccess(deviceToken))
  } catch (error) {
    console.log("add device token error: ", error)
  }
}

function* handleAddDeviceIdFirebase(action) {
  const { userId, deviceId } = action.payload
  try {
    yield call(addDeviceId, userId, deviceId)
    yield put(addDeviceIdFirebaseSuccess())
  } catch (err) {
    console.log("Add device id to firebase error: ", err)
    yield put(addDeviceIdFirebaseFailed(err.toString()))
  }
}

export default function* authSaga() {
  yield takeLatest(
    actionTypes.AUTH_LOGIN_WITH_EMAIL_REQUEST,
    handleAuthLoginWithEmail
  )

  yield takeLatest(
    actionTypes.AUTH_LOGIN_WITH_PHONE_REQUEST,
    handleLoginWithPhone
  )

  yield takeLatest(
    actionTypes.FETCH_AUTH_PROFILE_REQUEST,
    handleFetchAuthProfile
  )
  yield takeLatest(actionTypes.ADD_DEVICE_TOKEN_REQUESTED, handleAddDeviceToken)
  yield takeLatest(
    actionTypes.ADD_DEVICE_ID_FIREBASE_REQUESTED,
    handleAddDeviceIdFirebase
  )
}
