import { takeLatest, put, call } from "redux-saga/effects"

import {
  getMessageListSuccess,
  getMessageListFailed,
  sendMessageSuccess,
  sendMessageFailed
} from "../actions/chat"
import {
  GET_MESSAGE_LIST_REQUESTED,
  SEND_MESSAGE_REQUESTED,
  NOTIFY_MESSAGE
} from "../constants/actions"
import { getMessageList, sendMessage } from "../utils/firebase"
import { uploadImage, notifyMessage } from "../services/chat"
import { MESSAGE_TYPES } from "../constants/env"

function* handleGetMessageList(action) {
  try {
    yield getMessageList(
      action.payload.likeId,
      (messages) => {
        action.dispatch(getMessageListSuccess(messages))
      },
      (error) => {
        action.dispatch(getMessageListFailed(error.toString()))
      }
    )
  } catch (error) {
    yield put(getMessageListFailed(error.toString()))
  }
}

function* handleSendMessage(action) {
  try {
    let message = action.payload.message
    if (action.payload.message.type === MESSAGE_TYPES.IMAGE) {
      const res = yield uploadImage(action.payload.message.imageFile)
      let imageUrl = null
      if (res && res.status === 200) {
        imageUrl = res.image_url
        message = { ...message, imageUrl }
        delete message.imageFile
      } else {
        throw new Error("Some error happened")
      }
    }
    yield sendMessage(message, action.payload.likeId)
    yield put(sendMessageSuccess())
  } catch (error) {
    action.onError()
    yield put(sendMessageFailed(error.toString()))
  }
}

function* handleNotifyMessage(action) {
  try {
    const response = yield notifyMessage(action.payload, action)
    const { onSuccess, onFailed } = action.callbacks
    if (response) {
      yield call(onSuccess, response)
    } else {
      yield call(onFailed)
    }
  } catch (error) {}
}

export default function* chatSaga() {
  yield takeLatest(GET_MESSAGE_LIST_REQUESTED, handleGetMessageList)
  yield takeLatest(SEND_MESSAGE_REQUESTED, handleSendMessage)
  yield takeLatest(NOTIFY_MESSAGE, handleNotifyMessage)
}
