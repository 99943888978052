import { takeEvery, put, call } from "redux-saga/effects"
import { fetchUserDetailSuccess, fetchUserDetailFailed } from "../actions/user"
import {
  FETCH_USER_DETAIL_REQUEST,
  UPDATE_BONUS
} from "../constants/actions/user"
import { getUserDetail, updateBonus } from "../services/user"

function* handleGetUserDetail(action) {
  const { onSuccess, onFailed } = action.callbacks
  try {
    const response = yield getUserDetail(action.payload.data, action)
    if (response) {
      if (response.status === 200) {
        if (typeof onSuccess === "function") {
          yield call(onSuccess, response.data)
        }
        yield put(fetchUserDetailSuccess(response.data))
      } else {
        if (typeof onFailed === "function") {
          yield call(onFailed, response.data.error.message)
        }
        yield put(fetchUserDetailFailed())
      }
    } else {
      if (typeof onFailed === "function") {
        yield call(onFailed, "Something wrong happened")
      }
      yield put(fetchUserDetailFailed())
    }
  } catch (error) {
    if (typeof onFailed === "function") {
      yield call(onFailed, error.toString())
    }
    yield put(fetchUserDetailFailed())
  }
}

function* handleUpdateBonus(action) {
  const { onSuccess, onFailed } = action.callbacks
  try {
    const response = yield updateBonus(action)
    if (response) {
      if (typeof onSuccess === "function") {
        yield call(onSuccess, response.data)
      }
      //yield put(fetchUserDetailSuccess(response.data))
    } else {
      if (typeof onFailed === "function") {
        yield call(onFailed)
      }

      //yield put(fetchUserDetailFailed())
    }
  } catch (error) {
    if (typeof onFailed === "function") {
      yield call(onFailed)
    }
    //yield put(fetchUserDetailFailed())
  }
}

export default function* watchUser() {
  yield takeEvery(FETCH_USER_DETAIL_REQUEST, handleGetUserDetail)
  yield takeEvery(UPDATE_BONUS, handleUpdateBonus)
}
