import {
  CHECK_PHONE_NUMBER,
  CHECK_PHONE_NUMBER_FAILED,
  CHECK_PHONE_NUMBER_SUCCESS,
  UPDATE_PHONE_NUMBER,
} from "../constants/actions/signup"

const initialState = {
  checked: false,
  phone: "",
}

const checkPhoneNumberReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHECK_PHONE_NUMBER:
      return {
        ...state,
        checked: false,
      }

    case CHECK_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        checked: true,
        phone: action.data.phone,
      }

    case CHECK_PHONE_NUMBER_FAILED:
      return {
        ...state,
        checked: false,
      }

    case UPDATE_PHONE_NUMBER:
      return {
        ...state,
        phone: action.data,
      }

    default:
      return state
  }
}

export default checkPhoneNumberReducer
