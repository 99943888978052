import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "./styles.scss"

export const Toast = () => {
  const contextClass = {
    default: "bg-indigo-600"
  }

  return (
    <ToastContainer
      className="text-center mb-5 rounded-pill"
      autoClose={2000}
      closeButton={false}
      position="bottom-center"
      draggable={false}
      hideProgressBar
    />
  )
}
