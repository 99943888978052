import classNames from "classnames"
import { Fragment, useContext, useEffect } from "react"
import AppContext from "../../../Context"

import "./styles.scss"

const Alert = ({ type, message }) => {
  const { setContext } = useContext(AppContext)

  useEffect(() => {
    setContext(
      "overlay",
      <div
        className={classNames("alert h-100 d-flex justify-content-center", {
          "align-items-center": type !== "toast",
          "align-items-end pb-5": type === "toast",
        })}
      >
        <p>{message}</p>
      </div>
    )

    return () => {
      setContext("overlay", null)
    }
  }, [])

  return <Fragment />
}

export default Alert
