import _ from "lodash"
import { useSwipeable } from "react-swipeable"
import { useDispatch, useSelector } from "react-redux"
import { useContext, useEffect, useState } from "react"
import { useParams, useHistory, useLocation } from "react-router-dom"

import "./styles.scss"
import AppContext from "../../Context"
import AppBar from "../../components/AppBar"
import Layout from "../../components/Layout"
import UserDetail from "../../components/UserLogin/Detail"
import { selectUser, fetchRelatedUsersRequested } from "../../actions/filter"
import { fetchUserDetailRequested } from "../../actions/user"
import { LoadingIndicator, ErrorModal } from "../../components"

const Users = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { setContext } = useContext(AppContext)
  const myProfile = useSelector((state) => state.auth.profile)
  const userDetail = useSelector((state) => {
    return state.user.detail
  })
  // const relateUsers = useSelector((state) => {
  //   return state.filter.users
  // })
  const relatedUsers = useSelector((state) => state.filter.relatedUsers)
  //const nextId = useSelector((state) => state.filter.next)
  //const prevId = useSelector((state) => state.filter.prev)
  const [nextId, setNextId] = useState(null)
  const [prevId, setPrevId] = useState(null)
  const { id: userId } = useParams()
  const location = useLocation()
  const query = new URLSearchParams(location.search)
  let swipeable = false
  if (location.state) {
    swipeable = location.state.swipeable
  }

  localStorage.setItem("fromdetail", "1")

  const [showActions, setShowActions] = useState(false)

  const onSwipeLeft = (eventData) => {
    var target = eventData.event ? eventData.event.target : eventData.target
    var parent = target.parentElement
    if (
      parent.classList.contains("child-avatar") ||
      target.classList.contains("not-swipe-user")
    ) {
      return
    }
    let from = "list_user"
    for (const [key, value] of query) {
      if (key === "from") {
        if (value.includes("relate")) {
          from = "list_user_relate"
        }
        if (value.includes("like")) {
          from = "list_user_like"
        }
      }
    }
    let saved = localStorage.getItem(from)
    let ids = JSON.parse(saved ? saved : "[]")
    if (nextId) {
      let from = null
      for (const [key, value] of query) {
        if (key === "from") {
          from = value
        }
      }
      dispatch(selectUser(nextId))
      history.replace(`/users/${nextId}?from=${from}`, {
        swipeable: true
      })
    } else if (userId === nextId) {
      return false
    } else {
      dispatch(selectUser(ids[0]))
      history.replace(`/users/${ids[0]}?from=${from}`, {
        swipeable: true
      })
    }
  }

  const onSwipeRight = (eventData) => {
    var target = eventData.event ? eventData.event.target : eventData.target
    var parent = target.parentElement
    if (
      parent.classList.contains("child-avatar") ||
      target.classList.contains("not-swipe-user")
    ) {
      return
    }

    let from = "list_user"
    for (const [key, value] of query) {
      if (key === "from") {
        if (value.includes("relate")) {
          from = "list_user_relate"
        }
        if (value.includes("like")) {
          from = "list_user_like"
        }
      }
    }

    if (prevId) {
      let from = null
      for (const [key, value] of query) {
        if (key === "from") {
          from = value
        }
      }
      dispatch(selectUser(prevId))
      history.replace(`/users/${prevId}?from=${from}`, {
        swipeable: true
      })
    } else {
      return false
    }
  }

  const swipeHandlers = useSwipeable({
    onSwipedRight: onSwipeRight,
    onSwipedLeft: onSwipeLeft
  })

  const handleToggleMenu = () => {
    setShowActions(!showActions)
  }

  useEffect(() => {
    setContext("overlay", <LoadingIndicator />)
    if (userId && myProfile.user_id) {
      dispatch(
        fetchUserDetailRequested(
          { id: userId },
          {
            onSuccess: (response) => {
              setContext("overlay", null)
              if (response.sex === myProfile.sex) {
                history.replace("/")
              }
            },
            onFailed: () => {
              setContext("overlay", null)
              setContext(
                "overlay",
                <ErrorModal
                  show={true}
                  content="退会済みユーザーです"
                  onClose={() => {
                    setContext("overlay", null)
                    history.goBack()
                  }}
                />
              )
            }
          }
        )
      )
    }
  }, [userId, myProfile.user_id])

  useEffect(() => {
    if (!_.isEmpty(userDetail)) {
      dispatch(
        fetchRelatedUsersRequested({
          page: 1,
          perpage: 5,
          sort: localStorage.getItem("sort")
            ? localStorage.getItem("sort")
            : "",
          age_lower: (userDetail?.age ?? 23) - 5,
          age_upper: (userDetail?.age ?? 23) + 5
        })
      )
    }
    let from = "list_user"
    for (const [key, value] of query) {
      if (key === "from") {
        if (value.includes("relate")) {
          from = "list_user_relate"
        }
        if (value.includes("like")) {
          from = "list_user_like"
        }
      }
    }
    let saved = localStorage.getItem(from)
    let ids = JSON.parse(saved ? saved : "[]")

    if (ids.length < 2) {
      setNextId(null)
      setPrevId(null)
    } else {
      let idx = ids.indexOf(userDetail.user_id)

      if (idx <= 0) {
        setPrevId(null)
        setNextId(ids[1])
      } else if (idx >= ids.length - 1) {
        setPrevId(ids[ids.length - 2])
        setNextId(null)
      } else {
        setPrevId(ids[idx - 1])
        setNextId(ids[idx + 1])
      }
    }
    if (!nextId) {
      setNextId(ids[0])
    }
  }, [userDetail.user_id])

  return (
    <Layout>
      <AppBar withBack title={userDetail.nickname} />
      <div
        {...swipeHandlers}
        className="bg-white d-flex flex-column full-height-with-navbar wrap-detail-user"
      >
        {!_.isEmpty(userDetail) && (
          <UserDetail
            menu={showActions}
            onHideMenu={handleToggleMenu}
            user={userDetail}
            // relate={relateUsers}
            relate={relatedUsers}
            swipeAble={swipeable}
            hasNext={nextId}
            hasPrev={prevId}
            onSwipeLeft={onSwipeLeft}
            onSwipeRight={onSwipeRight}
          />
        )}
      </div>
    </Layout>
  )
}

export default Users
