import {
  REGISTER_WITH_PHONE_FAILED,
  REGISTER_WITH_PHONE_SUCCESS,
  REGISTER_WITH_PHONE_REQUEST,
} from "../constants/actions/register"

export const registerWithPhoneRequest = (data = {}, callbacks) => {
  return {
    type: REGISTER_WITH_PHONE_REQUEST,
    payload: {
      data,
      callbacks,
    },
  }
}
export const registerWithPhoneSuccess = (data) => {
  return {
    type: REGISTER_WITH_PHONE_SUCCESS,
  }
}

export const registerWithPhoneFailed = (error) => {
  return {
    type: REGISTER_WITH_PHONE_FAILED,
  }
}
