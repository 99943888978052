import { Button, Modal } from "react-bootstrap"

export const VideoCallConfirmModal = ({ onClose, onConfirm }) => {
  return (
    <Modal show={true} size="sm" centered className="border border-1">
      <div
        className="yu-gothic-bold text-center p-2 pt-3"
        style={{ fontSize: "1rem" }}
      >
        ビデオ通話での禁止事項
      </div>
      <div className="yu-gothic-regular p-3" style={{ fontSize: "0.8rem" }}>
        以下の内容は監視システムによる通話停止対象になりますのでご注意ください。
        <br />
        <br />
        ※違法行為
        <br />
        ※卑猥表現の通話
        <br />
        ※その他利用規約違反に値する行為通話を終了します。
        <br />
        ※最大2分間まで通話ができます。
        <br />
        ※通話開始時は映像がOFFになっています。ご希望の方は途中で自分の映像をONにできます。
      </div>
      <div className="d-flex flex-column text-center">
        <Button
          className="yu-gothic-bold border-0 border-top border-1 bg-white p-2"
          style={{ fontSize: "1rem", borderRadius: 0, color: "#EC3838" }}
          onClick={onConfirm}
        >
          同意して発信する
        </Button>
        <Button
          className="yu-gothic-bold border-0 border-top border-1 bg-white p-2"
          style={{
            fontSize: "1rem",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            color: "#A8A8AB"
          }}
          onClick={onClose}
        >
          キャンセル
        </Button>
      </div>
    </Modal>
  )
}
