import React from "react"
import ReactDOM from "react-dom"

import { Provider } from "react-redux"
import store from "./store"
import { BrowserRouter as Router } from "react-router-dom"
import TagManager from 'react-gtm-module';

import "./index.scss"
import App from "./App"
import reportWebVitals from "./reportWebVitals"

const tagManagerArgs = {
  gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
