import HttpClient from "../utils/client"

export function* uploadImage(imageFile) {
  const url = "/messages/upload-image"
  const data = new FormData()
  data.append("image", imageFile)
  return yield HttpClient("POST", { url, data }, null, "multipart/form-data")
}

export function* notifyMessage(data, action) {
  const url = `/users/${data}/notify-message`
  return yield HttpClient("GET", { url }, action)
}