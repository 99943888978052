import {
  UPDATE_FOLLOW_USER_REQUEST,
  UPDATE_FOLLOW_USER_SUCCESS,
  UPDATE_FOLLOW_USER_FAILED,
} from "../constants/actions/follow"

export const updateFollowUserRequest = (data = {}, callbacks) => {
  return {
    type: UPDATE_FOLLOW_USER_REQUEST,
    payload: {
      data,
      callbacks: callbacks || {}
    }
  }
}
export const updateFollowUserSuccess = (data) => {
  return {
    type: UPDATE_FOLLOW_USER_SUCCESS,
    data,
  }
}

export const updateFollowUserFailed = () => {
  return {
    type: UPDATE_FOLLOW_USER_FAILED,
  }
}
