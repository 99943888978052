import {
  SCHEDULE_LIST_REQUESTED,
  SCHEDULE_LIST_SUCCESS,
  SCHEDULE_LIST_FAILED,
  CREATE_SCHEDULE_REQUESTED,
  CREATE_SCHEDULE_SUCCESS,
  CREATE_SCHEDULE_FAILED,
  SCHEDULE_DETAIL_REQUESTED,
  SCHEDULE_DETAIL_SUCCESS,
  SCHEDULE_DETAIL_FAILED,
  MY_SCHEDULE_REQUESTED,
  MY_SCHEDULE_SUCCESS,
  MY_SCHEDULE_FAILED,
  EDIT_SCHEDULE_REQUESTED,
  EDIT_SCHEDULE_SUCCESS,
  EDIT_SCHEDULE_FAILED,
  DELETE_SCHEDULE_REQUESTED,
  DELETE_SCHEDULE_SUCCESS,
  DELETE_SCHEDULE_FAILED
} from "../constants/actions/schedule"

export const scheduleListRequested = (data, callbacks) => ({
  type: SCHEDULE_LIST_REQUESTED,
  payload: {
    data,
    callbacks: callbacks || {}
  }
})

export const scheduleListSuccess = (data) => ({
  type: SCHEDULE_LIST_SUCCESS,
  data
})

export const scheduleListFailed = () => ({
  type: SCHEDULE_LIST_FAILED
})

export const myScheduleRequested = (data, callbacks) => ({
  type: MY_SCHEDULE_REQUESTED,
  payload: {
    data,
    callbacks: callbacks || {}
  }
})

export const myScheduleSuccess = (data) => ({
  type: MY_SCHEDULE_SUCCESS,
  data
})

export const myScheduleFailed = () => ({
  type: MY_SCHEDULE_FAILED
})

export const scheduleDetailRequested = (data, callbacks) => ({
  type: SCHEDULE_DETAIL_REQUESTED,
  payload: {
    data,
    callbacks: callbacks || {}
  }
})

export const scheduleDetailSuccess = (data) => ({
  type: SCHEDULE_DETAIL_SUCCESS,
  data
})

export const scheduleDetailFailed = () => ({
  type: SCHEDULE_DETAIL_FAILED
})

export const createScheduleRequested = (data = {}, callbacks) => ({
  type: CREATE_SCHEDULE_REQUESTED,
  payload: {
    data,
    callbacks: callbacks || {}
  }
})

export const createScheduleSuccess = (data) => ({
  type: CREATE_SCHEDULE_SUCCESS,
  data
})

export const createScheduleFailed = () => ({
  type: CREATE_SCHEDULE_FAILED
})

export const updateScheduleRequested = (data = {}, callbacks) => ({
  type: EDIT_SCHEDULE_REQUESTED,
  payload: {
    data,
    callbacks: callbacks || {}
  }
})

export const updateScheduleSuccess = (data) => ({
  type: EDIT_SCHEDULE_SUCCESS,
  data
})

export const updateScheduleFailed = () => ({
  type: EDIT_SCHEDULE_FAILED
})

export const deleteScheduleRequested = (data = {}, callbacks) => ({
  type: DELETE_SCHEDULE_REQUESTED,
  payload: {
    data,
    callbacks: callbacks || {}
  }
})

export const deleteScheduleSuccess = (data) => ({
  type: DELETE_SCHEDULE_SUCCESS,
  data
})

export const deleteScheduleFailed = () => ({
  type: DELETE_SCHEDULE_FAILED
})
