import PropTypes from "prop-types"

import "./styles.scss"

const Ticker = ({ size, label, color }) => {
  return (
    <div
      style={{ zIndex: 0 }}
      className={`ticker ticker-${size} ticker-${color}`}
      label={label}
    />
  )
}

Ticker.propTypes = {
  color: PropTypes.string,
  label: PropTypes.string
}

Ticker.defaultProps = {
  color: "red",
  label: "New"
}

export default Ticker
