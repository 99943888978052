import HttpClient from "../utils/client"

export function* getPlans(action) {
  return yield HttpClient("GET", { url: `/plans?app_type=Web` }, action)
}

export function* postSubscribe(action) {
  const payload = {
    url: `/purchase-plan?plan_id=${action.data.plan_id}&credit_type=${action.data.credit_type}`
  }
  return yield HttpClient("GET", payload)
}

export function* postUnSubscribe(action) {
  const payload = {
    url: "/web-unsubscribe",
    data: action.data
  }
  return yield HttpClient("POST", payload, action)
}

export function* postChangeInfoPlan(action) {
  const payload = {
    url: `/change-info-credix?plan_id=${action.data.plan_id}&type_change=${action.data.type_change}`
  }
  return yield HttpClient("GET", payload)
}

export function* renewPlan(setting) {
  const url = `/change-renew-flag?setting=${setting}`

  return yield HttpClient("GET", { url })
}
