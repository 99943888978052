export const loginStatuses = [
  "オフライン",
  "３日以内",
  "オフライン",
  "オンライン",
  "オフライン"
]

export const conditionMeets = [
  {
    id: 1,
    name: "まずは会いたい"
  },
  {
    id: 2,
    name: "まずはお茶から"
  },
  {
    id: 3,
    name: "初回からお食事OK"
  },
  {
    id: 4,
    name: "相手に合わせる"
  },
  {
    id: 5,
    name: "条件が合えば会いたい"
  },
  {
    id: 6,
    name: "気が合えば会いたい"
  },
  {
    id: 7,
    name: "メッセージ交換してから会いたい"
  },
  {
    id: 8,
    name: "まずは通話で話したい"
  },
  {
    id: 9,
    name: "その他"
  }
]

export const bodyMale = [
  {
    id: 1,
    name: "スリム"
  },
  {
    id: 2,
    name: "細め"
  },
  {
    id: 3,
    name: "普通"
  },
  {
    id: 4,
    name: "やや筋肉質"
  },
  {
    id: 5,
    name: "筋肉質"
  },
  {
    id: 6,
    name: "ややぽっちゃり"
  },
  {
    id: 7,
    name: "ぽっちゃり"
  },
  {
    id: 8,
    name: "太め"
  },
  {
    id: 9,
    name: "マッチョ"
  },
  {
    id: 10,
    name: "細マッチョ"
  }
]

export const bodyFeMale = [
  {
    id: 1,
    name: "スリム"
  },
  {
    id: 2,
    name: "細め"
  },
  {
    id: 3,
    name: "普通"
  },
  {
    id: 4,
    name: "グラマー"
  },
  {
    id: 5,
    name: "やや筋肉質"
  },
  {
    id: 6,
    name: "筋肉質"
  },
  {
    id: 7,
    name: "ややぽっちゃり"
  },
  {
    id: 8,
    name: "ぽっちゃり"
  },
  {
    id: 9,
    name: "太め"
  }
]

export const myPersonality = [
  {
    id: 1,
    name: "優しい"
  },
  {
    id: 2,
    name: "素直"
  },
  {
    id: 3,
    name: "決断力がある"
  },
  {
    id: 4,
    name: "穏やか"
  },
  {
    id: 5,
    name: "親しみやすい"
  },
  {
    id: 6,
    name: "明るい"
  },
  {
    id: 7,
    name: "インドア"
  },
  {
    id: 8,
    name: "アウトドア"
  },
  {
    id: 9,
    name: "真面目"
  },
  {
    id: 10,
    name: "知的"
  },
  {
    id: 11,
    name: "誠実"
  },
  {
    id: 12,
    name: "几帳面"
  },
  {
    id: 13,
    name: "楽観的"
  },
  {
    id: 14,
    name: "照れ屋"
  },
  {
    id: 15,
    name: "いつも笑顔"
  },
  {
    id: 16,
    name: "落ち着いている"
  },
  {
    id: 17,
    name: "思いやりがある"
  },
  {
    id: 18,
    name: "社交的"
  },
  {
    id: 19,
    name: "冷静沈着"
  },
  {
    id: 20,
    name: "好奇心旺盛"
  },
  {
    id: 21,
    name: "仕事好き"
  },
  {
    id: 22,
    name: "責任感がある"
  },
  {
    id: 23,
    name: "面倒見が良い"
  },
  {
    id: 24,
    name: "話し上手"
  },
  {
    id: 25,
    name: "さわやか"
  },
  {
    id: 26,
    name: "行動的"
  },
  {
    id: 27,
    name: "負けず嫌い"
  },
  {
    id: 28,
    name: "面白い"
  },
  {
    id: 29,
    name: "気が利く"
  },
  {
    id: 30,
    name: "マメ"
  },
  {
    id: 31,
    name: "寛容"
  },
  {
    id: 32,
    name: "天然と言われる"
  },
  {
    id: 33,
    name: "裏表がない"
  },
  {
    id: 34,
    name: "マイペース"
  },
  {
    id: 35,
    name: "奥手"
  },
  {
    id: 36,
    name: "気分屋"
  },
  {
    id: 37,
    name: "大人数が好き"
  },
  {
    id: 38,
    name: "少人数が好き"
  }
]

export const matchArea = [
  {
    id: 1,
    name: "北海道"
  },
  {
    id: 2,
    name: "青森県"
  },
  {
    id: 3,
    name: "岩手県"
  },
  {
    id: 4,
    name: "宮城県"
  },
  {
    id: 5,
    name: "秋田県"
  },
  {
    id: 6,
    name: "山形県"
  },
  {
    id: 7,
    name: "福島県"
  },
  {
    id: 8,
    name: "茨城県"
  },
  {
    id: 9,
    name: "栃木県"
  },
  {
    id: 10,
    name: "群馬県"
  },
  {
    id: 11,
    name: "埼玉県"
  },
  {
    id: 12,
    name: "千葉県"
  },
  {
    id: 13,
    name: "東京都"
  },
  {
    id: 14,
    name: "神奈川県"
  },
  {
    id: 15,
    name: "新潟県"
  },
  {
    id: 16,
    name: "富山県"
  },
  {
    id: 17,
    name: "石川県"
  },
  {
    id: 18,
    name: "福井県"
  },
  {
    id: 19,
    name: "山梨県"
  },
  {
    id: 20,
    name: "長野県"
  },
  {
    id: 21,
    name: "岐阜県"
  },
  {
    id: 22,
    name: "静岡県"
  },
  {
    id: 23,
    name: "愛知県"
  },
  {
    id: 24,
    name: "三重県"
  },
  {
    id: 25,
    name: "滋賀県"
  },
  {
    id: 26,
    name: "京都府"
  },
  {
    id: 27,
    name: "大阪府"
  },
  {
    id: 28,
    name: "兵庫県"
  },
  {
    id: 29,
    name: "奈良県"
  },
  {
    id: 30,
    name: "和歌山県"
  },
  {
    id: 31,
    name: "鳥取県"
  },
  {
    id: 32,
    name: "島根県"
  },
  {
    id: 33,
    name: "岡山県"
  },
  {
    id: 34,
    name: "広島県"
  },
  {
    id: 35,
    name: "山口県"
  },
  {
    id: 36,
    name: "徳島県"
  },
  {
    id: 37,
    name: "香川県"
  },
  {
    id: 38,
    name: "愛媛県"
  },
  {
    id: 39,
    name: "高知県"
  },
  {
    id: 40,
    name: "福岡県"
  },
  {
    id: 41,
    name: "佐賀県"
  },
  {
    id: 42,
    name: "長崎県"
  },
  {
    id: 43,
    name: "熊本県"
  },
  {
    id: 44,
    name: "大分県"
  },
  {
    id: 45,
    name: "宮崎県"
  },
  {
    id: 46,
    name: "鹿児島県"
  },
  {
    id: 47,
    name: "沖縄県"
  },
]

export const holiday = [
  {
    id: 1,
    name: "土日"
  },
  {
    id: 2,
    name: "平日"
  },
  {
    id: 3,
    name: "不定期"
  },
  {
    id: 4,
    name: "その他"
  }
]