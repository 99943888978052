import AppBar from "../../../components/AppBar"
import Layout from "../../../components/Layout"
import { Image } from "react-bootstrap"
import { useLocation } from "react-router-dom"

const ShowImage = () => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search)
  }

  const query = useQuery()
  let imageUrl = query.get("image")

  return (
		<Layout>
			<AppBar title="" withBack />
			<div
        className="bg-white d-flex flex-column full-height-with-navbar text-center"
      >
				<Image
          src={imageUrl}
        />
			</div>
		</Layout>
	)
}

export default ShowImage
