import * as yup from "yup"
import { Formik } from "formik"
import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import InputGroup from "react-bootstrap/InputGroup"

import matches from "../../../constants/matches"

const schema = yup.object().shape({
  phone: yup.string().required().max(11).matches(matches.phone),
  password: yup.string().required().min(6).matches(matches.phone)
})

const Signup = ({ onSignup, title, phone, password }) => {
  return (
    <Formik
      // validationSchema={schema}
      onSubmit={onSignup}
      initialValues={{ phone: phone, password: password }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors
      }) => (
        <Form noValidate onSubmit={handleSubmit} className="d-flex flex-column">
          <h3 style={{ marginTop: 80 }}>{title || "電話番号の入力"}</h3>
          <p className="mb-4">
            まず、携帯電話番号を入力してください。
            <br />
            入力した電話番号に認証コードを通知します。
          </p>
          <Form.Group controlId="phone">
            <Form.Label>携帯電話番号</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                type="tel"
                placeholder="ハイフンなし"
                name="phone"
                value={values.phone}
                onChange={handleChange}
                onBlur={() => window.scrollTo(0, 0)}
                // isInvalid={!!errors.phone}
              />
              {/* <Form.Control.Feedback type="invalid">
                {_.isEmpty(values.phone)
                  ? messages.emptyPhoneNumber
                  : messages.wrongPhoneNumber}
              </Form.Control.Feedback> */}
            </InputGroup>
          </Form.Group>
          {/* <Form>
          <Form.Group className="mt-4 mb-5" controlId="exampleForm.ControlInput1">
            <Form.Label style={{ fontSize: 20, color: '#707070', fontWeight: 'bold' }}>電話番号</Form.Label>
            <Form.Control
              className="input-outline"
              type="text"
              name="phone"
              value={values.phone}
              onChange={handleChange}
              isInvalid={!!errors.phone}
            />
          </Form.Group>
          <Form.Group className="mb-5" controlId="exampleForm.ControlInput2">
            <Form.Label style={{ fontSize: 20, color: '#707070', fontWeight: 'bold' }}>パスワード</Form.Label>
            <Form.Control
              className="input-outline"
              type="password"
              name="password"
              value={values.password}
              onChange={handleChange}
              isInvalid={!!errors.password}
            />
          </Form.Group> */}

          <Button
            className="align-self-center w-100 mt-3"
            variant="danger"
            type="submit"
            style={{
              fontSize: 20,
              color: "white",
              fontWeight: "bold",
              padding: 12,
              borderRadius: 6
            }}
          >
            次へ
          </Button>
        </Form>
      )}
    </Formik>
  )
}

export default Signup
