import _ from "lodash"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import "./styles.scss"
import Item from "./Components/Item"
import AppBar from "../../../components/AppBar"
import Layout from "../../../components/Layout"
import { ErrorModal } from "../../../components/ErrorModal"
import { getHideListRequested } from "../../../actions/message"

const SkipUsers = () => {
  const dispatch = useDispatch()

  const [errorModal, setErrorModal] = useState(false)

  const error = useSelector((state) => state.likes.error)
  const currentUserId = useSelector((state) => {
    return state.auth.profile.user_id
  })
  const hideList = useSelector((state) => {
    return state.message.hideList
  })

  useEffect(() => {
    dispatch(getHideListRequested(currentUserId, dispatch))
  }, [currentUserId])

  return (
    <Layout>
      <AppBar title="非表示リスト" withBack />
      <div className="bg-white d-flex flex-column px-3 wrap-child-setting">
        {!_.isEmpty(hideList) ? (
          <div className="users">
            {hideList?.map((user, index) => (
              <Item user={user} key={index} />
            ))}
          </div>
        ) : (
          <div className="h-100 w-75 d-flex justify-content-center mx-auto align-items-center text-center">
            あなたが非表示にしたユーザーはまだいません。
          </div>
        )}
        <ErrorModal
          show={errorModal}
          content={error}
          onClose={() => setErrorModal(false)}
        />
      </div>
    </Layout>
  )
}

export default SkipUsers
