import { combineReducers } from "redux"

import errorReducer from "./error"
import authReducer from "./auth"
import filterReducer from "./filter"
import messageReducer from "./message"
import chatReducer from "./chat"
import videoCallReducer from "./videoCall"
import skipUserReducer from "./likes"
import userReducer from "./user"
import mypageReducer from "./mypage"
import planReducer from "./plan"
import buyPointReducer from "./buy-point"
import otherReducer from "./other"
import signupReducer from "./signup"
import footPrintReducer from "./footprint"
import notificationReducer from "./notifications"
import skippedReducer from "./skipped"
import mutedReducer from "./muted"
import scheduleReducer from "./schedule"
import popupsReducer from "./popups"

export default combineReducers({
  error: errorReducer,
  auth: authReducer,
  filter: filterReducer,
  likes: skipUserReducer,
  user: userReducer,
  message: messageReducer,
  chat: chatReducer,
  mypage: mypageReducer,
  plan: planReducer,
  buyPoint: buyPointReducer,
  other: otherReducer,
  signup: signupReducer,
  videoCall: videoCallReducer,
  footPrint: footPrintReducer,
  notification: notificationReducer,
  skipped: skippedReducer,
  muted: mutedReducer,
  schedule: scheduleReducer,
  popups: popupsReducer,
})
