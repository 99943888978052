import AppBar from "../../../components/AppBar"
import Layout from "../../../components/Layout"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import NotificationItem from "../Components/BoxNotificationItem"
import { Empty } from "../Components/Empty"
import {
  fetchDataBoxNotification,
  fetchDataBoxNotificationSuccess,
} from "../../../actions/other"
import { LoadingIndicator } from "../../../components/LoadingIndicator"
import { useHistory } from "react-router-dom"
import InfiniteScroll from "react-infinite-scroller"
import _ from "lodash"
import "./styles.scss"

const BoxNotification = () => {
  const [listNotifications, setListNotifications] = useState([])
  const [countPage, setCountPage] = useState(1)
  const dispatch = useDispatch()
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [hasNextPage, setHasNextPage] = useState(true)
  const perpage = 10

  const apiResult = useSelector((state) => state.notification?.data)

  useEffect(() => {
    let data = []
    if (apiResult) {
      data = listNotifications.concat(apiResult.box_notifications)
      setListNotifications(data)
      setLoading(false)
    }
  }, [apiResult])

  useEffect(() => {
    dispatch(fetchDataBoxNotification({ page: countPage, perpage: perpage }))
    setListNotifications([])
  }, [])

  const loadData = () => {
    if (apiResult) {
      if (apiResult?.box_notifications.length < perpage) {
        setHasNextPage(false)
      } else {
        setCountPage(countPage + 1)
        dispatch(
          fetchDataBoxNotification({ page: countPage + 1, perpage: perpage })
        )
      }
    }
    return false
  }

  const onItemClick = (id) => {
    history.push(`/notice?id=${id}`)
  }

  return (
    <Layout>
      <AppBar title="お知らせ" withBack />
      <div className="bg-white d-flex flex-column full-height-with-navbar pt-2">
        {loading ? (
          <LoadingIndicator />
        ) : !_.isEmpty(listNotifications) ? (
          <div className="notifications">
            <InfiniteScroll
              pageStart={0}
              loadMore={loadData}
              hasMore={hasNextPage}
              loader={<LoadingIndicator key={0} sm />}
              useWindow={false}
            >
              {listNotifications.map((notification, index) => (
                <NotificationItem
                  key={index}
                  id={notification.id}
                  title={notification.title}
                  status={notification.is_read}
                  datetime={notification.created_at}
                  onClick={onItemClick}
                />
              ))}
            </InfiniteScroll>
          </div>
        ) : (
          <Empty />
        )}
      </div>
    </Layout>
  )
}

export default BoxNotification
