import { call, put, takeLatest } from "redux-saga/effects"
import {
  FETCH_POPUPS_REQUEST,
  fetchPopupsSuccess,
  fetchPopupsFailure,
} from "../actions/popups"
import { fetchPopupsApi } from "../services/popups"

function* handleFetchPopups(action) {
  try {
    const response = yield call(fetchPopupsApi)
    yield put(fetchPopupsSuccess(response.data))
  } catch (error) {
    yield put(fetchPopupsFailure(error.message))
  }
}

export default function* watchPopups() {
  yield takeLatest(FETCH_POPUPS_REQUEST, handleFetchPopups)
}