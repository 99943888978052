export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB"

export const GET_MATCH_LIST_REQUESTED = "GET_MATCH_LIST_REQUESTED"
export const GET_MATCH_LIST_SUCCESS = "GET_MATCH_LIST_SUCCESS"
export const GET_MATCH_LIST_FAILED = "GET_MATCH_LIST_FAILED"

export const GET_CHAT_LIST_REQUESTED = "GET_CHAT_LIST_REQUESTED"
export const GET_CHAT_LIST_SUCCESS = "GET_CHAT_LIST_SUCCESS"
export const GET_CHAT_LIST_FAILED = "GET_CHAT_LIST_FAILED"

export const GET_HIDE_LIST_REQUESTED = "GET_HIDE_LIST_REQUESTED"
export const GET_HIDE_LIST_SUCCESS = "GET_HIDE_LIST_SUCCESS"
export const GET_HIDE_LIST_FAILED = "GET_HIDE_LIST_FAILED"

export const GET_UNREAD_MATCH_COUNT_REQUESTED =
  "GET_UNREAD_MATCH_COUNT_REQUESTED"
export const GET_UNREAD_MATCH_COUNT_SUCCESS = "GET_UNREAD_MATCH_COUNT_SUCCESS"
export const GET_UNREAD_MATCH_COUNT_FAILED = "GET_UNREAD_MATCH_COUNT_FAILED"

export const GET_UNREAD_MESSAGE_COUNT_REQUESTED =
  "GET_UNREAD_MESSAGE_COUNT_REQUESTED"
export const GET_UNREAD_MESSAGE_COUNT_SUCCESS =
  "GET_UNREAD_MESSAGE_COUNT_SUCCESS"
export const GET_UNREAD_MESSAGE_COUNT_FAILED = "GET_UNREAD_MESSAGE_COUNT_FAILED"

export const CLEAR_UNREAD_MATCH_COUNT_REQUESTED =
  "CLEAR_UNREAD_MATCH_COUNT_REQUESTED"
export const CLEAR_UNREAD_MATCH_COUNT_SUCCESS =
  "CLEAR_UNREAD_MATCH_COUNT_SUCCESS"
export const CLEAR_UNREAD_MATCH_COUNT_FAILED = "CLEAR_UNREAD_MATCH_COUNT_FAILED"

export const CLEAR_UNREAD_MESSAGE_COUNT_REQUESTED =
  "CLEAR_UNREAD_MESSAGE_COUNT_REQUESTED"
export const CLEAR_UNREAD_MESSAGE_COUNT_SUCCESS =
  "CLEAR_UNREAD_MESSAGE_COUNT_SUCCESS"
export const CLEAR_UNREAD_MESSAGE_COUNT_FAILED =
  "CLEAR_UNREAD_MESSAGE_COUNT_FAILED"

export const GET_MESSAGE_TEMPLATE_REQUESTED = "GET_MESSAGE_TEMPLATE_REQUESTED"
export const GET_MESSAGE_TEMPLATE_SUCCESS = "GET_MESSAGE_TEMPLATE_SUCCESS"
export const GET_MESSAGE_TEMPLATE_FAILED = "GET_MESSAGE_TEMPLATE_FAILED"

export const GET_MESSAGE_TEMPLATE_ME_REQUESTED =
  "GET_MESSAGE_TEMPLATE_ME_REQUESTED"
export const GET_MESSAGE_TEMPLATE_ME_SUCCESS = "GET_MESSAGE_TEMPLATE_ME_SUCCESS"
export const GET_MESSAGE_TEMPLATE_ME_FAILED = "GET_MESSAGE_TEMPLATE_ME_FAILED"

export const CREATE_AND_UPDATE_MESSAGE_TEMPLATE =
  "CREATE_AND_UPDATE_MESSAGE_TEMPLATE"
export const CREATE_AND_UPDATE_MESSAGE_TEMPLATE_SUCCESS =
  "CREATE_AND_UPDATE_MESSAGE_TEMPLATE_SUCCESS"
export const CREATE_AND_UPDATE_MESSAGE_TEMPLATE_FAILED =
  "CREATE_AND_UPDATE_MESSAGE_TEMPLATE_FAILED"

export const DELETE_MESSAGE_TEMPLATE = "DELETE_MESSAGE_TEMPLATE"
export const DELETE_MESSAGE_TEMPLATE_SUCCESS = "DELETE_MESSAGE_TEMPLATE_SUCCESS"
export const DELETE_MESSAGE_TEMPLATE_FAILED = "DELETE_MESSAGE_TEMPLATE_FAILED"
