import { useState, useContext } from "react"
import { Image, Row, Col, Modal } from "react-bootstrap"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import IconMenu from "../../../../icons/ic_dot.png"
import "./styles.scss"
import { StatusDot } from "../../../../components/StatusDot"
import { USER_STATUS, COUNT_PINNED_MAX } from "../../../../constants/env"
import DefaultAvatar from "../../../../images/avatar-app.png"
import { calculateAge, getUserStatus } from "../../../../utils"
import AppContext from "../../../../Context"
import { LoadingIndicator } from "../../../../components/LoadingIndicator"
import { ReportModal } from "../../../../components/ReportModal"
import routes from "../../../../constants/routes"
import { toast } from "react-toastify"
import {
  onDataReport,
  onDataIsMatchRead,
  muteUserRequested,
  labelUserRequested,
  pinUserRequested,
  onHideUser,
  fetchDataListMuted,
  fetchNotiMessageRequested
} from "../../../../actions"
import IconTag from "../../../../icons/tag.png"
import IconMute from "../../../../icons/icon-mute.png"
import IconPinned from "../../../../icons/pinned.png"
import _ from 'lodash'
import moment from "moment"

export const UserItem = ({ user, children, onAvatarClick, onRightClick, currentUserId, countPinned }) => {
  const { setContext } = useContext(AppContext)
  const dispatch = useDispatch()
  const history = useHistory()
  const status = user.last_login
    ? getUserStatus(user.last_login)
    : USER_STATUS.OFFLINE
  const dateTimeStr = user?.lastSentDatetime

  let time = null
  if (dateTimeStr) {
    time = moment(new Date(dateTimeStr)).fromNow()
  }

  const [reportModal, setReportModal] = useState(false)
  const [show, setShow] = useState(false)
  const handleClose = () => {
    setShow(false)
  }

  const onBlock = () => {
    setContext("overlay", <LoadingIndicator />)
    dispatch(
      muteUserRequested(
        { id: user?.id },
        {
          onSuccess: (response) => {
            dispatch(fetchDataListMuted({ page: 1, perpage: 100 }))
            toast("ブロックしました")
            setContext("overlay", null)
            setShow(false)
            history.push(routes.CHAT)
          },
          onFailed: () => {
            setContext("overlay", null)
            setShow(false)
          }
        }
      )
    )
  }

  const onLabel = (toUserId, isLabel) => {
    const data = {
      toUserId: toUserId,
      isLabel: isLabel
    }

    dispatch(
      labelUserRequested(data, {
        onSuccess: () => {
          toast("更新されました")
          setShow(false)
        },
        onFailed: () => {
          setContext("overlay", null)
          setShow(false)
        }
      })
    )
  }

  const onPin = (isPinned) => {
    if(countPinned >= COUNT_PINNED_MAX && !isPinned) {
      toast('１０個まで')
      setShow(false)
      return;
    }
    const data = {
      likeID: user?.likeId,
      fromUserId: currentUserId,
      isPinned: !isPinned
    }

    dispatch(
      pinUserRequested(data, {
        onSuccess: (response) => {
          toast(response.message)
          setContext("overlay", null)
          setShow(false)
        },
        onFailed: (response) => {
          toast(response.message)
          setContext("overlay", null)
          setShow(false)
        }
      })
    )
  }

  const onReport = () => {
    dispatch(
      onDataReport(user?.id, {
        onSuccess: (response) => {
          setShow(false)
          setReportModal(true)
        },
        onFailed: () => {
          setContext("overlay", null)
          setShow(false)
        }
      })
    )
  }

  const onHideUserChat = () => {
    dispatch(
      onHideUser({ likeID: user?.likeId, isHide: true, fromUserId: currentUserId, } , {
        onSuccess: (response) => {
          toast("非表示にしました")
          setShow(false)
        },
        onFailed: () => {
          setContext("overlay", null)
          setShow(false)
        }
      })
    )
  }

  const onIsRead = () => {
    dispatch(
      onDataIsMatchRead({ likeID: user?.likeId, isMatchingRead: true } , {
        onSuccess: (response) => {
          toast("既読しました")
          setShow(false)
        },
        onFailed: () => {
          setContext("overlay", null)
          setShow(false)
        }
      })
    )
  }

  const onPushNotification = (isOffnotificationChat) => {
    const data = {
      likeID: user?.likeId,
      fromUserId: currentUserId,
      isOffnotificationChat: !isOffnotificationChat,
    }
    dispatch(
      fetchNotiMessageRequested(data, {
        onSuccess: (response) => {
          if(isOffnotificationChat) {
            toast('通知をオンしました')
          } else {
            toast('通知をオフしました')
          }
          setContext("overlay", null)
          setShow(false)
        },
        onFailed: (response) => {
          toast(response.message)
          setContext("overlay", null)
          setShow(false)
        }
      })
    )
  }

  const conditionRead = () => {
    if (user.lastMessage?.userId === currentUserId) {
      return 'white'
    }
    if (user.isMatchingRead === 1 && user.lastMessage?.isRead === 1) {
      return 'white'
    }
    return '#FFE3E0';
  }

  return (
    <div
      style={{
        backgroundColor: conditionRead()
      }}
      className="yu-gothic"
    >
      <div className="d-flex border-1 px-2 py-1 user-item">
        <Col
          xs={3}
          md={2}
          className="d-flex justify-content-start align-items-center ps-2"
          style={{ position: "relative" }}
        >
          <StatusDot
            color={
              status === 3 ? "#38C638" : status === 1 ? "#FF7018" : "#939393"
            }
          />
          {user?.is_pinned && (
            <img className="pinned" src={IconPinned} width={22} alt="Icon pinned" />
          )}
          <Image
            className={`img ${
              user?.paymentType === 4
                ? "vip_4"
                : user?.paymentType === 3
                ? "vip_3"
                : user?.paymentType === 2
                ? "vip_2"
                : ""
            }`}
            style={{ cursor: "pointer" }}
            height={60}
            width={60}
            src={user.imageUrl || DefaultAvatar}
            onClick={() => onAvatarClick && onAvatarClick()}
          />
          {(user?.paymentType=== 4 ||
          user?.paymentType === 3 ||
          user?.paymentType === 2) && (
          <p
            className="vip-chat"
            style={{
              backgroundColor:
                user?.paymentType === 4
                  ? "#00205D"
                  : user?.paymentType === 3
                  ? "#5E08B4"
                  : user?.paymentType === 2
                  ? "#35ADD9"
                  : "black"
            }}
          >
            VIP
          </p>
        )}
        </Col>
        <Col
          xs={9}
          md={10}
          className="py-1 d-flex flex-row justify-content-between"
        >
          <Row
            style={{ width: "100%" }}
            onClick={() => {
              onRightClick && onRightClick()
            }}
          >
            <Col>
              <Row>
                <Col className="d-flex justify-content-between" xs={8}>
                  <div className="overflow d-flex align-items-center">
                    <span
                      className="overflow yu-gothic-bold"
                      style={{ fontSize: 14 }}
                    >
                      {user?.nickname || ""} (
                      {user.date_of_birth
                        ? `${calculateAge(user.date_of_birth)}`
                        : `${user?.age}`}
                      )
                    </span>
                    {user?.is_label && (
                      <img className="ms-1" src={IconTag} width={18} alt="Icon tag" />
                    )}
                    {user?.isOffnotificationChat && (
                      <img className="ms-1" src={IconMute} width={18} alt="Icon tag" />
                    )}
                  </div>
                </Col>
                <Col>
                  <div className="d-flex align-items-center yu-gothic-regular float-right">
                    {time === "数秒後" ? "数秒前" : time}
                  </div>
                </Col>
              </Row>
              <Row className="mt-3 align-items-end">
                <Col>{children}</Col>
              </Row>
            </Col>
          </Row>
          <Row 
            className="mt-3 align-items-center"
            onClick={() => setShow(true)}
          >
            <Col>
              <Image
                src={IconMenu}
                fluid
                style={{ transform: "rotate(90deg)", height: 3 }}
              />
            </Col>
          </Row>
        </Col>
        <Modal
          show={show}
          onHide={handleClose}
          centered
          dialogClassName="w-90 mx-auto modal-list-chat"
          user={user}
        >
          <Modal.Body>
            <h6 className="text-center pt-2 pb-2 yu-gothic-bold h4">
              {user?.nickname || ""}
              ({user.date_of_birth
                ? `${calculateAge(user.date_of_birth)}歳`
                : `${user?.age}歳`}
              )
            </h6>
            <button
              className="btn font-simple text-dark w-100 m-0 rounded-0 confirm-button"
              onClick={onIsRead}
              disabled={!((user.isMatchingRead === 0) ||
                ((user.isMatchingRead === 1) && (user.lastMessage?.isRead !== 1) && (user.lastMessage?.userId !== currentUserId)))}
            >
              既読にする
            </button>
            <button
              className="btn font-simple text-dark w-100 m-0 rounded-0 confirm-button"
              onClick={() => {
                onPushNotification(user.isOffnotificationChat)
              }}
            >
              {user.isOffnotificationChat ? "通知オン" : "通知オフ"}
            </button>
            <button
              className="btn font-simple text-dark w-100 m-0 rounded-0 confirm-button"
              disabled={countPinned >= COUNT_PINNED_MAX && !user.is_pinned}
              onClick={() => {
                onPin(_.has(user, 'is_pinned') ? user.is_pinned : false)
              }}
            >
              {user?.is_pinned ? 'ピン外す' : countPinned >= COUNT_PINNED_MAX && !user.is_pinned ? 'ピン留め（１０個まで）' : 'ピン留め'}
            </button>
            <button
              className="btn font-simple text-dark w-100 m-0 rounded-0 confirm-button"
              onClick={() => {
                onLabel(user.id || user.user_id, user?.is_label)
                handleClose()
              }}
            >
              {user.is_label ? "ラベルを削除する" : "ラベル"}
            </button>
            <button
              className="btn font-simple text-dark w-100 m-0 rounded-0 confirm-button"
              onClick={onHideUserChat}
            >
              一覧から非表示
            </button>
            <button
              className="btn font-simple text-dark w-100 m-0 rounded-0 confirm-button"
              onClick={onBlock}
            >
              ブロック
            </button>
            <button
              className="btn font-simple text-dark w-100 m-0 rounded-0 confirm-button"
              onClick={onReport}
            >
              通報する
            </button>
            <button
              className="btn font-simple text-dark w-100 m-0 rounded-0 confirm-button"
              onClick={handleClose}
            >
              キャンセル
            </button>
          </Modal.Body>
        </Modal>
        <ReportModal
          show={reportModal}
          onClose={() => setReportModal(false)}
        />
      </div>
    </div>
  )
}
