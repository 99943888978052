import classnames from "classnames"
import { UserItem } from "../UserItem"
import VideoIcon from "../../../../svgs/Video.svg"
import "../styles.scss"

export const Item = ({ user, currentUserId, onAvatarClick, onRightClick, countPinned }) => {
  const isFromMe =
    currentUserId?.toString() === user.lastMessage?.userId?.toString()

  const renderLastMessage = () => {
    switch (user.lastMessage.type) {
      case "text":
        return <>{user.lastMessage.message}</>
      case "video_call":
        return (
          <>
            <img src={VideoIcon} alt="Not found" />
            <span style={{ marginLeft: "0.5rem" }}>
              {user?.messages[0]?.videoCallStartDatetime ? '通話を終了しました' : isFromMe ? '通話をキャンセルしました' : '不在着信'}
            </span>
          </>
        )
      case "image":
        return (
          <>
            {isFromMe
              ? "写真を送信しました"
              : `${user.nickname} が写真を送信しました`}{" "}
          </>
        )

      default:
        return null
    }
  }

  const renderRelatedMessage = () => {
    if(user?.countRelatedMessage && user.countRelatedMessage > 0) {
      return (
        <>
          <div className="one-line-text related-message">
            {user.countRelatedMessage} 件が該当しました
          </div>
        </>
      )
    }

    return null
  }

  return (
    <UserItem
      user={user}
      onAvatarClick={onAvatarClick}
      onRightClick={onRightClick}
      currentUserId={currentUserId}
      countPinned={countPinned}
    >
      <div className="d-flex justify-content-between align-items-center">
        <div
          className={classnames(
            "one-line-text",
            "message",
            user.lastMessage && !isFromMe && !user.lastMessage.isRead ? "unread" : "read",
            user.lastMessage && !isFromMe && !user.lastMessage.isRead && "yu-gothic-bold",
            "w-100",
            "text-downline-list"
          )}
        >
          {user?.lastMessage?.type ? renderLastMessage() : 'マッチしました！先にメッセージを送ると成約率UP！'}
        </div>
        {user.lastMessage && !isFromMe && user.unreadMessageCount > 0 && (
          <div className="new-message-circle">{user.unreadMessageCount}</div>
        )}
      </div>
      { renderRelatedMessage() }
    </UserItem>
  )
}
