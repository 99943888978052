import {
  FETCH_PLAN_REQUEST,
  FETCH_PLAN_SUCCESS,
  FETCH_PLAN_FAILED,
  SUBSCRIBE_PLAN_FAILED,
  SUBSCRIBE_PLAN_REQUEST,
  SUBSCRIBE_PLAN_SUCCESS,
  UNSUBSCRIBE_PLAN_FAILED,
  UNSUBSCRIBE_PLAN_REQUEST,
  UNSUBSCRIBE_PLAN_SUCCESS,
  CHANGE_INFO_CREDIT_REQUEST,
  CHANGE_INFO_CREDIT_SUCCESS,
  CHANGE_INFO_CREDIT_FAILED,
  RENEW_PLAN_REQUEST
} from "../constants/actions/plan"

export const fetchPlanRequest = (callbacks) => {
  return {
    type: FETCH_PLAN_REQUEST,
    payload: {
      data: {}
    },
    callbacks
  }
}
export const fetchPlanSuccess = (data) => {
  return {
    type: FETCH_PLAN_SUCCESS,
    data
  }
}

export const fetchPlanFailed = (data) => {
  return {
    type: FETCH_PLAN_FAILED,
    data
  }
}

export const subscribePlanRequest = (data, callbacks) => {
  return {
    type: SUBSCRIBE_PLAN_REQUEST,
    data,
    callbacks
  }
}
export const subscribePlanSuccess = (data) => {
  return {
    type: SUBSCRIBE_PLAN_SUCCESS,
    data
  }
}

export const subscribePlanFailed = (data) => {
  return {
    type: SUBSCRIBE_PLAN_FAILED,
    data
  }
}

export const unSubscribePlanRequest = (data, callbacks) => {
  return {
    type: UNSUBSCRIBE_PLAN_REQUEST,
    data,
    callbacks
  }
}
export const unSubscribePlanSuccess = (data) => {
  return {
    type: UNSUBSCRIBE_PLAN_SUCCESS,
    data
  }
}

export const unSubscribePlanFailed = (data) => {
  return {
    type: UNSUBSCRIBE_PLAN_FAILED,
    data
  }
}

export const changeInfoPlanRequest = (data, callbacks) => {
  return {
    type: CHANGE_INFO_CREDIT_REQUEST,
    data,
    callbacks
  }
}
export const changeInfoPlanSuccess = (data) => {
  return {
    type: CHANGE_INFO_CREDIT_SUCCESS,
    data
  }
}

export const changeInfoPlanFailed = (data) => {
  return {
    type: CHANGE_INFO_CREDIT_FAILED,
    data
  }
}

export const renewMyPlanRequest = (data, callbacks) => {
  return {
    type: RENEW_PLAN_REQUEST,
    data,
    callbacks
  }
}
