import * as actionTypes from "../constants/actions"

const initialState = {
  data: null,
}

const footPrintReducer = (state = initialState, action) => {
  let data = null
  switch (action.type) {
    case actionTypes.FETCH_DATA_FOOT_PRINTS:
      return {
        data,
      }
    case actionTypes.FETCH_DATA_FOOT_PRINTS_SUCCESS:
      data = action.payload.data
      return {
        data,
      }
    case actionTypes.FETCH_DATA_FOOT_PRINTS_FAIL:
      return {
        data,
      }

    case actionTypes.FETCH_DATA_FOOT_UPDATE:
      return true
    case actionTypes.FETCH_DATA_FOOT_UPDATE_SUCCESS:
      return true
    case actionTypes.FETCH_DATA_FOOT_UPDATE_FAIL:
      return false
    default:
      return state
  }
}

export default footPrintReducer
