import { Modal } from "react-bootstrap"
import { Image } from "react-bootstrap"
import Like from "../../icons/good_red.png"
import { useEffect } from "react"

export const ToastLikeIcon = ({ show, onClose, content }) => {
  useEffect(() => {
    setTimeout(() => {
      onClose()
    }, 5000)
  })

  return (
    <Modal size="lg" show={show} centered className="toast-like-icon">
      <Image src={Like} width={100} />
      <p className="my-0 toast-like-text">{content}</p>
    </Modal>
  )
}
