import { useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { TabPanel } from "react-headless-tabs"
import {
  getUnreadMatchCountRequested,
  getUnreadMessageCountRequested
} from "../../actions/message"
import AppBar from "../../components/AppBar"
import Layout from "../../components/Layout"
import { MatchedList } from "./components/listMessage/List"
import { useHistory, useLocation } from "react-router-dom"
import { filterAccountPopups } from "../../components/FilterAccountPopups";
import CustomPopups from "../../components/CustomPopups";

import "./styles.scss"

const Chats = () => {
  const dispatch = useDispatch()
  const [bannerHeight, setBannerHeight] = useState(0)
  const currentUserId = useSelector((state) => state.auth.profile.user_id)
  const history = useHistory()
  const location = useLocation()

  const profileUserData = useSelector((state) => state.auth.profile);
  const data = useSelector((state) => state.popups.data);
  const [filteredData, setFilteredData] = useState([]);
  const [openPopups, setOpenPopups] = useState(false);
  const [statePopUps, setOpenStatePopUps] = useState(false);

  const filterAndSetData = () => {
    const result = filterAccountPopups(data, profileUserData);
    const validFilteredData = result.filter(item => item !== []);

    if (validFilteredData.length > 0 && result.length > 0
      && validFilteredData[0]?.screen_active === location.pathname.replace(/^\//, '')) {
      setFilteredData(validFilteredData);
      if (!statePopUps) {
        setOpenPopups(true);
      }
    }
  };

  useEffect(() => {
    filterAndSetData();
  }, [data, profileUserData, openPopups]);

  const handleClosePopups = () => {
    setOpenStatePopUps(true);
    setOpenPopups(false);
  };

  useEffect(() => {
    dispatch(getUnreadMatchCountRequested(currentUserId, dispatch))
    dispatch(getUnreadMessageCountRequested(currentUserId, dispatch))
  }, [])

  return (
    <Layout nav>
      <AppBar
        title="トーク"
        search
        placeholder=" "
        banner
        setBannerHeight={setBannerHeight}
      />
      <div
        className="bg-white d-flex flex-column full-height-with-navbar wrap-message-list"
        style={{ top: bannerHeight + 90 }}
      >
        <div className="h-100">
          <div className="h-100">
            <TabPanel className="h-100">
              <MatchedList valueSearch={history.location.state?.values} />
            </TabPanel>
          </div>
        </div>
      </div>
      {(openPopups && filteredData?.length > 0) && <CustomPopups openPopups={openPopups} onClose={handleClosePopups} filteredData={filteredData[0]} />}
    </Layout>
  )
}

export default Chats
