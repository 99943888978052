import moment from "moment"
import { USER_IMAGE_TYPES } from "../constants/env"

export const calculateAge = (dob) => {
  const diff_ms = Date.now() - new Date(dob).getTime()
  const age_dt = new Date(diff_ms)
  return Math.abs(age_dt.getUTCFullYear() - 1970)
}

export const getUserStatus = (lastLogin) => {
  const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
  const lastLoginDate = new Date(lastLogin)
  const todaysDate = new Date() // Current date
  const diffDays = Math.round(Math.abs((lastLoginDate - todaysDate) / oneDay))

  if (diffDays <= 1) {
    return 3
  } else if (diffDays > 1 && diffDays <= 3) {
    return 1
  } else {
    return 0
  }
}

export const getAvatarUrl = (images) => {
  let avatarUrl = null
  if (images) {
    for (let image of images) {
      if (image.type === USER_IMAGE_TYPES.AVATAR && image.display_order === 1) {
        avatarUrl = image.image_url
        break
      }
    }
  }
  return avatarUrl
}

export const getLikeTime = (likedDatetime) => {
  const hour = moment(likedDatetime).format("HH:mm")
  const today = moment().format("YYYY/MM/DD")
  const likeDay = moment(likedDatetime).format("YYYY/MM/DD")
  if (today !== likeDay) {
    var day = likeDay
  } else {
    day = ""
  }
  return {
    date: day.trim(),
    time: hour.trim()
  }
}

export const getSentDateTime = (sentDateTime) => {
  const hour = moment(sentDateTime).format("HH:mm")
  const today = moment().format("YYYY/MM/DD")
  const likeDay = moment(sentDateTime).format("YYYY/MM/DD")
  if (today !== likeDay) {
    var day = likeDay
  } else {
    day = ""
  }
  return {
    date: day.trim(),
    time: hour.trim()
  }
}

export const b64toBlob = (b64Data, contentType = "", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    byteArrays.push(new Uint8Array(byteNumbers));
  }

  return new Blob(byteArrays, { type: contentType });
};

export const millisToTimeString = (millis) => {
  const minutes = Math.floor(millis / 60000)
  const seconds = Math.floor((millis % 60000) / 1000)
  return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`
}
