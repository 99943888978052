import Form from "react-bootstrap/Form"
import Button from "react-bootstrap/Button"
import InputGroup from "react-bootstrap/InputGroup"
import { Formik } from "formik"
import * as yup from "yup"
import messages from "../../../constants/messages"

const schema = yup.object().shape({
  email_for_takeover: yup.string().email().required(),
  password_for_takeover: yup.string().required().min(8),
})

const Login = ({ onSubmit }) => {
  return (
    <Formik
      validationSchema={schema}
      onSubmit={onSubmit}
      initialValues={{ email_for_takeover: "", password_for_takeover: "" }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
      }) => (
        <Form
          noValidate
          onSubmit={handleSubmit}
          className="d-flex flex-column full-height-with-navbar"
        >
          <h1>アカウントの引き継ぎ</h1>
          <p className="mb-5">
            以前登録したメールアドレスとパスワードを入力して ください。
          </p>
          <Form.Group className="mb-3" controlId="email">
            <Form.Label>メールアドレス</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                name="email_for_takeover"
                type="email"
                placeholder="●●●●＠●●●●.com"
                value={values.email_for_takeover}
                onChange={handleChange}
                isInvalid={!!errors.email_for_takeover}
              />
              <Form.Control.Feedback type="invalid">
                {messages.emailInvalid}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3" controlId="password">
            <Form.Label>パスワード</Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                name="password_for_takeover"
                type="password"
                placeholder="半角英数字８文字以上"
                value={values.password_for_takeover}
                onChange={handleChange}
                isInvalid={!!errors.password_for_takeover}
              />
              <Form.Control.Feedback type="invalid">
                {messages.wrongPasswordFormat}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <div className="mt-auto py-3">
            <div className="d-grid">
              <Button
                className="align-self-center"
                variant="danger"
                type="submit"
              >
                次へ
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Login
