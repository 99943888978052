import { getAuth, RecaptchaVerifier } from "firebase/auth"
import { useEffect, useState } from "react"
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"

import "./styles.scss"
import _ from "lodash"

const SMS = ({ onRefresh, onSubmit, onChange, reSendCode }) => {
  const [verified, setVerified] = useState(false)
  let [segments, setSements] = useState(_.times(6, ""))

  const handleSegmentChange = (seg, val) => {
    segments[seg] = val
    setSements(segments)

    if (val !== "") {
      // Get the next input field
      const nextSibling = document.querySelector(
        `input[name=segment-${parseInt(seg, 10) + 1}]`
      )

      // If found, focus the next field
      if (nextSibling !== null) {
        nextSibling.focus()
      }
    }

    onChange({ target: { value: segments.join("") } })
  }

  useEffect(() => {
    const inputs = document.getElementsByClassName("input-segment-sms")

    _.map(inputs, (el, idx) => {
      el.onkeydown = (e) => {
        var key = e.charCode || e.keyCode || 0
        // allow backspace, tab, delete, enter, arrows, numbers and keypad numbers ONLY
        // home, end, period, and numpad decimal
        if (
          !(
            key === 8 ||
            key === 9 ||
            key === 13 ||
            key === 46 ||
            key === 110 ||
            key === 190 ||
            (key >= 35 && key <= 40) ||
            (key >= 48 && key <= 57) ||
            (key >= 96 && key <= 105)
          )
        ) {
          e.preventDefault()
        }
        if (key === 8 && segments[idx - 1] !== undefined) {
          const nexSibling = document.querySelector(
            `input[name=segment-${parseInt(idx, 10) + 1}]`
          )
          if (
            !(nexSibling && nexSibling.length > 0) &&
            segments[idx] !== "" &&
            segments[idx] !== undefined
          ) {
            segments[idx] = ""
          } else {
            segments[idx - 1] = ""
            const prevSibling = document.querySelector(
              `input[name=segment-${parseInt(idx, 10) - 1}]`
            )
            // If found, focus the next field
            if (prevSibling !== null) {
              prevSibling.focus()
            }
          }
          setSements(segments)
        }
      }
    })

    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          setVerified(true)
        }
      },
      getAuth()
    )

    window.recaptchaVerifier.render()
    window.recaptchaVerifier.verify()

    return () => {
      if (window.recaptchaVerifier) {
        window.recaptchaVerifier.clear()
      }
    }
  }, [])

  return (
    <Form
      onSubmit={onSubmit}
      className="d-flex flex-column disable-focus hide-scroll"
    >
      <h1 className="mt-3 fs-3 text-grey-bold">SMS認証</h1>
      <p className="mb-5 fs-6 text-grey">
        SMSで届いた認証コードを入力してください。
      </p>
      <Form.Group className="mb-3" controlId="otp">
        <Form.Label className="fs-4 text-grey-bold">認証コード</Form.Label>
        <Row>
          {_.map(Array(6), (_v, idx) => (
            <Col key={idx}>
              <Form.Control
                className="input-segment-sms"
                placeholder=""
                type="tel"
                name={`segment-${idx}`}
                maxLength={1}
                onChange={({ target: { value } }) =>
                  handleSegmentChange(idx, value)
                }
                onBlur={() => window.scrollTo(0, 0)}
              />
            </Col>
          ))}
        </Row>
      </Form.Group>
      <div id="recaptcha-container"></div>
      <Button
        className="align-self-center w-100 mt-3"
        variant="danger"
        type="submit"
        disabled={!verified}
        style={{
          fontSize: 20,
          fontWeight: "bold",
          padding: 10,
          borderRadius: 6
        }}
      >
        次へ
      </Button>
      <Button
        onClick={onRefresh}
        className="btn-resend mt-4 fs-4 text-grey-bold align-self-center w-100"
        variant="outline-dark"
        size="lg"
        type="button"
        disabled={reSendCode}
      >
        認証コードを再送
      </Button>
      <br />
      <br />
    </Form>
  )
}

export default SMS
