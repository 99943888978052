import _ from "lodash"
import { useContext, useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import AppBar from "../../../components/AppBar"
import Layout from "../../../components/Layout"
import { LoadingIndicator } from "../../../components/LoadingIndicator"
import SMSForm from "../../../components/Form/SMS"
import AppContext from "../../../Context"
import {
  upsertAuthProfile,
  loginWithPhoneRequest,
  fetchAuthProfileRequest,
  showPopupAfterLogin,
  addDeviceTokenRequested
} from "../../../actions/auth"

import { updatePhoneNumber } from "../../../actions/signup"

import messages from "../../../constants/messages"
import { sendVerifyCode, signOut } from "../../../utils/firebase"
import routes from "../../../constants/routes"

const VerifyPhone = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { setContext } = useContext(AppContext)
  const [code, setCode] = useState("")
  const [confirm, setConfirm] = useState(null)

  const phone = useSelector((state) => {
    return state.auth.phone
  })

  async function confirmCode(code) {
    try {
      await confirm.confirm(code)
      dispatch(
        loginWithPhoneRequest(phone, {
          onSuccess: async () => {
            setTimeout(() => {
              dispatch(
                fetchAuthProfileRequest(
                  {},
                  {
                    onSuccess: async (response) => {
                      dispatch(upsertAuthProfile({ phoneVerified: true }))
                      dispatch(updatePhoneNumber(""))
                      dispatch(showPopupAfterLogin(true))
                      setContext("overlay", null)
                      setTimeout(() => {
                        if (response.is_created == 0) {
                          history.push({
                            pathname: routes.REGISTER_PROFILE,
                            search: "?login=true"
                          })
                        } else {
                          dispatch(addDeviceTokenRequested())
                          history.push({
                            pathname: routes.USERS,
                            search: "?login=true"
                          })
                        }
                      }, 100)
                    },
                    onFailed: () => {
                      setContext("overlay", null)
                      alert(messages.somethingWrong)
                    }
                  }
                )
              )
            }, 100)
          },
          onFailed: () => {
            setContext("overlay", null)
            alert(messages.registerFail)
          }
        })
      )
    } catch (error) {
      setContext("overlay", null)
      alert(messages.invalidCode)
    }
  }

  const handleConfirmCode = (e) => {
    e.preventDefault()
    setContext("overlay", <LoadingIndicator />)
    if (_.isEmpty(code)) {
      alert(messages.emptyCode)
      setContext("overlay", null)
      return
    }

    confirmCode(code)
  }

  const handleChange = ({ target: { value } }) => {
    setCode(value)
  }

  const handleSendVerifySuccess = (isResend) => (confirmation) => {
    setContext("overlay", null)
    setConfirm(confirmation)
    if (isResend) alert(messages.resendCodeSuccesss)
  }

  const handleSendVerifyFailure = (isResend) => () => {
    setContext("overlay", null)
    if (isResend) alert(messages.codeSendFail)
  }

  useEffect(() => {
    if (_.isEmpty(phone)) {
      history.push(routes.TITLE)
    } else {
      setContext("overlay", <LoadingIndicator />)
      signOut()
      sendVerifyCode(
        phone,
        handleSendVerifySuccess(false),
        handleSendVerifyFailure(false)
      )
    }
  }, [])

  return (
    <Layout height={false}>
      <AppBar withBack title="TUTUにログイン" colorTitle="red" />
      <div className="container" style={{ paddingTop: 63 }}>
        <SMSForm
          onChange={handleChange}
          onRefresh={() => {
            setContext("overlay", <LoadingIndicator />)
            sendVerifyCode(
              phone,
              handleSendVerifySuccess(true),
              handleSendVerifyFailure(true)
            )
          }}
          onSubmit={handleConfirmCode}
        />
      </div>
    </Layout>
  )
}

export default VerifyPhone
