import { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import Nav from "react-bootstrap/Nav"
import Form from "react-bootstrap/Form"
import { Image } from "react-bootstrap"
import { useSelector } from "react-redux"
import Navbar from "react-bootstrap/Navbar"
import { useHistory } from "react-router-dom"
import Container from "react-bootstrap/Container"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import "./styles.scss"
import Search from "../../icons/search.svg"
import Filter from "../../pages/Users/Filter"
import FilterSchedule from "../../pages/Users/FilterSchedule"
import FilterChat from "../../pages/Users/FilterChat"
import Logo from "../../images/startlogo.png"
import { TopBanner } from "../../components/TopBanner"
import { getBannerRequested } from "../../actions"
import {useDispatch} from "react-redux"
import { useEffect } from "react"

const NavBar = ({
  withBack,
  onGoBack,
  backToLink,
  title,
  colorTitle,
  notification,
  action,
  handleAction,
  className,
  logo,
  search,
  placeholder,
  banner,
  titleLeft,
  noBorderBottom,
  setBannerHeight
}) => {
  let history = useHistory()
  const dispatch = useDispatch()
  const [message, setMessage] = useState('');
  let [open, setOpen] = useState(false)
  let [openFilterSchedule, setOpenFilterSchedule] = useState(false)
  let [openFilterChat, setOpenFilterChat] = useState(false)

  const callbackFunction = (childData) => {
    setMessage(childData)
  }

  const handleGoBack = (route) => {
    if (typeof onGoBack === "function") {
      onGoBack()
      return
    }
    history.goBack(route)
  }

  const handleGoBackLink = (route) => {
    window.location.href = backToLink
  }

  const handleGoSearch = () => {
    if (!openFilterSchedule && window.location.pathname === "/schedules") {
      setOpenFilterSchedule(true)
    } else if (!openFilterChat && window.location.pathname === "/chats") {
      setOpenFilterChat(true)
    } else if (!open && window.location.pathname === "/users") {
      setOpen(true)
    }
  }

  const handleClose = () => {
    setTimeout(() => {
      setOpen(false)
    }, 1)
  }

  const handleCloseFilterSchedule = () => {
    setTimeout(() => {
      setOpenFilterSchedule(false)
    }, 1)
  }

  const handleCloseFilterChat = () => {
    setTimeout(() => {
      setOpenFilterChat(false)
    }, 1)
  }

  const profile = useSelector((state) => {
    return state.auth.profile
  })

  useEffect(() => {
    dispatch(getBannerRequested())
  }, [dispatch])

  const banners = useSelector((state) => state.other.banners)

  return (
    <div className={noBorderBottom ? "header-fixed border-0" : "header-fixed"}>
      <Navbar className={className}>
        <Container className="nav-container d-flex">
          {(withBack || onGoBack) && (
            <Navbar.Brand onClick={handleGoBack} className="back-btn">
              <FontAwesomeIcon icon={faArrowLeft} />
            </Navbar.Brand>
          )}

          {backToLink && (
            <Navbar.Brand onClick={handleGoBackLink} className="back-btn">
              <FontAwesomeIcon icon={faArrowLeft} />
            </Navbar.Brand>
          )}

          <Navbar.Collapse
            className={classNames("show justify-content-end navbar-title", {
              "with-back": withBack || onGoBack,
              "with-notification": notification,
              "with-actions": action === "" ? false : true
            })}
          >
            <Nav
              className="nav-stretch div-title"
              style={{
                paddingLeft: backToLink || withBack || onGoBack ? 25 : 0,
                textAlign: search || titleLeft ? "start" : "center"
              }}
            >
              <Navbar.Text
                className={`w-100 mb-0 fw-bold text-center py-0 h4 navbar-text title text-truncate-${colorTitle}`}
              >
                {title}
              </Navbar.Text>
            </Nav>

            {logo && (
              <Nav className="logo-nav justify-content-center">
                <Image src={Logo} width={150} />
              </Nav>
            )}

            {search && (
              <Navbar className="search-btn" onClick={handleGoSearch}>
                <Form>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Image className="icon-search" src={Search} width={20} />
                    <Form.Control
                      className="input-search"
                      size="sm"
                      placeholder={placeholder || "好みの相手を検索する"}
                      // disabled
                      value={(message?.valueKeyword || message?.valueKeyword === "") ? message?.valueKeyword : message}
                    />
                  </Form.Group>
                </Form>
                <Filter open={open} onClose={handleClose} parentCallback={callbackFunction} />
                <FilterSchedule
                  openFilterSchedule={openFilterSchedule}
                  onCloseFilterSchedule={handleCloseFilterSchedule}
                />
                <FilterChat
                  openFilterChat={openFilterChat}
                  onCloseFilterChat={handleCloseFilterChat}
                  parentCallback={callbackFunction}
                />
              </Navbar>
            )}

            <Nav className="div-notify">
              {action && (
                <div className="action" onClick={() => handleAction()}>
                  {action}
                </div>
              )}

              {notification && (
                <div
                  className="notification me-3"
                  onClick={() => {
                    window.location = "/notices"
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="44.4"
                    height="47.396"
                    viewBox="0 0 44.4 47.396"
                  >
                    <g
                      id="Icon_feather-bell"
                      data-name="Icon feather-bell"
                      transform="translate(-3 -1.5)"
                    >
                      <path
                        id="パス_1"
                        data-name="パス 1"
                        d="M39,18.456C39,9.92,32.822,3,25.2,3S11.4,9.92,11.4,18.456c0,18.032-6.9,23.183-6.9,23.183H45.9S39,36.487,39,18.456"
                        transform="translate(0 0)"
                        fill="none"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="3"
                      />
                      <path
                        id="パス_2"
                        data-name="パス 2"
                        d="M19.391,31.5a2.3,2.3,0,0,1-3.986,0"
                        transform="translate(7.802 14.747)"
                        fill="none"
                        stroke="#000"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="3"
                      />
                    </g>
                  </svg>
                  {profile && profile?.countNotifications > 0 && (
                    <span className="bubble" />
                  )}
                </div>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {banner && banners[0]?.active === 0 && (
        <Nav className="logo-nav justify-content-center max-w-screen-xl">
          <TopBanner setBannerHeight={setBannerHeight} />
        </Nav>
      )}
    </div>
  )
}

NavBar.propTypes = {
  withBack: PropTypes.bool,
  notification: PropTypes.bool,
  actions: PropTypes.string,
  handleAction: PropTypes.func
}

NavBar.defaultProps = {
  notification: false
}

export default NavBar
