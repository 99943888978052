import { useEffect } from "react"
import _ from "lodash"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { getChatListRequested, setUnreadMessageNumber } from "../../../../actions/message";
import { LoadingIndicator } from "../../../../components/LoadingIndicator"
import { Item } from "./Item"
import { EmptySecondTab } from "./EmptySecondTab"
import {
  onDataIsMatchRead
} from "../../../../actions"
import moment from "moment"

export const MatchedList = ({ valueSearch }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const isLoading = useSelector((state) => state.message.isLoading)
  const currentUserId = useSelector((state) => state.auth.profile.user_id)
  const users = useSelector((state) => state.message.chatList)

  var usersSorted = _.orderBy(users, (user) => [user.pinned_at, user?.lastMessage?.createdAt?.seconds || moment(user.matchingDatetime).unix(), user.matchingDatetime ], ['desc', 'desc', 'desc'])

  const countPinned = _.without(_.map(users, function(user) {
    return user.is_pinned ? user : null;
  }), null).length;

  useEffect(() => {
    let count = 0
    users.forEach((user) => {
      if (conditionRead(user) === '#FFE3E0') {
        count++
      }
    })
    dispatch(setUnreadMessageNumber(count))
  }, [users, currentUserId, dispatch])

  const conditionRead = (user, currentUserId) => {
    if (user.lastMessage?.userId === currentUserId) {
      return 'white';
    }
    if (user.isMatchingRead === 1 && user.lastMessage?.isRead === 1) {
      return 'white';
    }
    return '#FFE3E0';
  };

  useEffect(() => {
    dispatch(getChatListRequested(currentUserId, dispatch))
  }, [currentUserId])

  var usersSorted = !valueSearch ? usersSorted : usersSorted.filter((user) => {
    var conditionLabel = (user?.is_label === true)
    if (valueSearch?.label) {
      return conditionLabel;
    }
    return true;
  }).filter((user) => {
    var conditionUnRead = (((user.isMatchingRead === 0)  && (user.lastMessage?.userId !== currentUserId)) ||
    ((user.isMatchingRead === 1) && (user.lastMessage?.isRead !== 1) && (user.lastMessage?.userId !== currentUserId)));
    var conditionRead = !conditionUnRead
    if (valueSearch?.read) {
      return conditionRead;
    }
    return true;
  }).filter((user) => {
    var conditionUnRead = (((user.isMatchingRead === 0)  && (user.lastMessage?.userId !== currentUserId)) ||
    ((user.isMatchingRead === 1) && (user.lastMessage?.isRead !== 1) && (user.lastMessage?.userId !== currentUserId)));
    if (valueSearch?.unread) {
      return conditionUnRead;
    }
    return true;
  }).filter((user) => {
    var conditionNoReply = (user.lastMessage?.userId !== currentUserId)
    if (valueSearch?.reply) {
      return conditionNoReply;
    }
    return true;
  })

  if(!_.isEmpty(valueSearch?.valueKeyword)) {
    var userFilter = [];
    let relatedMessages = [];
    usersSorted.map((user) => {
      if(user.nickname.includes(valueSearch.valueKeyword)) {
        userFilter.push(user)
      }

      if(!_.isEmpty(user.messages)) {
        let indexRelatedMessage = 1;
        user.messages.forEach(message => {
          if(message.message && message.message.includes(valueSearch.valueKeyword)) {
            userFilter.push({ ...user, lastMessage: message })
            relatedMessages.push({
              id: user.id,
              countRelatedMessage: indexRelatedMessage,
            })
            indexRelatedMessage ++
          }
        });
      }
    })

    userFilter = _.uniqBy(userFilter, (user) => {
      return user.id;
    })

    relatedMessages = _(relatedMessages)
      .orderBy(['countRelatedMessage'], ['desc'])
      .uniqBy('id')
      .value();

    userFilter = userFilter.map((user) => {
      let relatedMessage = _.find(relatedMessages, {id: user.id});
      if(relatedMessage) {
        user.countRelatedMessage = relatedMessage.countRelatedMessage
      }

      return user
    })

    usersSorted = _.cloneDeep(userFilter)
  }

  return (
    <>
      {isLoading || !currentUserId ? (
        <LoadingIndicator />
      ) : usersSorted.length > 0 ? (
        <div>
          {usersSorted.map((user, index) => (
            <Item
              key={index}
              user={user}
              currentUserId={currentUserId}
              countPinned={countPinned}
              onAvatarClick={() => {
                history.push(`/users/${user.id}`)
              }}
              onRightClick={() => {
                dispatch(
                  onDataIsMatchRead({ likeID: user?.likeId, isRead: true, isMatchingRead: 1 }, {
                    onSuccess: (response) => {
                      history.push({
                        pathname: `/private-chat/${user.id}`,
                        search: valueSearch?.valueKeyword ? `?textSearch=${valueSearch.valueKeyword}&countRelatedMessage=${user.countRelatedMessage || 0}` : '',
                        state: { user }
                      })
                    },
                    onFailed: () => {
                      history.push({
                        pathname: `/private-chat/${user.id}`,
                        search: valueSearch?.valueKeyword ? `?textSearch=${valueSearch.valueKeyword}&countRelatedMessage=${user.countRelatedMessage || 0}` : '',
                        state: { user }
                      })
                    }
                  })
                )
              }}
            />
          ))
          }
        </div>
      ) : (
        <EmptySecondTab />
      )}
    </>
  )
}
