import Form from "react-bootstrap/Form"
import Modal from "react-bootstrap/Modal"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import TextareaAutosize from "react-textarea-autosize"
import { useState, useContext, useEffect } from "react"

import "./styles.scss"
import AppContext from "../../../Context"
import AppBar from "../../../components/AppBar"
import Layout from "../../../components/Layout"
import { sendQuestionRequest } from "../../../actions/other"
import { LoadingIndicator } from "../../../components/LoadingIndicator"

const Contact = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { setContext } = useContext(AppContext)

  const [step, setStep] = useState(1)
  const [mail, setMail] = useState("")
  const [show, setShow] = useState(false)
  const [notice, setNotice] = useState("")
  const [content, setContent] = useState("")
  const [category, setCategory] = useState("")
  const [showNotification, setShowNotification] = useState(false)

  const handleConfirm = () => {
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
    if (reg.test(mail.trim()) === true) {
      setStep(2)
    } else {
      setNotice("メールアドレスは正しくありません。")
      setShowNotification(true)
    }
  }

  const handleCloseNotification = () => {
    setShowNotification(false)
  }

  const handleSend = () => {
    setContext("overlay", <LoadingIndicator />)
    const data = {
      email_user: mail,
      category: category,
      question: content
    }

    dispatch(
      sendQuestionRequest(data, {
        onSuccess: (response) => {
          setContext("overlay", null)
          setNotice("システム管理者へお問い合わせ内容を送信しました。")
          setShow(true)
        },
        onFailed: (error) => {
          setNotice("システムエラー")
        }
      })
    )
  }

  useEffect(() => {
    if (!mail || !content) {
      document.getElementById("confirmBtn").disabled = true
    } else {
      document.getElementById("confirmBtn").disabled = false
    }
  }, [mail, content])

  return (
    <Layout>
      <AppBar title="お問い合わせ" withBack />
      {step === 1 ? (
        <div
          className="bg-white d-flex flex-column container px-3 pb-3 wrap-child-setting contact"
          id="formInput"
        >
          <p className="contact-text">
            いつもTutuをご利用いただき、誠にありがとうございます。ご利用中にわからないことがあった場合には、よくある質問をお読みください。
          </p>

          <button
            className="btn btn-danger p-3 contact-button"
            onClick={() => history.push("/qa")}
          >
            よくある質問を見る
          </button>
          <br />

          <p className="contact-text">
            上記をお読みいただいても解決しない場合は、以下のフォームよりお問い合わせください。
          </p>
          <p
            className="contact-text"
            style={{textDecoration: "underline"}}
            onClick={() => history.push("/close")}
          >
            退会について ＞
          </p>

          <div className="d-flex flex-column border-bottom mt-5">
            <label className="contact-label">メールアドレス</label>
            <input
              type="text"
              className="border-0 mt-1 pt-2 pb-2 contact-input"
              placeholder="●●●@●●●●.com"
              value={mail}
              onChange={(e) => setMail(e.target.value)}
              id="mail"
              onBlur={() => {
                window.scrollTo(0, 0)
              }}
            />
          </div>

          <div className="d-flex flex-column mt-5">
            <label className="contact-label">お問い合わせカテゴリ</label>
            <div className="contact-select mt-2 px-2">
              <Form.Select
                className="pt-3 pb-3 border-0 text-center contact-select-text"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="未選択">未選択</option>
                <option value="悪質なユーザーがいる">
                  悪質なユーザーがいる
                </option>
                <option value="システム障害">システム故障</option>
                <option value="サービスへのご意見等">
                  サービスへのご意見等
                </option>
                <option value="その他">その他</option>
                <option value="退会">退会</option>
                <option value="お支払いについて">お支払いについて</option>
              </Form.Select>
            </div>
          </div>

          <div className="d-flex flex-column border-bottom mt-5">
            <label className="contact-label">お問い合わせ内容</label>
            <TextareaAutosize
              className="border-0 mt-1 pt-2 pb-2 contact-input"
              placeholder="内容を入力してください"
              style={{ resize: "none" }}
              value={content}
              onChange={(e) => setContent(e.target.value)}
              onBlur={() => {
                window.scrollTo(0, 0)
              }}
            />
          </div>

          <button
            className="btn btn-danger p-3 mt-5 disabled-button contact-button"
            onClick={handleConfirm}
            id="confirmBtn"
          >
            内容を確認
          </button>

          <Modal
            show={showNotification}
            onHide={handleCloseNotification}
            centered
            size="sm"
          >
            <Modal.Body>
              <h6 className="text-center p-3">{notice}</h6>
              <button
                className="btn text-danger w-100 m-0 rounded-0 confirm-button"
                onClick={handleCloseNotification}
              >
                OK
              </button>
            </Modal.Body>
          </Modal>
        </div>
      ) : (
        <div
          className="bg-white d-flex flex-column full-height-with-navbar pt-4"
          id="formConfirm"
        >
          <div className="border-bottom p-3">
            以下の内容で送信します。よろしいですか？
          </div>
          <div className="m-3 border-bottom p-2">
            <label>メールアドレス</label>
            <div className="mt-2">{mail}</div>
          </div>

          <div className="m-3 border-bottom p-2">
            <label>お問い合わせカテゴリ</label>
            <div className="mt-2">{category}</div>
          </div>

          <div className="m-3 border-bottom p-2">
            <label>お問い合わせ内容</label>
            <div className="mt-2" style={{ whiteSpace: "pre-line" }}>
              {content}
            </div>
          </div>

          <button
            className="btn btn-danger p-3 m-3 mt-5 contact-button"
            onClick={handleSend}
          >
            送信する
          </button>

          <Modal show={show} centered size="sm">
            <Modal.Body>
              <h6 className="text-center p-3">{notice}</h6>
              <button
                className="btn text-danger w-100 m-0 rounded-0 confirm-button"
                onClick={() => history.push(`/settings`)}
              >
                OK
              </button>
            </Modal.Body>
          </Modal>
        </div>
      )}
    </Layout>
  )
}

export default Contact
