import { Button } from "react-bootstrap"
import { useHistory } from "react-router-dom"

export const ErrorSheet = ({ type }) => {
  const history = useHistory()

  const handleRedirect = () => {
    if(type === 1){
      history.push('/plans')
    }else if(type === 3){
      history.push('/verify')
    }
  }

  return (
    <div className="position-fixed bottom-0 bg-white text-center border-top border-1 d-flex flex-column w-100 dialog-errSheet">
      <div className="yu-gothic-bold mb-3" style={{ fontSize: "1rem" }}>
        あなたからのメッセージを待っています！
      </div>
      <div className="yu-gothic-regular" style={{ fontSize: "0.8rem" }}>
        {type === 1
          ? "有料プランに変更して、メッセージを送りましょう！"
          : "本人確認を完了させてメッセージを送りましょう！"}
      </div>
      <Button
        className="yu-gothic-bold mt-3 p-3 align-self-center w-100"
        variant={type === 2 ? "secondary" : "danger"}
        style={{ fontSize: "1rem" }}
        onClick={handleRedirect}
      >
        {type === 1 && "有料プランを表示する"}
        {type === 2 && "本人確認申請中"}
        {type === 3 && "本人確認に進む"}
      </Button>
    </div>
  )
}
