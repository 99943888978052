import _ from "lodash"
import * as yup from "yup"
import moment from "moment"
import { Formik } from "formik"
import Form from "react-bootstrap/Form"
import { useEffect, useState } from "react"
import Button from "react-bootstrap/Button"
import InputGroup from "react-bootstrap/InputGroup"
import "react-datepicker/dist/react-datepicker.css"
import { useDispatch, useSelector } from "react-redux"
import { YearPicker, MonthPicker, DayPicker } from "react-dropdown-date"
import { toast } from "react-toastify"

import "../../styles.scss"
import messages from "../../../constants/messages"
import { getResidencesRequest } from "../../../actions/filter"

const profileFields = {
  nickname: "",
  residence_id: "13",
  occupation_id: "",
  date_of_birth: "1990/01/01"
}

const schema = yup.object().shape({
  nickname: yup.string().trim().min(2).max(8)
})

const Profile = ({ onSubmit, initial }) => {
  const dispatch = useDispatch()

  const [date, setDate] = useState({
    year: "1990",
    month: "0",
    day: "1"
  })
  const [sex, setSex] = useState(1)

  const residences = useSelector((state) => {
    return state.filter.options.residences
  })

  const handleFormSubmit = (values) => {
    const birthday = moment(
      `${date.year}/${parseInt(date.month) + 1}/${date.day}`
    ).format("YYYY/MM/DD")
    const age = moment().diff(birthday, 'years')
    if (age < 18) {
      toast.error("登録するには 18 歳以上である必要があります。")
      return
    }

    values.date_of_birth = values.date_of_birth?.replace(/ /g, "")
    var newvalue = {}
    newvalue.email_for_takeover = values.email_for_takeover
    newvalue.date_of_birth = birthday
    newvalue.nickname = values.nickname
    newvalue.residence_id = values.residence_id
    newvalue.sex = sex

    onSubmit(newvalue)
  }

  useEffect(() => {
    if (_.isEmpty(residences)) {
      dispatch(getResidencesRequest())
    }
  }, [])

  return (
    <Formik
      validationSchema={schema}
      onSubmit={handleFormSubmit}
      initialValues={{ ...profileFields, ...initial }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors
      }) => (
        <Form noValidate onSubmit={handleSubmit} className="d-flex flex-column">
          <h1 className="register-profile-heading">プロフィールの入力</h1>
          <p className="mb-4 register-profile-desc">
            あなたのプロフィールを作成します。
          </p>
          <Form.Group className="mb-3" controlId="nickname">
            <Form.Label className="fw-bold register-profile-label">
              ニックネーム
            </Form.Label>
            <InputGroup hasValidation>
              <Form.Control
                className="input-outline register-profile-input"
                name="nickname"
                placeholder="8文字以内"
                value={values.nickname}
                onChange={handleChange}
                isInvalid={!!errors.nickname}
              />
              <Form.Control.Feedback type="invalid">
                {messages.nicknameEmpty}
              </Form.Control.Feedback>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-3" controlId="residences">
            <Form.Label className="fw-bold register-profile-label">
              居住地
            </Form.Label>
            <Form.Select
              className="input-outline register-profile-select"
              onChange={handleChange}
              name="residence_id"
              value={values.residence_id}
            >
              {_.map(residences, ({ name, residence_id }) => (
                <option key={residence_id} value={residence_id}>
                  {name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Form.Group className="mb-3" controlId="gender">
            <Form.Label className="d-block fw-bold register-profile-label">
              性別
            </Form.Label>
            <div className="d-flex">
              <Form.Control
                value={"男性"}
                className={`input-outline register-profile-sex me-1  ${
                  sex === 1 ? "register-profile-sex__checked" : ""
                } `}
                type="button"
                onClick={() => setSex(1)}
              />
              <Form.Control
                value={"女性"}
                className={`input-outline register-profile-sex ms-1 ${
                  sex === 2 ? "register-profile-sex__checked" : ""
                } `}
                type="button"
                onClick={() => setSex(2)}
              />
            </div>
          </Form.Group>
          <Form.Group className="mb-3" controlId="birthday">
            <Form.Label className="fw-bold register-profile-label">
              生年月日
            </Form.Label>
            <div className="d-flex align-items-end">
              <YearPicker
                defaultValue="年"
                reverse
                required
                value={date.year}
                start={1940}
                name={"年"}
                end={new Date().getFullYear() - 18}
                onChange={(year) => {
                  setDate((prev) => ({ ...prev, year }))
                }}
                id={"year"}
                classes="register-profile-date-picker"
              />
              <h2 className="register-profile-date-label">/</h2>
              <MonthPicker
                defaultValue="月"
                numeric
                endYearGiven
                year={date.year}
                value={date.month}
                name={"月"}
                required
                onChange={(month) => {
                  setDate((prev) => ({ ...prev, month }))
                }}
                id={"month"}
                classes="register-profile-date-picker"
              />
              <h2 className="register-profile-date-label">/</h2>
              <DayPicker
                defaultValue="日"
                year={date.year}
                month={date.month}
                name={"日"}
                required
                endYearGiven
                value={date.day}
                onChange={(day) => {
                  setDate((prev) => ({ ...prev, day }))
                }}
                id={"day"}
                classes="register-profile-date-picker day-picker"
              />
            </div>
          </Form.Group>
          <div className="position-fixed bottom-0 start-0 bg-white p-2 w-100">
            <Button
              className="align-self-center w-100 register-profile-button text-center"
              style={{
                backgroundColor:
                  !isValid || values.nickname?.trim() === ""
                    ? "#8e8e8e"
                    : "#ec3838",
                height: "55px",
                border: "none"
              }}
              type="submit"
              disabled={!isValid || values.nickname?.trim() === ""}
            >
              次へ
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default Profile
