import { getAvatarUrl } from "../../utils"
import ImgEndCall from "../../images/img_end_call.svg"
import ImgPhone from "../../images/img_phone.svg"
import ImgDefaultAvatar from "../../images/avatar-app.png"
import IconCancel from "../../icons/ic_cancel_call.png"

import "./styles.scss"

export const CallStarting = ({ user, out, onCancel, cancellable }) => {
  const avatarUrl = getAvatarUrl(user?.images)

  return (
    <div className="video-call-container d-flex flex-column justify-content-around">
      <div className="d-flex flex-column align-items-center">
        <img
          src={avatarUrl || ImgDefaultAvatar}
          alt="Not found"
          style={{
            height: "40vw",
            width: "40vw",
            objectFit: "cover",
            maxHeight: "15rem",
            maxWidth: "15rem",
            borderRadius: "1rem"
          }}
        />
        <div
          className="mt-4 yu-gothic-bold"
          style={{
            overflow: "hidden",
            maxWidth: "70vw",
            fontSize: "1.3rem"
          }}
        >
          {user?.nickname}
        </div>
        <div className="mt-3">通話呼び出し中</div>
      </div>
      {out ? (
        <div className="d-flex flex-column align-items-center">
          <button
            className="call-btn call-btn-cancel border-0"
            onClick={onCancel}
            disabled={cancellable != null ? !cancellable : false}
          >
            <img src={IconCancel} alt="Not found" />
          </button>
          <span className="mt-2">キャンセル</span>
        </div>
      ) : (
        <div className="d-flex justify-content-around w-100">
          <div className="d-flex flex-column align-items-center">
            <div className="call-btn call-btn-end">
              <img src={ImgEndCall} alt="Not found" />
            </div>
            拒否
          </div>
          <div className="d-flex flex-column align-items-center">
            <div className="call-btn call-btn-phone">
              <img src={ImgPhone} alt="Not found" />
            </div>
            応答
          </div>
        </div>
      )}
    </div>
  )
}
