import _ from "lodash"

export const persistFilterParams = (phone, params) => {
  localStorage.setItem(
    "PERSIST_FILTER_PARAMS",
    JSON.stringify({
      phone: phone,
      params: params
    })
  )
}

export const getPersistFilterParams = (phone) => {
  const persist = localStorage.getItem("PERSIST_FILTER_PARAMS")
  const data = _.isEmpty(persist) ? {} : JSON.parse(persist)
  if (data.phone !== phone) {
    localStorage.removeItem("PERSIST_FILTER_PARAMS")
    return {}
  }
  return data.params ? data.params : {}
}

export const getShowMatchConfirmation = () => {
  const flag = localStorage.getItem("FLAG_SHOW_MATCH_CONFIRMATION")
  return flag === null ? false : JSON.parse(flag)
}

export const setShowMatchConfirmation = (value) => {
  localStorage.setItem("FLAG_SHOW_MATCH_CONFIRMATION", JSON.stringify(value))
}
