import {
  GET_MATCH_LIST_REQUESTED,
  GET_MATCH_LIST_SUCCESS,
  GET_MATCH_LIST_FAILED,
  GET_CHAT_LIST_REQUESTED,
  GET_CHAT_LIST_SUCCESS,
  GET_CHAT_LIST_FAILED,
  GET_UNREAD_MATCH_COUNT_REQUESTED,
  GET_UNREAD_MATCH_COUNT_SUCCESS,
  GET_UNREAD_MATCH_COUNT_FAILED,
  GET_UNREAD_MESSAGE_COUNT_REQUESTED,
  GET_UNREAD_MESSAGE_COUNT_SUCCESS,
  GET_UNREAD_MESSAGE_COUNT_FAILED,
  CLEAR_UNREAD_MATCH_COUNT_REQUESTED,
  CLEAR_UNREAD_MATCH_COUNT_SUCCESS,
  CLEAR_UNREAD_MATCH_COUNT_FAILED,
  CLEAR_UNREAD_MESSAGE_COUNT_REQUESTED,
  CLEAR_UNREAD_MESSAGE_COUNT_SUCCESS,
  CLEAR_UNREAD_MESSAGE_COUNT_FAILED,
  SET_ACTIVE_TAB,
  GET_MESSAGE_TEMPLATE_REQUESTED,
  GET_MESSAGE_TEMPLATE_SUCCESS,
  GET_MESSAGE_TEMPLATE_FAILED,
  GET_MESSAGE_TEMPLATE_ME_REQUESTED,
  GET_MESSAGE_TEMPLATE_ME_SUCCESS,
  GET_MESSAGE_TEMPLATE_ME_FAILED,
  CREATE_AND_UPDATE_MESSAGE_TEMPLATE,
  CREATE_AND_UPDATE_MESSAGE_TEMPLATE_SUCCESS,
  CREATE_AND_UPDATE_MESSAGE_TEMPLATE_FAILED,
  DELETE_MESSAGE_TEMPLATE,
  DELETE_MESSAGE_TEMPLATE_SUCCESS,
  DELETE_MESSAGE_TEMPLATE_FAILED,
  GET_HIDE_LIST_REQUESTED,
  GET_HIDE_LIST_SUCCESS,
  GET_HIDE_LIST_FAILED,
  PIN_USER_REQUESTED,
} from "../constants/actions"

export const getMatchListRequested = (userId, dispatch) => ({
  type: GET_MATCH_LIST_REQUESTED,
  payload: {
    userId
  },
  dispatch
})

export const getMatchListSuccess = (users) => ({
  type: GET_MATCH_LIST_SUCCESS,
  payload: { users }
})

export const getMatchListFailed = (error) => ({
  type: GET_MATCH_LIST_FAILED,
  payload: { error }
})

export const getChatListRequested = (userId, dispatch) => ({
  type: GET_CHAT_LIST_REQUESTED,
  payload: { userId },
  dispatch
})

export const getChatListSuccess = (users) => ({
  type: GET_CHAT_LIST_SUCCESS,
  payload: { users },
})

export const getChatListFailed = (error) => ({
  type: GET_CHAT_LIST_FAILED,
  payload: { error }
})

export const getHideListRequested = (userId, dispatch) => ({
  type: GET_HIDE_LIST_REQUESTED,
  payload: { userId },
  dispatch
})

export const getHideListSuccess = (users) => ({
  type: GET_HIDE_LIST_SUCCESS,
  payload: {
    users
  }
})

export const getHideListFailed = (error) => ({
  type: GET_HIDE_LIST_FAILED,
  payload: { error }
})

export const PinUserRequested = (data = {}, callbacks) => {
  return {
    type: PIN_USER_REQUESTED,
    payload: data,
    callbacks
  }
}

export const getUnreadMatchCountRequested = (userId, dispatch) => ({
  type: GET_UNREAD_MATCH_COUNT_REQUESTED,
  payload: { userId },
  dispatch
})

export const getUnreadMatchCountSuccess = (count) => ({
  type: GET_UNREAD_MATCH_COUNT_SUCCESS,
  payload: { count }
})

export const getUnreadMatchCountFailed = (error) => ({
  type: GET_UNREAD_MATCH_COUNT_FAILED,
  payload: { error }
})

export const setUnreadMessageNumber = (count) => ({
  type: 'SET_UNREAD_MESSAGE_NUMBER',
  payload: { count }
})

export const getUnreadMessageCountRequested = (userId, dispatch) => ({
  type: GET_UNREAD_MESSAGE_COUNT_REQUESTED,
  payload: { userId },
  dispatch
})

export const getUnreadMessageCountSuccess = (count) => ({
  type: GET_UNREAD_MESSAGE_COUNT_SUCCESS,
  payload: { count }
})

export const getUnreadMessageCountFailed = (error) => ({
  type: GET_UNREAD_MESSAGE_COUNT_FAILED,
  payload: { error }
})

export const clearUnreadMatchCountRequested = (userId) => ({
  type: CLEAR_UNREAD_MATCH_COUNT_REQUESTED,
  payload: { userId }
})

export const clearUnreadMatchCountSuccess = () => ({
  type: CLEAR_UNREAD_MATCH_COUNT_SUCCESS
})

export const clearUnreadMatchCountFailed = (error) => ({
  type: CLEAR_UNREAD_MATCH_COUNT_FAILED,
  payload: { error }
})

export const clearUnreadMessageCountRequested = (userId) => ({
  type: CLEAR_UNREAD_MESSAGE_COUNT_REQUESTED,
  payload: { userId }
})

export const clearUnreadMessageCountSuccess = () => ({
  type: CLEAR_UNREAD_MESSAGE_COUNT_SUCCESS
})

export const clearUnreadMessageCountFailed = (error) => ({
  type: CLEAR_UNREAD_MESSAGE_COUNT_FAILED,
  payload: { error }
})

export const setActiveTab = (tabIndex) => ({
  type: SET_ACTIVE_TAB,
  payload: { tabIndex }
})

export const getMessageTemplate = () => ({
  type: GET_MESSAGE_TEMPLATE_REQUESTED
})

export const getMessageTemplateSuccess = (data) => ({
  type: GET_MESSAGE_TEMPLATE_SUCCESS,
  payload: { messages: data }
})

export const getMessageTemplateFailed = (error) => ({
  type: GET_MESSAGE_TEMPLATE_FAILED,
  payload: { error }
})

export const getMessageTemplateMe = (callbacks) => ({
  type: GET_MESSAGE_TEMPLATE_ME_REQUESTED,
  callbacks
})

export const getMessageTemplateMeSuccess = (data) => ({
  type: GET_MESSAGE_TEMPLATE_ME_SUCCESS,
  payload: { messages: data }
})

export const getMessageTemplateMeFailed = (error) => ({
  type: GET_MESSAGE_TEMPLATE_ME_FAILED,
  payload: { error }
})

export const createAndUpdateMessageTemplate = (data, callbacks) => ({
  type: CREATE_AND_UPDATE_MESSAGE_TEMPLATE,
  payload: {
    ...data
  },
  callbacks
})

export const createAndUpdateMessageTemplateSuccess = (data) => ({
  type: CREATE_AND_UPDATE_MESSAGE_TEMPLATE_SUCCESS,
  payload: {
    ...data
  }
})

export const createAndUpdateMessageTemplateFailed = (error) => ({
  type: CREATE_AND_UPDATE_MESSAGE_TEMPLATE_FAILED,
  payload: {
    error
  }
})

export const deleteMessageTemplate = (data, callbacks) => ({
  type: DELETE_MESSAGE_TEMPLATE,
  payload: {
    ...data
  },
  callbacks
})

export const deleteMessageTemplateSuccess = (data) => ({
  type: DELETE_MESSAGE_TEMPLATE_SUCCESS,
  payload: {
    ...data
  }
})

export const deleteMessageTemplateFailed = (error) => ({
  type: DELETE_MESSAGE_TEMPLATE_FAILED,
  payload: {
    error
  }
})
