import { Button, Modal } from "react-bootstrap"

export const ConfirmModal = ({ header, content, onConfirm }) => {
  return (
    <Modal show={true} size="sm" centered className="border border-1">
      {header && (
        <div
          className="yu-gothic-bold text-center p-2 pt-3"
          style={{ fontSize: "1rem" }}
        >
          {header}
        </div>
      )}

      <Modal.Body>
        <div
          className="yu-gothic-regular text-center p-3"
          style={{ fontSize: "0.8rem" }}
        >
          {content}
        </div>
      </Modal.Body>

      <div className="d-flex flex-column text-center">
        <Button
          className="yu-gothic-bold border-0 border-top border-1 bg-white p-2"
          style={{
            fontSize: "1rem",
            color: "#EC3838",
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0
          }}
          onClick={onConfirm}
        >
          OK
        </Button>
      </div>
    </Modal>
  )
}
