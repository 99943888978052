import { useState } from "react"
import { Image, Button, Form } from "react-bootstrap"

import "./styles.scss"
import Search from "../../../icons/search.svg"

const Match = ({ onSubmit, props }) => {
  const [label, setLabel] = useState(props.label)
  const [read, setRead] = useState(props.read)
  const [unread, setUnread] = useState(props.unread)
  const [reply, setReply] = useState(props.reply)
  const [valueKeyword, setValueKeyword] = useState(props.valueKeyword)

  const handleReset = () => {
    setLabel(false)
    setRead(false)
    setUnread(false)
    setReply(false)
    setValueKeyword("")
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit({
      label: label,
      read: read,
      unread: unread,
      reply: reply,
      valueKeyword: valueKeyword
    })
  }

  const handleChangeLabel = () => {
    setLabel(!label)
  }
  const handleChangeRead = () => {
    setRead(!read)
  }
  const handleChangeUnread = () => {
    setUnread(!unread)
  }
  const handleChangeReply = () => {
    setReply(!reply)
  }
  const handleChangeKeywork = (e) => {
    setValueKeyword(e.target.value)
  }

  return (
    <Form
      onSubmit={handleSubmit}
      className="bg-white d-flex flex-column form-filter"
    >
      <div className="container form-containers">
        <Form className="col-12">
          <Form.Check
            onChange={handleChangeLabel}
            name="label"
            size="lg"
            type="switch"
            label="ラベル"
            checked={label}
            className="checked"
          />
          <Form.Check
            onChange={handleChangeRead}
            name="read"
            size="lg"
            type="switch"
            label="既読"
            checked={read}
            className="checked"
          />
          <Form.Check
            onChange={handleChangeUnread}
            name="unread"
            size="lg"
            type="switch"
            label="未読"
            checked={unread}
            className="checked"
          />
          <Form.Check
            onChange={handleChangeReply}
            name="reply"
            size="lg"
            type="switch"
            label="未返信"
            checked={reply}
            className="checked"
          />
          <Form.Group
            controlId="exampleForm.ControlInput1"
            style={{ position: "relative" }}
          >
            <Image className="icon-search" src={Search} width={20} />
            <Form.Control
              className="input-search"
              size="sm"
              placeholder={"フリーワードで検索"}
              onChange={handleChangeKeywork}
              value={valueKeyword}
              name="keyword"
              onBlur={() => window.scrollTo(0, 0)}
            />
          </Form.Group>
        </Form>
        <div className="mt-auto" style={{ padding: "13px 0px" }}>
          <div className="d-grid container d-flex justify-content-between">
            <Button
              className="border-0 btn-w-45"
              variant="dark"
              type="button"
              onClick={handleReset}
            >
              条件リセット
            </Button>
            <Button
              className="align-self-center btn-w-45"
              variant="danger"
              type="submit"
            >
              検索する
            </Button>
          </div>
        </div>
      </div>
    </Form>
  )
}

Match.defaultProps = {
  sex: 1
}

export default Match
