import {
  FETCH_ME_REQUEST,
  FETCH_ME_SUCCESS,
  FETCH_ME_FAILED,
  UPDATE_EDIT_PROFILE,
  FETCH_POINT_HISTORIES_REQUEST,
  FETCH_POINT_HISTORIES_SUCCESS,
  FETCH_POINT_HISTORIES_FAILED
} from "../constants/actions/mypage"

export const fetchMeRequest = (callbacks) => {
  return {
    type: FETCH_ME_REQUEST,
    payload: {
      data: {}
    },
    callbacks
  }
}
export const fetchMeSuccess = (data) => {
  return {
    type: FETCH_ME_SUCCESS,
    data
  }
}

export const fetchMeFailed = (data) => {
  return {
    type: FETCH_ME_FAILED,
    data
  }
}

export const updateDataProfile = (data) => {
  return {
    type: UPDATE_EDIT_PROFILE,
    data
  }
}

export const fetchPointHistoriesRequest = (callbacks) => {
  return {
    type: FETCH_POINT_HISTORIES_REQUEST,
    payload: {
      data: {}
    },
    callbacks
  }
}
export const fetchPointHistoriesSuccess = (data) => {
  return {
    type: FETCH_POINT_HISTORIES_SUCCESS,
    data
  }
}

export const fetchPointHistoriesFailed = (data) => {
  return {
    type: FETCH_POINT_HISTORIES_FAILED,
    data
  }
}
