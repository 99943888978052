export const DOMAIN =
  process.env.REACT_APP_API_URL || "https://dev-app.tu-tu.jp/"
export const BASE_URL = `${DOMAIN}api/v1`

export const AGORA_APP_ID =
  process.env.REACT_APP_AGORA_APP_ID || "415ee921337f43b99246431bfadea9da"
export const FIRST_PAGE = 1
export const PER_PAGE = 10
export const NATIONAL_CODE = "+81"
export const PHONE_NUMBER_LENGTH = 13
export const PHONE_NUMBER_VN_LENGTH = 12
export const PHONE_NUMBER_REGEX = /^[+]?\d+$/
export const LIKE_NOTI_TOGGLE = 1
export const MESSAGE_NOTI_TOGGLE = 2
export const OTHER_NOTI_TOGGLE = 3
export const DATA_SEARCH_LOCAL = "DATA_SEARCH_LOCAL"
export const HTTP_CODE = {
  EXPIRED_TOKEN_ERR: 401,
  FORBIDDEN: 403,
  OK: 200
}
export const FIREBASE_COLLECTION_CHAT_ROOMS = "chat_rooms"
export const COUNT_PINNED_MAX = 10;
export const FIREBASE_COLLECTION_MESSAGES = "messages"
export const FIREBASE_COLLECTION_LIKES = "likes"
export const FIREBASE_COLLECTION_USERS = "users"
export const FIREBASE_COLLECTION_VIDEO_CALLS = "video_calls"
export const MESSAGE_VIDEO_CALL = {
  SEND_GIFT: "SEND_GIFT",
  DISABLE_VIDEO: "DISABLE_VIDEO",
  END_CALL: "END_CALL",
  SEND_DATA_TO_PEER: "SEND_DATA_TO_PEER",
  SEND_BUSY: "SEND_BUSY"
}

export const CALL_TIMEOUT = parseInt(
  process.env.REACT_APP_CALL_TIMEOUT || 300000
)

export const MEASUREMENT_ID = process.env.REACT_APP_MEASUREMENT_ID;

export const VIDEO_STREAM_STATUS = {
  STOPPED: 0,
  STARTING: 1,
  DECODING: 2,
  FROZEN: 3,
  FAILED: 4
}

export const STATUS_RESPONSE = {
  NO_RESPONSE_YET: 0,
  ASKED_FOR_ATTENTION: 1,
  SKIPPED: 2,
  RECEIVED_A_THANK_YOU: 3
}

export const FIREBASE_MATCHING_READ = {
  READ: 1,
  UN_READ: 0
}

export const USER_STATUS = {
  OFFLINE: 0,
  ONLINE: 1,
  AWAY: 2
}

export const LIKE_STATUS = {
  NO_RESPONSE: 0,
  SKIPPED: 2,
  MATCHED: 3
}

export const CONTRACT_TYPE = {
  FREE: 1,
  PAID: 2
}

export const SEX = {
  MALE: 1,
  FEMALE: 2
}

export const IDENTITY_VERIFICATION_STATUS = {
  NOT_APPLIED_YET: 0,
  APPLIED: 1,
  DENIED: 2,
  APPROVED: 3
}

export const MESSAGE_TYPES = {
  TEXT: "text",
  VIDEO_CALL: "video_call",
  IMAGE: "image"
}

export const VIDEO_CALL_STATUS = {
  NOT_STARTED: 0,
  STARTED: 1,
  ENDED: 2,
  MISSED: 3
}

export const USER_IMAGE_TYPES = {
  AVATAR: 1,
  GIF: 2,
  IDENTITY_VERIFICATION: 3
}

export const AGORA_MESSAGE_TYPES = {
  SEND_GIFT: "SEND_GIFT",
  DISABLE_VIDEO: "DISABLE_VIDEO",
  END_CALL: "END_CALL",
  SEND_DATA_TO_PEER: "SEND_DATA_TO_PEER",
  SEND_BUSY: "SEND_BUSY"
}

export const DATE_FORMAT = "yyyy / MM / dd"
export const MOMENT_DATE_FORMAT = "YYYY / MM / DD"
export const USER_TYPE = {
  KOL_OR_IDOL: 9,
}
