export const SET_CALLER = "SET_CALLER"
export const SET_CALLEE = "SET_CALLEE"

export const SET_CALLEE_UID = "SET_CALLEE_UID"
export const SET_CALLER_UID = "SET_CALLER_UID"

export const SET_AGORA_DATA = "SET_AGORA_DATA"

export const SET_IS_CALLING = "SET_IS_CALLING"

export const SET_LIKE_ID = "SET_LIKE_ID"

export const FETCH_GIFTS_REQUEST = "FETCH_GIFTS_REQUEST"
export const FETCH_GIFTS_SUCCESS = "FETCH_GIFTS_SUCCESS"
export const FETCH_GIFTS_FAILED = "FETCH_GIFTS_FAILED"

export const TIPPING_GIFT_REQUEST = "TIPPING_GIFT_REQUEST"
export const TIPPING_GIFT_SUCCESS = "TIPPING_GIFT_SUCCESS"
export const TIPPING_GIFT_FAILED = "TIPPING_GIFT_FAILED"

export const RETRIEVE_AGORA_TOKEN_REQUESTED = "RETRIEVE_AGORA_TOKEN_REQUESTED"
export const RETRIEVE_AGORA_TOKEN_SUCCESS = "RETRIEVE_AGORA_TOKEN_SUCCESS"
export const RETRIEVE_AGORA_TOKEN_FAILED = "RETRIEVE_AGORA_TOKEN_FAILED"

export const GET_RTM_TOKEN_REQUESTED = "GET_RTM_TOKEN_REQUESTED"
export const GET_RTM_TOKEN_SUCCESS = "GET_RTM_TOKEN_SUCCESS"
export const GET_RTM_TOKEN_FAILED = "GET_RTM_TOKEN_FAILED"

export const UPDATE_VIDEO_CALL_REQUESTED = "UPDATE_VIDEO_CALL_REQUESTED"
export const UPDATE_VIDEO_CALL_SUCCESS = "UPDATE_VIDEO_CALL_SUCCESS"
export const UPDATE_VIDEO_CALL_FAILED = "UPDATE_VIDEO_CALL_FAILED"

export const UPDATE_VIDEO_CALL_MSG_REQUESTED = "UPDATE_VIDEO_CALL_MSG_REQUESTED"
export const UPDATE_VIDEO_CALL_MSG_SUCCESS = "UPDATE_VIDEO_CALL_MSG_SUCCESS"
export const UPDATE_VIDEO_CALL_MSG_FAILED = "UPDATE_VIDEO_CALL_MSG_FAILED"

export const FETCH_GIFT_LIST_REQUESTED = "FETCH_GIFT_LIST_REQUESTED"
export const FETCH_GIFT_LIST_SUCCESS = "FETCH_GIFT_LIST_SUCCESS"
export const FETCH_GIFT_LIST_FAILED = "FETCH_GIFT_LIST_FAILED"

export const PURCHASE_GIFT_REQUESTED = "PURCHASE_GIFT_REQUESTED"
export const PURCHASE_GIFT_SUCCESS = "PURCHASE_GIFT_SUCCESS"
export const PURCHASE_GIFT_FAILED = "PURCHASE_GIFT_FAILED"

export const SEND_GIFT_REQUESTED = "SEND_GIFT_REQUESTED"
export const SEND_GIFT_SUCCESS = "SEND_GIFT_SUCCESS"
export const SEND_GIFT_FAILED = "SEND_GIFT_FAILED"

export const START_VIDEO_CALL_REQUESTED = "START_VIDEO_CALL_REQUESTED"
export const START_VIDEO_CALL_SUCCESS = "START_VIDEO_CALL_SUCCESS"
export const START_VIDEO_CALL_FAILED = "START_VIDEO_CALL_FAILED"

export const EXTEND_VIDEO_CALL_REQUESTED = "EXTEND_VIDEO_CALL_REQUESTED"
export const EXTEND_VIDEO_CALL_SUCCESS = "EXTEND_VIDEO_CALL_SUCCESS"
export const EXTEND_VIDEO_CALL_FAILED = "EXTEND_VIDEO_CALL_FAILED"
