import { useEffect, useContext, useState } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import Layout from "../../components/Layout"
import {
  fetchAuthProfileRequest,
  addDeviceTokenRequested
} from "../../actions/auth"
import AppContext from "../../Context"
import LogoSplash from "../../images/startlogo.png"
import "./styles.scss"
import routes from "../../constants/routes"
import { getAccessToken } from "../../utils/auth"
import messages from "../../constants/messages"

const Splash = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { setContext } = useContext(AppContext)

  useEffect(() => {
    const accessToken = getAccessToken()
    if (accessToken) {
      dispatch(
        fetchAuthProfileRequest(
          {},
          {
            onSuccess: async (response) => {
              setContext("auth", true)
              setTimeout(() => {
                if (response.is_created == 0) {
                  history.push({
                    pathname: routes.REGISTER_PROFILE,
                    search: "?login=true"
                  })
                } else {
                  dispatch(addDeviceTokenRequested())
                  history.push({
                    pathname: routes.USERS,
                    search: "?login=true"
                  })
                }
              }, 100)
            },
            onFailed: () => {
              setContext("auth", false)
              alert(messages.somethingWrong)
              history.push("/title")
            }
          }
        )
      )
    } else {
      setTimeout(() => {
        setContext("auth", false)
        history.push("/title")
      }, 1000)
    }
  }, [])

  return (
    <Layout>
      <div className="container">
        <div className="page page-splash d-flex min-vh-100 justify-content-center">
          <div className="align-self-center">
            <img src={LogoSplash} alt="" />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Splash
