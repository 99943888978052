import HttpClient from "../utils/client"

export function* getMessageTemplate(me) {
  return yield HttpClient("GET", {
    url: me ? "/template-message" : "/template-message-master"
  })
}

export function* createAndUpdateMessageTemplate(dataMessage) {
  const data = {
    message: dataMessage.message
  }

  if (dataMessage.messageId) {
    return yield HttpClient("PUT", {
      url: `/template-message/${dataMessage.messageId}`,
      data
    })
  } else {
    return yield HttpClient("POST", { url: "/template-message", data })
  }
}

export function* deleteMessageTemplate(data) {
  const url = `/template-message/${data}`

  return yield HttpClient("DELETE", { url })
}
