import React from "react"

const NotiItem = ({ title, checked, setChecked, type }) => {
  return (
    <div className="noti-setting-item d-flex align-items-center">
      <div className="noti-setting-item-title">
        <p>{title}</p>
      </div>
      <div className="noti-setting-item-switch form-check form-switch">
        <input
          className="form-check-input"
          type="checkbox"
          role="switch"
          checked={checked}
          onChange={() => {
            setChecked(type, !checked)
          }}
        />
      </div>
    </div>
  )
}

export default NotiItem
