import classNames from "classnames"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import Button from "react-bootstrap/Button"
import { getFilterOptionsRequest } from "../../actions/filter"
import settingIcon from "../../icons/setting.svg"
import "./styles.scss"
import _ from "lodash"

const boolFields = {
  mainpic: "メイン写真",
  subpic: "サブ写真",
  popular: "人気会員",
  paid: "有料会員",
  verified: "年齢確認済み",
  biography: "自己紹介文あり",
  recent: "登録日が3日以内",
}

const fieldOptions = {
  alcohol_ids: "alcohols",
  annual_income_ids: "annual_incomes",
  baby_aspiration_ids: "baby_aspirations",
  birthplace_ids: "birthplaces",
  cigarette_ids: "cigarettes",
  date_ids: "dates",
  educational_background_ids: "educational_backgrounds",
  figure_ids: "figures",
  // residence_ids: "residences",
  marriage_ids: "marriages",
  occupation_ids: "occupations",
}

const renderFilters = (params, options) => {
  const { age_lower, age_upper, height_lower, height_upper, ...others } = params
  const filterValues = []

  if (age_lower || age_upper) {
    filterValues.push(`${age_lower || ""}~${age_upper || ""}歳`)
  }

  if (height_lower || height_upper) {
    filterValues.push(`${height_lower || ""}~${height_upper || ""}cm`)
  }

  // _.map(others, (value, key) => {
  //   if (boolFields[key] !== undefined && value == 1) {
  //     filterValues.push(boolFields[key])
  //   } else {
  //     const values = _.filter(options[fieldOptions[key]], (opt) => {
  //       const keys = _.keys(opt)
  //       const key = keys[0] == "name" ? keys[1] : keys[0]
  //       return _.indexOf(value.split(","), opt[key] + "") !== -1
  //     }).map((item) => item.name)
  //     let values_str = ""
  //     if (!_.isEmpty(values)) {
  //       values_str = values.join(",")
  //     }
  //     // if (_.indexOf(value.split(','), 'こだわらない') !== -1) {
  //     //   values_str = 'こだわらない' + (values_str.length > 0 ? (',' + values_str) : '')
  //     // }
  //     if (values_str.length > 0) {
  //       filterValues.push(values_str)
  //     }
  //   }
  // })

  let value = filterValues.join("/")
  return value.length <= 15 ? value : value.substring(0, 15) + "..."
}

const useQuery = () => {
  return new URLSearchParams(useLocation().search)
}

const Filter = ({ sort, onChange, toggleFilter }) => {
  const filterOptions = useSelector((state) => state.filter.options)
  const [filterParams, setFilterParams] = useState({})
  const dispatch = useDispatch()
  const query = useQuery()

  useEffect(() => {
    if (_.isEmpty(filterOptions)) {
      dispatch(getFilterOptionsRequest())
    } else {
      const params = {}
      for (const [key, value] of query) {
        params[key] = params[key] || value
      }

      setFilterParams(params)
    }
  }, [filterOptions])

  return (
    <div className="filter-form">
      <div className="btns">
        {/* <Button
          className={classNames({ active: sort === "rand" })}
          variant="outline-warning"
          size="lg"
          onClick={() => onChange("sort", "rand")}
        >
          オススメ順
        </Button> */}
        <Button
          className={classNames({ active: sort === "login" })}
          variant="outline-danger"
          size="lg"
          onClick={() => onChange("sort", "login")}
        >
          ログイン順
        </Button>
        <Button
          className={classNames({ active: sort === "new" })}
          variant="outline-danger"
          size="lg"
          onClick={() => onChange("sort", "new")}
        >
          新規登録順
        </Button>
      </div>
      {/* <Button
        className="btn-setting rounded-circle"
        variant="outline-warning"
        size="lg"
        onClick={toggleFilter}
      >
        <img src={settingIcon} alt="" />
      </Button> */}
      <p className="text-end d-block text-truncate bold my-0">
        {renderFilters(filterParams, filterOptions)}
      </p>
    </div>
  )
}

export default Filter
