import * as actionTypes from "../constants/actions"

export const getLikersRequested = (userId, dispatch) => ({
  type: actionTypes.GET_LIKERS_REQUESTED,
  payload: { userId },
  dispatch
})

export const getLikersSuccess = (users) => ({
  type: actionTypes.GET_LIKERS_SUCCESS,
  payload: { users }
})

export const getLikersFailed = (message) => ({
  type: actionTypes.GET_LIKERS_FAILED,
  payload: { message }
})

export const getLikedUsersRequested = (userId, dispatch) => ({
  type: actionTypes.GET_LIKED_USERS_REQUESTED,
  payload: { userId },
  dispatch
})

export const getLikedUsersSuccess = (users) => ({
  type: actionTypes.GET_LIKED_USERS_SUCCESS,
  payload: { users }
})

export const getLikedUsersFailed = (message) => ({
  type: actionTypes.GET_LIKED_USERS_FAILED,
  payload: { message }
})

export const skipUserRequested = (toUserId, fromUserId, likeId, callbacks) => {
  return {
    type: actionTypes.SKIP_USER_REQUESTED,
    payload: {
      toUserId,
      fromUserId,
      likeId
    },
    callbacks
  }
}

export const skipUserSuccess = () => ({
  type: actionTypes.SKIP_USER_SUCCESS
})

export const skipUserFailed = (message) => {
  return {
    type: actionTypes.SKIP_USER_FAILED,
    payload: { message }
  }
}

export const markReadUserRequested = (likeId, tab, callbacks) => {
  return {
    type: actionTypes.MARK_READ_USER_REQUESTED,
    payload: {
      likeId,
      tab
    },
    callbacks
  }
}

export const markReadUserSuccess = () => {
  return {
    type: actionTypes.MARK_READ_USER_SUCCESS
  }
}

export const markReadUserFailed = (message) => {
  return {
    type: actionTypes.MARK_READ_USER_FAILED,
    payload: { message }
  }
}

export const thankUserRequested = (
  toUserId,
  fromUserId,
  likeId,
  callbacks
) => ({
  type: actionTypes.THANK_USER_REQUESTED,
  payload: {
    toUserId,
    fromUserId,
    likeId
  },
  callbacks
})

export const thankUserSuccess = () => ({
  type: actionTypes.THANK_USER_SUCCESS
})

export const thankUserFailed = (message) => ({
  type: actionTypes.THANK_USER_FAILED,
  payload: { message }
})

export const likeUserFootPrintRequested = (data, callbacks) => ({
  type: actionTypes.LIKE_USER_FOOTPRINT_REQUESTED,
  payload: {
    ...data
  },
  callbacks
})

export const likeUserFootPrintSuccess = () => ({
  type: actionTypes.LIKE_USER_FOOTPRINT_SUCCESS
})

export const likeUserFootPrintFailed = (message) => ({
  type: actionTypes.LIKE_USER_FOOTPRINT_FAILED,
  payload: { message }
})

export const labelUserRequested = (data, callbacks) => ({
  type: actionTypes.LABEL_USER_REQUESTED,
  payload: {
    ...data
  },
  callbacks
})

export const labelUserSuccess = () => ({
  type: actionTypes.LABEL_USER_SUCCESS
})

export const labelUserFailed = (message) => ({
  type: actionTypes.LABEL_USER_FAILED,
  payload: {
    message
  }
})

export const pinUserRequested = (data, callbacks) => ({
  type: actionTypes.PIN_USER_REQUESTED,
  payload: {
    ...data
  },
  callbacks
})

export const pinUserSuccess = () => ({
  type: actionTypes.PIN_USER_SUCCESS
})

export const pinUserFailed = (message) => ({
  type: actionTypes.PIN_USER_FAILED,
  payload: {
    message
  }
})
