import moment from "moment"
import { Image } from "react-bootstrap"
import { useHistory } from "react-router"
import htmlParser from "react-html-parser"
import { faPhoneAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { millisToTimeString } from "../../../utils"
import DefaultAvatar from "../../../images/avatar-app.png"
import { MESSAGE_TYPES, VIDEO_CALL_STATUS } from "../../../constants/env"

import "./styles.scss"

export const Message = ({
  message,
  isFromMe,
  showDate = false,
  showAvatar = false,
  avatarUrl
}) => {
  let date = ""
  let time = ""
  try {
    date = moment(message.createdAt.toDate()).format("YYYY/MM/DD")
    time = moment(message.createdAt.toDate()).format("HH:mm")
  } catch (err) {
    console.log("Parsing date err: ")
  }

  const history = useHistory()

  const renderDate = () => {
    return (
      <div className="yu-gothic-regular px-2 py-1 mb-2 mt-3 sent-date">
        {date}
      </div>
    )
  }

  const renderMyMessage = () => {
    return (
      <div className="my-message-container d-flex">
        <div className="sent-time pt-1 align-self-end me-2">
          {message.type === MESSAGE_TYPES.VIDEO_CALL ? "" : time}
        </div>
        {message.type === MESSAGE_TYPES.TEXT && renderTextMessage()}
        {message.type === MESSAGE_TYPES.IMAGE && renderImageMessage()}
        {message.type === MESSAGE_TYPES.VIDEO_CALL && renderVideoCallMessage()}
      </div>
    )
  }

  const renderOtherMessage = () => {
    return (
      <div className="other-message-container d-flex">
        <div className="d-flex">
          {showAvatar ? (
            <Image
              src={avatarUrl || DefaultAvatar}
              style={{ verticalAlign: "top" }}
              className="avatar-container"
            />
          ) : (
            <div className="avatar-container"></div>
          )}

          {message.type === MESSAGE_TYPES.TEXT && renderTextMessage()}
          {message.type === MESSAGE_TYPES.IMAGE && renderImageMessage()}
          {message.type === MESSAGE_TYPES.VIDEO_CALL &&
            renderVideoCallMessage()}
        </div>
        <div className="sent-time pt-1 align-self-end ms-2">
          {message.type === MESSAGE_TYPES.VIDEO_CALL ? "" : time}
        </div>
      </div>
    )
  }

  const renderTextMessage = () => (
    <div className="px-2 py-1 text-message text-downline align-self-center">
      {htmlParser(message.message)}
    </div>
  )

  const renderImageMessage = () => {
    return (
      <Image
        src={message.imageUrl}
        style={{ maxWidth: "40vw", height: "auto", borderRadius: "0.75rem" }}
        onClick={() => history.push("/image?image=" + message.imageUrl)}
      />
    )
  }

  const renderVideoCallMessage = () => {
    let text = ""
    let color = "red"
    if (
      message.videoCallStatus === VIDEO_CALL_STATUS.ENDED &&
      message.videoCallStartDatetime == null
    ) {
      text = isFromMe ? "キャンセル" : "不在着信"
    } else if (
      message.videoCallStatus === VIDEO_CALL_STATUS.NOT_STARTED ||
      message.videoCallStatus === VIDEO_CALL_STATUS.MISSED
    ) {
      text = isFromMe ? "キャンセル" : "不在着信"
    } else if (
      message.videoCallStatus === VIDEO_CALL_STATUS.ENDED &&
      message.videoCallStartDatetime != null &&
      message.videoCallEndDatetime != null
    ) {
      text = millisToTimeString(
        message.videoCallEndDatetime - message.videoCallStartDatetime
      )
      color = "black"
    }

    return (
      <div
        className={`d-flex justify-content-center align-items-center ${color} video-call-message-container`}
        style={{ height: "3.5rem", width: "7.5rem" }}
      >
        <div className="text-center d-flex align-items-center justify-content-center">
          <FontAwesomeIcon className="icon-call" icon={faPhoneAlt} size="lg" />
          <div
            className="mt-2 yu-gothic-regular text-start time-video-call"
            style={{ color: "#FFFFFF" }}
          >
            <div
              className="yu-gothic-regular mb-0"
              style={{ color: "#FFFFFF" }}
            >
              {text}
            </div>
            <div
              className="yu-gothic-regular mb-0"
              style={{ color: "#FFFFFF" }}
            >
              {time}
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      {showDate && (
        <div className="d-flex justify-content-center align-items-center">
          {renderDate()}
        </div>
      )}
      <div className="py-2 " align={isFromMe ? "right" : "left"}>
        {isFromMe ? renderMyMessage() : renderOtherMessage()}
      </div>
    </div>
  )
}
