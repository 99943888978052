import {
  GET_LIKERS_REQUESTED,
  GET_LIKERS_SUCCESS,
  GET_LIKERS_FAILED,
  GET_LIKED_USERS_REQUESTED,
  GET_LIKED_USERS_SUCCESS,
  GET_LIKED_USERS_FAILED,
  SKIP_USER_REQUESTED,
  SKIP_USER_SUCCESS,
  SKIP_USER_FAILED,
  THANK_USER_REQUESTED,
  THANK_USER_SUCCESS,
  THANK_USER_FAILED,
  MARK_READ_USER_REQUESTED,
  MARK_READ_USER_SUCCESS,
  MARK_READ_USER_FAILED,
  LIKE_USER_FOOTPRINT_REQUESTED,
  LIKE_USER_FOOTPRINT_SUCCESS,
  LIKE_USER_FOOTPRINT_FAILED,
  LABEL_USER_REQUESTED,
  PIN_USER_REQUESTED,
  LABEL_USER_SUCCESS,
  LABEL_USER_FAILED
} from "../constants/actions/likes"

const initialState = {
  isLoading: false,
  likers: [],
  likedUsers: [],
  error: null
}

const likesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIKERS_REQUESTED:
      return {
        ...state,
        isLoading: true,
        likers: [],
        error: null
      }

    case GET_LIKERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        likers: [...action.payload.users],
        error: null
      }
    case GET_LIKERS_FAILED:
      return {
        ...state,
        isLoading: false,
        likers: [],
        error: action.payload.message
      }
    case GET_LIKED_USERS_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case GET_LIKED_USERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        likedUsers: action.payload.users,
        error: null
      }
    case GET_LIKED_USERS_FAILED:
      return {
        ...state,
        isLoading: false,
        likedUsers: [],
        error: action.payload.message
      }
    case SKIP_USER_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case SKIP_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null
      }
    case SKIP_USER_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.message
      }
    case MARK_READ_USER_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case MARK_READ_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null
      }
    case MARK_READ_USER_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.message
      }
    case THANK_USER_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case THANK_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null
      }
    case THANK_USER_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.message
      }
    case LIKE_USER_FOOTPRINT_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case LIKE_USER_FOOTPRINT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null
      }
    case LIKE_USER_FOOTPRINT_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.message
      }
    case LABEL_USER_REQUESTED:
      return {
        ...state,
        isLoading: false,
        error: null
      }
    case PIN_USER_REQUESTED:
      return {
        ...state,
        isLoading: false,
        error: null
      }
    case LABEL_USER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: null
      }
    case LABEL_USER_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.message
      }
    default:
      return state
  }
}

export default likesReducer
