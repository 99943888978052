import {
  FETCH_USER_DETAIL_REQUEST,
  FETCH_USER_DETAIL_SUCCESS,
  FETCH_USER_DETAIL_FAILED,
  UPDATE_BONUS,
} from "../constants/actions/user"

export const fetchUserDetailRequested = (data = {}, callbacks) => {
  return {
    type: FETCH_USER_DETAIL_REQUEST,
    payload: {
      data: data
    },
    callbacks
  }
}
export const fetchUserDetailSuccess = (data) => {
  return {
    type: FETCH_USER_DETAIL_SUCCESS,
    data
  }
}

export const fetchUserDetailFailed = () => {
  return {
    type: FETCH_USER_DETAIL_FAILED
  }
}

export const updateBonus = (data = {}, callbacks) => {
  return {
    type: UPDATE_BONUS,
    payload: {
      data: data
    },
    callbacks
  }
}
