import { Image } from "react-bootstrap"
import BellIcon from "../../../icons/feather-bell.svg"

export const Empty = () => {
  return (
    <div className="h-100 d-flex flex-column justify-content-center align-items-center">
      <Image src={BellIcon} width="100" className="m-5" />
      <div className="text-center yu-gothic-bold">
        <h3 className="yu-gothic-bold">お知らせはありません</h3>
        <p className="p-3 pt-2">
          運営からの情報やその他の新着情報をお知らせします
        </p>
      </div>
    </div>
  )
}
