import _ from "lodash"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import { useEffect, useState, Fragment } from "react"

import Item from "./Item"
import useWindowSize from "../../hooks/use-window-size"

import "./styles.scss"

const List = ({ items, onClick, excludeId }) => {
  const windowSize = useWindowSize()
  const [_heightAvatar, setHeightAvatar] = useState(0)
  useEffect(() => {
    let obj = document.querySelector(".field-avatar.in-list")
    if (obj != null) {
      setHeightAvatar(obj.clientWidth)
    }
  }, [windowSize, items])
  return (
    <div className="list list-users">
      <Row className="g-2">
        {_.map(
          items,
          (item, idx) =>
            item.user_id !== excludeId && (
              <Fragment key={idx}>
                <Col xs={12} className="px-3" style={{ paddingTop: "0.5rem" }}>
                  <Item
                    onClick={onClick}
                    key={idx}
                    user={item}
                    height={_heightAvatar}
                  />
                </Col>
                <hr className="my-2" />
              </Fragment>
            )
        )}
      </Row>
    </div>
  )
}

export default List
