import { takeLatest, put, call, takeEvery } from "redux-saga/effects"
import { FETCH_DATA_MUTED, UNMUTE_USER } from "../constants/actions/other"
import { mutedListApi, unmuteUser } from "../services/muted"
import { fetchDataMutedSuccess, fetchDataMutedFail } from "../actions/other"

function* handleFetchListMuted(action) {
  try {
    const response = yield mutedListApi(action.payload, action)
    const { onSuccess, onFailed } = action?.payload?.callbacks || {}
    if (response) {
      yield put(fetchDataMutedSuccess(response.data))
      if (typeof onSuccess === "function") {
        yield call(onSuccess, response.data)
      }
    } else {
      yield put(fetchDataMutedFail())
      if (typeof onSuccess === "function") {
        yield call(onFailed, response.data)
      }
    }
  } catch (error) {}
}

function* unMuteUserHandler(action) {
  const { userId } = action.payload
  const { onSuccess, onFailed } = action.payload.callbacks
  try {
    const response = yield unmuteUser(userId, action)
    if (response.status === 200) {
      if (typeof onSuccess === "function") {
        yield call(onSuccess, response.data)
      }
    } else {
      if (typeof onFailed === "function") {
        yield call(onFailed)
      }
    }
  } catch (error) {
    yield call(onFailed)
  }
}

export default function* watchMuted() {
  yield takeEvery(FETCH_DATA_MUTED, handleFetchListMuted)
  yield takeLatest(UNMUTE_USER, unMuteUserHandler)
}
