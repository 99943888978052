const messages = {
  emptyPhoneNumber: "電話番号を入力してください。",
  closeAccount: "アカウントが退会済みです。復帰希望の場合は support＠tu-tu.jp へ連絡ください。",
  wrongPhoneNumber: "電話番号が正しくありません。",
  resendCodeSuccesss: "認証コードを再送信しました。",
  invalidCode: "認証コードが正しくありません。",
  emptyCode: "認証コードを入力してください。",
  duplicatePhoneNumber: "この電話番号は既に登録されています。",
  phoneNotRegistered: "電話番号が正しくありません。",
  somethingWrong: "エラーが発生します。再度お試しください。",
  emailInvalid: "メールアドレスは正しくありません。",
  registerFail: "登録できません。",
  codeSendFail: "認証コードを再送信できません。",
  emailOrPasswordIncorrect:
    "メールアドレス、又はパスワードは正しくありません。",
  phoneRegistered: "この電話番号は既に登録されています。",
  imageTooLarge: "サイズ：5MB以下、長さ：10秒以下かご確認のうえ再度アップロードを行ってください",
  passwordEmpty: "パスワードは必ず入力してください。",
  updateEmailSuccess: "引き継ぎ設定が完了しました。",
  updateEmailFail: "メールアドレスは更新できません。",
  residenceSelect: "居住地を選択してください。",
  ok: "閉じる",
  thankUserDetail: "スキップしました",
  muteUser: "ブロックしました",
  reportUser: "通報しました",
  cancel: "キャンセル",
  defaultTitle: "エラー",
  thanksUserSuccess: "気にしました",
  likeUserSuccess: "気にしました。",
  getSkippedFail: "スキップユーザー一覧は表示できません。",
  removeMutedSuccess: "ブロックを解除しました",
  wrongPasswordFormat: "パスワードを正しく入力してください。",
  duplicateEmail: "このメールアドレスは既に存在しています。",
  loginFail: "ログインできません。もう一度お試しください。",
  notEnoughPonint: "ポイントは不足しています。",
  mypageImageTooLarge: "10MB以下の画像を指定してください。",
  nothingToRestore: "復元できる購入はありません。",
  failToCancelPackage: "このプランは解約できません。",
  updatingContract: "情報は更新されています。",
  contractExist:
    "新しい料金プランを申し込むため、加入中の料金プランを解約してください。",
  failToGetPackages: "プラン一覧表示へエーラーが発生しています。",
  failToGetPointPackage: "ポイント一覧表示へエーラーが発生しています。",
  confirmCancelPackage: "このプランを解約しても宜しいでしょうか。",
  failToConnectWithBillingServer: "サーバーに接続できません",
  restoreSubscripSuccess: "購入内容の復元は完了しました。",
  purchaseExsist:
    "反映されていない申し込み済みプランがあります。設定画面から購入内容の復元を実行してください。",
  connectionError: "サーバーに接続できません。",
  confirmRestore:
    "有料オプションをご購入の場合、Email から購入履歴を復元できます",
  confirmRestoreTitle: "購入履歴の復元",
  confirm2: "復元する",
  failToUpgrade: "このプランを申し込みできません。",
  noInternet: "インターネットに接続できません。後ほど改めてお試しください。",
  nicknameEmpty: "ニックネームと2から8文字までで入力してください。"
}

export default messages
