export const FETCH_PLAN_REQUEST = "FETCH_PLAN_REQUEST"
export const FETCH_PLAN_SUCCESS = "FETCH_PLAN_SUCCESS"
export const FETCH_PLAN_FAILED = "FETCH_PLAN_FAILED"

export const SUBSCRIBE_PLAN_REQUEST = "SUBSCRIBE_PLAN_REQUEST"
export const SUBSCRIBE_PLAN_SUCCESS = "SUBSCRIBE_PLAN_SUCCESS"
export const SUBSCRIBE_PLAN_FAILED = "SUBSCRIBE_PLAN_FAILED"

export const UNSUBSCRIBE_PLAN_REQUEST = "UNSUBSCRIBE_PLAN_REQUEST"
export const UNSUBSCRIBE_PLAN_SUCCESS = "UNSUBSCRIBE_PLAN_SUCCESS"
export const UNSUBSCRIBE_PLAN_FAILED = "UNSUBSCRIBE_PLAN_FAILED"

export const CHANGE_INFO_CREDIT_REQUEST = "CHANGE_INFO_CREDIT_REQUEST"
export const CHANGE_INFO_CREDIT_SUCCESS = "CHANGE_INFO_CREDIT_SUCCESS"
export const CHANGE_INFO_CREDIT_FAILED = "CHANGE_INFO_CREDIT_FAILED"

export const RENEW_PLAN_REQUEST = "RENEW_PLAN_REQUEST"
