import {
  SET_CHATTING_USER,
  GET_MESSAGE_LIST_REQUESTED,
  GET_MESSAGE_LIST_SUCCESS,
  GET_MESSAGE_LIST_FAILED,
  SEND_MESSAGE_REQUESTED,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAILED
} from "../constants/actions"

const initialState = {
  isLoading: false,
  error: null,
  user: null,
  messageList: []
}

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CHATTING_USER:
      if (action.payload.user) {
        return {
          ...state,
          user: action.payload.user
        }
      } else {
        return {
          ...state,
          user: null,
          messageList: []
        }
      }

    case GET_MESSAGE_LIST_REQUESTED:
      return { ...state, isLoading: true, messageList: [], error: null }
    case GET_MESSAGE_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        messageList: action.payload.messageList,
        error: null
      }
    case GET_MESSAGE_LIST_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    case SEND_MESSAGE_REQUESTED:
      return {
        ...state,
        isLoading: true,
        error: null
      }
    case SEND_MESSAGE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: null
      }
    }
    case SEND_MESSAGE_FAILED: {
      return {
        ...state,
        isLoading: false,
        error: action.payload.error
      }
    }

    default:
      return state
  }
}

export default chatReducer
