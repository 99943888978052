import _ from "lodash"
import PropTypes from "prop-types"
import queryString from "querystring"
import { useHistory } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import AppContext from "../../../Context"
import Modal from "react-bootstrap/Modal"
import routes from "../../../constants/routes"
import { LoadingIndicator } from "../../../components"
import MatchForm from "../../../components/Filter/Match"
import { filterUsersRequest } from "../../../actions/filter"
import { getFilterOptionsRequest } from "../../../actions"

import "./styles.scss"

const Filter = ({ open, onClose, parentCallback, ...rest }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { setContext } = useContext(AppContext)

  const { options, sex } = useSelector((state) => {
    return {
      sex: state.auth.profile.sex,
      options: state.filter.options
    }
  })

  const handleSubmit = (values) => {
    parentCallback(values.keyword)
    onClose()
    history.push({
      pathname: routes.USERS,
      search: `?${queryString.stringify(values)}`
    })
    const sort = localStorage.getItem("sort")
    dispatch(
      filterUsersRequest(
        {
          page: 1,
          sort: sort || "login",
          perpage: 12,
          ...values
        },
        {
          onSuccess: (response) => {
            setContext("overlay", null)
          },
          onFailed: () => {
            setContext("overlay", null)
          }
        }
      )
    )
  }

  useEffect(() => {
    if (_.isEmpty(options)) {
      setContext("overlay", <LoadingIndicator />)
      dispatch(
        getFilterOptionsRequest(
          {},
          {
            onSuccess: () => {
              setContext("overlay", null)
            },
            onFailed: () => {
              setContext("overlay", null)
            }
          }
        )
      )
    }
  }, [])

  return (
    // <Layout>
    //   <AppBar title="絞り込み検索" withBack />
    <Modal
      show={open}
      centered
      onHide={onClose}
      className="modal-search-user"
      backdrop="static"
    >
      <Modal.Header closeButton style={{ borderBottom: "none" }}>
        <Modal.Title>検索条件</Modal.Title>
      </Modal.Header>
      <MatchForm options={options} sex={sex} onSubmit={handleSubmit} />
    </Modal>
  )
}

Filter.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
}

export default Filter
