import ImgCamera from "../../../images/img_camera.svg"
import ImgCameraMute from "../../../images/img_camera_mute.svg"
import ImgMicro from "../../../images/img_microphone.svg"
import ImgMicroMute from "../../../images/img_microphone_mute.svg"
import ImgGift from "../../../images/img_gift.png"
import ImgCoin from "../../../images/img_coin.svg"
import ImgEndCall from "../../../images/img_end_call.svg"

export const InCall = ({
  trackState,
  onMute,
  onEnd,
  isCaller,
  onShowGift,
  onShowPoint
}) => {
  return (
    <div
      className="position-fixed bottom-0 mb-3 w-100"
      style={{ maxWidth: "21rem", zIndex: 1000 }}
    >
      <div className="d-flex justify-content-around">
        {isCaller ? (
          <>
            <div className="call-btn" onClick={() => onMute("video")}>
              <img src={trackState.video ? ImgCamera : ImgCameraMute} alt="" />
            </div>
            <div className="call-btn call-btn-cancel" onClick={onEnd}>
              <img src={ImgEndCall} alt="" />
            </div>
            <div className="call-btn" onClick={() => onMute("audio")}>
              <img src={trackState.audio ? ImgMicro : ImgMicroMute} alt="" />
            </div>
            {/* <div className="call-btn" onClick={onShowGift}>
              <img src={ImgGift} alt="" />
            </div>
            <div className="call-btn" onClick={onShowPoint}>
              <img src={ImgCoin} alt="" />
            </div> */}
          </>
        ) : (
          <>
            <div className="call-btn" onClick={() => onMute("video")}>
              <img src={trackState.video ? ImgCamera : ImgCameraMute} alt="" />
            </div>
            <div className="call-btn call-btn-cancel" onClick={onEnd}>
              <img src={ImgEndCall} alt="" />
            </div>
            <div className="call-btn" onClick={() => onMute("audio")}>
              <img src={trackState.audio ? ImgMicro : ImgMicroMute} alt="" />
            </div>
          </>
        )}
      </div>
    </div>
  )
}
