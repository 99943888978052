import _ from "lodash"
import { toast } from "react-toastify"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import InfiniteScroll from "react-infinite-scroller"
import { useSelector, useDispatch } from "react-redux"

import { Item } from "./Item"
import { EmptyThirdTab } from "./EmptyThirdTab"
import routes from "../../../../constants/routes"
import { ErrorModal } from "../../../../components/ErrorModal"
import { LoadingIndicator } from "../../../../components/LoadingIndicator"
import {
  fetchDataFootPrints,
  labelUserRequested,
  likeUserFootPrintRequested,
  markReadUserRequested,
  muteUserRequested
} from "../../../../actions"
import { fetchUserDetailSuccess } from "../../../../actions"
import { fetchAuthProfileRequest } from "../../../../actions/auth"

export const List = ({ onViewUsers }) => {
  const perPage = 10

  const [loading, setLoading] = useState(true)
  const [countPage, setCountPage] = useState(1)
  const [errorModal, setErrorModal] = useState(false)
  const [hasNextPage, setHasNextPage] = useState(true)
  const [displayedUsers, setDisplayedUsers] = useState([])

  const history = useHistory()
  const dispatch = useDispatch()

  const currentUser = useSelector((state) => {
    return state.auth.profile
  })
  const error = useSelector((state) => state.likes.error)
  const footPrintsData = useSelector((state) => state.footPrint?.data)

  useEffect(() => {
    let data = []
    if (footPrintsData) {
      data = displayedUsers.concat(footPrintsData.users)
      setDisplayedUsers(_.reverse(_.uniqBy(_.reverse([...data]), "user_id")))
      setLoading(false)
    }
  }, [footPrintsData?.users])

  useEffect(() => {
    onLoadMore(1)
  }, [window.location.search])

  const onLoadMore = (options) => {
    setHasNextPage(false)

    let page
    if (options === undefined) {
      page = countPage
    } else {
      page = options
    }

    dispatch(
      fetchDataFootPrints(
        { page: page, perpage: perPage },
        {
          onSuccess: (response) => {
            setHasNextPage(_.size(response.data.users) > 10)
          },
          onFailed: () => {
            setLoading(false)
          }
        }
      )
    )

    setCountPage(page + 1)
  }

  const handleBlockUser = (userId) => {
    dispatch(
      muteUserRequested(
        { id: userId },
        {
          onSuccess: (response) => {
            toast("ブロックしました")
            setDisplayedUsers(
              _.reject(displayedUsers, {
                user_id: userId
              })
            )
          },
          onFailed: () => {}
        }
      )
    )
  }

  const handleThankUser = (data) => {
    dispatch(
      likeUserFootPrintRequested(data, {
        onSuccess: (response) => {
          if (response.data.matching_status === 3) {
            dispatch(fetchUserDetailSuccess(data.user))
            history.push(routes.MATCH)
          } else {
            toast("気にしました")
            const newDataDisPlayers = displayedUsers.map((item) => {
              if (item.user_id == response.data.to_user_id) {
                return {
                  ...item,
                  is_liked: !item.is_label
                }
              } else {
                return item
              }
            })
            setDisplayedUsers(newDataDisPlayers)
            dispatch(fetchAuthProfileRequest({}))
          }
        },
        onFailed: () => {
          setErrorModal(true)
        }
      })
    )
  }

  const handleMarkReadUser = (userId) => {
    dispatch(
      markReadUserRequested(userId, 3, {
        onSuccess: () => {
          const newList = displayedUsers.map((item) => {
            if (item.user_id === userId) {
              return { ...item, is_read: 1 }
            }
            return { ...item }
          })
          setDisplayedUsers(newList)
          toast("既読しました")
        },
        onFailed: () => {
          setErrorModal(true)
        }
      })
    )
  }

  const handleLabelUser = (toUserId, isLabel) => {
    const data = {
      toUserId: toUserId,
      isLabel: isLabel
    }

    dispatch(
      labelUserRequested(data, {
        onSuccess: () => {
          toast("更新されました")
          const newData = displayedUsers.map((item) => {
            if (item.user_id === toUserId) {
              return {
                ...item,
                is_label: !item.is_label
              }
            } else {
              return item
            }
          })

          setDisplayedUsers(newData)
        },
        onFailed: () => {
          setErrorModal(true)
        }
      })
    )
  }

  const handleClickItem = (user) => {
    let ids = []
    displayedUsers.forEach(function (u) {
      ids.push(parseInt(u.user_id))
    })
    localStorage.setItem("list_user_like", JSON.stringify(ids))
    history.push(`${routes.USERS}/${user.user_id}?from=like`, {
      swipeable: true
    })
    if (user.is_read === 0) {
      handleMarkReadUser(user.user_id)
    }
  }

  return (
    <>
      {loading || !currentUser?.user_id ? (
        <LoadingIndicator />
      ) : !_.isEmpty(displayedUsers) ? (
        <div className="user-list">
          <InfiniteScroll
            pageStart={0}
            loadMore={onLoadMore}
            hasMore={hasNextPage}
            loader={<LoadingIndicator key={0} sm />}
            useWindow={false}
          >
            {displayedUsers.map((user, index) => (
              <Item
                key={index}
                user={user}
                onBlock={handleBlockUser}
                onThank={handleThankUser}
                onMark={handleMarkReadUser}
                onLabel={handleLabelUser}
                myProfile={currentUser}
                onAvatarClick={() => {
                  handleClickItem(user)
                }}
                onRightClick={() => {
                  handleClickItem(user)
                }}
              />
            ))}
          </InfiniteScroll>
        </div>
      ) : (
        <EmptyThirdTab onClick={onViewUsers} />
      )}
      <ErrorModal
        show={errorModal}
        content={error}
        onClose={() => setErrorModal(false)}
      />
    </>
  )
}
