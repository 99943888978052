import { takeLatest, put } from "redux-saga/effects"
import { FETCH_DATA_SKIPPED } from "../constants/actions/other"
import { skipUsersApi } from "../services/skipped"
import {
  fetchDataListSkippedSuccess,
  fetchDataListSkippedFail
} from "../actions/other"

function* handleFetchListSkipped(action) {
  try {
    const response = yield skipUsersApi(action.payload, action)
    if (response) {
      yield put(fetchDataListSkippedSuccess(response.data))
    } else {
      yield put(fetchDataListSkippedFail())
    }
  } catch (error) {}
}

export default function* watchSkipped() {
  yield takeLatest(FETCH_DATA_SKIPPED, handleFetchListSkipped)
}
