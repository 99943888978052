import HttpClient from "../utils/client"

function* exChangePointApi(data, action) {
  const url = `/users/exchange-points`
  return yield HttpClient("POST", { url, data }, action)
}

function* sendQuestionApi(data, action) {
  const url = "/users/1/send-question"
  return yield HttpClient("POST", { url, data }, action)
}

function* deleteAccountApi(action) {
  const url = "/close-account"
  return yield HttpClient("DELETE", { url, data: { app_type: "Web" } }, action)
}

function* takeOverApi(data) {
  const url = "/send-verify-mail"
  return yield HttpClient("POST", { url, data })
}

function* verifyEmail(data) {
  const url = "/verify-mail"
  return yield HttpClient("POST", { url, data })
}

function* sendWithdrawalRequest(data, action) {
  const url = `/request-withdrawal`
  return yield HttpClient("POST", { url, params: data }, action)
}

function* updateBankRequest(data, action) {
  const url = `/users/1`
  return yield HttpClient("POST", { url, params: data }, action)
}

function* listDataWithdrawal(data, action) {
  const url = "/withdrawals"
  return yield HttpClient("GET", { url }, action)
}

function* userReportData(data, action) {
  const url = `/users/${data}/report`
  return yield HttpClient("POST", { url }, action)
}

function* getBanner() {
  const url = "/banner/list"
  return yield HttpClient("GET", { url })
}

function* getNotificationSetting() {
  const url = "/settings"
  return yield HttpClient("GET", { url })
}

function* notificationSetting(data) {
  const url = `/change-notification-settings?status=${data.status}&type=${data.type}`
  return yield HttpClient("GET", { url })
}

export {
  exChangePointApi,
  sendQuestionApi,
  deleteAccountApi,
  takeOverApi,
  sendWithdrawalRequest,
  updateBankRequest,
  listDataWithdrawal,
  userReportData,
  getBanner,
  verifyEmail,
  getNotificationSetting,
  notificationSetting
}
