import Loader from "react-loader-spinner"
import { useHistory } from "react-router-dom"
import { useContext, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import "../style.scss"
import AppContext from "../../../Context"
import AppBar from "../../../components/AppBar"
import Layout from "../../../components/Layout"
import { LoadingIndicator } from "../../../components/LoadingIndicator"
import { subscribePlanRequest, unSubscribePlanRequest } from "../../../actions"

const PaymentType = () => {
  const history = useHistory()
  const dispatch = useDispatch()

  const profile = useSelector((state) => {
    return state.auth.profile
  })

  const { setContext } = useContext(AppContext)

  const [isLoading, setIsLoading] = useState(false)
  const [showPaymentPopup, setShowPaymentPopup] = useState(false)
  const [paymentUrl, setPaymentUrl] = useState("")
  const [showClose, setShowClose] = useState(true)
  const [showLoadingFrame, setShowLoadingFrame] = useState(false)
  const popupPayment = useRef(null)

  const onSubscribe = (plan_id, creditType, isCancel) => {
    if (isCancel) {
      if (profile.plan_cancel_status === 1) {
        alert("当プランは既に解約されました。")
      } else {
        if (window.confirm("このプランを解約しても宜しいでしょうか。")) {
          setIsLoading(true)
          dispatch(
            unSubscribePlanRequest(
              {},
              {
                onSuccess: (response) => {
                  alert("プランを解約しました。")
                  reload()
                },
                onFailed: () => {
                  setIsLoading(false)
                  alert("プランを解約するすることを失敗しました。")
                }
              }
            )
          )
        }
      }
    } else {
      setIsLoading(true)
      dispatch(
        subscribePlanRequest(
          {
            credit_type: creditType,
            plan_id: plan_id
          },
          {
            onSuccess: (response) => {
              setPaymentUrl(response.url)
              window.open(response.url, "_self")
              // setShowPaymentPopup(true)
              // setShowClose(true)
              setIsLoading(false)
              // setShowLoadingFrame(true)
              setContext("overlay", null)
              // setTimeout(() => {
              //   setShowLoadingFrame(false)
              // }, 400)
            },
            onFailed: () => {
              setIsLoading(false)
              setContext("overlay", null)
              alert("プランを申し込むすることを失敗しました。")
            }
          }
        )
      )
    }
  }

  const reload = () => {
    setPaymentUrl("")
    setShowPaymentPopup(false)
    history.push("/profile")
  }

  return (
    <Layout>
      <AppBar title="決済種別選択" withBack />
      <div className="bg-white d-flex flex-column full-height-with-navbar">
        <div className="payment-type-page">
          {isLoading ? (
            <LoadingIndicator />
          ) : (
            <>
              {showPaymentPopup && paymentUrl !== "" ? (
                <>
                  <div className="popup-payment">
                    {showClose && (
                      <button
                        type="button"
                        className="btn-cancel-payment"
                        onClick={() => reload()}
                      >
                        x
                      </button>
                    )}
                    <iframe
                      src={paymentUrl}
                      ref={popupPayment}
                      title="Credix株式会社"
                    ></iframe>
                  </div>
                  {showLoadingFrame && (
                    <div className="loading-frame">
                      <Loader
                        type="Oval"
                        color="grey"
                        width="4rem"
                        height="4rem"
                      />
                    </div>
                  )}
                </>
              ) : (
                <div className="payment-type">
                  <div
                    className="payment-type-item"
                    onClick={() => {
                      const planId = history.location.state.plan_id
                      onSubscribe(planId, 1, false)
                    }}
                  >
                    <img
                      className="payment-type-image"
                      src="https://as2.ftcdn.net/v2/jpg/04/07/39/35/1000_F_407393539_vn134IDVdg6UkULr4CJgZjshgImuGve1.jpg"
                      alt="Payment type logo"
                    />
                    <p className="payment-type-title">クレジットカード</p>
                  </div>
                  <div className="payment-type-item">
                    <img
                      className="payment-type-image"
                      src="https://seeklogo.com/images/P/paidy-logo-AB393EA5FB-seeklogo.com.png"
                      alt="Payment type logo"
                    />
                    <p className="payment-type-title">ペイディ</p>
                    <div className="payment-type-overlay">カミングスーン</div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </Layout>
  )
}

export default PaymentType
