import { all } from "redux-saga/effects"

import authSaga from "./auth"
import errorSaga from "./error"
import watchSignUp from "./signup"
import watchFilter from "./filter"
import profileFilter from "./profile"
import likesSaga from "./likes"
import messageSaga, { watchChatListSuccess } from "./message"
import chatSaga from "./chat"
import videoCallSaga from "./videoCall"
import watchUser from "./user"
import watchMyPage from "./mypage"
import watchPlan from "./plan"
import watchBuyPoint from "./buy-point"
import watchMuteUser from "./mute"
import watchFollowUser from "./follow"
import watchOther from "./other"
import watchNotification from "./notifications"
import watchSkipped from "./skipped"
import watchScheduleList from "./schedule"
import watchMuted from "./muted"
import watchPopups from "./popups"

export default function* booberSaga() {
  yield all([
    authSaga(),
    errorSaga(),
    watchSignUp(),
    watchFilter(),
    profileFilter(),
    likesSaga(),
    watchUser(),
    messageSaga(),
    chatSaga(),
    videoCallSaga(),
    watchMyPage(),
    watchPlan(),
    watchBuyPoint(),
    watchMuteUser(),
    watchFollowUser(),
    watchOther(),
    watchNotification(),
    watchSkipped(),
    watchMuted(),
    watchScheduleList(),
    watchChatListSuccess(),
    watchPopups(),
  ])
}
