import {
  SET_CHATTING_USER,
  GET_MESSAGE_LIST_REQUESTED,
  GET_MESSAGE_LIST_SUCCESS,
  GET_MESSAGE_LIST_FAILED,
  SEND_MESSAGE_REQUESTED,
  SEND_MESSAGE_SUCCESS,
  SEND_MESSAGE_FAILED,
  NOTIFY_MESSAGE
} from "../constants/actions"

export const setChattingUser = (user) => ({
  type: SET_CHATTING_USER,
  payload: { user },
})

export const getMessageListRequested = (likeId, dispatch) => ({
  type: GET_MESSAGE_LIST_REQUESTED,
  payload: { likeId },
  dispatch,
})

export const getMessageListSuccess = (messageList) => ({
  type: GET_MESSAGE_LIST_SUCCESS,
  payload: { messageList },
})

export const getMessageListFailed = (error) => ({
  type: GET_MESSAGE_LIST_FAILED,
  payload: { error },
})

export const sendMessageRequested = (message, likeId, onError) => {
  return {
    type: SEND_MESSAGE_REQUESTED,
    payload: { message, likeId },
    onError,
  }
}

export const sendMessageSuccess = () => {
  return {
    type: SEND_MESSAGE_SUCCESS,
  }
}

export const sendMessageFailed = (error) => {
  return {
    type: SEND_MESSAGE_FAILED,
    payload: { error },
  }
}

export const notifyMessage = (data = {}, callbacks) => {
  return {
    type: NOTIFY_MESSAGE,
    payload: data,
    callbacks
  }
}