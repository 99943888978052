import moment from "moment"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useState, useEffect, useRef, useContext, useCallback } from "react"
import {
  createClient,
  AgoraVideoPlayer,
  createMicrophoneAndCameraTracks
} from "agora-rtc-react"

import {
  updateVideoCallRequested,
  updateVideoMessageRequested,
  fetchGiftsRequest,
  setIsCalling,
  fetchAuthProfileRequest,
  extendVideoCallSuccess
} from "../../actions"
import { fetchPointPackageRequest } from "../../actions/buy-point"
import { InCall } from "./components/InCall"
import { ConfirmModal } from "../../components/ConfirmModal"
import { LoadingIndicator } from "../../components/LoadingIndicator"
import Gifts from "../../components/Gifts"
import { GIFTS } from "../../components/Gifts/images"
import BuyPointGift from "../../components/BuyPointGift"
import {
  AGORA_APP_ID,
  VIDEO_CALL_STATUS,
  MESSAGE_TYPES,
  AGORA_MESSAGE_TYPES
} from "../../constants/env"
import rtmClient from "../../agora/agoraRTMClient"
import { getAvatarUrl } from "../../utils"
import ImgCameraMute from "../../images/img_camera_mute.svg"
import DefaultAvatar from "../../images/avatar-app.png"
import { AnimatedGift } from "./components/AnimatedGift"
import AppContext from "../../Context"

import "./styles.scss"

const config = {
  mode: "rtc",
  codec: "vp8"
}

const useClient = createClient(config)
const useMicrophoneAndCameraTracks = createMicrophoneAndCameraTracks()
let timeout = null

const VideoCallScreen = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const isCalling = useSelector((state) => state.videoCall.isCalling)
  const caller = useSelector((state) => state.videoCall.caller)
  const callee = useSelector((state) => state.videoCall.callee)
  const isCaller = useSelector((state) => state.videoCall.isCaller)
  const calleeUid = useSelector((state) => state.videoCall.calleeUid)
  const callerUid = useSelector((state) => state.videoCall.callerUid)
  const likeId = useSelector((state) => state.videoCall.likeId)
  const { token, channel, videoCallData } = useSelector(
    (state) => state.videoCall
  )
  const [showPopupGift, setShowPopupGift] = useState(false)
  const [showPopupPoint, setShowPopupPoint] = useState(false)
  const pointPackages = useSelector((state) => {
    return state.buyPoint.pointPackages
  })
  const gifts = useSelector((state) => {
    return state.videoCall.gifts
  })

  const [confirmModal, setConfirmModal] = useState({ visible: false })
  const [remoteUser, setRemoteUser] = useState(null)
  const [trackState, setTrackState] = useState({ audio: true, video: false })
  const [remoteTrackState, setRemoteTrackState] = useState({
    audio: true,
    video: false
  })
  const [receivedGifts, setReceivedGifts] = useState([])
  const [sentGifts, setSentGifts] = useState([])
  const [shownGift, setShownGift] = useState(null)
  const endDatetimeRef = useRef(
    videoCallData ? videoCallData.end_datetime : null
  )
  const endReasonRef = useRef(null)
  const { setContext } = useContext(AppContext)

  const client = useClient()
  const { ready, tracks } = useMicrophoneAndCameraTracks()
  const tracksRef = useRef(null)
  const avatarUrl =
    isCaller && callee
      ? getAvatarUrl(callee.images)
      : !isCaller && caller
      ? caller.avatarUrl
      : null

  const handleMessageFromPeer = useCallback((message, peerId, messageProps) => {
    const messageContent = JSON.parse(message.text)
    const data = messageContent.data
    switch (messageContent.type) {
      case AGORA_MESSAGE_TYPES.SEND_GIFT:
        const giftImg = GIFTS[data.item.gift_id]
        setReceivedGifts([...receivedGifts, giftImg])
        setShownGift(giftImg)
        toast(`お相手から「${data.item.name}」が届きました！`)
        setTimeout(() => {
          setShownGift(null)
        }, 5000)
        break
      case AGORA_MESSAGE_TYPES.END_CALL:
        setContext("overlay", <LoadingIndicator />)
        setRemoteUser(null)
        endDatetimeRef.current = moment().valueOf()
        endReasonRef.current = 1
        leaveChannel()
        break
      case AGORA_MESSAGE_TYPES.DISABLE_VIDEO:
        setRemoteTrackState({ ...remoteTrackState, video: data })
        break
      default:
      // console.log("Unknown agora message type")
    }
  }, [])

  const init = async (token, channel) => {
    client.on("user-published", async (user, mediaType) => {
      await client.subscribe(user, mediaType)
      if (mediaType === "video") {
        setRemoteUser(user)
      }
      if (mediaType === "audio") {
        user.audioTrack?.play()
      }
    })
    client.on("user-unpublished", (user, mediaType) => {
      if (mediaType === "audio") {
        user.audioTrack?.stop()
      } else if (mediaType === "video") {
        setRemoteUser(null)
      }
    })
    client.on("user-left", (user, reason) => {
      setContext("overlay", <LoadingIndicator />)
      setRemoteUser(null)
      endDatetimeRef.current = moment().valueOf()
      endReasonRef.current = 1
      leaveChannel()
    })

    client.on("user-joined", (user) => {
      // console.log("anhanq. user joined: ", user)
    })

    rtmClient.on("MessageFromPeer", handleMessageFromPeer)

    await client.join(AGORA_APP_ID, channel, token)
    if (tracks) {
      await client.publish([tracks[0], tracks[1]])
    }
  }

  const onGoBack = useCallback(async () => {
    endDatetimeRef.current = moment().valueOf()
    sendEndCallMessage()
    await leaveChannel()
    window.removeEventListener("popstate", onGoBack)
  }, [])

  useEffect(() => {
    if (isCalling) {
      if (isCaller) {
        setConfirmModal({
          visible: false,
          content: (
            <p>
              10分ごとに8ポイントずつ消費されます。
              <br />
              この方との通話が初めての場合始めの5分間はポイントの消費はありません。5分経過後、ポイントの消費が始まります。
            </p>
          )
        })
      }

      dispatch(
        fetchPointPackageRequest({
          onSuccess: (response) => {},
          onFailed: () => {}
        })
      )
      // dispatch(
      //   fetchGiftsRequest({
      //     onSuccess: (response) => {},
      //     onFailed: () => {}
      //   })
      // )
    }

    window.addEventListener("popstate", onGoBack)

    return () => {
      rtmClient.off("MessageFromPeer", handleMessageFromPeer)
      dispatch(setIsCalling(false, endReasonRef.current))
      clearTimeout(timeout)
      setContext("overlay", null)
    }
  }, [])

  useEffect(() => {
    if (ready) {
      tracksRef.current = tracks
    }
    if (!isCalling && ready) {
      if (tracks && tracks[0]) {
        tracks[0].close()
      }
      if (tracks && tracks[1]) {
        tracks[1].close()
      }
      history.replace("/chats?tab=2")
    }
  }, [ready])

  useEffect(() => {
    if (isCalling && token && channel && ready && tracks) {
      init(token, channel)
    }
  }, [token, channel, ready, tracks])

  useEffect(() => {
    const endCall = async () => {
      if (videoCallData) {
        const { start_datetime, end_datetime } = videoCallData
        if (start_datetime && end_datetime) {
          const leftTime = end_datetime - moment().valueOf()
          if (leftTime > 0) {
            setTimeout(() => {
              endDatetimeRef.current = moment().valueOf()
              endReasonRef.current = 1
              sendEndCallMessage()
              leaveChannel()
            }, leftTime)
          }
          dispatch(fetchAuthProfileRequest({}))
        } else {
          if (isCaller) {
            endDatetimeRef.current = moment().valueOf()
            endReasonRef.current = 0
            await leaveChannel(true)
          }
        }
      }
    }
    endCall()
  }, [videoCallData])

  const mute = async (type) => {
    if (type === "audio") {
      await tracks[0].setEnabled(!trackState.audio)
      setTrackState((ps) => ({ ...ps, audio: !ps.audio }))
    } else if (type === "video") {
      const partnerUid = isCaller ? calleeUid : callerUid
      rtmClient.sendMessageToPeer(
        partnerUid,
        { type: AGORA_MESSAGE_TYPES.DISABLE_VIDEO, data: !trackState.video },
        (data) => {
          // console.log("send mute message success: ", data)
        },
        (err) => {
          console.log("send mute message err: ", err)
        }
      )
      await tracks[1].setEnabled(!trackState.video)
      setTrackState((ps) => ({ ...ps, video: !ps.video }))
    }
  }

  const leaveChannel = async () => {
    setRemoteUser(null)
    await client.leave()
    client.removeAllListeners()
    try {
      tracksRef.current[0].close()
    } catch (err) {
      console.log("Cannot close track 0: ", err)
    }
    try {
      tracksRef.current[1].close()
    } catch (err) {
      console.log("Cannot close track 1: ", err)
    }
    dispatch(extendVideoCallSuccess(null))
    dispatch(
      updateVideoCallRequested(channel, VIDEO_CALL_STATUS.ENDED, {
        onSuccess: (videoCallDoc) => {
          setTimeout(
            () => {
              const msg = {
                userId: caller.user_id,
                type: MESSAGE_TYPES.VIDEO_CALL,
                // message: "",
                isRead: 0,
                imageUrl: null,
                videoCallId: channel,
                videoCallStatus: VIDEO_CALL_STATUS.ENDED,
                videoCallStartDatetime: videoCallDoc?.startDatetime,
                videoCallEndDatetime: videoCallDoc?.endDatetime
              }
              dispatch(updateVideoMessageRequested(likeId, null, msg))
              rtmClient.setStatus("online")
              if (isCaller) {
                history.replace(`/private-chat/${callee.user_id}`)
              } else {
                history.replace(`/chats?tab=2`)
              }
            },
            isCaller ? 0 : 3000
          )
        },
        onFailed: () => {}
      })
    )
  }

  const sendEndCallMessage = () => {
    const agoraMessage = {
      type: AGORA_MESSAGE_TYPES.END_CALL
    }
    rtmClient.sendMessageToPeer(
      isCaller ? calleeUid : callerUid,
      agoraMessage,
      (data) => {
        // console.log("send end call message success: ", data)
      },
      (err) => {
        console.log("send end call message error: ", err)
      }
    )
  }

  return (
    <div className="d-flex flex-column justify-content-around align-items-center video-call-container in-call">
      <InCall
        trackState={trackState}
        onMute={mute}
        onEnd={() => {
          console.log("Leave due to click")
          endDatetimeRef.current = moment().valueOf()
          endReasonRef.current = 1
          sendEndCallMessage()
          leaveChannel()
        }}
        isCaller={isCaller}
        onShowGift={() => {
          setShowPopupGift(true)
        }}
        onShowPoint={() => {
          setShowPopupPoint(true)
        }}
      />
      {tracks && tracks[1] && trackState.video ? (
        <AgoraVideoPlayer className="my-video" videoTrack={tracks[1]} />
      ) : (
        <div className="d-flex align-items-center justify-content-center my-video">
          <img src={ImgCameraMute} alt="" />
        </div>
      )}
      {remoteUser && remoteUser.hasVideo && remoteTrackState.video ? (
        <AgoraVideoPlayer
          videoTrack={remoteUser.videoTrack}
          className="remote-video"
        />
      ) : (
        <img
          src={avatarUrl || DefaultAvatar}
          alt=""
          className="remote-video-alt"
        />
      )}

      {shownGift && <AnimatedGift giftSrc={shownGift} />}

      {showPopupGift && (
        <Gifts
          gifts={gifts}
          channelId={channel}
          toUserId={
            isCaller
              ? callee == null
                ? 0
                : callee.user_id
              : caller == null
              ? 0
              : caller.user_id
          }
          callerId={
            !isCaller
              ? callee == null
                ? 0
                : callee.user_id
              : caller == null
              ? 0
              : caller.user_id
          }
          onClickOutside={() => {
            setShowPopupGift(false)
          }}
          onBuyPoint={() => {
            setShowPopupGift(false)
            setShowPopupPoint(true)
          }}
          onTipped={(data, gift) => {
            data.name = gift.name
            const msgContent = {
              type: AGORA_MESSAGE_TYPES.SEND_GIFT,
              data: { item: gift }
            }
            rtmClient.sendMessageToPeer(
              calleeUid,
              msgContent,
              () => {
                setSentGifts([...sentGifts, GIFTS[data.gift_id]])
                setShownGift(GIFTS[data.gift_id])
                setTimeout(() => {
                  setShownGift(null)
                }, 5000)
              },
              () => {}
            )
            toast("ギフトを贈りしました。")
            setShowPopupGift(false)
          }}
        />
      )}
      {showPopupPoint && (
        <BuyPointGift
          pointPackages={pointPackages}
          onClickOutside={() => {
            setShowPopupPoint(false)
          }}
          onPayment={() => {
            setShowPopupPoint(false)
          }}
        />
      )}
      {confirmModal.visible && (
        <ConfirmModal
          content={confirmModal.content}
          onConfirm={() => setConfirmModal({ visible: false })}
        />
      )}
    </div>
  )
}

export default VideoCallScreen
