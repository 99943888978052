import HttpClient from "../utils/client"

function* footPrintsApi(data, action) {
  const url = "/footprint-users"
  // const dataBody = {
  //     email: data?.email,
  //     password: data?.password,
  // };
  return yield HttpClient("GET", { url, params: data.data }, action)
}

function* footUpdate(action) {
  const url = "/footprint-update-users"
  return yield HttpClient("GET", { url }, action)
}

export { footPrintsApi, footUpdate }
