import { Row } from "react-bootstrap"
import { StatusDot } from "../../../components/StatusDot"
import moment from "moment"
import "moment/locale/ja"
import "./styles.scss"

const NotificationItem = ({ id, title, datetime, status, onClick }) => {
  moment.locale("ja")
  const dateTimeStr = moment(datetime).format("LLL")
  const readStatus = status === 0
  return (
    <div className="px-4 yu-gothic" onClick={() => onClick(id)}>
      <Row className="mb-2 pb-1 border-bottom border-1">
        <div className="d-flex justify-content-between notification-bottom">
          <div className="text-small">{dateTimeStr}</div>
          {readStatus && (
            <div>
              <StatusDot color={"#EC7838"} />
            </div>
          )}
        </div>
        <div>
          {readStatus ? <strong>{title}</strong> : <span>{title}</span>}
        </div>
      </Row>
    </div>
  )
}

export default NotificationItem
