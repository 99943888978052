import * as actionTypes from "../constants/actions"

const initialState = {
  profile: {},
  loadingProfile: false,
  isShowPopupAfterLogin: false,
  errorProfile: {},
  deviceToken: null
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPSERT_AUTH_PROFILE:
      const { data } = action.payload
      return {
        ...state,
        ...data
      }

    case actionTypes.CHECK_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        ...action.data
      }

    case actionTypes.UPDATE_USER_PROFILE_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.data.payload
        }
      }

    case actionTypes.FETCH_AUTH_PROFILE_REQUEST:
      return {
        ...state,
        loadingProfile: true
      }

    case actionTypes.FETCH_AUTH_PROFILE_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.data
        },
        loadingProfile: false
      }

    case actionTypes.FETCH_AUTH_PROFILE_FAILED:
      return {
        ...state,
        loadingProfile: false,
        errorProfile: action.data
      }

    case actionTypes.SHOW_POPUP_AFTER_LOGIN:
      return {
        ...state,
        isShowPopupAfterLogin: action.data
      }

    case actionTypes.AUTH_LOGIN_WITH_EMAIL_REQUEST:
    case actionTypes.AUTH_LOGIN_WITH_EMAIL_SUCCESS:
    case actionTypes.AUTH_LOGIN_WITH_EMAIL_FAILED:
    case actionTypes.ADD_DEVICE_TOKEN_SUCCESS:
      return { ...state, deviceToken: action.payload ? action.payload.deviceToken : null }
    default:
      return state
  }
}

export default authReducer
