import { Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faHeart, faThumbsUp } from "@fortawesome/free-solid-svg-icons"

import { UserItem } from "../../../../components/UserItem"

export const Item = ({
  user,
  onThank,
  onAvatarClick,
  onRightClick,
  onBlock,
  onMark,
  onLabel,
  myProfile
}) => {
  return (
    <UserItem
      user={user}
      onAvatarClick={onAvatarClick}
      onRightClick={onRightClick}
      onBlock={onBlock}
      onMark={onMark}
      onLabel={onLabel}
    >
      {user.is_liked === 0 && (
        <div className="d-flex justify-content-end yu-gothic-bold flex-column">
          {user.is_user_liked === 0 && myProfile.isUsedSuperLike === false && (
            <Button
              style={{
                fontSize: 9,
                padding: 4,
                borderRadius: 4,
                marginBottom: 3,
                width: 63,
                borderWidth: 2
              }}
              className="d-flex align-items-center"
              variant="outline-danger"
              size="sm"
              onClick={() => onThank({ user, likeType: 2 })}
            >
              <FontAwesomeIcon icon={faThumbsUp} style={{ marginRight: 4 }} />
              <span
                style={{
                  width: "69%",
                  margin: "0 auto",
                  lineHeight: 0.9,
                  fontSize: 8
                }}
              >
                スーパー気になる
              </span>
            </Button>
          )}
          <Button
            style={{
              fontSize: 9,
              padding: 4,
              borderRadius: 4,
              borderWidth: user.is_user_liked === 0 ? 2 : 1
            }}
            variant={user.is_user_liked === 0 ? "outline-danger" : "danger"}
            size="sm"
            onClick={() => onThank({ user, likeType: 1 })}
          >
            <FontAwesomeIcon
              icon={user.is_user_liked === 0 ? faThumbsUp : faHeart}
              style={{ marginRight: 4 }}
            />
            {user.is_user_liked === 0 ? "気になる" : "マッチする"}
          </Button>
        </div>
      )}
    </UserItem>
  )
}
