import HttpClient from "../utils/client"

function* getResidences(action) {
  return yield HttpClient("GET", { url: "/data-register" }, action)
}

function* filterUsers({ data: params }, action) {
  return yield HttpClient("GET", { url: "/users", params }, action)
}

function* getFilterOptions(params, action) {
  return yield HttpClient("GET", { url: `/data-default` }, action)
}

export { getResidences, filterUsers, getFilterOptions }
