import {
  FETCH_POPUPS_REQUEST,
  FETCH_POPUPS_SUCCESS,
  FETCH_POPUPS_FAILURE,
} from "../actions/popups"

const initialState = {
  loading: false,
  data: [],
  error: null,
}

const popupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_POPUPS_REQUEST:
      return { ...state, loading: true }
    case FETCH_POPUPS_SUCCESS:
      return { ...state, loading: false, data: action.payload }
    case FETCH_POPUPS_FAILURE:
      return { ...state, loading: false, error: action.payload }
    default:
      return state
  }
}

export default popupsReducer