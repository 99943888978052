import { useState, useContext } from "react"
import _ from "lodash"
import moment from "moment"
import { Image, Row, Col, Button } from "react-bootstrap"
import "./styles.scss"
import DefaultAvatar from "../../../../images/avatar-app.png"
import { calculateAge } from "../../../../utils"
import Like from "../../../../icons/good.png"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import {
  updateFollowUserRequest,
  fetchAuthProfileRequest
} from "../../../../actions"
import AppContext from "../../../../Context"
import { LoadingIndicator } from "../../../../components/LoadingIndicator"
import { statuses } from "../../../../constants/follow"
import routes from "../../../../constants/routes"
import messages from "../../../../constants/messages"
import { getShowMatchConfirmation } from "../../../../utils/persist"

const getActionFallback = (action, status) => {
  switch (action.followState) {
    case statuses.NEW:
      return { message: messages.likeUserSuccess }

    case statuses.FOLLOW_ME:
      return {
        message: action.state
          ? messages.thanksUserSuccess
          : messages.thankUserDetail,
        callback: (state, history, flag) => {
          if (state && !flag) history.push(routes.MATCH)
        }
      }

    case statuses.FOLLOW_SKIP:
      if (action.state) {
        return {
          message: messages.thanksUserSuccess,
          callback: (state, history, flag) => {
            if (state && !flag) history.push(routes.MATCH)
          }
        }
      }
      return { message: messages.thanksUserSuccess, cacllback: () => {} }

    default:
      return {}
  }
}

export const UserDetail = ({ user, children, onAvatarClick, onRightClick }) => {
  const { setContext } = useContext(AppContext)
  const history = useHistory()
  const dispatch = useDispatch()
  const [alert, setAlert] = useState(null)

  const auth = useSelector((state) => {
    return state.auth.profile
  })

  const calculateMatchStatus = () => {
    // if (user.matching_status === 3) {
    //   return statuses.MATCHED
    // } else if (user.is_liked) {
    //   return statuses.FOLLOW_UP
    // }

    // switch (user.matching_status) {
    //   case 0:
    //     return statuses.FOLLOW_ME
    //   case 2:
    //     return statuses.FOLLOW_SKIP
    //   case null:
    //   default:
    //     return statuses.NEW
    // }
    return statuses.NEW
  }

  const matchStatus = calculateMatchStatus()

  const handleFollowAction = (state, likeType) => {
    setContext("overlay", <LoadingIndicator />)

    dispatch(
      fetchAuthProfileRequest(
        {},
        {
          onSuccess: () => {
            setContext("overlay", null)

            if (matchStatus === statuses.MATCHED) {
              history.push(routes.MATCH)
              return
            }

            setContext("overlay", <LoadingIndicator />)

            const fallback = getActionFallback({
              followState: matchStatus,
              state
            })

            dispatch(
              updateFollowUserRequest(
                {
                  id: user?.user_id,
                  followState: matchStatus,
                  state,
                  likeType: likeType
                },
                {
                  onSuccess: (response) => {
                    if (fallback.message) {
                      setAlert(fallback.message)
                      setTimeout(() => {
                        const persist = getShowMatchConfirmation()
                        const persistUid = _.get(persist, "uid", false)
                        const persistFlag = _.get(persist, "flag", false)

                        fallback.callback &&
                          fallback.callback(
                            state,
                            history,
                            auth.user_id === persistUid ? persistFlag : false
                          )
                        setContext("overlay", null)
                        dispatch(fetchAuthProfileRequest())
                        history.push(routes.SCHEDULE)
                      }, 2000)
                    }
                    if (!fallback.message || !fallback.callback) {
                      setContext("overlay", null)
                    }
                  },
                  onFailed: () => {
                    setAlert(messages.somethingWrong)
                    setTimeout(() => {
                      setContext("overlay", null)
                    }, 2000)
                  }
                }
              )
            )
          },
          onFailed: () => {
            setContext("overlay", null)
          }
        }
      )
    )
  }

  const typeOfHouse = () => {
    switch (user?.type_of_hour) {
      case 1:
        return "午前中"
      case 2:
        return "昼"
      case 3:
        return "午後"
      case 4:
        return "夕方"
      case 5:
        return "夜"
      case 6:
        return "深夜"
      default:
        break
    }
  }

  return (
    <div className="yu-gothic border-1 mt-3">
      <div className="d-flex px-2">
        <Col
          xs={2}
          md={2}
          className="d-flex justify-content-start align-items-center wrap-avatar"
          style={{ position: "relative" }}
        >
          <Image
            className={`img ${
              user?.payment_type === 4
                ? "vip_4"
                : user?.payment_type === 3
                ? "vip_3"
                : user?.payment_type === 2
                ? "vip_2"
                : ""
            }`}
            style={{ cursor: "pointer" }}
            height={60}
            width={60}
            src={user?.imagePaths || DefaultAvatar}
            onClick={() => onAvatarClick && onAvatarClick()}
          />
          {(user?.payment_type === 4 ||
            user?.payment_type === 3 ||
            user?.payment_type === 2) && (
            <p
              className="vip"
              style={{
                backgroundColor:
                  user?.payment_type === 4
                    ? "#00205D"
                    : user?.payment_type === 3
                    ? "#5E08B4"
                    : user?.payment_type === 2
                    ? "#35ADD9"
                    : "black",
                top: 24,
                left: 12
              }}
            >
              VIP
            </p>
          )}
        </Col>
        <Col
          xs={10}
          md={10}
          className="py-1 d-flex flex-row justify-content-between mt-4"
        >
          <Row
            style={{ width: "100%" }}
            onClick={() => {
              onRightClick && onRightClick()
            }}
          >
            <Col>
              <Row>
                <Col className="d-flex justify-content-between">
                  <div className="overflow d-flex align-items-center">
                    <span className="overflow status-label fs-8">
                      {user?.purpose || ""}
                    </span>
                  </div>
                </Col>
                <Col>
                  <div className="d-flex align-items-center float-right text-black-bold">
                    <span className="overflow fs-8">
                      {moment(user?.date).format("MM月DD日")} {typeOfHouse()}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-between">
                  <div className="overflow d-flex align-items-center">
                    <span className="overflow position-label fs-8">
                      {user?.title || ""}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-between">
                  <div className="overflow d-flex align-items-center">
                    <span className="overflow yu-gothic-bold fs-8">
                      {user?.nickname || ""}
                    </span>
                    <span className="overflow mx-2 fs-8">
                      {user?.date_of_birth
                        ? `${calculateAge(user?.date_of_birth)}歳`
                        : `${user?.age}歳`}
                      {user?.residence_id ? "｜" : ""}
                      {user?.residence_id || ""}
                      {user?.occupation_id ? "｜" : ""}
                      {user?.occupation_id || ""}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row className="mt-3 align-items-end">
                <Col>{children}</Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </div>
      <div className="overflow d-flex align-items-center px-3 pb-3">
        <span className="overflow" style={{ fontSize: 14 }}>
          {user?.content}
        </span>
      </div>
      {auth.sex !== user?.sex && (
        <div className="overflow d-flex align-items-center mt-5 justify-content-center wrap-button-like">
          {user?.is_matched ? (
            <Button
              onClick={() => {
                history.push(`${routes.PRIVATE_CHAT}/${user?.user_id}`)
              }}
              className="align-self-center align-items-center d-flex button-chat"
              variant="danger"
              type="submit"
              size="xl"
            >
              メッセージを送る
            </Button>
          ) : (
            <Button
              onClick={(event) => {
                handleFollowAction(event, 1)
              }}
              className="align-self-center align-items-center d-flex"
              variant="danger"
              type="submit"
              size="xl"
              disabled={user?.is_liked === 1 ? true : false}
            >
              <Image className="" src={Like} />
              <p className="my-0">
                {user?.is_liked === 1 ? "気にしました" : "気になる"}
              </p>
            </Button>
          )}
        </div>
      )}
    </div>
  )
}
