import * as actionTypes from "../constants/actions"

const initialState = {
  data: null
}

const mutedReducer = (state = initialState, action) => {
  let data = null
  switch (action.type) {
    case actionTypes.FETCH_DATA_MUTED:
      return {
        data
      }

    case actionTypes.FETCH_DATA_MUTED_SUCCESS:
      data = action.payload.data
      return {
        data
      }

    case actionTypes.FETCH_DATA_MUTED_FAIL:
      return {
        data
      }

    case actionTypes.UNMUTE_USER:
      return {
        data
      }

    default:
      return state
  }
}

export default mutedReducer
