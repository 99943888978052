import AppBar from "../../../../components/AppBar"
import Layout from "../../../../components/Layout"

const PrivacyPolicy = () => {
  return (
    <Layout>
      <AppBar title="プライバシーポリシー" withBack />
      <div
        className="bg-white d-flex flex-column px-3 wrap-child-setting"
      >
        <p>
        &ensp;株式会社DaDa（以下、「弊社」といいます）は、弊社サービス（以下、「本サービス」といいます）を利用する個人（以下、「ユーザ」という）の個人情報を保護することが弊社の重要な責務であると認識し、個人情報の保護に関する法律（以下、「個人情報保護法」といいます）その他の関係法令・ガイドライン等を遵守するとともに、弊社がここに定めるプライバシーポリシー（以下「本プライバシーポリシー」といいます）に則り、個人情報の収集や取り扱いに細心の注意を払います。。
        </p>

        <p>
          &ensp;<strong>１．個人情報とは</strong>
          <br />
          &ensp;本プライバシーポリシーにおいて「個人情報」とは、個人情報保護法に定義された個人情報をいい、ユーザ個人を識別できる情報のほか、他の情報と容易に照合することができ、それにより特定の個人を識別することができる情報を含みます。なお、本プライバシーポリシーおける個人情報には、氏名、住所、電話番号、メールアドレス、銀行口座番号を含むものとします。本サービスでは、ユーザが個人情報を送信することを了承した場合にのみ、それらの情報が収集されます。
        </p>

        <p>
          &ensp;<strong>２．個人情報の利用目的</strong>
          <br />
          &ensp;本サービスでは、ご利用いただくにあたって、個人情報を登録していただく場合があります。登録いただいたユーザの個人情報（保有個人データを含みます）は、以下に掲げる目的のために限定して利用し、弊社はその情報を厳重に管理します。
          <br />
          <br />
          &emsp;本サービスを提供・管理・運営するため（興味や好み、閲覧のパターンについてのユーザのプロフィールを作成し、ユーザが本サービスに参加できるようにするために、当社がこの情報を利用することを含みます）
          <br />
          &emsp;ユーザが本サービスを利用するために、弊社と必要な連絡をするため
          <br />
          &emsp;本サービスを運営するために、個人の特定されない統計データを収集し、アンケートを作成･送付するため
          <br />
          &emsp;安全かつ安心な取引を実現するために、エスクローサービスを提供するため
          <br />
          &emsp;安全かつ安心な取引を実現するために、本人確認サービスを提供するため
          <br />
          &emsp;信頼性を向上するために、電話確認サービスを提供するため
          <br />
          &emsp;信頼性を向上するために、ユーザランクサービスを提供するため
          <br />
          &emsp;ユーザからのお問い合わせに返答するため
          <br />
          &emsp;その他、ユーザの同意を得た目的のため
          <br />
          <br />
          上記以外に個人情報の利用の必要がある場含は、ユーザの皆様方に、事前にその旨を連絡させて頂きます。
        </p>

        <p>
          &ensp;<strong>３．個人情報の第三者提供</strong>
          <br />
          &ensp;弊社がユーザの個人情報を利用し、または第三者（日本国外にある第三者を含みます）に開示するのは、下記の場合に限ります。
          <br />
          <br />
          &emsp;ユーザが同意した場合
          <br />
          &emsp;弊社が利用目的の達成に必要な範囲において個人情報の取扱いの全部または一部を委託する場合
          <br />
          &emsp;合併その他の事由による事業の承継に伴って個人情報が提供される場合
          <br />
          &emsp;ユーザその他の第三者の生命・身体・財産等の重大な利益を保護するために必要な場合
          <br />
          &emsp;法令で認められる範囲内において、公共の利益の保護または弊社もしくは個人情報を開示する対象となる第三者の権限行使のために必要な場合
          <br />
          &emsp;弊社が、ユーザの属性の集計、分析を行い、個人が識別・特定できないように加工したもの（以下、「統計資料」という）を作成し、新規サービスの開発等の業務遂行のために利用、処理する場合。また、弊社が、統計資料を業務提携先等に提供する場合
          <br />
          &emsp;法令で認められる範囲内において、弊社及び個人情報の開示の対象となる第三者その他当事者の利益のため必要な場合
          <br />
          &emsp;個人情報の開示が裁判所、検察庁もしくは行政機関の命令に基づく場含、または、警察等の行政機関から捜査権限を定める法令に基づき正式な照会を受けた場合、または、弁護士法23条の2等の法令に基づき開示を求める場合
        </p>

        <p>
          &ensp;<strong>４．個人関連情報の結合</strong>
          <br />
          &ensp;弊社は、広告事業者からユーザに関する個人関連情報を受領した場合、これを当社が保有するユーザーの個人情報と結合の上、当該個人情報がどのユーザに関するものであるかを特定の上で利用します。
        </p>

        <p>
          &ensp;<strong>５．クッキーの利用</strong>
          <br />
          &ensp;弊社は、本サービスのコンテンツの提供を管理・維持したり、新しいサービスを検討したり、ログイン情報管理や広告配信のために端末情報をクッキーにて利用しています。ユーザがクッキーの受入を拒否する場合、本サービスの一部が利用できないことがあります。
        </p>

        <p>
          &ensp;<strong>６．プライバシーポリシーの変更・改定について</strong>
          <br />
          &ensp;弊社は、必要に応じて、プライバシーポリシーの全部または一部を変更し、改定することができるものとします。なお、変更・改定に際しては、変更・改定の効力発生日を定め、変更・改定の内容および効力発生日を事前に本サイトに掲載する等の方法でユーザに告知するものとします。プライバシーポリシーの変更・改定は、弊社が定めた効力発生日に発効します。
        </p>

        <p>
          &ensp;<strong>７．個人情報の安全管理について</strong>
          <br />
          &emsp;弊社は、内部規定・体制整備等の組織的安全管理措置、従業者の教育等の人的安全管理措置、盗難防止等の物理的安全管理措置、アクセス制御等の技術的安全管理措置など、個人情報の安全管理のために必要かつ適切な措置を講じます。
          <br />
          &emsp;弊社は、利用目的に必要な範囲内で、個人情報の保存期間を定め保有いたします。
        </p>

        <p>
          &ensp;<strong>８．個人情報の取扱いに課する質問及び苦情等の窓口</strong>
          <br />
          &emsp;弊社は、ユーザご本人から自己の個人情報についての開示の請求がある場合、速やかに開示をいたします。個人情報の内容に誤りがあり、ご本人から訂正・追加・利用停止・削除・第三者への提供記録の開示等の請求がある場合、調査の上、速やかにこれらの請求に対応いたします。それらの請求の際、ご本人であることが確認できない場合、または、法令に定めのあるに基づく理由がある場合（開示・訂正・追加・利用停止・削除等の義務を負わない場合を含みます）には、これらの請求に応じません。
          <br />
          &emsp;上記の請求のほか、弊社の個人情報の取扱いに関するご相談や苦情、異議等のお問い合わせについては、下記の窓口までご連絡ください。
          <br />
          &emsp;dm@tu-tu.jp
        </p>
      </div>
    </Layout>
  )
}

export default PrivacyPolicy
