import classNames from "classnames"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons"

import "./styles.scss"
import DefaultAvatar from "../../images/avatar-app.png"

const Item = ({ user, onClick, height }) => {
  return (
    <div onClick={() => onClick(user.user_id)} className="item mb-2 d-flex">
      <div
        className={classNames("field field-status", {
          "field-status-online": user.login_status === 3,
          "field-status-away": user.login_status === 1,
          "field-status-offline":
            user.login_status !== 3 && user.login_status !== 1
        })}
      ></div>
      <div className="field field-avatar in-list">
        {user.is_new === 1 && (
          <p className="new" style={{ bottom: user.biography ? 3 : -8 }}>
            NEW
          </p>
        )}

        <img
          className={`avatar ${
            user.contract?.plan_type === 4
              ? "vip_4"
              : user.contract?.plan_type === 3
              ? "vip_3"
              : user.contract?.plan_type === 2
              ? "vip_2"
              : ""
          }`}
          src={user.image_url || DefaultAvatar}
          alt={user.nickname}
        />

        {(user.contract?.plan_type === 4 ||
          user.contract?.plan_type === 3 ||
          user.contract?.plan_type === 2) && (
          <p
            className="vip"
            style={{
              backgroundColor:
                user.contract?.plan_type === 4
                  ? "#00205D"
                  : user.contract?.plan_type === 3
                  ? "#5E08B4"
                  : user.contract?.plan_type === 2
                  ? "#35ADD9"
                  : "black"
            }}
          >
            VIP
          </p>
        )}
      </div>
      <div className="fields field-group field-group-info fw-bold my-1 in-list px-2">
        <div className="field field-nickname text-truncate in-list">
          {user.nickname}
        </div>
        <div className="field field-age">({user.age}歳)</div>
        <div className="field field-age">{user?.occupation}</div>
        {user?.residence ? (
          <div className="field field-residence in-list">
            <FontAwesomeIcon
              className="field field-residence icon-map"
              icon={faMapMarkerAlt}
            />
            {user.residence}
          </div>
        ) : (
          ""
        )}
        <div className="field field-bio clearfix">{user.biography}</div>
      </div>
    </div>
  )
}

export default Item
