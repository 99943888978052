import { Button } from "react-bootstrap"
import { faHeart } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { UserItem } from "../../../../components/UserItem"

export const Item = ({
  user,
  onThank,
  onAvatarClick,
  onRightClick,
  onMark,
  onBlock,
  onLabel
}) => {
  return (
    <UserItem
      user={user}
      onAvatarClick={onAvatarClick}
      onRightClick={onRightClick}
      onMark={onMark}
      onBlock={onBlock}
      onLabel={onLabel}
    >
      <div className="d-flex justify-content-end yu-gothic-bold">
        <Button
          style={{ fontSize: 9, padding: 4, borderRadius: 4 }}
          variant="danger"
          size="sm"
          onClick={() => onThank(user)}
        >
          <FontAwesomeIcon icon={faHeart} style={{ marginRight: 4 }} />
          マッチする
        </Button>
      </div>
    </UserItem>
  )
}
