import { Image, Button } from "react-bootstrap"
import HeartIcon from "../../../../icons/ic_bottom_heart@3x.png"

export const EmptyFirstTab = ({ onClick }) => {
  return (
    <div className="h-100 p-3 d-flex flex-column justify-content-center align-items-center">
      <Image src={HeartIcon} className="mb-4" />
      <div className="h5 yu-gothic-regular">いいねをしてくれた</div>
      <div className="h5 mb-4 yu-gothic-regular">
        新着のお相手はまだいません。
      </div>
      <Button
        variant="danger"
        className="w-100 yu-gothic-bold"
        onClick={onClick}
      >
        <span className="h5"> お相手を探す </span>
      </Button>
    </div>
  )
}
