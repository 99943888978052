import {
  MUTE_USER_REQUESTED,
  MUTE_USER_SUCCESS,
  MUTE_USER_FAILED,
} from "../constants/actions/mute"

export const muteUserRequested = (data = {}, callbacks = {}) => ({
  type: MUTE_USER_REQUESTED,
  payload: {
    data: data,
  },
  callbacks,
})

export const muteUserSuccess = (data) => ({
  type: MUTE_USER_SUCCESS,
  data,
})

export const muteUserFailed = () => ({
  type: MUTE_USER_FAILED,
})
