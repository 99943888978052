import {
  GET_RESIDENCE_FAILED,
  GET_RESIDENCE_SUCCESS,
  GET_RESIDENCE_REQUEST,
  FILTER_USERS_REQUEST,
  FILTER_USERS_SUCCESS,
  FILTER_USERS_FAILED,
  GET_FILTER_OPTIONS_FAILED,
  GET_FILTER_OPTIONS_SUCCESS,
  GET_FILTER_OPTIONS_REQUEST,
  SELECT_USER,
  FETCH_RELATED_USERS_REQUESTED,
  FETCH_RELATED_USERS_SUCCESS,
  FETCH_RELATED_USERS_FAILED
} from "../constants/actions/filter"

export const getResidencesRequest = (data = {}, callbacks) => {
  return {
    type: GET_RESIDENCE_REQUEST,
    payload: {
      data,
      callbacks: callbacks || {}
    }
  }
}
export const getResidencesSuccess = (data) => {
  return {
    type: GET_RESIDENCE_SUCCESS,
    data
  }
}

export const getResidencesFailed = () => {
  return {
    type: GET_RESIDENCE_FAILED
  }
}

export const filterUsersRequest = (data = {}, callbacks) => {
  if (data['sort'] == null || data['sort'] == undefined) {
    data['sort'] = ''
  }
  for (var key in data) {
    if (data.hasOwnProperty(key)) {
      var value = data[key]
      if (value == "こだわらない") {
        data[key] = null
      } else {
        if ((value + "").split(",").indexOf("こだわらない") > -1) {
          data[key] = value.replace(/こだわらない,/g, "")
        }
      }
    }
  }
  return {
    type: FILTER_USERS_REQUEST,
    payload: {
      data,
      callbacks: callbacks || {}
    }
  }
}
export const filterUsersSuccess = (data) => {
  return {
    type: FILTER_USERS_SUCCESS,
    data
  }
}

export const filterUsersFailed = () => {
  return {
    type: FILTER_USERS_FAILED
  }
}

export const getFilterOptionsRequest = (data = {}, callbacks) => {
  return {
    type: GET_FILTER_OPTIONS_REQUEST,
    payload: {
      data,
      callbacks: callbacks || {}
    }
  }
}
export const getFilterOptionsSuccess = (data) => {
  return {
    type: GET_FILTER_OPTIONS_SUCCESS,
    data
  }
}

export const getFilterOptionsFailed = () => {
  return {
    type: GET_FILTER_OPTIONS_FAILED
  }
}

export const selectUser = (userId) => {
  return {
    type: SELECT_USER,
    payload: { userId }
  }
}

export const fetchRelatedUsersRequested = (data = {}, callbacks) => {
  if (data['sort'] == null || data['sort'] == undefined) {
    data['sort'] = ''
  }
  for (var key in data) {
    if (data.hasOwnProperty(key)) {
      var value = data[key]
      if (value == "こだわらない") {
        data[key] = null
      } else {
        if ((value + "").split(",").indexOf("こだわらない") > -1) {
          data[key] = value.replace(/こだわらない,/g, "")
        }
      }
    }
  }
  return {
    type: FETCH_RELATED_USERS_REQUESTED,
    payload: {
      data,
      callbacks: callbacks || {}
    }
  }
}
export const fetchRelatedUsersSuccess = (data) => {
  return {
    type: FETCH_RELATED_USERS_SUCCESS,
    data
  }
}

export const fetchRelatedUsersFailed = () => {
  return {
    type: FETCH_RELATED_USERS_FAILED
  }
}
