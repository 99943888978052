import { useSelector } from "react-redux"
import { useRef, useState, useEffect } from "react"

import "./styles.scss"

export const TopBanner = ({ setBannerHeight }) => {
  const banners = useSelector((state) => state.other.banners)
  const bannerRef = useRef(null)

  useEffect(() => {
    if (bannerRef.current) {
      const height = bannerRef.current.offsetHeight
      setBannerHeight(height)
    }
  }, [banners, setBannerHeight])

  const handleBannerClick = () => {
    const url = banners[0]?.banner_url
    if (url) {
      window.open(url)
    }
  }

  return (
    <div className="wrap-banner">
      {banners.length > 0 && (
        <img
          ref={bannerRef}
          src={banners[0]?.banner_image}
          alt="Banner"
          className="banner-image"
          onClick={handleBannerClick}
        />
      )}
    </div>
  )
}
