import birthday_cake from "./birthday_cake.png"
import boat from "./boat.png"
import car from "./car.png"
import firework from "./firework.png"
import flight from "./flight.png"
import flowers from "./flowers.png"
import god from "./god.png"
import spacex from "./spacex.png"
import wine from "./wine.png"
import wine_tree from "./wine_tree.png"
import rose from "./rose.png"
const GiftsImg = [
  rose,
  birthday_cake,
  wine,
  flowers,
  wine_tree,
  firework,
  car,
  boat,
  flight,
  spacex,
  god
]

export const GIFTS = {
  1: rose,
  2: birthday_cake,
  3: wine,
  4: flowers,
  5: wine_tree,
  6: firework,
  7: car,
  8: boat,
  9: flight,
  10: spacex,
  11: god
}

export default GiftsImg
