import { useEffect, useState, useCallback, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { Modal } from "react-bootstrap"
import ImgEndCall from "../images/img_end_call.svg"
import ImgPhone from "../images/img_phone.svg"
import ImgDefaultAvatar from "../images/avatar-app.png"
import {
  getRtmTokenRequested,
  setCaller,
  setCallee,
  setAgoraData,
  addDeviceIdFirebaseRequested,
  setIsCalling,
  setCallerUid,
  setLikeId,
  fetchDataListMuted,
  updateVideoCallRequested,
  updateVideoMessageRequested
} from "../actions"
import rtmClient from "../agora/agoraRTMClient"
import { IDENTITY_VERIFICATION_STATUS } from "../constants/env"
import {
  VIDEO_CALL_STATUS,
  MESSAGE_TYPES,
} from "../constants/env"

export const IncomingCallModal = () => {
  const dispatch = useDispatch()
  const userProfile = useSelector((state) => state.auth.profile)
  const deviceToken = useSelector((state) => state.auth.deviceToken)
  const caller = useSelector((state) => state.videoCall.caller)
  const history = useHistory()
  const myProfileRef = useRef(userProfile)
  const acceptClick = useRef(false)
  const refuseClick = useRef(false)
  const dataRef = useRef(null)
  const isCaller = useSelector((state) => state.videoCall.isCaller)
  const callee = useSelector((state) => state.videoCall.callee)
  const likeId = useSelector((state) => state.videoCall.likeId)
  const { token, channel, videoCallData } = useSelector(
    (state) => state.videoCall
  )
  useEffect(() => {
    if (videoCallData === null) {
      setTimeout(() => {
        onRemoteInvitationCancelled()
        dispatch(
          updateVideoCallRequested(channel, VIDEO_CALL_STATUS.ENDED, {
            onSuccess: (videoCallDoc) => {
              setTimeout(
                () => {
                  const msg = {
                    userId: caller?.user_id,
                    type: MESSAGE_TYPES.VIDEO_CALL,
                    // message: "",
                    isRead: 0,
                    imageUrl: null,
                    videoCallId: channel,
                    videoCallStatus: VIDEO_CALL_STATUS.ENDED,
                    videoCallStartDatetime: videoCallDoc?.startDatetime,
                    videoCallEndDatetime: videoCallDoc?.endDatetime
                  }
                  dispatch(updateVideoMessageRequested(likeId, null, msg))
                  rtmClient.setStatus("online")
                  if (isCaller) {
                    history.replace(`/private-chat/${callee.user_id}`)
                  } else {
                    history.replace(`/chats?tab=2`)
                  }
                },
                isCaller ? 0 : 3000
              )
            },
            onFailed: () => {}
          })
        )
      }, 180000);
    }
  }, [token, channel, videoCallData])

  const mutedUsersData = useSelector((state) => state?.muted?.data)
  const muteUserChat = mutedUsersData?.users?.filter(({ user_id }) => user_id === caller?.user_id);
  dataRef.current = mutedUsersData
  const [show, setShow] = useState(false)

  useEffect(() => {
    if(muteUserChat?.length > 0) {
      return false
    }
  }, [muteUserChat])

  useEffect(() => {
    myProfileRef.current = userProfile
  }, [userProfile.user_id])

  useEffect(() => {
    dispatch(fetchDataListMuted({ page: 1, perpage: 1000 }), {})
  }, [])
  const onRemoteInvitationReceived = (params) => {
    // dataRef.current = params;
    dispatch(fetchDataListMuted({ page: 1, perpage: 1000 }, {
      onSuccess: (response) => {
        onRemoteInvitationReceived2(params)
      },
      onFailed: () => {
        onRemoteInvitationReceived2(params)
      }
    }))
  }
  const onRemoteInvitationReceived2 = (params) => {
    const content = JSON.parse(params.content)
    const muteUserChat = dataRef.current?.users?.filter(({ user_id }) => user_id == content.caller_id);
    if (
      (myProfileRef.current.identity_verification_status !==
      IDENTITY_VERIFICATION_STATUS.APPROVED) || (muteUserChat?.length > 0)
    ) {
      rtmClient.refuseCall()
    } else {
      setShow(true)
      const content = JSON.parse(params.content)
      dispatch(
        setCaller(
          {
            user_id: content.caller_id,
            nickname: content.userName,
            avatarUrl: content.image_url
          },
          false
        )
      )
      dispatch(setCallee(myProfileRef.current, false))
      dispatch(setCallerUid(params.callerId))
      dispatch(setLikeId(content.likeId))
      dispatch(
        setAgoraData({
          token: content.token,
          channel_id: content.channelName
        })
      )
    }
  }
  const onRemoteInvitationFailed = (params) => {
    acceptClick.current = false
    refuseClick.current = false
    setShow(false)
  }
  const onRemoteInvitationCancelled = (params) => {
    acceptClick.current = false
    refuseClick.current = false
    setShow(false)
  }
  const onRemoteInvitationAccepted = (params) => {
    if (caller) {
      dispatch(setIsCalling(true))
      acceptClick.current = false
      refuseClick.current = false
      setShow(false)
      history.push(`/video-call/${caller.user_id}`)
    }
  }
  const onRemoteInvitationRefused = (params) => {
    acceptClick.current = false
    refuseClick.current = false
    setShow(false)
  }

  const setupListeners = useCallback(() => {
    rtmClient.on("RemoteInvitationReceived", onRemoteInvitationReceived)
    rtmClient.on("RemoteInvitationFailure", onRemoteInvitationFailed)
    rtmClient.on("RemoteInvitationCanceled", onRemoteInvitationCancelled)
    rtmClient.on("RemoteInvitationAccepted", onRemoteInvitationAccepted)
    rtmClient.on("RemoteInvitationRefused", onRemoteInvitationRefused)
  }, [caller])

  const cleanup = useCallback(() => {
    rtmClient.off("RemoteInvitationReceived", onRemoteInvitationReceived)
    rtmClient.off("RemoteInvitationFailure", onRemoteInvitationFailed)
    rtmClient.off("RemoteInvitationCanceled", onRemoteInvitationCancelled)
    rtmClient.off("RemoteInvitationAccepted", onRemoteInvitationAccepted)
    rtmClient.off("RemoteInvitationRefused", onRemoteInvitationRefused)
  }, [caller])

  useEffect(() => {
    setupListeners()
    return () => {
      cleanup()
    }
  }, [setupListeners])

  useEffect(() => {
    if (userProfile && userProfile.user_id > 0 && deviceToken) {
      dispatch(
        getRtmTokenRequested(deviceToken, {
          onSuccess: async (data) => {
            const deviceId = data.data.deviceId
            dispatch(
              addDeviceIdFirebaseRequested(userProfile.user_id, deviceId)
            )
            rtmClient.login(deviceId.toString(), data.data.rtmToken)
            rtmClient.on("ConnectionStateChanged", (params) => {
              console.log("connection state changed: ", params)
              //TODO: logout
            })
          },
          onFailed: (err) => {}
        })
      )
    }
  }, [userProfile.user_id, deviceToken])

  return (
    <Modal show={show} fullscreen>
      <div
        className="w-100 h-100 d-flex flex-column justify-content-around"
        style={{ backgroundColor: "#303038" }}
      >
        <div className="d-flex flex-column align-items-center">
          <img
            src={(caller && caller.avatarUrl) || ImgDefaultAvatar}
            alt="Not found"
            style={{
              height: "40vw",
              width: "40vw",
              objectFit: "cover",
              maxHeight: "15rem",
              maxWidth: "15rem",
              borderRadius: "1rem"
            }}
          />
          <div
            className="mt-4 yu-gothic-bold"
            style={{
              overflow: "hidden",
              maxWidth: "70vw",
              fontSize: "1.3rem",
              color: "#C5C5C5"
            }}
          >
            {caller ? caller.nickname : ""}
          </div>
          <div className="mt-3" style={{ color: "#C5C5C5" }}>
            通話呼び出し中
          </div>
        </div>

        <div className="d-flex justify-content-around w-100">
          <div
            className="d-flex flex-column align-items-center"
            style={{ color: "#C5C5C5" }}
          >
            <div
              className="call-btn call-btn-end"
              disabled={refuseClick.current}
              onClick={() => {
                refuseClick.current = true
                rtmClient.refuseCall()
              }}
            >
              <img src={ImgEndCall} alt="Not found" />
            </div>
            拒否
          </div>
          <div
            className="d-flex flex-column align-items-center"
            style={{ color: "#C5C5C5" }}
          >
            <div
              className="call-btn call-btn-phone"
              disabled={acceptClick.current}
              onClick={() => {
                acceptClick.current = true
                rtmClient.acceptCall()
              }}
            >
              <img src={ImgPhone} alt="Not found" />
            </div>
            応答
          </div>
        </div>
      </div>
    </Modal>
  )
}
