import moment from "moment"
import { Image, Row, Col } from "react-bootstrap"
import "./styles.scss"
import DefaultAvatar from "../../../../images/avatar-app.png"
import { calculateAge } from "../../../../utils"
import routes from "../../../../constants/routes"
import { useHistory } from "react-router-dom"

export const UserItem = ({ user, children, onAvatarClick, onRightClick }) => {
  // const [show, setShow] = useState(false)
  // const handleClose = () => {
  //   setShow(false)
  // }
  const history = useHistory()
  const handleClick = () => {
    history.push({
      pathname: `${routes.SCHEDULE}/${user.id}`,
      state: { user }
    })
  }

  const typeOfHouse = () => {
    switch (user.type_of_hour) {
      case 1:
        return "午前中"
      case 2:
        return "昼"
      case 3:
        return "午後"
      case 4:
        return "夕方"
      case 5:
        return "夜"
      case 6:
        return "深夜"
      default:
        break
    }
  }

  return (
    <div className="yu-gothic border-1 user-item" onClick={() => handleClick()}>
      <div className="d-flex px-2">
        <Col
          xs={2}
          md={2}
          className="d-flex justify-content-start align-items-center wrap-avatar"
          style={{ position: "relative" }}
        >
          <Image
            className={`img ${
              user?.payment_type === 4
                ? "vip_4"
                : user?.payment_type === 3
                ? "vip_3"
                : user?.payment_type === 2
                ? "vip_2"
                : ""
            }`}
            style={{ cursor: "pointer" }}
            height={60}
            width={60}
            src={user.imagePaths || DefaultAvatar}
            onClick={() => onAvatarClick && onAvatarClick()}
          />
          {(user?.payment_type === 4 ||
            user?.payment_type === 3 ||
            user?.payment_type === 2) && (
            <p
              className="vip"
              style={{
                backgroundColor:
                  user?.payment_type === 4
                    ? "#00205D"
                    : user?.payment_type === 3
                    ? "#5E08B4"
                    : user?.payment_type === 2
                    ? "#35ADD9"
                    : "black",
                top: 24,
                left: 12
              }}
            >
              VIP
            </p>
          )}
        </Col>
        <Col className="py-1 d-flex flex-row justify-content-between mt-4">
          <Row
            style={{ width: "100%" }}
            onClick={() => {
              onRightClick && onRightClick()
            }}
          >
            <Col>
              <Row>
                <Col className="d-flex justify-content-between">
                  <div className="overflow d-flex align-items-center">
                    <span className="overflow status-label fs-8">
                      {user?.purpose || ""}
                    </span>
                  </div>
                </Col>
                <Col>
                  <div className="d-flex align-items-center float-right text-black-bold">
                    <span className="fs-8">
                      {moment(user.date).format("MM月DD日")} {typeOfHouse()}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-between">
                  <div className="overflow d-flex align-items-center">
                    <span className="overflow position-label fs-8">
                      {user?.title || ""}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className="d-flex justify-content-between">
                  <div className="overflow d-flex align-items-center">
                    <span className="overflow yu-gothic-bold fs-8">
                      {user?.nickname || ""}
                    </span>
                    <span
                      className="overflow mx-2 fs-8"
                      style={{ fontSize: 14 }}
                    >
                      {user.date_of_birth
                        ? `${calculateAge(user.date_of_birth)}歳`
                        : `${user?.age}歳`}
                      {user.residence_id ? "｜" : ""}
                      {user.residence_id || ""}
                      {user.occupation_id ? "｜" : ""}
                      {user.occupation_id || ""}
                    </span>
                  </div>
                </Col>
              </Row>
              <Row className="mt-3 align-items-end">
                <Col>{children}</Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </div>
      <div className="overflow d-flex align-items-center px-3 pb-3">
        <span className="overflow fs-8 text-downline-schedule-list">
          {user?.content}
        </span>
      </div>
    </div>
  )
}
