export const GET_LIKERS_REQUESTED = "GET_LIKERS_REQUESTED"
export const GET_LIKERS_SUCCESS = "GET_LIKERS_SUCCESS"
export const GET_LIKERS_FAILED = "GET_LIKERS_FAILED"

export const GET_LIKED_USERS_REQUESTED = "GET_LIKED_USERS_REQUESTED"
export const GET_LIKED_USERS_SUCCESS = "GET_LIKED_USERS_SUCCESS"
export const GET_LIKED_USERS_FAILED = "GET_LIKED_USERS_FAILED"

export const SKIP_USER_REQUESTED = "SKIP_USER_REQUESTED"
export const SKIP_USER_SUCCESS = "SKIP_USER_SUCCESS"
export const SKIP_USER_FAILED = "SKIP_USER_FAILED"

export const THANK_USER_REQUESTED = "THANK_USER_REQUESTED"
export const THANK_USER_SUCCESS = "THANK_USER_SUCCESS"
export const THANK_USER_FAILED = "THANK_USER_FAILED"

export const MARK_READ_USER_REQUESTED = "MARK_READ_USER_REQUESTED"
export const MARK_READ_USER_SUCCESS = "MARK_READ_USER_SUCCESS"
export const MARK_READ_USER_FAILED = "MARK_READ_USER_FAILED"

export const LIKE_USER_FOOTPRINT_REQUESTED = "LIKE_USER_FOOTPRINT_REQUESTED"
export const LIKE_USER_FOOTPRINT_SUCCESS = "LIKE_USER_FOOTPRINT_SUCCESS"
export const LIKE_USER_FOOTPRINT_FAILED = "LIKE_USER_FOOTPRINT_FAILED"

export const LABEL_USER_REQUESTED = "LABEL_USER_REQUESTED"
export const LABEL_USER_SUCCESS = "LABEL_USER_SUCCESS"
export const LABEL_USER_FAILED = "LABEL_USER_FAILED"

export const PIN_USER_REQUESTED = "PIN_USER_REQUESTED"
export const PIN_USER_SUCCESS = "PIN_USER_SUCCESS"
export const PIN_USER_FAILED = "PIN_USER_FAILED"
