import React, { useState, useEffect } from "react"
import { useDispatch } from "react-redux"
import { Modal, Image, Button } from "react-bootstrap"
import { fetchPopupsRequest } from "../../actions/popups"
import htmlParser from "react-html-parser"
import PropTypes from "prop-types"

const CustomPopups = ({ openPopups, onClose, filteredData }) => {
  const dispatch = useDispatch()
  const [isModalDismissed, setIsModalDismissed] = useState(
    localStorage.getItem("modalDismissed") === "true"
  )
  const modalDismissedTime = localStorage.getItem("modalDismissedTime")
  const currentTime = new Date().getTime()
  const timeDifference = currentTime - modalDismissedTime

  useEffect(() => {
    if (timeDifference >= 48 * 60 * 60 * 1000) {
      dispatch(fetchPopupsRequest())
    }
  }, [dispatch])

  useEffect(() => {
    if (isModalDismissed && timeDifference < 48 * 60 * 60 * 1000) return
  }, [isModalDismissed, modalDismissedTime, timeDifference])

  const handleDismiss = () => {
    const currentTime = new Date().getTime()
    localStorage.setItem("modalDismissed", "true")
    localStorage.setItem("modalDismissedTime", currentTime)
    setIsModalDismissed(true)
    onClose()
  }

  const handleAccept = () => {
    onClose()
    window.open(filteredData?.button_url)
  }

  if (isModalDismissed && timeDifference < 48 * 60 * 60 * 1000) return null

  return (
    <Modal show={openPopups} centered>
      <Modal.Body className="p-0">
        <Image className="image" src={filteredData?.image_url} />
        <div className="py-3">
          <p className="my-3 text-center px-3">
            {htmlParser(filteredData?.content)}
          </p>
          <div className="d-grid justify-content-center gap-2 mt-3">
            <Button
              onClick={handleAccept}
              variant="danger"
              size="lg"
              className="btn fs-6 btn-update-schedule"
              style={{ width: 150 }}
            >
              {filteredData?.button_text}
            </Button>
            <button
              className="btn fs-6 primary"
              style={{ width: 150 }}
              onClick={handleDismiss}
            >
              スキップ
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

CustomPopups.propTypes = {
  onClose: PropTypes.func.isRequired,
  filteredData: PropTypes.object.isRequired
}

export default CustomPopups
