import ReactCrop from "react-image-crop"
import Button from "react-bootstrap/Button"
import "react-image-crop/dist/ReactCrop.css"
import Container from "react-bootstrap/Container"
import { useState, useRef, useCallback, useEffect } from "react"

import "./styles.scss"
import AppBar from "../../AppBar"
import Layout from "../../Layout"

const CropImages = ({ src, onCancel, onComplete }) => {
  const [upImg, setUpImg] = useState(src)
  const imgRef = useRef(null)
  const previewCanvasRef = useRef(null)
  const [crop, setCrop] = useState({ unit: "%", width: 100, aspect: 16 / 16 })
  const [completedCrop, setCompletedCrop] = useState(null)
  let imgCrop = ""

  const onLoad = useCallback((img) => {
    imgRef.current = img
  }, [])

  useEffect(() => {
    if (!completedCrop || !previewCanvasRef.current || !imgRef.current) {
      return
    }

    const image = imgRef.current
    const canvas = previewCanvasRef.current
    const crop = completedCrop

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const ctx = canvas.getContext("2d")
    const pixelRatio = window.devicePixelRatio

    canvas.width = crop.width * pixelRatio * scaleX
    canvas.height = crop.height * pixelRatio * scaleY

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0)
    ctx.imageSmoothingQuality = "high"

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    )
    imgCrop = canvas.toDataURL("image/jpeg")
  }, [completedCrop])

  const handleCancel = () => {
    if (onCancel) {
      onCancel(imgCrop)
    }
  }

  const handleComplete = () => {
    if (onComplete) {
      onComplete(imgCrop)
    }
  }

  return (
    <Layout>
      <AppBar title="画像を切り抜く" />
      <div className="bg-white d-flex flex-column full-height-with-navbar user-review user-review-fix">
        <div className="user-detail">
          <div className="user-fields">
            <ReactCrop
              src={upImg}
              imageStyle={{ width: window.outerWidth }}
              onImageLoaded={onLoad}
              crop={crop}
              onChange={(c) => setCrop(c)}
              onComplete={(c) => setCompletedCrop(c)}
            />
            <div className="d-none">
              <canvas
                ref={previewCanvasRef}
                style={{
                  width: Math.round(completedCrop?.width ?? 0),
                  height: Math.round(completedCrop?.height ?? 0)
                }}
              />
            </div>
          </div>

          <Container className="fixed-bottom">
            <div className="d-grid position-relative py-3 bg-white">
              <div className="row">
                <div className="col-6">
                  <Button
                    className="align-self-center col-12"
                    variant="secondary"
                    type="button"
                    onClick={() => handleCancel()}
                  >
                    戻る
                  </Button>
                </div>
                <div className="col-6">
                  <Button
                    className="align-self-center col-12"
                    variant="danger"
                    type="button"
                    onClick={() => handleComplete()}
                  >
                    完了
                  </Button>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
    </Layout>
  )
}

export default CropImages
