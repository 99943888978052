import * as actionTypes from "../constants/actions"

const initialState = {
  data: null,
  isLoading: false,
  schedule: [],
  scheduleDetail: [],
  mySchedule: {},
  updateSchedule: [],
  deleteSchedule: []
}

const scheduleReducer = (state = initialState, action) => {
  let data = null
  switch (action.type) {
    case actionTypes.SCHEDULE_LIST_REQUESTED:
      return {
        ...state,
        isLoading: true,
        schedule: [],
      }

    case actionTypes.SCHEDULE_LIST_SUCCESS:
      data = action.data
      return {
        ...state,
        isLoading: false,
        schedule: action?.data
      }

    case actionTypes.SCHEDULE_LIST_FAILED:
      return {
        isLoading: false,
        data
      }

    case actionTypes.MY_SCHEDULE_REQUESTED:
      return {
        ...state,
        isLoading: true,
      }

    case actionTypes.MY_SCHEDULE_SUCCESS:
      data = action.data
      return {
        ...state,
        isLoading: false,
        mySchedule: action?.data
      }

    case actionTypes.MY_SCHEDULE_FAILED:
      return {
        isLoading: false,
        data
      }

    case actionTypes.SCHEDULE_DETAIL_REQUESTED:
      return {
        isLoading: true,
        ...state
      }

    case actionTypes.SCHEDULE_DETAIL_SUCCESS:
      data = action.data
      return {
        isLoading: false,
        scheduleDetail: action.data
      }

    case actionTypes.SCHEDULE_DETAIL_FAILED:
      return {
        isLoading: false,
        data
      }

    case actionTypes.CREATE_SCHEDULE_REQUESTED:
      return {
        ...state,
        isLoading: true
      }

    case actionTypes.CREATE_SCHEDULE_SUCCESS:
      data = action.data
      return {
        schedule: action.data,
        isLoading: false
      }

    case actionTypes.CREATE_SCHEDULE_FAILED:
      return {
        data,
        isLoading: false
      }

    case actionTypes.EDIT_SCHEDULE_REQUESTED:
      return {
        ...state,
        isLoading: true
      }

    case actionTypes.EDIT_SCHEDULE_SUCCESS:
      data = action.data
      return {
        ...state,
        updateSchedule: action.data,
        isLoading: false
      }

    case actionTypes.EDIT_SCHEDULE_FAILED:
      return {
        data,
        isLoading: false
      }

    case actionTypes.DELETE_SCHEDULE_REQUESTED:
      return {
        ...state,
        isLoading: true
      }

    case actionTypes.DELETE_SCHEDULE_SUCCESS:
      data = action.data
      return {
        ...state,
        deleteSchedule: action.data,
        isLoading: false
      }

    case actionTypes.DELETE_SCHEDULE_FAILED:
      return {
        data,
        isLoading: false
      }

    default:
      return state
  }
}

export default scheduleReducer
