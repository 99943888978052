import _ from "lodash"
import { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import InfiniteScroll from "react-infinite-scroller"
import { useDispatch, useSelector } from "react-redux"

import "./styles.scss"
import Item from "./components/Item"
import routes from "../../../constants/routes"
import AppBar from "../../../components/AppBar"
import Layout from "../../../components/Layout"
import { EmptyList } from "./components/EmptyList"
import {
  fetchDataFootPrints,
  fetchDataFootUpdate
} from "../../../actions/other"
import { LoadingIndicator } from "../../../components/LoadingIndicator"

const FootPrint = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [listUser, setListUser] = useState([])
  const [countPage, setCountPage] = useState(1)
  const [hasNextPage, setHasNextPage] = useState(true)
  const [loading, setLoading] = useState(true)
  const perPage = 10

  const footPrintsData = useSelector((state) => state.footPrint?.data)

  useEffect(() => {
    let data = []
    if (footPrintsData) {
      data = listUser.concat(footPrintsData.users)
      setListUser(data)
      setLoading(false)
    }
  }, [footPrintsData])

  useEffect(() => {
    dispatch(fetchDataFootPrints({ page: countPage, perpage: perPage }))
    setListUser([])
  }, [])

  const loadData = () => {
    if (footPrintsData) {
      if (footPrintsData.users.length < perPage) {
        setHasNextPage(false)
        dispatch(fetchDataFootUpdate())
      } else {
        setCountPage(countPage + 1)
        dispatch(fetchDataFootPrints({ page: countPage + 1, perpage: perPage }))
      }
    }
    return false
  }

  const onItemClick = (id) => {
    history.push(`${routes.USERS}/${id}`)
  }

  return (
    <Layout>
      <AppBar title="足あと" withBack />
      <div className="bg-white d-flex flex-column full-height-with-navbar pt-2">
        {loading ? (
          <LoadingIndicator />
        ) : !_.isEmpty(listUser) ? (
          <div className="users">
            <InfiniteScroll
              pageStart={0}
              loadMore={loadData}
              hasMore={hasNextPage}
              loader={<LoadingIndicator key={0} sm />}
              useWindow={false}
            >
              {listUser?.map((user, index) => (
                <Item user={user} onClick={onItemClick} key={index} />
              ))}
            </InfiniteScroll>
          </div>
        ) : (
          <EmptyList />
        )}
      </div>
    </Layout>
  )
}

export default FootPrint
