export const observeFadeInElements = () => {
  const elements = document.querySelectorAll('.fadein');

  const observer = new IntersectionObserver((entries) => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        entry.target.classList.add('scrollin');
      } else {
        entry.target.classList.remove('scrollin');
      }
    });
  });

  elements.forEach(element => observer.observe(element));
};