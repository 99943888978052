import _ from "lodash"
import classNames from "classnames"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"
import Container from "react-bootstrap/Container"
import { useDispatch, useSelector } from "react-redux"
import { Modal, Image, Button } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Fragment, useState, useContext, useEffect } from "react"
import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons"

import "./styles.scss"
import Alert from "../Elements/Alert"
import AppContext from "../../Context"
import Ticker from "../Elements/Ticker"
import Select from "../Elements/Select"
import Like from "../../icons/good.png"
import routes from "../../constants/routes"
import messages from "../../constants/messages"
import { statuses } from "../../constants/follow"
import LeftArrow from "../../icons/ic_arrow_left.png"
import { LoadingIndicator } from "../LoadingIndicator"
import useWindowSize from "../../hooks/use-window-size"
import RightArrow from "../../icons/ic_arrow_right.png"
import { getShowMatchConfirmation } from "../../utils/persist"
import {
  onDataReport,
  muteUserRequested,
  updateFollowUserRequest,
  fetchAuthProfileRequest
  // selectUser
} from "../../actions"
import { ToastLikeIcon } from "./ToastLikeIcon"

const actionOptions = ["通報する", "ブロックする", "キャンセル"]

const getActionFallback = (action, status) => {
  switch (action.followState) {
    case statuses.NEW:
      return { message: messages.likeUserSuccess }

    case statuses.FOLLOW_ME:
      return {
        message: action.state
          ? messages.thanksUserSuccess
          : messages.thankUserDetail,
        callback: (state, history, flag) => {
          if (state && !flag) history.push(routes.MATCH)
        }
      }

    case statuses.FOLLOW_UP:
      return {
        message: messages.likeUserSuccess
      }

    case statuses.FOLLOW_SKIP:
      if (action.state) {
        return {
          message: messages.thanksUserSuccess,
          callback: (state, history, flag) => {
            if (state && !flag) history.push(routes.MATCH)
          }
        }
      }
      return { message: messages.thanksUserSuccess, cacllback: () => {} }

    default:
      return { message: messages.likeUserSuccess }
  }
}

const Detail = ({
  relate,
  user,
  menu,
  onHideMenu,
  swipeAble = false,
  hasNext,
  hasPrev,
  onSwipeLeft,
  onSwipeRight
}) => {
  const dispatch = useDispatch()
  const { setContext } = useContext(AppContext)
  const history = useHistory()
  const [alert, setAlert] = useState(null)
  const windowSize = useWindowSize()
  const [_heightAvatar, setHeightAvatar] = useState(0)
  const [_heightChildAvatar, setHeightChildAvatar] = useState(0)
  const [_showPopup, setShowPopup] = useState(false)
  const [browser, setBrowser] = useState("")
  const [modalLike, setModalLike] = useState(false)
  const [modalSuperLike, setModalSuperLike] = useState(false)

  const auth = useSelector((state) => {
    return state.auth.profile
  })

  const calculateMatchStatus = () => {
    if (user.matching_status === 3) {
      return statuses.MATCHED
    } else if (user.is_liked) {
      return statuses.FOLLOW_UP
    }

    switch (user.matching_status) {
      case 0:
        return statuses.FOLLOW_ME
      case 2:
        return statuses.FOLLOW_SKIP
      case null:
      default:
        return statuses.NEW
    }
  }

  const matchStatus = calculateMatchStatus()

  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleAction = (name, value) => {
    switch (actionOptions[value]) {
      case "通報する":
        setShowConfirm(true)
        setSelectOption(0)
        break

      case "ブロックする":
        setShowConfirm(true)
        setSelectOption(1)
        break

      case "キャンセル":
      default:
        onHideMenu && onHideMenu()
        break
    }
  }

  const [showConfirm, setShowConfirm] = useState(false)
  const [selectOption, setSelectOption] = useState(0)
  const handleCloseConfirm = () => {
    setShowConfirm(false)
  }

  const onReport = () => {
    dispatch(
      onDataReport(user.user_id, {
        onSuccess: (response) => {
          toast("通報しました")
          history.push(routes.USERS)
        },
        onFailed: () => {
          setContext("overlay", null)
          onHideMenu && onHideMenu()
        }
      })
    )
  }

  const onBlock = () => {
    setContext("overlay", <LoadingIndicator />)
    dispatch(
      muteUserRequested(
        { id: user.user_id },
        {
          onSuccess: (response) => {
            toast("ブロックしました")
            setContext("overlay", null)
            onHideMenu && onHideMenu()
            history.push(routes.USERS)
          },
          onFailed: () => {
            setContext("overlay", null)
            onHideMenu && onHideMenu()
          }
        }
      )
    )
  }

  const [message, setMessage] = useState(0)
  const handleSwitchModel = () => {
    if (message >= 2) {
      handleClose()
      setMessage(0)
    } else {
      setMessage(message + 1)
    }
  }

  const handleFollowAction = (state, likeType) => {
    setContext("overlay", <LoadingIndicator />)

    dispatch(
      fetchAuthProfileRequest(
        {},
        {
          onSuccess: () => {
            setContext("overlay", null)

            if (matchStatus === statuses.MATCHED) {
              history.push(routes.MATCH)
              return
            }

            setContext("overlay", <LoadingIndicator />)

            const fallback = getActionFallback({
              followState: matchStatus,
              state
            })
            dispatch(
              updateFollowUserRequest(
                {
                  id: user.user_id,
                  followState: matchStatus,
                  state,
                  likeType: likeType
                },
                {
                  onSuccess: (response) => {
                    if (fallback.message) {
                      if (likeType === 1) {
                        // toast("気にしました")
                        setModalLike(true)
                        setContext("overlay", null)
                      } else {
                        // toast("スーパー気にしました")
                        setModalSuperLike(true)
                        setContext("overlay", null)
                      }
                      setTimeout(() => {
                        const persist = getShowMatchConfirmation()
                        const persistUid = _.get(persist, "uid", false)
                        const persistFlag = _.get(persist, "flag", false)

                        fallback.callback &&
                          fallback.callback(
                            state,
                            history,
                            auth.user_id === persistUid ? persistFlag : false
                          )
                        setContext("overlay", null)
                        dispatch(fetchAuthProfileRequest())
                      }, 2000)
                    }
                    if (!fallback.message || !fallback.callback) {
                      setContext("overlay", null)
                    }
                  },
                  onFailed: () => {
                    setAlert(messages.somethingWrong)
                    setTimeout(() => {
                      setContext("overlay", null)
                    }, 2000)
                  }
                }
              )
            )
          },
          onFailed: () => {
            setContext("overlay", null)
          }
        }
      )
    )
  }

  const handleMatchAction = (state, likeType) => {
    setContext("overlay", <LoadingIndicator />)

    dispatch(
      fetchAuthProfileRequest(
        {},
        {
          onSuccess: () => {
            setContext("overlay", null)

            if (matchStatus === statuses.MATCHED) {
              history.push(routes.MATCH)
              return
            }

            setContext("overlay", <LoadingIndicator />)

            const fallback = getActionFallback({
              followState: matchStatus,
              state
            })
            dispatch(
              updateFollowUserRequest(
                {
                  id: user.user_id,
                  followState: matchStatus,
                  state,
                  likeType: likeType
                },
                {
                  onSuccess: (response) => {
                    if (fallback.message) {
                      if (likeType === 1) {
                        setContext("overlay", null)
                      } else {
                        setModalSuperLike(true)
                        setContext("overlay", null)
                      }
                      setTimeout(() => {
                        const persist = getShowMatchConfirmation()
                        const persistUid = _.get(persist, "uid", false)
                        const persistFlag = _.get(persist, "flag", false)

                        fallback.callback &&
                          fallback.callback(
                            state,
                            history,
                            auth.user_id === persistUid ? persistFlag : false
                          )
                        setContext("overlay", null)
                        dispatch(fetchAuthProfileRequest())
                      }, 2000)
                    }
                    if (!fallback.message || !fallback.callback) {
                      setContext("overlay", null)
                    }
                  },
                  onFailed: () => {
                    setAlert(messages.somethingWrong)
                    setTimeout(() => {
                      setContext("overlay", null)
                    }, 2000)
                  }
                }
              )
            )
          },
          onFailed: () => {
            setContext("overlay", null)
          }
        }
      )
    )
  }

  const handleRedirectChat = () => {
    if (user.user_id) {
      history.push(`/private-chat/${user.user_id}/`)
    } else if (user.id) {
      history.push(`/private-chat/${user.id}/`)
    }
  }

  const [activeAvatar, setActiveAvatar] = useState(0)

  useEffect(() => {
    let obj = document.querySelector(".field-avatar.main-avatar")
    if (obj != null) {
      setHeightAvatar(obj.clientWidth)
    }
    obj = document.querySelector(".field-avatar.child-avatar")
    if (obj != null) {
      setHeightChildAvatar(obj.clientWidth)
    }
  }, [windowSize])

  useEffect(() => {
    const userAgent = navigator.userAgent

    if (userAgent.includes("Chrome")) {
      setBrowser("Chrome")
    } else if (userAgent.includes("Firefox")) {
      setBrowser("Firefox")
    } else if (userAgent.includes("Safari")) {
      setBrowser("Safari")
    }
  }, [])

  return (
    <div className="user-detail container">
      <div className="user-detail-content">
        <div
          className="field field-avatar avatar-auto main-avatar rounded mb-3 d-flex justify-content-between align-items-center"
          style={{ height: _heightAvatar }}
        >
          {!!user.is_new === 0 && <Ticker size="large" />}
          {user.images && user.images[activeAvatar] && (
            <>
              {user.images[activeAvatar].type === 1 ? (
                <img
                  className="rounded avatar"
                  src={user.images[activeAvatar].image_url}
                  alt="avatar"
                  style={{ position: "absolute", top: 0, right: 0 }}
                />
              ) : (
                <>
                  {browser === "Chrome" ? (
                    <video
                      playsInline
                      autoPlay
                      loop
                      muted
                      className="rounded video"
                      alt="Avatar"
                    >
                      <source src={user.images[activeAvatar].image_url} />
                    </video>
                  ) : (
                    <video
                      poster={user.images[activeAvatar].image_url}
                      playsInline
                      autoPlay
                      loop
                      muted
                      className="rounded video"
                      alt="Avatar"
                    >
                      <source src={user.images[activeAvatar].image_url} />
                    </video>
                  )}
                </>
              )}
            </>
          )}
          {swipeAble ? (
            <img
              src={LeftArrow}
              alt=""
              style={{
                zIndex: 5,
                marginLeft: 7,
                position: "absolute",
                left: 0
              }}
              onClick={onSwipeRight}
            />
          ) : (
            <div />
          )}
          {swipeAble ? (
            <img
              src={RightArrow}
              alt=""
              style={{
                zIndex: 5,
                marginRight: 7,
                position: "absolute",
                right: 0
              }}
              onClick={onSwipeLeft}
            />
          ) : (
            <div />
          )}
        </div>
        <Container>
          <div className="user-fields">
            {!_.isEmpty(user.images) && (
              <div className="swipe-scroll not-swipe-user">
                {_.map(user.images, (item, indx) => (
                  <div
                    key={indx}
                    className={classNames(
                      "swipe-item field field-avatar child-avatar rounded",
                      { active: indx === activeAvatar }
                    )}
                    onClick={() => setActiveAvatar(indx)}
                    style={{ height: _heightChildAvatar }}
                  >
                    {item.type === 1 ? (
                      <img
                        className="rounded avatar not-swipe-user"
                        src={item.image_url}
                        alt="avatar"
                      />
                    ) : (
                      <>
                        {browser === "Chrome" ? (
                          <video
                            playsInline
                            autoPlay
                            loop
                            muted
                            className="rounded video"
                            alt="Avatar"
                          >
                            <source src={item?.image_url} />
                          </video>
                        ) : (
                          <video
                            poster={item?.image_url}
                            playsInline
                            autoPlay
                            loop
                            muted
                            className="rounded video"
                            alt="Avatar"
                          >
                            <source src={item?.image_url} />
                          </video>
                        )}
                      </>
                    )}
                  </div>
                ))}
              </div>
            )}
            <div className="fields field-group field-group-info field-label">
              <div className="field field-nickname cut-text">
                {user.nickname}
              </div>
              &nbsp;
              <div className="field field-residence">{user.age}歳</div>
              &nbsp;
              <div className="field field-residence">
                &nbsp;{_.get(user, "residence.name", null)}
              </div>
            </div>
            <div className="field field-memo clearfix">
              <p className="user-detail-bio">{user.biography}</p>
            </div>
            {!_.isEmpty(user.gif_url) && (
              <div className="field field-gift clearfix">
                <div className="field-label">GIF画像</div>
                <div className="swipe-scroll">
                  {_.map(user.gif_url, (gif, indx) => (
                    <div
                      key={indx}
                      className="swipe-item field field-avatar ratio ratio-1x1 rounded"
                    >
                      <img className="rounded" src={gif.image_url} alt="" />
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div className="field-group field-others clearfix">
              <div className="fields-wrapper">
                {user.condition && (
                  <div className="field field-age clearfix">
                    <div className="field-label">会う条件</div>
                    <div className="field-value">{user.condition}</div>
                  </div>
                )}
                {user.height && (
                  <div className="field field-residence clearfix">
                    <div className="field-label">身長</div>
                    <div className="field-value">{user.height}cm</div>
                  </div>
                )}
                {user.body && (
                  <div className="field field-occupation clearfix">
                    <div className="field-label">体型</div>
                    <div className="field-value">{user.body}</div>
                  </div>
                )}
                {user?.match_area_1 && (
                  <div className="field field-height clearfix">
                    <div className="field-label">希望エリア</div>
                    <div className="field-value">
                      {JSON?.parse(user.match_area_1)?.map((area, index) => (
                        <span key={index}>
                          {_.get(area, "name", null)}
                          {index < user.match_area_1.length - 1 && ", "}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
                {user.birthplace && (
                  <div className="field field-height clearfix">
                    <div className="field-label">出身地</div>
                    <div className="field-value">
                      {_.get(user, "birthplace.name", null)}
                    </div>
                  </div>
                )}
                {user.occupation && (
                  <div className="field field-figure clearfix">
                    <div className="field-label">職業</div>
                    <div className="field-value">
                      {_.get(user, "occupation.name", null)}
                    </div>
                  </div>
                )}
                {user.baby_aspiration && (
                  <div className="field field-birthplace clearfix">
                    <div className="field-label">子供の有無</div>
                    <div className="field-value">
                      {_.get(user, "baby_aspiration.name", null)}
                    </div>
                  </div>
                )}
                {user.personality && (
                  <div className="field field-educational_background clearfix">
                    <div className="field-label">自分の性格</div>
                    <div className="field-value">
                      {JSON.parse(user.personality).map((personality, idx) => {
                        return (
                          <div key={idx} className="point-item">
                            <div className="icon">
                              <span>{personality.name}</span>
                              <span className="point-slash">/</span>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}
                {user.hobby && (
                  <div className="field field-annual_income clearfix">
                    <div className="field-label">好きなこと・趣味</div>
                    <div className="field-value">{user.hobby}</div>
                  </div>
                )}
                {user.alcohol && (
                  <div className="field field-baby_aspiration clearfix">
                    <div className="field-label">お酒</div>
                    <div className="field-value">
                      {_.get(user, "alcohol.name", null)}
                    </div>
                  </div>
                )}
                {user.cigarette && (
                  <div className="field field-date clearfix">
                    <div className="field-label">タバコ</div>
                    <div className="field-value">
                      {_.get(user, "cigarette.name", null)}
                    </div>
                  </div>
                )}
                {user.holiday && (
                  <div className="field field-cigarette clearfix">
                    <div className="field-label">休日</div>
                    <div className="field-value">{user.holiday}</div>
                  </div>
                )}
              </div>
            </div>
          </div>
          
          {(user?.is_liked === 0 && user?.matching_status === null) ?
            <div className="wrap-button">
              <Button
                onClick={(event) => {
                  handleFollowAction(event, 1)
                }}
                className="align-self-center rounded align-items-center d-flex"
                variant="danger"
                type="submit"
                size="xl"
                disabled={user.is_liked === 1 || user.isSuperLiked}
              >
                <Image className="" src={Like} />
                <p className="my-0">
                  {user.is_liked === 1 ? "気にしました" : "気になる"}
                </p>
              </Button>
              <Button
                onClick={(event) => {
                  handleFollowAction(event, 2)
                }}
                className="align-self-center rounded align-items-center d-flex"
                variant="danger"
                type="submit"
                disabled={
                  auth.isUsedSuperLike ||
                  user.isSuperLiked ||
                  user.matching_status === 3
                }
              >
                <Image src={Like} /> <p className="my-0">スーパー<br />気になる</p>
              </Button>
            </div> :
            (user?.is_liked === 1 && user?.matching_status === 3) ?
            <div className="wrap-button-liked">
              <Button
                onClick={handleRedirectChat}
                className="align-self-center rounded align-items-center d-flex w-100 justify-content-center"
                variant="danger"
                type="submit"
                size="xl"
              >
                メッセージを送る
              </Button>
            </div> :
            <div className="wrap-button">
              <Button
                onClick={(event) => {
                  handleMatchAction(event, 1)
                }}
                className="align-self-center rounded align-items-center d-flex justify-content-center"
                variant="danger"
                type="submit"
                size="xl"
                disabled={user.is_liked === 1}
                style={{ width: user?.is_liked !== 1 ? '100%' : '45%' }}
              >
                <Image className="" src={Like} />
                <p className="my-0">マッチする</p>
              </Button>
              {user?.is_liked === 1 && <Button
                onClick={(event) => {
                  handleFollowAction(event, 2)
                }}
                className="align-self-center rounded align-items-center d-flex"
                variant="danger"
                type="submit"
                disabled={
                  auth.isUsedSuperLike ||
                  user.isSuperLiked ||
                  user.matching_status === 3
                }
              >
                <Image src={Like} /> <p className="my-0">スーパー<br />気になる</p>
              </Button>}
            </div>
          }
        </Container>
      </div>

      {menu && (
        <Select
          className="user-actions fixed-bottom"
          onChange={handleAction}
          options={actionOptions}
        />
      )}

      <ToastLikeIcon
        show={modalLike}
        content={"気になる"}
        onClose={() => setModalLike(false)}
      />
      <ToastLikeIcon
        show={modalSuperLike}
        content={"スーパー 気になる"}
        onClose={() => setModalSuperLike(false)}
      />

      <Modal show={show} onHide={handleClose} centered>
        <Modal.Body>
          {message === 0 && (
            <Fragment>
              <h3>スクリーンショットを検知しました。</h3>
              <p>
                お相手の内容など、他者に関する情報はインター
                ネットなどで公開しないようお願いします
              </p>
            </Fragment>
          )}
          {message === 1 && (
            <Fragment>
              <h3>いいね不足しています。</h3>
              <p>
                いいねが不足しています。ポイント購入は下記 よりご購入ください
              </p>
            </Fragment>
          )}
          {message === 2 && (
            <div className="text-center">
              <h3>いいねを押せる回数が増えました。</h3>
              <p>※30日に1回の間隔で、100いいねが追加されます。</p>
              <p className="mt-5">残いいね</p>
              <p>
                <FontAwesomeIcon
                  className="text-danger"
                  icon={faHeartSolid}
                  size="lg"
                />{" "}
                30
              </p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button
            className="text-danger border-0 py-0 disable-focus"
            variant="light"
            onClick={handleSwitchModel}
          >
            {message === 1 ? "ポイント購入はこちら" : "OK"}
          </Button>
        </Modal.Footer>
      </Modal>
      {alert && <Alert type="toast" message={alert} />}
      {_showPopup && auth.contract_type === 2 && (
        <>
          <div
            className="popup-like-fade"
            onClick={() => {
              setShowPopup(false)
            }}
          ></div>
          <div className="popup-like in-detail">
            <h2 className="text-center">いいねが足りません。</h2>
            <p>いいねの交換画面へ遷移しますか？</p>
            <div className="footer d-flex">
              <button
                className="btn text-danger w-50"
                onClick={() => history.push("/exchange")}
              >
                はい
              </button>
              <button className="btn w-50" onClick={() => setShowPopup(false)}>
                いいえ
              </button>
            </div>
          </div>
        </>
      )}
      {_showPopup && auth.contract_type === 1 && (
        <>
          <div
            className="popup-like-fade"
            onClick={() => {
              setShowPopup(false)
            }}
          ></div>
          <div className="popup-like in-detail">
            <h2 className="text-center">いいねが足りません。</h2>
            <p>有料プランへの登録ページへ遷移しますか？</p>
            <div className="footer d-flex">
              <button
                className="btn text-danger w-50"
                onClick={() => history.push("/plans")}
              >
                はい
              </button>
              <button className="btn w-50" onClick={() => setShowPopup(false)}>
                いいえ
              </button>
            </div>
          </div>
        </>
      )}
      <Modal
        show={showConfirm}
        onHide={handleCloseConfirm}
        centered
        dialogClassName="w-75 mx-auto"
      >
        <Modal.Body>
          <h6 className="text-center pt-3 pb-3">
            {selectOption === 0 ? "通報しますか？" : "本当にしますか？"}
          </h6>

          {selectOption === 0 ? (
            <button
              className="btn text-danger w-100 m-0 rounded-0 confirm-button"
              onClick={onReport}
            >
              通報する
            </button>
          ) : (
            <button
              className="btn text-danger w-100 m-0 rounded-0 confirm-button"
              onClick={onBlock}
            >
              ブロックする
            </button>
          )}
          <button
            className="btn w-100 m-0 rounded-0 confirm-button"
            onClick={handleCloseConfirm}
          >
            キャンセル
          </button>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default Detail
